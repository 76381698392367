import React, { useState, useEffect } from "react";
import HawkEyeAppBar from "../../components/AppBar";
import {
  Box,
  Divider,
  Typography,
  Grid,
  Chip,
  Button,
  Modal,
  Card,
  CardContent,
  Checkbox,
} from "@mui/material";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ListIcon from '@mui/icons-material/List';
import GetAppIcon from '@mui/icons-material/GetApp';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import instance from "../../globals/axios";
import { makeStyles } from "@mui/styles";


import WarehouseReciept from "../../components/WarehouseReciept";
import TestCertificate from "../../components/TestCertificate"

import 'react-phone-input-2/lib/material.css'
import { MuiOtpInput } from 'mui-one-time-password-input'
import axios from "axios";




const useStyles = makeStyles((theme) => ({
  rootVideo: {
    minWidth: 275,
  },
  titleVideo: {
    fontSize: 20,
  },
  buttonVideo: {
    marginTop: theme.spacing(2),
  },
  cardHeader: {
    backgroundColor: "#0073b1",
    color: "#fff",
    fontWeight: "bold",
  },
  cardBody: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dropdownWrapper: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  dropdown: {
    textDecoration: "underline",
    marginRight: 8,
  },
  expandIcon: {
    transform: "rotate(0deg)",
    transition: "transform 0.2s ease-in-out",
  },
  expandIconOpen: {
    transform: "rotate(180deg)",
  },
  dropdownContent: {
    marginTop: 16,
    paddingLeft: 16,
    // borderLeft: "1px solid #ccc",
  },
  outline: {
    borderRadius:"1rem",
    border:"2px solid #d8daed", 
    backgroundColor: "#fff"
  },
  customCard: {
    backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)',
    boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
    borderRadius: '1rem',
    border:"2px solid #d8daed", 
  },
  subtitleFont: {
    fontSize: '1.5rem',
    fontWeight: '600',
  }
}));


const LoanStatusDetails = (props) => {
  const myProp = props;
  const [tabvalue, setTabvalue] = useState(0);
  const classes = useStyles();
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [qrcode, setQrCode] = useState(false);
  const [loanStatus, setLoanStatus] = useState("");
  const [passData, setPassData] = useState({});
  const location = useLocation();
  const { data } = location.state;
  const [showMoreDetails, setShowMoreDetails] = useState(false);

  // Function to toggle dropdown state
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleScan = () => {
    setQrCode(!qrcode);
  };

  console.log(data)

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [tokenStatus, setTokenStatus] = useState("");
  const handleTokenVerification = (status) => {
    // Here you would add your code to handle the token verification
    setTokenStatus(status);
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  let popupMessage = "";
  if (tokenStatus === "accepted") {
    popupMessage = "Token Accepted";
  } else if (tokenStatus === "rejected") {
    popupMessage = "Token Rejected";
  }

  // const handleChange = (event, newValue) => {
  //   setTabvalue(newValue);
  // };
  const handleLoanStatus = (text) => {
    setLoanStatus(text);
    // window.location.reload();
    console.log("loan status", loanStatus);
  };

  const [loanId, setLoanId] = useState(data.loan_id)

  const [showOtpField, setShowOtpField] = useState(false);
  const [otp, setOtp] = useState('');

  const sendOTP = async () => {

    setTimeout(function () {
      setShowOtpField(true)
    }, 500);

  };

  const handleChange = (newValue) => {
    setOtp(newValue)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setPassData(data)
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const approveBody = {
    "loan_offer_id": loanId,
    "action": "approve"
  };

  const approveLoan = async () => {
    console.log("approve:",approveBody)

    try {      
      const response = await instance.post("loan/cm/approveLoanRequest", approveBody);
      console.log("ResponseData", response);
      alert(`Offer Approved succesfully`);
      setTimeout(function () {
        window.location.replace("/admin");
      }, 500);

    } catch (error) {
    console.log(error);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  // Function to open the modal
  const openModal = () => {
    setIsOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsOpen(false);
  };

    const [digitalCopyLink, setDigitalCopyLink] = useState(null);

    const handleClick = async () => {

      const wrNo = data.attributes.inward_details.wr_reciept_no;
      const url = `https://www.agrigates.com/gtsEntryForBlockChain.php?url=url_link&wr_no=${wrNo}`;

      axios.get(url)
        .then(response => {
          if (response.data && response.data.data && response.data.data.length > 0) {
            const rawLink = response.data.data[0].digital_copy_link;
            const formattedLink = rawLink.replace(/\\\//g, '/');
            setDigitalCopyLink(formattedLink);
            window.open(formattedLink, '_blank');

          } else {
            alert('Warehouse Receipt Digital Copy not found.');
          }
        })
        .catch(error => {
          console.error('Error:', error.message);
        });
    };

    return (
      <Box fullWidth>
        <HawkEyeAppBar />
        <Box mx='auto' sx={{ bgcolor: "background.paper", padding: "1.5rem 8rem", marginTop: "50px" }}>
          <Grid  mt="1rem">
            <Link to='/admin'>
              <Chip icon={<KeyboardBackspaceIcon />} style={{ cursor: "pointer" }} label='Admin' variant='outlined' />
            </Link>
          </Grid>
          <Divider />
          <Grid fullWidth>
          

            {
              showMoreDetails ? (
                <TestCertificate data={data} />
              ) :
              (
                <WarehouseReciept data={data} setShowMoreDetails={setShowMoreDetails} />
              )
            }


            <Grid className={classes.customCard} xs={12}>
                    <Box p={4}>
                      <Grid mb={1} container style={{ marginTop: "30px" }}>
                        <Grid xs={10} md={10}>
                          <Typography item xs={4} variant='h6' gutterBottom>
                            Loan Offer
                          </Typography>
                        </Grid>
                      </Grid>
                      <Card onClick={toggleDropdown}>
                        <CardContent>
                          <div>
                            <Grid container>
                              <Grid item md={8}>
                                <Typography style={{ fontWeight: 400, fontSize: "18px" }}>Quantity </Typography>
                              </Grid>
                              <Grid item md={4}>
                                <Typography style={{ fontWeight: 500, fontSize: "18px" }}>{data.balance} MT</Typography>
                              </Grid>
                            </Grid>
                            <Grid container style={{ marginTop: "10px" }}>
                              <Grid item md={8}>
                                <Typography style={{ fontWeight: 400, fontSize: "18px" }}>Rate of Interest </Typography>
                              </Grid>
                              <Grid item md={4}>
                                <Typography style={{ fontWeight: 500, fontSize: "18px" }}>{data.loan_offer_detail.interest_rate}%</Typography>
                              </Grid>
                            </Grid>
                            <Grid container style={{ marginTop: "10px" }}>
                              <Grid item md={8}>
                                <Typography style={{ fontWeight: 400, fontSize: "18px" }}>Market Value</Typography>
                              </Grid>
                              <Grid item md={4}>
                                <Typography style={{ fontWeight: 500, fontSize: "18px" }}>₹ {data.loan_offer_detail.market_value}</Typography>
                              </Grid>
                            </Grid>
                            <Grid container style={{ marginTop: "10px" }}>
                              <Grid item md={8}>
                                <Typography style={{ fontWeight: 400, fontSize: "18px" }}>Loan Amount</Typography>
                              </Grid>
                              <Grid item md={4}>
                                <Typography style={{ fontWeight: 500, fontSize: "18px" }}>₹ {data.loan_offer_detail.loan_amount}</Typography>
                              </Grid>
                            </Grid>
                          </div>
                          {/* <Divider /> */}
                        </CardContent>
                      </Card>
                      
                    </Box>
            </Grid>
    
            <Grid mx="auto" mt={4} mb={8} sx={{ display: "flex", justifyContent: "center"}}>
                
                 <Button variant="outlined" sx={{ mx:2, width:"15rem", fontSize: "1rem"}} color='primary' 
                  startIcon={<GetAppIcon />} 
                  onClick={handleClick}
                >
                  Download WR
                </Button>
    
                  <Button variant="contained" sx={{ mx:2, color:"#fff", width:"15rem", fontSize: "1rem"}} color='success' 
                  startIcon={<TaskAltIcon />}
                  onClick={() => {
                  
                    window.location.href = `/audit-trail/${data.attributes.inward_details.wr_reciept_no}`;
                  }}> 
                  Check Audit Trail
              </Button>
              

            { data.loan_status === "Accepted" &&


                <Button p={2} style={{ background: "#5A9A07", color: "white", width: "250px" }} onClick={openModal}>
                  <Typography fontSize={18}><b>Approve Loan Request</b>
                  </Typography> 
                </Button>

          }
              
                
              
                {
                  showMoreDetails ? (
                    <Button onClick={() => setShowMoreDetails(false)} variant="outlined" sx={{ mx:2, width:"15rem", fontSize: "1rem"}} outlined color='primary' startIcon={<KeyboardBackspaceIcon sx={{ fontSize: '1.75rem' }}/>}>
                    Show WR
                    </Button>
                  ) : (
                  <Button onClick={() => setShowMoreDetails(true)} variant="outlined" sx={{ mx:2, width:"15rem", fontSize: "1rem"}} outlined color='primary' endIcon={<ListIcon sx={{ fontSize: '1.75rem' }}/>}>
                    More Details
                  </Button>
                  )
                }
                
            </Grid>
    

            <Modal open={isOpen} onClose={closeModal}>
              <Box sx={{ position: 'absolute', top: '50%', left: '50%', borderRadius: 4, transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, maxWidth: 400 }}>
                <Typography variant="h6" component="h2" gutterBottom>
                  Approve Loan Offer
                </Typography>
                <Grid mt={2} container flex>
                  <Grid item xs={1}>
                  <Checkbox />

                  </Grid>
                  <Grid ml={2} mb={4} item xs={9}>
                    <Typography variant="body1">
                    I have read the loan details and I approve the loan offer on behalf of the farmer.
                    </Typography>
                  </Grid>
                  <Grid my="1rem" item xs={12}>
                    {showOtpField ? (
                        <Button variant="outlined" color="success" fullWidth>
                          Sent
                        </Button>
                      ) : (
                        <Button variant="contained" color="primary" onClick={sendOTP} fullWidth>
                          Send OTP
                        </Button>
                      )}
                    </Grid>
                    {showOtpField && (
                      <Grid item px={10} py={2}  mb="1.25rem" xs={12} >
                        {/* <TextField
                          label="OTP"
                          variant="outlined"
                          fullWidth
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        /> */}
                        <MuiOtpInput value={otp} onChange={handleChange} />
                      </Grid>
                    )}
                </Grid>
              
                {showOtpField ? (
                        <Button fullWidth style={{ background: "#5A9A07", color: "white", padding: "0.5rem" }} onClick={approveLoan}>   
                        Approve Offer
                      </Button>
                      ) : (
                        <Button fullWidth disabled>   
                        Approve Offer
                      </Button>
                      )}
              
              </Box>
            </Modal>


          </Grid>
        </Box>
      </Box>
    );
};

export default LoanStatusDetails;
