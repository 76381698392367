import React, { useEffect, useState, useRef } from "react";
import HawkEyeAppBar from "../../components/AppBar";
import { styled } from '@mui/material/styles';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { MenuList, ButtonBase, MenuItem, Divider, Container, Select, TextField, Button, Chip, CircularProgress } from "@mui/material";
import { Card, CardContent} from '@mui/material';
import { Link } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Autocomplete from '@mui/material/Autocomplete';
import instance from "../../globals/dashboard_instance";
import { Chart } from "react-google-charts";
import { convertToMillion, convertToCommaSeperated } from "../../globals/utils"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Stack from '@mui/material/Stack';
import dayjs from "dayjs";
import BankerDashboardAUMchart from "../../components/BankerDashboardAUMchart";
import BankerViewWRs from "../../components/BankerViewWR";
import RepaymentsAndDisbursementGraphs from "../../components/RepaymentsDisbursementGraphs";
import SearchBarCard from "../../components/SearchBarCard";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format } from 'date-fns';
import { subDays } from 'date-fns';
import ReportsComponent from "../../components/ReportsComponent";


import { makeStyles } from "@mui/styles";
import { BsNodeMinusFill } from "react-icons/bs";


    const StyledDataCell = styled('div')({
        textAlign: 'right',
        paddingRight: '20px', 
    });


    const Legend = ({ data, colors }) => (
        <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
            {data.map((status, index) => (
            <Box key={status} display="flex" alignItems="center" >
                <Box
                    width={16}
                    height={16}
                    marginRight={1}
                    borderRadius="50%"
                    bgcolor={colors[index]}
                />
                <Typography variant="body2">{status}</Typography>
            </Box>
            ))}
        </Stack>
    );


    const BankerAdminDashboard = () => {
        const useStyles = makeStyles(() => ({
            chipCustom: {
            margin: "0 1rem",
            borderRadius: "9px", //some style
            "& .MuiChip-label": { fontSize: 14, fontWeight: "bold" }, // sub-selector
            },
            chartContainer: {
                position: 'relative',
            },
            chart: {
                position: 'absolute',
                top: 0,
                left: 0,
            },
            glassCard: {
                background: 'rgba(255, 255, 255, 0.25)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255, 255, 255, 0.125)',
                boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
                backdropFilter: 'blur(4px)',
                borderRadius: '10px',
                border: '1px solid rgba(255, 255, 255, 0.18)',
              },
              appContainer: {
                padding: '20px',
              },
        }));

        const classes = useStyles();




        // new code 
        const [branches, setBranches] = useState([]);
        const [branchSelected, setBranchSelected] = useState("");
        const [showData, setShowData] = useState(false);
        const [bankerAumData, setBankerAumData] = useState({});
        const [closingPledgeChartData, setClosingPledgeChartData] = useState([]);
        const [leinBalanceQtyChartData, setLeinBalanceQtyChartData] = useState([]);
        const [interval, setInterval] = useState('daily');
        const [bankerOverviewLoading, setBankerOverviewLoading] = useState(false);

        const [openLoans, setOpenLoans] = useState(null);
        const [closedLoans, setClosedLoans] = useState(null);
        const [totalFundedAmt, setTotalFundedAmt] = useState("");
        const [totalRepaidAmt, setTotalRepaidAmt] = useState("");

        const [aumData, setAumData] = useState(null);
      

        const [pieData1, setPieData1] = useState([
            ["Status", "Loans"],
            ["Open Loans", null],
            ["Closed Loans", null],
        ]);
    
        const pieOptions1 = {
            // title: "Loans Distribution",
            pieHole: 0.45,
            is3D: true,
            colors: ['#4caf50', '#78909c'],
            legend: 'none',
                                    chartArea: {
                                      width: '90%',
                                      height: '70%',
                                    },
        };
  
        
        const [startDate, setStartDate] = useState(null);
        const [endDate, setEndDate] = useState(null);
    
        useEffect(() => {
            fetchGeneralData();
    
            instance.get('dashboard/branch_admin')
                .then(response => {
                    if (response.data.status === 'success') {
                        setBranches(response.data.data);
                    }
                })
                .catch(error => {
                    console.error('Error fetching branches:', error);
                });
        }, []);
    
        useEffect(() => {
            fetchBankerAumData();
        }, [ startDate, endDate]);

    
        const fetchGeneralData = async () => {        

            try {
                setBankerOverviewLoading(true)
              const response = await instance.get('dashboard/banker_view');
              const {
                open_loans,
                closed_loans,
                total_funded_amount,
                total_repaid_amount,
              } = response.data.data;

              setOpenLoans(open_loans)
              setClosedLoans(closed_loans)
              setTotalFundedAmt(total_funded_amount)
              setTotalRepaidAmt(total_repaid_amount)
      
              setPieData1([
                ["Status", "Loans"],
                ["Open Loans", open_loans],
                ["Closed Loans", closed_loans],
              ]);
              
              setBankerOverviewLoading(false)

            } catch (error) {
                setBankerOverviewLoading(false)

                console.error('Error fetching data:', error);
            }
        };
    
        const [ aumLoading, setAumLoading ] = useState(false);

        const fetchBankerAumData = async () => {
            try {
                setAumLoading(true);
                const today = new Date();
                const lastWeek = new Date();
                lastWeek.setDate(today.getDate() - 8);

                const formattedStartDate = format(lastWeek, 'yyyy-MM-dd');
                const formattedEndDate = format(today, 'yyyy-MM-dd');
        
                let response=null;
                // if (startDate === null || endDate === null) {
                //     response = await instance.get(`dashboard/banker_aum`);
                // } else {
                // }
                response = await instance.get(`dashboard/banker_aum?from_date=${formattedStartDate}&end_date=${formattedEndDate}`);

                setAumLoading(false);

                if (response.data.status === 'success') {
                    setAumLoading(false);
                    setBankerAumData(response.data.data);
                }
            } catch (error) {
                setAumLoading(false);
                console.error('Error fetching AUM data:', error);
            }
        };
    
        useEffect(() => {
          fetchBankerAumData();
        }, [])
        

        useEffect(() => {
            if (Object.keys(bankerAumData).length) {
                const dates = Object.keys(bankerAumData).sort();
                const closingPledgeDataArray = [['Date', 'Closing Pledge Value (in ₹)']];
                const leinBalanceQtyDataArray = [['Date', 'Lein Balance Qty (in MT)']];
    
                dates.forEach(date => {
                    const totalClosingPledgeValue = bankerAumData[date].reduce((sum, entry) => sum + entry.closing_pledge_value, 0);
                    const totalLeinBalanceQty = bankerAumData[date].reduce((sum, entry) => sum + entry.lein_balance_qty, 0);
                    closingPledgeDataArray.push([date, totalClosingPledgeValue]);
                    leinBalanceQtyDataArray.push([date, totalLeinBalanceQty]);
                });
    
                setClosingPledgeChartData(closingPledgeDataArray);
                setLeinBalanceQtyChartData(leinBalanceQtyDataArray);
            }
        }, [bankerAumData]);
    
        const handleBranchChange = (event, newValue) => {
            setBranchSelected(newValue);
        };
    
        const handleDateChange = (newDate, type) => {
            if (type === 'start') {
                setStartDate(newDate);
            } else {
                setEndDate(newDate);
            }
        };

        return (
            <Box fullWidth>
                <HawkEyeAppBar />

               

                <Paper sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px", borderRadius:"20px", mt:18, mb:6, mx:8, p:2, backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)'}}>
            
                    <Typography variant="h4" sx={{ px:2, py:1, fontWeight: 500}}>
                        Banker Dashboard 
                    </Typography>


                    <Divider sx={{ mt:2, mb:3, pr:2 }}/>

                    <Grid container mx="auto" spacing={4} sx={{ pl:2, py:2 }}>

                        {
                            ( !bankerOverviewLoading ) ? (

                                <React.Fragment>

                                <Grid xs={5} my="auto">
                                    <Grid sx={{ p:2, borderRadius:"1rem", border:"2px solid #d8daed", m:2, backgroundColor: "#fff", }}>
                                    <Typography textAlign="center" sx={{ mx:"auto", fontSize: "1.25rem", fontWeight: "500" }}>
                                    Token Distribution 
                                    <br/>
                                    </Typography>
                                    <Box>
                                        <Chart
                                            chartType="PieChart"
                                            // width="400px"
                                            // height="350px"
                                            width="19rem"
                                            height="20rem"
                                            data={pieData1}
                                            options={pieOptions1}
                                        />
                                    </Box>
                                    
                                    <Grid fullwidth p={2}>
                                        <Legend data={["Open Loans", "Closed Loans"]} colors={['#4caf50', '#78909c']} />
                                    </Grid>

                                </Grid>
                                </Grid>
                            
                                <Grid container sx={{ p:2 }} spacing={4} xs={7} >

                                <Grid sx={{ textAlign: "center"}} item xs={12} sm={6}>
                                    <Card sx={{ borderRadius: "22px"}} elevation={3} className={classes.glassCard}>
                                        <CardContent>
                                        <Typography variant="h6" component="div">
                                        Open Loans:
                                        </Typography>
                                        <Chip sx={{ fontWeight: 700,  marginTop: "10px"}} label={convertToCommaSeperated(openLoans)}/>

                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid sx={{ textAlign: "center"}} item xs={12} sm={6}>
                                    <Card sx={{ borderRadius: "22px"}} elevation={3} className={classes.glassCard}>
                                        <CardContent>
                                        <Typography variant="h6" component="div">
                                        Closed Loans:
                                        </Typography>
                                        
                                        <Chip sx={{ fontWeight: 700,  marginTop: "10px"}} label={convertToCommaSeperated(closedLoans)}/>

                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid sx={{ textAlign: "center"}} item xs={12} sm={6}>
                                    <Card sx={{ borderRadius: "22px"}} elevation={3} className={classes.glassCard}>
                                        <CardContent>
                                        <Typography variant="h6" component="div">
                                        Total Funded Amount:
                                        </Typography>
                                        
                                        <Chip sx={{ fontWeight: 700,  marginTop: "10px"}} label={convertToCommaSeperated(totalFundedAmt)} />
                                        
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid sx={{ textAlign: "center"}} item xs={12} sm={6}>
                                    <Card sx={{ borderRadius: "22px"}} elevation={3} className={classes.glassCard}>
                                        <CardContent>
                                        <Typography variant="h6" component="div">
                                        Total Repaid Amount:
                                        </Typography>
                                        
                                        <Chip sx={{ fontWeight: 700,  marginTop: "10px"}} label={convertToCommaSeperated(totalRepaidAmt)} />
                                        
                                        </CardContent>
                                    </Card>
                                </Grid>  

                                <Grid sx={{ textAlign: "center"}} item xs={12} sm={6}>
                                    <Card sx={{ borderRadius: "22px"}} elevation={3} className={classes.glassCard}>
                                        <CardContent>
                                        <Typography variant="h6" component="div">
                                        Total Outstanding Amount:
                                        </Typography>
                                        
                                        <Chip sx={{ fontWeight: 700,  marginTop: "10px"}} label={convertToCommaSeperated(totalFundedAmt - totalRepaidAmt)} />
                                        
                                        </CardContent>
                                    </Card>
                                </Grid>  
                                </Grid>
                                </React.Fragment>

                            ) : (
                                    <Grid sx={{ mx:"auto", my:4, alignItems:"center", justifyContent: "center"}} container>
                                        <CircularProgress />
                                    </Grid>                                
                            )
                        }
                    </Grid>
                    
                </Paper>

                
                <BankerDashboardAUMchart
                    leinBalanceQtyChartData={leinBalanceQtyChartData}
                    aumLoading={aumLoading}
                    closingPledgeChartData={closingPledgeChartData}
                /> 

                <Grid sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px", borderRadius:"20px", my:4, mx:8, p:4, backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)'}}>
                    <Grid sx={{ background: "#fff"}}>
                        <RepaymentsAndDisbursementGraphs />
                    </Grid>
                </Grid>
               
                <BankerViewWRs />

                <Grid sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px", borderRadius:"20px", my:4, mx:8, p:4, backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)'}}>
                    <Grid sx={{ zIndex: 4, borderRadius: "15px", background: "#fff"}}>
                        <SearchBarCard />
                    </Grid>
                </Grid>

                <Grid sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px", borderRadius:"20px", my:4, mx:8, p:4, backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)'}}>
                <Grid sx={{ zIndex: 4, borderRadius: "15px", background: "#fff"}}>                    
                <ReportsComponent />
                </Grid>
                </Grid>
                
            </Box>
        );
    };

export default BankerAdminDashboard;
