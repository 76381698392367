import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Container,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Slide,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {
  getUserId,
  getUserName,
  is_new_user,
  SERVER_BASE_ADDRESS,
} from "../globals/const";
import instance from "../globals/axios";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  mainContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    flexGrow: 1,
  },
  row: {
    item: true,
    container: true,
    spacing: 1,
    justify: "center",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    left: theme.spacing(2),
  },
  fabRow: {},
  fundSelectorRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  buttonRow: {
    margin: "10px",
    display: "flex",
    justifyContent: "space-around",
  },
  alert: {
    backgroundColor: "white",
    marginRight: ".5rem",
    borderRadius: "100%",
    textAlign: "center",
    width: "25px",
    color: "red",
  },
}));

export default function MainScreen() {
  const classes = useStyles();
  const [role, setRole] = useState(null);
  const [welcome, setWelcome] = useState(true);
  const [is_HQ_leader, setIs_HQ_leader] = useState(false);
  const [is_Agri_Admin, setIs_Agri_Admin] = useState(false);
  const [isQualityDI, setIsQualityDI] = useState(false);
  const [alertCount, setAlertCount] = useState(null);
  const [HQalertCount, setHQalertCount] = useState(null);
  const [isQualityHead, setIsQualityHead] = useState(false);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const getAlerts = async () => {
    try {
      let { data } = await instance.get(
        `${SERVER_BASE_ADDRESS}/procurement/get-alerts?id=${getUserId()}&only_count=1`
      );
      setAlertCount(data);
    } catch (error) {
      alert("Error. Please refresh the page or try logging in again.");
    }
  };

  const getHQAlerts = async () => {
    try {
      let { data } = await instance.get(
        `${SERVER_BASE_ADDRESS}/procurement/get-hq-alerts?id=${getUserId()}&only_count=1`
      );
      setHQalertCount(data);
    } catch (error) {
      alert("Error. Please refresh the page or try logging in again.");
    }
  };

  useEffect(() => {
    getAlerts();
    let userRole = JSON.parse(window.sessionStorage.getItem("role"));
    let HQ = JSON.parse(window.sessionStorage.getItem("auth"));

    // If QC ashish redirect to Plant Inward
    if (userRole === "Quality" && HQ.is_Quality_head & HQ.is_Agri_head) {
      window.location = "/confirm-plant-inward";
      window.sessionStorage.setItem("is_QC_ashish", true);
    }

    if (HQ.is_Quality_DI) {
      setIsQualityDI(HQ.is_Quality_DI);
    }
    if (HQ.is_HQ_leader) {
      getHQAlerts();
      setIs_HQ_leader(HQ.is_HQ_leader);
    }
    if (HQ.is_Quality_head) {
      setIsQualityHead(true);
    }
    if (HQ.is_Agri_head) {
      setIs_Agri_Admin(true);
    }
    setRole(userRole);
    is_new_user();
    let shouldWelcome = window.localStorage.getItem("welcomeUser");
    if (shouldWelcome === "true") {
      setWelcome(true);
    } else {
      setWelcome(false);
    }

    return () => {
      setRole(null);
    };
  }, []);

  const handleWelcomeClose = () => {
    setWelcome((prev) => !prev);
    window.localStorage.setItem("welcomeUser", false);
  };

  return (
    <Container maxWidth="xs">
      {welcome && (
        <Dialog
          open={welcome}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleWelcomeClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            <span style={{ fontWeight: "normal" }}>Welcome</span>{" "}
            {getUserName()}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleWelcomeClose} style={{ color: "red" }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <div className={classes.mainContent}>
        <Grid
          container
          // spacing={1}
          direction="column"
          style={{ marginTop: "10px" }}
        >
          {/* FundSelector */}
          <Grid container item>
            <Grid
              item
              xs
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                marginTop: "20px",
              }}
            >
              {/* Role for Quality - Direct Inward */}
              {role === "Quality" && isQualityDI && (
                <>
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      marginTop: "20px",
                    }}
                  >
                    <Card
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#253D72",
                        color: "whitesmoke",
                        textAlign: "center",
                      }}
                      onClick={() =>
                        (window.location = "/add-new-delivery-challen")
                      }
                    >
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                        >
                          Add New Delivery Challan
                        </Typography>
                      </CardContent>
                    </Card>
                  </Box>
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      marginTop: "20px",
                    }}
                  >
                    <Card
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#253D72",
                        color: "whitesmoke",
                        textAlign: "center",
                      }}
                      onClick={() => (window.location = "/my-delivery-challan")}
                    >
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                        >
                          My Challans
                        </Typography>
                      </CardContent>
                    </Card>
                  </Box>
                </>
              )}

              {/* Role for Agri */}
              {role === "Agri" && (
                <>
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      marginTop: "20px",
                    }}
                  >
                    <Card
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#253D72",
                        color: "whitesmoke",
                        textAlign: "center",
                      }}
                      onClick={() =>
                        (window.location = "/add-new-delivery-challen")
                      }
                    >
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                        >
                          Add New Delivery Challan
                        </Typography>
                      </CardContent>
                    </Card>
                  </Box>
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      marginTop: "20px",
                    }}
                  >
                    <Card
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#253D72",
                        color: "whitesmoke",
                        textAlign: "center",
                      }}
                      onClick={() => (window.location = "/alerts")}
                    >
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {alertCount > 0 && (
                            <div className={classes.alert}>{alertCount}</div>
                          )}
                          Alerts
                        </Typography>
                      </CardContent>
                    </Card>
                  </Box>
                </>
              )}

              {/* Role for Quality */}
              {role === "Quality" && (
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    marginTop: "20px",
                  }}
                >
                  <Card
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#253D72",
                      color: "whitesmoke",
                      textAlign: "center",
                    }}
                    onClick={() =>
                      (window.location = isQualityDI
                        ? "/direct-inward"
                        : "/confirm-plant-inward")
                    }
                  >
                    <CardContent>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        {isQualityHead ? "All " : ""}
                        {isQualityDI
                          ? "Add New Quality Report"
                          : "Plant Inward"}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              )}

              {/* Generic section for both Agri & Quality */}
              {/* {!is_Agri_Admin && ( */}
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  marginTop: "20px",
                }}
              >
                <Card
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#253D72",
                    color: "whitesmoke",
                    textAlign: "center",
                  }}
                  onClick={() =>
                    (window.location =
                      role === "Quality"
                        ? "/my-quality-check"
                        : "/my-delivery-challan")
                  }
                >
                  <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                      {isQualityDI
                        ? "My Quality Reports"
                        : role === "Quality"
                        ? "My Plant Inwards"
                        : "My Challans "}
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
              {/* )} */}

              {/* Only for AGRI HQ Leader */}
              {is_HQ_leader && (
                <>
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      marginTop: "20px",
                    }}
                  >
                    <Card
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#253D72",
                        color: "whitesmoke",
                        textAlign: "center",
                      }}
                      onClick={() => (window.location = "/hq-alerts")}
                    >
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14 }}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {HQalertCount > 0 && (
                            <div className={classes.alert}>{HQalertCount}</div>
                          )}
                          All HQ Alerts
                        </Typography>
                      </CardContent>
                    </Card>
                  </Box>
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      marginTop: "20px",
                    }}
                  >
                    <Card
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#253D72",
                        color: "whitesmoke",
                        textAlign: "center",
                      }}
                      onClick={() => (window.location = "/initiate-invoice")}
                    >
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14 }}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          Initiate Invoice Request
                        </Typography>
                      </CardContent>
                    </Card>
                  </Box>
                </>
              )}

              {/* Store inward for Quality-Direct Inward */}
              {isQualityDI && (
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    marginTop: "20px",
                  }}
                >
                  <Card
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#253D72",
                      color: "whitesmoke",
                      textAlign: "center",
                    }}
                    onClick={() =>
                      (window.location = "/direct-inward/?type=store_inward")
                    }
                  >
                    <CardContent>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        All Store Inwards
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
