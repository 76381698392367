import React, { useState, useEffect } from "react";
import HawkEyeAppBar from "../components/AppBar";
import { Routes, Route, Outlet, useRoutes, Link, BrowserRouter as Router, Navigate } from 'react-router-dom';

import { HOME_PAGE } from "../globals/const";
import { isLoggedIn } from "../globals/utils";
import { initDB } from "../globals/db";

import Login from "./Login";
import Register from "./Register";
import MainScreen from "./MainScreen";
import ChangePassword from "./ChangePassword";
import ViewLandNFT from "./ViewLandNFT";
import QrScanner from "./QrScanner"
import AuditTrail from "./AuditTrail"
import NotFound from "./NotFound";


import DepositorDashboard from "./Farmer/DepositorDashboard";
import DashboardDetails from "./Farmer/DashboardDetails";

import NBFCScreen from "./NBFC/NBFCScreen";
import NBFCScreenDetails from "./NBFC/NBFCScreenDetails";
import MakeLoanOffer from "./NBFC/NBFCMakeLoanOffer";
import OffersMade from "./NBFC/NBFCOfferMade";
import NBFCLoanRequestDetails from "./NBFC/NBFCLoanRequestDetails";
import NBFCTransactions from "./NBFC/NBFCTransactions";
import DataFromWR from "./NBFC/DataFromWr";

import AdminScreen from "./Admin/AdminScreen";
import AdminLoanStatusDetails from "./Admin/LoanStatusDetails";
import ApplyLoan from "./Admin/ApplyLoan";
import ApplyLoansDetails from "./Admin/ApplyLoansDetails";
import AcceptReject from "./Admin/AcceptReject";
import AdminDashboard from "./Admin/AdminDashboard";
import LienStatusDetails from "./Admin/LienStatusDetails";

import BankerScreen from "./Banker/BankerScreen";
import BankerScreenDetails from "./Banker/BankerScreenDetails";
import MarkFunded from "./Banker/MarkFunded";
import MarkUnfunded from "./Banker/MarkUnfunded"; 
import NewBankerDetails from "./Banker/NewDetails"
import BankerDashboard from "./Banker/BankerDashboard"
import BankerAdminDashboard from "./Banker/BankerAdminDashboard"
import CsvUploadScreen from "./Banker/CsvUpload";

import OperationsScreen from "./Operations/OperationsScreen";
import MarkLienDetails from "./Operations/MarkLienDetails";
import MarkLien from "./Operations/MarkLien";
import OperationsDetails from "./Operations/OperationsDetails";
import MarkFundedOps from "./Operations/MarkFunded"
import OpsScreenDetails from "./Operations/OpsDetails";
import AnalysisDashboard from "./Operations/AnalysisDashboard";
import OperationsDashboard from "./Operations/OperationsDashboard";

import StakeholderDashboard from "./Stakeholder/StakeholderDashboard";
import SanctionLimit from "./Banker/SanctionLimit";
import ConsentPage from "./Admin/ConsentPage";
import WarehouseStats from "./WarehouseStats"
import AllCommodity from "./Stakeholder/AllCommodity"


initDB(() => {});


export default function AppScreen() {
  const DefaultContainer = () => (
    <div>
      <HawkEyeAppBar />
      {isLoggedIn() && (
        <React.Fragment>
          <Route path={"/"} exact element={<DepositorDashboard />} />
          <Route path={"/change-password"} exact element={<ChangePassword />} />
          {/* <Route path={'/add-new-delivery-challen'} component={AddNewDeliveryChallen} /> */}
        </React.Fragment>
      )}
    </div>
  );


  const [ role, setRole ] = useState(null)

  useEffect(() => {
    setRole(sessionStorage.getItem('user_role'));
  }, [])


  const ProtectedCMRoute = () => {
    
    const user = sessionStorage.getItem('user_role')
    const cm = (user === '"1"' ? true : false);
    return cm ? <Outlet /> : <Navigate to="/not-found" />;
  }
  const ProtectedBankerRoute = () => {

      const user = sessionStorage.getItem('user_role')     
      const banker = (user === '"4"' || user === '"7"' ? true : false);
      return banker ? <Outlet /> : <Navigate to="/not-found" />;
  }
  
  const ProtectedOpsRoute = () => {
  
      const user = sessionStorage.getItem('user_role')
      const ops = (user === '"5"' ? true : false);
      return ops ? <Outlet /> : <Navigate to="/not-found" />;
  }

    
  const ProtectedSuperRoute = () => {
  
    const user = sessionStorage.getItem('user_role')
    const ops = (user === '"6"' ? true : false);
    return ops ? <Outlet /> : <Navigate to="/not-found" />;
}



  return (
    <Router>
      <Routes>

      
      {/* NBFC flow starts */}
      {/* 
      <Route path={HOME_PAGE + "/nbfc"} element={<NBFCScreen />} />
      <Route path={HOME_PAGE + "/nbfc-details/:id"} element={<NBFCScreenDetails />} />
      <Route path={HOME_PAGE + "/offer-made/:id"} element={<OffersMade />} />
      <Route path={HOME_PAGE + "/loan-details/:id"} element={<NBFCLoanRequestDetails />} />
      <Route path={HOME_PAGE + "/make-loan-offer/"} element={<MakeLoanOffer />} />
      <Route path={HOME_PAGE + "/nbfc-dashboard"} element={<BankerDashboard />} />
      <Route path={HOME_PAGE + "/my-transactions"} element={<NBFCTransactions />} />
      */}
      {/* NBFC flow ends */}
    
      {/* Banker flow starts */}

      <Route
              element={
                  <ProtectedBankerRoute>
                      <Outlet />
                  </ProtectedBankerRoute>
              }
      >
                  <Route path={"/banker"} element={<BankerScreen />} />
                  <Route path={"/banker-admin"} element={<BankerAdminDashboard />} />
                  <Route path={"/banker-details/:id"} element={<BankerScreenDetails />} />
                  <Route path={"/mark-funded/:id"} element={<MarkFunded />} />
                  <Route path={"/view-funded/:id"} element={<MarkUnfunded />} />
                  <Route path={"/new-banker-details"} element={<NewBankerDetails />} />
                  <Route path={"/banker-dashboard"} element={<BankerDashboard />} />
                  <Route path={"/sanction-limit"} element={<SanctionLimit />} />
                  <Route path={"/csv-upload"} element={<CsvUploadScreen />} />
      </Route>
      {/* Banker flow ends */}
    
      {/* Admin flow starts */}
      
      <Route
      element={
          <ProtectedCMRoute>
              <Outlet />
          </ProtectedCMRoute>
      }
      >
          <Route path={HOME_PAGE + "/admin"} element={<AdminScreen />} />
          <Route path={HOME_PAGE + "/apply-loan/:id"} element={<ApplyLoansDetails />} />
          <Route path={HOME_PAGE + "/loan-status/:id"} element={<AdminLoanStatusDetails />} />
          <Route path={HOME_PAGE + "/lien-status/:id"} element={<LienStatusDetails />} />
          <Route path={HOME_PAGE + "/make-loan"} element={<ApplyLoan />} />
          <Route path={HOME_PAGE + "/accept-reject-loan/:id"} element={<AcceptReject />} />
          <Route path={HOME_PAGE + "/admin-dashboard"} element={<AdminDashboard />} />
          <Route path={HOME_PAGE + "/update-consent"} element={<ConsentPage />} />
      </Route>
    
      {/* Admin flow ends */}
    
      {/* Operations flow starts */}
      <Route
              element={
                  <ProtectedOpsRoute>
                      <Outlet />
                  </ProtectedOpsRoute>
              }
        >
          <Route path={HOME_PAGE + "/operations"} element={<OperationsScreen />} />
          <Route path={HOME_PAGE + "/mark-lien-details/:id"} element={<MarkLienDetails />} />
          <Route path={HOME_PAGE + "/mark-lien"} element={<MarkLien />} />
          <Route path={HOME_PAGE + "/ops-details/:id"} element={<OpsScreenDetails />} />
          <Route path={HOME_PAGE + "/ops-mark-funded/:id"} element={<MarkFundedOps />} />
          {/* <Route path={HOME_PAGE + "/analysis-dashboard"} element={<AnalysisDashboard />} /> */}
          <Route path={HOME_PAGE + "/operations-dashboard"} element={<OperationsDashboard />} />
          <Route path={HOME_PAGE + "/details/:id"} element={<OperationsDetails />} />
      </Route>

      {/* Operations flow ends */}

      <Route
              element={
                  <ProtectedSuperRoute>
                      <Outlet />
                  </ProtectedSuperRoute>
              }
        >
          <Route path={HOME_PAGE + "/superuser-dashboard"} element={<StakeholderDashboard />} />
          <Route path={HOME_PAGE + "/analysis-dashboard"} element={<AnalysisDashboard />} />
      </Route>
    
    
      {/* Open to all */}
      <Route path={"/"} exact element={<Login />} />
      <Route path={HOME_PAGE + "/not-found"} element={<NotFound />} />
      <Route path={HOME_PAGE + "/login"} element={<Login />} />
      <Route path={HOME_PAGE + "/signup"} element={<Register />} />
      <Route path={HOME_PAGE + "/farmer"} element={<DepositorDashboard />} />
      <Route path={HOME_PAGE + "/farmer-details"} element={<DashboardDetails />} />
      <Route path={HOME_PAGE + "/qr-scan/:token_code"} element={<QrScanner />} />
      <Route path={HOME_PAGE + "/audit-trail/:wr_reciept_no"} element={<AuditTrail />} />
      <Route path={HOME_PAGE + "/wr-details/:wr_reciept_no"} element={<DataFromWR />} />
      <Route path={HOME_PAGE + "/all-commodity/:selectedOption/:search"} element={<AllCommodity />} />
      <Route path="/warehouse-stats/:stateId/:locationId/:warehouseId/" exact element={<WarehouseStats />} />
      {/* Open to all */}

        <Route component={DefaultContainer} />
      </Routes>
    </Router>
  );
}
