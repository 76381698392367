import React from "react";
import { Button, TextField, Grid, Typography, Divider, Card, Chip } from '@mui/material';
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import { useState } from "react";
import Copyright from "../components/Copyright";
import { HOME_PAGE } from "../globals/const";
import { SERVER_BASE_ADDRESS } from "../globals/const";
import { saveAuth, saveRole } from "../globals/utils";
import instance from "../globals/axios.js";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { isLoggedIn } from "../globals/utils";
import HawkEyeAppBar from "../components/AppBar";
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { MuiOtpInput } from 'mui-one-time-password-input'


import user from '../../src/assets/user.png'
import banker from "../../src/assets/bankerfront-img.png";
import bankerin from '../../src/assets/banker-staff.jpg'
import warehouse from '../../src/assets/warehouse.jpg'
import warehousein from '../../src/assets/warehouse-staff.jpg'
import agrigate from '../../src/assets/agrigate.png'



const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "10rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", 
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  row: {
    marginTop: theme.spacing(1),
  },
  outline: {
    border:"2px solid #d8daed", 
    backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)',
  },
  clickable: {
      '&:hover': {
        cursor: 'pointer',
        // backgroundColor: '#fff'
        transform: 'scale(1.025)',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    },    
    transition: 'transform 0.3s, box-shadow 0.3s',

  },             
  customCard: {
    backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)',
    boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
    borderRadius: '1rem',
    border:"2px solid #d8daed", 
  },
}));

export default function Login() {
  const classes = useStyles();
  const [password, setPassword] = useState(null);
  const [username, setUsername] = useState(null);
  const [creds, setCreds] = useState({});
  const [showScreen, setShowScreen] = useState(null);

  const [value, setValue] = useState()

  async function login(event) {
    event.preventDefault();
  }

  function setCredField(key, value) {
    let _creds = creds;
    creds[key] = value;
    setCreds(_creds);
  }

  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [showOtpField, setShowOtpField] = useState(false);

  const handleChange = (newValue) => {
    setOtp(newValue)
  }

  // const mobileBody = {
  //   "phone_number": phoneNumber.slice(2),
  //   // "mobile": "8652521225",
  //   // "send": 1 
  // };

  const otpBody = {
    "mobile": phoneNumber,
    // "verify": 1,
    "otp": otp
  }

  const loginWithOtpBody = {
      "ft_token": otp,
      // "mobile_number": phoneNumber.slice(2)
      "mobile_number": phoneNumber.slice(2)
  }
  

  const handlePhoneNumberChange = (formattedValue) => {
    setPhoneNumber(formattedValue);
  };

  const handleLogin = async () => {
    try {
      // console.log("number", mobileBody);
      const response = await instance.get(`otp/generate-opt-login?phone_number=${phoneNumber.slice(2)}`);
      // const response = await axios.get(`https://ewrapi.staragri.com/api/agri/otp/generate-opt-login?phone_number=${phoneNumber.slice(2)}`);
      setShowOtpField(true);
      alert("OTP sent successfully")
    
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  const handleVerifyOtp = () => {
    try {
      
      // const response = instance.get("loginWithOtp", loginWithOtpBody);

      axios.defaults.headers = { "Content-Type": "application/json" };
      axios
        .post(SERVER_BASE_ADDRESS + "/loginWithOtp", loginWithOtpBody)
        .then((res) => {
          window.localStorage.setItem("token", res.data.access);
          saveAuth(res.data);
          saveRole(res.data.authenticatedUser.role)

          if (res.data.authenticatedUser.role === "1") {
            alert('OTP verification successful!');
            window.open("/admin", "_self");
          } 
          if (res.data.authenticatedUser.role === "2") {
            alert("Authentication Failed: NBFC Login")
            window.open("/", "_self");
          } 
          if (res.data.authenticatedUser.role === "3") {
            alert("Authentication Failed: Farmer Login")
            window.open("/", "_self");
          } 
          if (res.data.authenticatedUser.role === "7") {
            alert("Authentication Failed: Banker Admin Login")
            window.open("/", "_self");
          } 
          if (res.data.authenticatedUser.role === "4") {
            alert("Please use Banker Login Screen")
            window.open("/", "_self");
          }
          if (res.data.authenticatedUser.role === "5") {
            alert('OTP verification successful!');
            window.open("/operations", "_self");
          }
          if (res.data.authenticatedUser.role === "6") {
            alert('OTP verification successful!');
            window.open("/superuser-dashboard", "_self");
          }

        })
        .catch((e) => {
          alert("Authentication Failed");
        });

    } catch (error) {
      console.error(error);
    }
    
  };

  const CustomInputComponent = React.forwardRef(({ value, ...rest }, ref) => (
    <input
      {...rest}
      ref={ref}
      value={value}
      style={{
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '10px',
        fontSize: '16px',
        // Add more custom styles here...
      }}
    />
  ));


  const handleSubmit = () => {
    // console.log("before", isLoggedIn());
    axios.defaults.headers = { "Content-Type": "application/json" };
    axios
      .post(SERVER_BASE_ADDRESS + "/login", creds)
      // .post("http://3.87.14.107:8920/api/agri/login", creds)
      .then((res) => {
        window.localStorage.setItem("token", res.data.access);
        // window.sessionStorage.setItem("role", res.data.authenticatedUser.role);  
        saveAuth(res.data);
        saveRole(res.data.authenticatedUser.role)
        // console.log();

        if (res.data.authenticatedUser.role === "1") {
          alert("Please use Operations and CM Login Screen")
          window.open("/", "_self");
        } 
        if (res.data.authenticatedUser.role === "2") {
          alert("Authentication Failed: NBFC Login")
          window.open("/", "_self");
        } 
        if (res.data.authenticatedUser.role === "3") {
          alert("Authentication Failed: Farmer Login")
          window.open("/", "_self");
        } 
        if (res.data.authenticatedUser.role === "4") {
          alert("Login Successful");
          window.open("/banker", "_self");
        }
        if (res.data.authenticatedUser.role === "7") {
          alert("Login Successful");
          window.open("/banker-admin", "_self");
        }
        if (res.data.authenticatedUser.role === "6") {
          alert("Please use Operations and CM Login Screen")
          window.open("/", "_self");
        }
        if (res.data.authenticatedUser.role === "5") {
          alert("Please use Operations and CM Login Screen")
          window.open("/", "_self");
        } 
        
      })
      .catch((e) => {
        // this.setState ({errorText: 'Authentication Failure'});
        alert("Authentication Failed");
      });
  };


  return (

    <Container component='main' fullWidth >
      <CssBaseline />
      { showScreen===null && (
        <React.Fragment>

          <Grid container mt="3%" fullWidth textAlign="center">
            <Box mx="auto">
              <img mx="auto" alt='StarAgri logo' src={HOME_PAGE + "/staragri-logo.png"} style={{ width: "65%" }} />
            </Box>
            <Box fullWidth textAlign="center"  sx={{ fontFamily: "Helvetica Neue" }} width="100%" mx="auto" color="#214383">
                <Typography mb={1} variant="h5" fontWeight={500} >
                  Electronic Warehouse Receipting (EWR)
                </Typography>
                <Typography variant="body1" fontWeight={500} >
                  Financing the Indian Agri-Ecosytem • Simplifying Indian Agriculture • Democratizing Agri Technology
                </Typography>
            </Box>

          </Grid>

       

        </React.Fragment>

      ) }

      { showScreen===null && (
      <Grid fullWidth mt={2} container justifyContent="center"
          sx={{ 
            borderRadius: '2rem',
            backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)'
        }}>

          <Box
              mt={2}
              display="flex"
              alignItems="center"
              padding = "5px"
              borderRadius= "20px"
              // style={{
              //   background: 'linear-gradient(45deg, #3b9b49, #258842, #1a5f35)',
              //   boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.3)',
              // }}
              style={{
                background: 'linear-gradient(135deg, #6bd779, #4dbd64, #3aa343)',
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
              }}
            >
              <Box textAlign="center"  sx={{ fontFamily: "Helvetica Neue" }} px={3} width="100%" mx="auto" color="#fff">
                <Typography variant="body1" fontWeight={500} >
                  16 years of serving farmers | 1100+ warehouses | 100+ mn tonnes of grains | 900+ bn collateral finance | 16 states presence
                </Typography>
              </Box>
          </Box>


        <Grid className={classes.clickable} backgroundColor="#fff" borderRadius={6}  onClick={() => setShowScreen("CM")} item my="auto" xs={12} md={4} m="2rem" p="1rem" >
         
          <Typography color="primary" variant="h5" mb="2rem"  align="center" gutterBottom>
              For Collateral Managers
          </Typography>
          <Box mx="auto" textAlign="center">
          {/* <img src={warehouse} alt="User login" style={{ height: '250px', width: '275px'}}/> */}

            <img src={agrigate} alt="User login" style={{ height: '215px', width: '290px'}}/>

          </Box>
        </Grid >
       
        <Grid className={classes.clickable} backgroundColor="#fff" borderRadius={6} onClick={() => setShowScreen("Banker")} item my="auto" xs={12} md={4} m="2rem" pt="1rem" alignItems="center" >
          {/* 
          <Grid textAlign="center" fullWidth>
            <AssignmentIndOutlinedIcon color="#fff" style={{ fontSize: '5rem' }}  />
          </Grid> */}
          <Grid item xs={12}>
           
            {/* <Typography variant="h4" mt="1.5rem" align="center" gutterBottom>
              Login
            </Typography> */}
            <Typography variant="h5" color="primary" align="center" gutterBottom>
              For Bankers and NBFCs
            </Typography>
            <Box fullWidth mt="3rem" textAlign="center">
            <img src={banker} alt="User login" style={{ mx:"auto", height: '250px', width: '325px'}}/>
            </Box>   
          </Grid>
          
        </Grid>

      </Grid>
      )}

      { showScreen==="CM" && 
      (
        <Grid backgroundColor="#fff" fullWidth mt="10%" container justifyContent="center">
        <Chip label="Go back" color="primary" onClick={() => setShowScreen(null)} />
        
       
       
        <Grid item my="auto" xs={12} md={5} m="2rem" p="2rem" alignItems="center" >
          
          
          <Grid item xs={12}>
            
            <Typography color="primary" variant="h6" align="center" gutterBottom>
              For StarAgri Collateral Managers 
            </Typography>
          </Grid>
          <Grid my="2rem" item xs={12}>
            <PhoneInput 
                  fullWidth
                  country={'in'}
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  inputProps={{
                    required: true,
                  }}
                  inputStyle={{ width: '100%' }}
                  containerStyle={{ width: '100%' }}
                />
          </Grid>
          {showOtpField && (
            <Grid px={4} my={2} item mb="1.25rem" xs={12} >
              <MuiOtpInput value={otp} onChange={handleChange} length={6} />
            </Grid>
          )}
          <Grid my="2rem" item xs={12}>
            {showOtpField ? (
              <Button variant="contained" onClick={handleVerifyOtp} fullWidth>
                Verify OTP
              </Button>
            ) : (
              <Button variant="contained" onClick={handleLogin} fullWidth>
                Get OTP
              </Button>
            )}
          </Grid>

         

        </Grid>
          {/* 
          <Box position="absolute" sx={{ zIndex: -10 }}  ml="45%" mt="30%">
            <img src={warehousein} sx={{ zIndex: -10 }} alt="Banker login" style={{ height: '175px', width: '300px'}}/>
          </Box>
          */}

        </Grid>    
      )}

      { showScreen==="Banker" && 
      (
        <Grid fullWidth mt="10%" container justifyContent="center">
        <Chip label="Go back" color="primary" onClick={() => setShowScreen(null)} />

        <Grid item my="auto" xs={12} md={5} m="2rem" p="2rem" >       
          <Box flex ml="20%">
            <img mx="auto" alt='StarAgri logo' src={HOME_PAGE + "/staragri-logo.png"} style={{ width: "65%" }} />
          </Box>
          <form className={classes.form} noValidate onSubmit={login}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='username'
              label='Login Id'
              name='username'
              autoComplete='username'
              autoFocus
              onChange={(event) => setCredField("email", event.target.value)}
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='password'
              label={"Password"}
              type='password'
              id='password'
              autoComplete='current-password'
              onChange={(event) => setCredField("password", event.target.value)}
            />
            <Button fullWidth variant='contained' color='primary' className={classes.submit} onClick={handleSubmit}>
              Login
            </Button>
            
          </form>
          <div container justifyContent='center' style={{ marginTop: "20px" }}>
            <div item>
              <Link to='/signup'>Dont have an Account? Create</Link>
            </div>
          </div>
          <Box mt={3}>
            <Copyright />
          </Box>
        </Grid>
      
        </Grid>    
      )}
     
     
    </Container>
    
  );
}
