import React, { useState } from 'react';
import { Grid, Popover, Button, CircularProgress, Typography, Box } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import { makeStyles } from "@mui/styles";
import dayjs from 'dayjs';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import { GrDocumentText} from "react-icons/gr";
import { AiOutlineCloseCircle } from "react-icons/ai";

import { IoDocumentTextOutline } from "react-icons/io5";
import { HiDocumentCheck } from "react-icons/hi2";


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const useStyles = makeStyles((theme) => ({
    gridContainer: {
      backgroundImage: `url(${require('../assets/StaragriCertificate.png')})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '100% 100%',
      // opacity: 0.8,
    },
    borderRight: {
      border:"1px solid #000", 
      borderLeft: 'none',
    },
    borderLeft: {
      border:"1px solid #000", 
      borderRight: 'none',
    },
    borderCenter: {
      border:"1px solid #000", 
    },
    gridItem: {
      padding: theme.spacing(2),
      cursor: 'pointer',
      transition: 'border 0.3s, background 0.3s',
      '&:hover': {
        border: '1px solid #ccc',
        background: '#f5f5f5',
      },
    },
    pdfContainer: {
      // maxWidth: 300,
      // maxHeight: 300,
      width: 500,
      height: 700, 
    },
    loader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '45%',
      width: '100%',
      height: '100%',
    },
    popoverContainer: {
      background: 'rgba(255, 255, 255, 0.35)', // Semi-transparent white background
      backdropFilter: 'blur(1px)', // Blur effect
      boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.2)', // Box shadow
      transition: 'background-color 0.3s, box-shadow 0.3s, backdrop-filter 0.3s', // Transitions
    },
  }));
  
// const InsuranceGrid = ({ data, policy_handlePopoverClose, policy_open, setPolicyOpen, policy_id, setPolicyId, policy_anchorEl, setPolicyAnchorEl}) => {
const InsuranceGrid = ({ data }) => {
 
    const classes = useStyles();

    const [policy_open, setPolicyOpen] = useState(false);
    const [policy_id, setPolicyId] = useState(null);
    const [policy_anchorEl, setPolicyAnchorEl] = useState(null);
    const [policyNumPages, setPolicyNumPages] = useState(0);
  
    const policy_handlePopoverOpen = (event) => {
      setPolicyOpen(true);
      setPolicyId(event.currentTarget.getAttribute('id'));
      setPolicyAnchorEl(event.currentTarget);
    };
  
    const policy_handlePopoverClose = () => {
      setPolicyOpen(false);
      setPolicyId(null);
      setPolicyAnchorEl(null);
    };
  
    const handleDocumentLoadSuccess = ({ numPages }) => {
      setPolicyNumPages(numPages);
    };

    function formatDate( inputDate 
      
      ) {
        const formattedDate = dayjs(inputDate).format('DD MMM YYYY');
        return formattedDate;
      }

    return (
        <div>
            <Grid
            container
            fullWidth
            // sx={{
            //     cursor: 'pointer',
            // }}
            // clickable
            >
                <Grid container fullWidth>
                
                    <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
                    Policy Type
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={4} px={1} py={1} textAlign="center">
                    Policy No.
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={3} px={1} py={1} textAlign="center">
                    Valid From
                    </Grid>

                <Grid container cols={12} xs={12} md={3} textAlign="center">
                        <Grid item className={classes.borderCenter} xs={12} md={6} px={1} py={1} textAlign="center">
                        Valid To
                        </Grid>
                        <Grid item className={classes.borderRight} xs={12} md={6} px={1} py={1} textAlign="center">
                        Sum Insured
                        </Grid>
                        <Grid>
                        </Grid>

                
                </Grid>
                </Grid>

                

                {data.attributes.insurance_details.map((policy, index) => (
                  <Grid
                    key={index}
                    container
                    fullWidth
                    // clickable
                    // sx={{ cursor: 'pointer' }}
                    // onClick={policy_handlePopoverOpen}
                  >
                    <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} justifyContent="center" textAlign="center">
                      <Typography  justifyContent="center" >{policy.insurance_type}</Typography>
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={4} px={1} py={1} textAlign="center">
                      <Typography  >{policy.insurance_policy_no}</Typography>
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={3} px={1} py={1} textAlign="center">
                      <Typography  >{formatDate(policy.insurance_validity_from)}</Typography>
                    </Grid>
                    <Grid container cols={12} xs={12} md={3} textAlign="center">
                      <Grid item className={classes.borderCenter} xs={12} md={6} px={1} py={1} textAlign="center">
                        <Typography  >{formatDate(policy.insurance_validity_to)}</Typography>
                      </Grid>
                      <Grid item className={classes.borderRight} xs={12} md={6} px={1} py={1} textAlign="center">
                        <Typography  >{policy.insurance_sum_assured}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
                

                <Popover
                open={policy_open}
                id={policy_id}
                anchorEl={policy_anchorEl}
                onClose={policy_handlePopoverClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                disableRestoreFocus
                className={classes.popoverContainer}
            >
                <Grid className={classes.pdfContainer}>
                <Button
                    onClick={policy_handlePopoverClose}
                    color="error"
                    sx={{ fontSize:"2rem", position: 'absolute', top: '10px', right: '10px', zIndex: 1 }}
                >
                    <AiOutlineCloseCircle />
                </Button>

                <Document
                    file={require('../assets/insurance.pdf')}
                    loading={<div className={classes.loader}><CircularProgress /></div>}
                    onLoadSuccess={handleDocumentLoadSuccess}
                >
                    <Button
                    sx={{ ml: "35%", my: 2 }}
                    onClick={() => window.open(require('../assets/insurance.pdf'))}
                    variant="outlined"
                    color="primary"
                    >
                    View Full Document
                    </Button>
                    {Array.from({ length: policyNumPages }, (_, index) => (
                    <Page key={index} pageNumber={index + 1} width={500} height={700} />
                    ))}
                </Document>
                </Grid>
            </Popover>
            </Grid>
        </div>
    );
};

export default InsuranceGrid;