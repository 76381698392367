import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid, Button, Typography, Alert, Chip, Box, CircularProgress, List, ListItem, ListItemText } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import GetAppIcon from '@mui/icons-material/GetApp';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HawkEyeAppBar from "../../components/AppBar";
import dayjs from 'dayjs'; // Import dayjs library
import instance from '../../globals/axios';

const CsvUploadScreen = () => {
  const [file, setFile] = useState(null);
  const [alert, setAlert] = useState({ type: '', message: '' });

  const onDrop = acceptedFiles => {
    const selectedFile = acceptedFiles[0];
    if (selectedFile.size > 1024 * 1024) {
      setAlert({ type: 'error', message: 'File size exceeds 1 MB' });
      return;
    }
    setFile(selectedFile);
    setAlert({ type: 'success', message: 'File selected successfully' });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.csv',
    maxSize: 1024 * 1024
  });

  const handleUpload = async () => {
    if (!file) {
      setAlert({ type: 'error', message: 'No file selected' });
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', 'disbursement');

    try {
      await instance.post('upload-data/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setAlert({ type: 'success', message: 'File uploaded successfully' });
    } catch (error) {
      setAlert({ type: 'error', message: 'Error uploading file' });
    }
  };

  const downloadTemplate = async (type) => {
    const url = type === 'repayment'
      ? 'download-template-repayment/'
      : 'download-template-disbursement/';

    try {
      const response = await instance.get(url, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${type}_template.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      setAlert({ type: 'error', message: `Error downloading ${type} template` });
    }
  };

  const [uploadStatusData, setUploadStatusData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUploadStatus = async () => {
            try {
                const response = await instance.get('upload-status/');
                setUploadStatusData(response.data);
            } catch (error) {
                console.error('Error fetching upload status data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUploadStatus();
    }, []);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 1,
            renderCell: (params) => <Chip label={params.value} />,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 2,
            renderCell: (params) => {
                let icon;
                let color;

                switch (params.value) {
                    case 'completed':
                        icon = <CheckCircleIcon />;
                        color = 'green';
                        break;
                    case 'failed':
                        icon = <CancelIcon />;
                        color = 'red';
                        break;
                    case 'pending':
                        icon = <HourglassEmptyIcon />;
                        color = 'orange';
                        break;
                    default:
                        icon = null;
                        color = 'default';
                }

                return (
                    <Box display="flex" alignItems="center" color={color}>
                        {icon}
                        <span style={{ marginLeft: 8 }}>{params.value}</span>
                    </Box>
                );
            },
        },
        {
          field: 'created_at',
          headerName: 'Created At',
          width: 200,
          valueGetter: (params) => dayjs(params.value).format('DD-MM-YYYY'),
      },
      {
          field: 'updated_at',
          headerName: 'Updated At',
          width: 200,
          valueGetter: (params) => dayjs(params.value).format('DD-MM-YYYY'),
      },
    ];

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }


  return (
    <>
    <HawkEyeAppBar />

    <Grid mt="80px" container spacing={2} alignItems="center" justifyContent="center" style={{ padding: 20 }}>
      <Grid item xs={12}>
        {alert.message && <Alert severity={alert.type}>{alert.message}</Alert>}
      </Grid>
      <Grid item xs={7} mx="auto">
        <div {...getRootProps()} style={{ border: '2px dashed #cccccc', padding: 20, textAlign: 'center' }}>
          <input {...getInputProps()} />
          <CloudUploadIcon style={{ fontSize: 50, color: '#cccccc' }} />
          <Typography>Drag 'n' drop a CSV file here, or click to select one</Typography>
        </div>
        {file && (
                <Grid item xs={12}>
                    <List>
                        <ListItem button component="a" href={URL.createObjectURL(file)} download={file.name}>
                            <ListItemText primary={file.name} />
                        </ListItem>
                    </List>
                </Grid>
            )}
      </Grid>
      <Grid item xs={12} mt={1} style={{ textAlign: 'center' }}>
        <Button variant="contained" color="primary" onClick={handleUpload} startIcon={<CloudUploadIcon />}>
          Upload File
        </Button>
      </Grid>
      <Grid container xs={12} fullWidth px={8} mt={2} spacing={2}>

      <Grid item xs={6} style={{ textAlign: 'center' }}>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={() => downloadTemplate('repayment')}
          startIcon={<GetAppIcon />}
        >
          Download Repayment Template
        </Button>
      </Grid>
      <Grid item xs={6} style={{ textAlign: 'center' }}>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={() => downloadTemplate('disbursement')}
          startIcon={<GetAppIcon />}
        >
          Download Disbursement Template
        </Button>
      </Grid>

      <Grid item xs={10} mx="auto">
      <Typography textAlign="center" mt={2} variant="h6" gutterBottom>
        Uploaded CSV Files status
      </Typography>
      <div style={{ width: '100%' }}>
            <DataGrid rows={uploadStatusData} columns={columns} pageSize={10} rowsPerPageOptions={[10]} />
      </div>

      </Grid>

      </Grid>
    </Grid>
    </>
  );
};

export default CsvUploadScreen;
