import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import HawkEyeAppBar from "../components/AppBar";
import { Box, Divider, Typography, Grid, Container, Chip, IconButton } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

import { makeStyles } from "@mui/styles";
import instance from "../globals/axios";
import dayjs from 'dayjs';

import { GiCorn, GiSmartphone, GiGrainBundle, GiGrain } from 'react-icons/gi';
import { MdDateRange, MdLocationOn, MdOutlineCancel, MdOutlineWarehouse, MdOutlineCheckCircleOutline } from 'react-icons/md';
import { BsCheck, BsX } from 'react-icons/bs';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";




const useStyles = makeStyles((theme) => ({
  outline: {
    borderRadius:"1rem",
    border:"2px solid #d8daed", 
    backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)',

  },
  customCard: {
    backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)',
    boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
    borderRadius: '1rem',
    border:"2px solid #d8daed", 
  },
  subtitleFont: {
    fontSize: "1.5rem",
    fontWeight: "600",
  },
}));

const AuditTrail = () => {
    const classes = useStyles();
    const location = useLocation();
    const { token_code } = useParams();

    const [loading, setLoading] = useState(true);
    const [ data, setData] = useState({})

    console.log(token_code)
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await instance.get(`/public/getTokenData?token_code=${token_code}`);
            console.log(response.data.data[0])
            setData(response.data.data[0]);
            setLoading(false);
          } catch (error) {
            console.error(error);
          }
        };
        fetchData();
    
    }, []);
   
    function formatDate({ inputDate }) {
        // const formattedDate = dayjs(inputDate).format('DD MMM YYYY');
        const formattedDate = dayjs(inputDate).format('hh:mm A DD MMM YYYY');
        return formattedDate;
    }


    return (
        
        <Container fullWidth bgcolor='#fff'>

        <Box className={classes.outline} sx={{ padding: "2rem 1rem", marginTop: { xs: "30%", md: "15%" } }}>
        <Grid m={1}>
            <Chip icon={<KeyboardBackspaceIcon />} style={{ cursor: "pointer", fontSize: "16px", fontWeight: "600" }} color="primary" label='Back' variant='outlined' onClick={() => window.history.back()} />
        </Grid>
            
        <Grid mt="1rem" mb="1rem">
            <Typography variant="h4" textAlign="center">
                Token Details
            </Typography>
        </Grid>

        <Divider />

        {loading ? (
            <Grid textAlign="center" alignItems="center" mt="3rem">
            <Typography textAlign="center" sx={{ mb:4}}>
                Loading....
            </Typography>
            <CircularProgress size={54} thickness={4} />
            </Grid>
          ) : (
            <pre>
            <Grid container spacing={2} p={3}>
                <Grid item xs={12}>
                    <Typography my={2} variant="subtitle1">
                        WR Receipt No: 
                        <span variant="h6">
                            <Chip
                                sx={{ fontSize: '1rem', fontWeight: 500, ml:2}}
                                label={data.wr_reciept_no}
                                color="primary"
                                variant="outlined"
                            />
                        </span>
                    </Typography>
                    <Typography my={2} variant="subtitle1">
                        Owner Code: <br/>
                        <Typography style={{ whiteSpace: 'pre-line' }} variant="h6">{data.owner}</Typography>
                    </Typography>
                    <Typography my={2} variant="subtitle1">
                        Token Code: <br/>
                        <Typography style={{ whiteSpace: 'pre-line' }} variant="h6">{data.token_code}</Typography>
                    </Typography>
                    <Typography my={2} variant="subtitle1">
                        Token Name: <br/>
                        <Typography style={{ whiteSpace: 'pre-line' }} variant="h6">{data.token_name}</Typography>
                    </Typography>
                    <Typography my={2} variant="subtitle1">
                        Balance: 
                        <Typography variant="h6">{data.balance}</Typography>
                    </Typography>
                </Grid>
        
                <Grid item xs={12} md={6}>
                    <Typography variant="h6">
                    <span style={{ fontSize: '24px', marginRight: '8px' }}><GiGrain /></span>
                    Commodity:{data.attributes.commodity}</Typography>
                    <Typography variant="h6">
                    <span style={{ fontSize: '24px', marginRight: '8px' }}><GiGrainBundle /></span>
                    Variety: {data.attributes.variety}</Typography>
                    <Typography variant="h6">
                    <span style={{ fontSize: '24px', marginRight: '8px' }}><MdOutlineWarehouse /></span>
                    Warehouse: {data.attributes.warehouse}</Typography>
                    <Typography variant="h6">Warehouse Code: {data.attributes.warehouse_code}</Typography>
                </Grid>
                <Grid item xs={12} md={6} >
                    <Typography my={2} variant="body1">
                    <span style={{ fontSize: '24px', marginRight: '8px' }}>
                        <MdDateRange />
                    </span>
                    Inward Date: <br/>
                    <Typography variant="h6"> {formatDate(data.attributes.inward_date)} </Typography>

                    </Typography>
                    <Typography my={2} variant="body1">
                    <span style={{ fontSize: '24px', marginRight: '8px' }}>
                        <MdDateRange />
                    </span>
                    Entry Date: <br/> 
                    <Typography variant="h6">{formatDate(data.entry_date)}</Typography>
                    </Typography>
                    <Typography  variant="h6">Status: {data.status === '1' ? 'Active' : 'Inactive'}</Typography>
                    <Typography  variant="h6">
                    Lien Status: 
                    <span sx={{ marginTop: 2, marginLeft: 2}}>
                    {data.lien_status ? <MdOutlineCheckCircleOutline /> : <MdOutlineCancel />}
                    </span>
                    </Typography>
                </Grid>
              
            </Grid>

            </pre>
        )}

        </Box>
        </Container>
        
    );
};

export default AuditTrail;
