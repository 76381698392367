import React, { useState, useRef, useEffect } from "react";
import HawkEyeAppBar from "../../components/AppBar";
import DashboardCard from "../../components/DashboardCard";
import StatusCard from "../../components/StatusCard";
import AcceptRejectCard from "../../components/AcceptRejectCard";
import { Autocomplete } from '@mui/material';

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";


import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { MenuList, MenuItem, Divider, ListItemText, Switch, TextField, Button, Chip, FormControlLabel, Checkbox } from "@mui/material";
import Check from "@mui/icons-material/Check";
import ListItemIcon from "@mui/material/ListItemIcon";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Link } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { Pagination } from '@mui/material';
import { convertToMillion, convertToCommaSeperated } from "../../globals/utils"
import usePagination from './usePagination'; 


import { makeStyles } from "@mui/styles";

import instance from "../../globals/axios";

const PaginationComponent = ({ data, currentPage, handlePageChange }) => (
  <Grid container justifyContent="center">
      <Grid item>
          <Box textAlign="center">
              <Pagination
                  count={data.pages || 1} 
                  page={currentPage} 
                  onChange={handlePageChange}
              />
          </Box>
      </Grid>
  </Grid>
);

const HeaderComponent = ({ data, tokenCount, tokenQuantity, commodityCount }) => 
{
  const customChipClass = useStyles();

  return (
    <Grid container justifyContent="center">
    <Grid md={4}></Grid>
    {/* {commodityCount !== null && commodityCount !== "" && tokenQuantity !== null && tokenQuantity !== "" && ( */}
      <Grid container xs={12} md={8} mx='auto' sx={{ textAlign: "center" }} mb={0}>
        <Grid container mx="auto" sx={{ textAlign: "center" }}>
          <Grid item xs={2}>
            <Typography variant='body1' sx={{ textAlign: "right", fontWeight: "700" }}>
              Recently Added:
            </Typography>
          </Grid>
          <Grid item ml={2} sx={{ borderRight: "1px solid black" }} xs={2}>
            <Typography variant='body1'>
              Commodities
            </Typography>
            <Chip color='success' sx={{ color: "#fff" }} className={customChipClass.chipCustom} component='box' mx={2} variant='h6' label={convertToCommaSeperated(commodityCount)} />
          </Grid>
          <Grid item ml={2} sx={{ borderRight: "1px solid black" }} xs={3}>
            <Typography variant='body1'>
              Quantity
            </Typography>
            <Chip color='success' sx={{ color: "#fff" }} className={customChipClass.chipCustom} component='box' mx={2} variant='h6' label={`${(tokenQuantity / 1000).toLocaleString('en-IN', { maximumFractionDigits: 2 })} MT`} />
          </Grid>
          <Grid item ml={2} xs={2}>
            <Typography variant='body1'>
              Tokens 
            </Typography>
            <Chip color='success' sx={{ color: "#fff" }} className={customChipClass.chipCustom} component='box' mx={2} variant='h6' label={convertToCommaSeperated(tokenCount)} />
          </Grid>
        </Grid>
      </Grid>
    {/* )} */}
    </Grid>
  );
}



const FilterComponent = (props) => (  
  <Paper elevation={2} sx={{ ml: 3, p: 3, mt: 8 }} style={{ borderRadius: "10px", top: "65px", bottom: "0px", width: "20%", position: "fixed", overflow: "scroll" }}>
    <Box fullWidth>
      <MenuList dense>
          <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant='h6'>Filters</Typography>
          <Button
            color='error'
            variant='outlined'
            onClick={() => {
              props.handleClearFilters();
            }}
          >
            Clear
          </Button>
        </Grid>
        <Divider sx={{ my: 2 }} />
       
        <Typography variant='subtitle1' sx={{ fontWeight: "bold" }}>
          Inward period
        </Typography>
       
        <Box m={1}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div>
              <Typography ml={1} variant='subtitle1' sx={{ fontWeight: "bold" }}>
                From
              </Typography>
              <Box m={1}>
                <DatePicker
                  value={props.inwardDateFrom}
                  onChange={(date) => props.setInwardDateFrom(date)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Typography ml={1} variant='subtitle1' sx={{ fontWeight: "bold" }}>
                to
              </Typography>
              <Box m={1} mb={2}>
                <DatePicker
                  value={props.inwardDateTo}
                  onChange={(date) => props.setInwardDateTo(date)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </div>
          </LocalizationProvider>
        </Box>

        <Divider />

        <Box my={2}>
          <Autocomplete
            fullWidth
            options={props.warehouseOptions}
            getOptionLabel={(option) => option.wh_name}
            value={props.selectedWarehouse?.wh_pid === null ? null : props.selectedWarehouse}
            onChange={props.handleWarehouseChange}
            disableClearable
            renderInput={(params) => <TextField {...params} label="Warehouse Name" variant="outlined" />}
          />
        </Box>

        <Divider />
       

        <Box my={2}>
          <Autocomplete
            fullWidth
            options={props.commodityOptions}
            getOptionLabel={(option) => option.commodity_name}
            value={props.selectedCommodity?.com_id === null ? null : props.selectedCommodity}
            onChange={props.handleCommodityChange}
            disableClearable
            renderInput={(params) => <TextField {...params} label="Commodity" variant="outlined" />}
          />
        </Box>

        <Divider />

        <Box my={2}>
        <Autocomplete
            fullWidth
            options={props.locations}
            getOptionLabel={(option) => option.location}
            value={props.selectedLocation?.location === null ? null : props.selectedLocation}
            onChange={props.handleLocationSelect}
            disableClearable
            renderInput={(params) => (
              <TextField 
                {...params} 
                label="Location" 
                variant="outlined" 
                defaultValue={props.selectedLocation ? props.selectedLocation.location : "Select a Location"} // Set the placeholder here
              />
            )}
          />
        </Box>

        <Button variant='contained' fullWidth color='primary' onClick={props.handleApplyFilters}>
          <Typography variant='subtitle1' color='white'>
            Apply Filters
          </Typography>
        </Button>

      </MenuList>
    </Box>
  </Paper>
);

const useStyles = makeStyles(() => ({
  chipCustom: {
    margin: "0 1rem",
    borderRadius: "9px", //some style
    "& .MuiChip-label": { fontSize: 14, fontWeight: "bold" }, // sub-selector
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.6em",
    },
    "*::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "#f8f9fd",
    },
    "*::-webkit-scrollbar-thumb": {
      borderRadius: "15px",
      backgroundColor: "#c1c1c1",
    },
    "*::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#214383",
    },
  },
}));

const BankerScreen = () => {


  const linkStyle = {
    textDecoration: "none", // Remove link decoration
    // Add any additional custom styling as needed
  };

  const customChipClass = useStyles();

  const [tabvalue, setTabvalue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);


  const [inwardDateFrom, setInwardDateFrom] = useState(null);
  const [inwardDateTo, setInwardDateTo] = useState(null);
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [commodityOptions, setCommodityOptions] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState({ wh_pid: null });
  const [selectedCommodity, setSelectedCommodity] = useState({ com_id: null });
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({ location: null});

  

    useEffect(() => {
        // const fetchData = async () => {
        //   try {
        //     const response = await instance.get('/wr/getHeaderData'); 
        //     setCommodityCount(response.data.data[0].commodity_count);
        //     setTokenCount(response.data.data[0].token_count);
        //     setTokenQuantity(response.data.data[0].token_quantity);
        //   } catch (error) {
        //     console.error('Error fetching data:', error);
        //   }
        // };

        const fetchDataFilter = async () => {
          try {
            const response = await instance.get('wr/getFilterData');
            const { warehouse_data, commodity_data, location } = response.data.data[0];
            setWarehouseOptions(warehouse_data);
            setCommodityOptions(commodity_data);
            setLocations(location);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };

        fetchDataFilter();

      return;


    }, []);

    const handleWarehouseChange = (_, value) => setSelectedWarehouse(value);
    const handleCommodityChange = (_, value) => setSelectedCommodity(value);
    const handleLocationSelect = (_, value) => setSelectedLocation(value)




    const lienDataReqBody = {
      fundable: [1],
      lien_status: [1],
      page: 0,
      limit: 20,
    };

    const fundedDataReqBody = {
      fundable: [0],
      lien_status: [1],
      page: 0,
      limit: 20,
    };

    const [responseData, setResponseData] = useState({});
    const [lienData, setLienData] = useState({});
    const [fundedData, setFundedData] = useState({});


    const [depositor, setDepositor] = useState(null);
    const [results, setResults] = useState([]);
    const [selectedDepositor, setSelectedDepositor] = useState(null);
    const [depositorSelected, setDepositorSelected] = useState(false);

    const fetchTokensData = async () => {

      const viewTokenBody = {
        loan_status: 1,
        status: [1],
        page: 0,
        limit: 20,
        lien_status: [0],
        fundable: [1],
        depositor: selectedDepositor.phone_number
      };
      
      try {
        const response = await instance.post("banker/getTokenData", viewTokenBody);
        console.log("ResponseData", response);
        setResponseData(response.data);
        setDepositorSelected(true)
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await instance.get('banker/getConsentUserData');
          setResults(response.data.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }, []);

    const handleInputChange = (e, value) => {
      setDepositor(value);
      setSelectedDepositor(null); // Reset selectedDepositor when input changes
    };

    const handleButtonClick = () => {
      if (selectedDepositor) {
        console.log('Selected Depositor Info:', selectedDepositor);
        fetchTokensData()
      }
    };

    const filteredResults = results.filter((item) => {
      const depositorString = (depositor || '').toString().toLowerCase();
      return (
        item.phone_number.includes(depositorString) ||
        item.first_name.toLowerCase().includes(depositorString) ||
        item.last_name.toLowerCase().includes(depositorString)
      );
    });

      // Fetch Paginated WR data
      const [currentPageViewToken, setCurrentPageViewToken] = useState(1);

      const fetchViewTokenData = async (page) => {
        const viewTokenBody = {
          loan_status: 1,
          status: [1],
          limit: 20,
          lien_status: [0],
          fundable: [1],
          depositor: selectedDepositor.phone_number
        };

        try {
          setLoading(true)
          const res = await instance.post("getTokenDataForUsers", {
            ...viewTokenBody,
            // page: page - 1, 
            page: page,
            ...(inwardDateFrom && inwardDateTo && { inward_date_from: inwardDateFrom, inward_date_to: inwardDateTo }),
            ...(selectedWarehouse.wh_pid && { wh_pid: [parseInt(selectedWarehouse.wh_pid)] }),
            ...(selectedCommodity.com_id && { commodity_id: [selectedCommodity.com_id] }),
            ...(selectedLocation.location && { location: [selectedLocation.location] } )

          });
          setResponseData(res.data);
          setLoading(false)
        } catch (error) {
          console.error(error);
        }
      };

      useEffect(() => {
        fetchViewTokenData(currentPageViewToken);
      }, [currentPageViewToken]);

      const handlePageChangeViewToken = (event, page) => {
        setCurrentPageViewToken(page);
      };


      // fetch Paginated Lien data
      const [currentPageLienData, setCurrentPageLienData] = useState(0);

      const fetchLienData = async (page) => {
        try {
          setLoading1(true)
          const res = await instance.post("getTokenDataForUsers", {
            ...lienDataReqBody,
            // page: page - 1, 
            page: page,
            ...(inwardDateFrom && inwardDateTo && { inward_date_from: inwardDateFrom, inward_date_to: inwardDateTo }),
            ...(selectedWarehouse.wh_pid && { wh_pid: [parseInt(selectedWarehouse.wh_pid)] }),
            ...(selectedCommodity.com_id && { commodity_id: [selectedCommodity.com_id] }),
            ...(selectedLocation.location && { location: [selectedLocation.location] } )

          });
          setLienData(res.data);
          setLoading1(false)
        } catch (error) {
          setLoading1(false)
          console.error(error);
        }
      };

      useEffect(() => {
        if (currentPageLienData===0) {
          setCurrentPageLienData(1)
          return;
        }
        fetchLienData(currentPageLienData);
      }, [currentPageLienData]);

      const handlePageChangeLienData = (event, page) => {
        setCurrentPageLienData(page);
      };


      // Fetch Paginated Funded WR data
      const [currentPageFundedData, setCurrentPageFundedData] = useState(0);

      const fetchFundedData = async (page) => {
        try {
          setLoading2(true)
          const res = await instance.post("getTokenDataForUsers", {
            ...fundedDataReqBody,
            // page: page - 1, 
            page: page,
            ...(inwardDateFrom && inwardDateTo && { inward_date_from: inwardDateFrom, inward_date_to: inwardDateTo }),
            ...(selectedWarehouse.wh_pid && { wh_pid: [parseInt(selectedWarehouse.wh_pid)] }),
            ...(selectedCommodity.com_id && { commodity_id: [selectedCommodity.com_id] }),
            ...(selectedLocation.location && { location: [selectedLocation.location] } )

          });
          setFundedData(res.data);
          setLoading2(false)
        } catch (error) {
          setLoading2(false)
          console.error(error);
        }
      };

      useEffect(() => {
        if (currentPageFundedData===0) {
          setCurrentPageFundedData(1)
          return;
        }
        fetchFundedData(currentPageFundedData);
      }, [currentPageFundedData]);


      const handlePageChangeFundedData = (event, page) => {
        setCurrentPageFundedData(page);
      };

      // Filter actions
      const handleApplyFilters = () => {

        fetchFundedData(currentPageFundedData);
        fetchLienData(currentPageLienData);
        fetchViewTokenData(currentPageViewToken)
      };    

      const handleClearFilters = () => {
        setInwardDateFrom(null);
        setInwardDateTo(null);
        setSelectedWarehouse({ wh_pid: null });
        setSelectedCommodity({ com_id: null });
        setSelectedLocation({ location: null });
      }

      const isFirstRender = useRef(true);

      useEffect(() => {
        if (isFirstRender.current) {
          isFirstRender.current = false;
          return;
        }
      
        if (selectedWarehouse.wh_pid === null && selectedCommodity.com_id === null && selectedLocation.location === null) {
          fetchFundedData(currentPageFundedData);
          fetchLienData(currentPageLienData);
          fetchViewTokenData(currentPageViewToken)
        }
      }, [selectedWarehouse, selectedCommodity, selectedLocation]);

      // Counter variables
      const [tokenCount, setTokenCount] = useState(null);
      const [commodityCount, setCommodityCount] = useState(null);
      const [tokenQuantity, setTokenQuantity] = useState(null);

      const [wrNumber, setWRNumber] = useState('');

      const handleChange = (event, newValue) => {
          // const reqBody = tabvalue === 1 ? lienDataReqBody : fundedDataReqBody;
          // fetchHeaderData(reqBody);
          setTabvalue(newValue);
      };

      const fetchHeaderData = () => {

        const body = tabvalue === 1 ? lienDataReqBody : fundedDataReqBody;

        instance.post('/wr/getHeaderData', body)
          .then((response) => {
            const { token_count, commodity_count, token_quantity } = response.data.data[0];
      
            setTokenCount(token_count);
            setCommodityCount(commodity_count);
            setTokenQuantity(token_quantity);

          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      };
    
    
      useEffect(() => {
        // const reqBody = tabvalue === 1 ? lienDataReqBody : fundedDataReqBody;
        fetchHeaderData();
      }, [tabvalue]);

      return (
        <div>
          <HawkEyeAppBar />
          <Grid container style={{ marginTop: "5rem" }}>
            <Grid xs={12} md={3}></Grid>
            <Grid xs={12} md={9} mx='auto'>
              <Box sx={{ bgcolor: "background.paper", padding: "2.5rem 0px" }}>
                <Tabs value={tabvalue} onChange={handleChange} centered outlined>
                  <Tab sx={{ fontSize: "1.15rem" }} label='View Warehouse Receipts' />
                  <Tab sx={{ fontSize: "1.15rem" }} label='Mark Funded' />
                  <Tab sx={{ fontSize: "1.15rem" }} label='View Funded' />
                </Tabs>
              </Box>
            </Grid>
          </Grid>
            
            <Box mb="3rem">
            {/* Uncomment Below to see consented UnLein Marked WRs  */}
            {/* {tabvalue === 0 && (

              depositorSelected ? (
                  <Grid container spacing={2}>
                  
                    <HeaderComponent tokenCount={tokenCount} commodityCount={commodityCount} tokenQuantity={tokenQuantity} />
                  
                    <Grid item xs={3}>
                    <FilterComponent 
                    handleClearFilters={handleClearFilters} handleApplyFilters={handleApplyFilters}
                    warehouseOptions={warehouseOptions} selectedWarehouse={selectedWarehouse} handleWarehouseChange={handleWarehouseChange} 
                    commodityOptions={commodityOptions} selectedCommodity={selectedCommodity} handleCommodityChange={handleCommodityChange}
                    locations={locations} selectedLocation={selectedLocation} handleLocationSelect={handleLocationSelect}
                    setInwardDateFrom={setInwardDateFrom} setInwardDateTo={setInwardDateTo} inwardDateFrom={inwardDateFrom} inwardDateTo={inwardDateTo}/>
                    </Grid>

                    <Grid item xs={9}>
                      <Paper elevation={0} sx={{ m: 2, px: 3 }}>
                        {Array.isArray(responseData.data) &&
                          responseData.data.map((item, index) => (
                            <Link key={index} to={`/banker-details/${index}`} state={{ data: item }} style={linkStyle}>
                              <Box>
                                <StatusCard status={item.attributes.status} fundable={true} makeOfferButton={false} data={item} />
                              </Box>
                            </Link>
                          ))}
                      </Paper>

                      {
                        (Array.isArray(responseData.data) && responseData.data.length > 0) && (

                          <PaginationComponent data={responseData} currentPage={currentPageViewToken} handlePageChange={handlePageChangeViewToken} />
                        )
                      }

                    </Grid>
                  </Grid>
              ) : (
                <Grid container fullWidth>
                    <Grid item xs={6} mx="auto" mt="2rem" flex>
                    <Typography variant="h5" sx={{ justifyContent: "center", textAlign:"center"}} >
                      Select depositor
                    </Typography>

                    <Grid xs={10} mx="auto" my={4}>
                    
                      <Autocomplete
                        fullWidth
                        options={filteredResults}
                        getOptionLabel={(option) => option.first_name + ' ' + option.last_name + ' - ' + option.phone_number}
                        renderInput={(params) => <TextField {...params} label="Search by phone number, first name, or last name" placeholder="Start typing..." />}
                        value={depositor}
                        onChange={(event, value) => {
                          setDepositor(value);
                          setSelectedDepositor(value);
                        }}
                      />
                        {selectedDepositor && (
                          <Grid mx="auto" my={4} xs={6}>
                            <Button fullWidth variant="outlined" color="primary" onClick={handleButtonClick}>
                              Get Warehouse Receipts
                            </Button>
                          </Grid>
                        )}
                    </Grid>
                  </Grid>


                </Grid>
              )
            
            )} */}

            {tabvalue === 0 && (
                        <Grid container justifyContent="center" alignItems="center" >

                        <Grid xs={5} mx="auto" my={4} p={4}>

                          <TextField
                            label="Search By Warehouse Receipt Number"
                            variant="outlined"
                            fullWidth
                            value={wrNumber}
                            onChange={(e) => setWRNumber(e.target.value)}
                          />
                          <Grid mx="auto" my={4} xs={9}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  if (wrNumber) {
                                    const url = `/wr-details/${wrNumber}`;
                                    window.open(url, '_blank');
                                  }
                                }}
                                style={{ marginTop: '10px' }}
                                  >
                                  Get Warehouse Receipt
                              </Button>
                            
                          </Grid>
                        </Grid>
                        </Grid>
              )
            }


            {tabvalue === 1 && (
              <Grid container spacing={2}>
                <HeaderComponent tokenCount={tokenCount} commodityCount={commodityCount} tokenQuantity={tokenQuantity} />


                <Grid item xs={3}>
                <FilterComponent 
                handleClearFilters={handleClearFilters} handleApplyFilters={handleApplyFilters}
                warehouseOptions={warehouseOptions} selectedWarehouse={selectedWarehouse} handleWarehouseChange={handleWarehouseChange} 
                commodityOptions={commodityOptions} selectedCommodity={selectedCommodity} handleCommodityChange={handleCommodityChange}
                locations={locations} selectedLocation={selectedLocation} handleLocationSelect={handleLocationSelect}
                setInwardDateFrom={setInwardDateFrom} setInwardDateTo={setInwardDateTo} inwardDateFrom={inwardDateFrom} inwardDateTo={inwardDateTo}/>
                </Grid>

                {
                  loading1 ? 
                  (
                  <Box sx={{ mx: "auto"}}>
                    <Box sx={{ my:"auto", mx:"auto", display: 'flex' }}>
                      <CircularProgress 
                        sx={{ mt:"5rem", mx:"auto", display: 'flex' }}
                          size={45} 
                      />
                    </Box>
                    <Grid sx={{ mx: "auto" }}>
                      <Typography variant="h5" sx={{ mt:"1rem", mx:"auto", display: 'flex' }} >
                      Fetching tokens ...
                      </Typography>
                    </Grid>
                  </Box>
                ) : 
                ( 
                <Grid item xs={9}>
                  <Paper elevation={0} sx={{ m: 2, px: 3 }}>
                    {Array.isArray(lienData.data) &&
                      lienData.data.map((loanRequest, index) => (
                        <Link key={index} to={`/mark-funded/${index}`} state={{ data: loanRequest }} style={linkStyle}>
                          <Box>
                            <StatusCard status={loanRequest.attributes.status} lienMarked={true} markFunded={true} data={loanRequest} />
                          </Box>
                        </Link>
                      ))}
                  </Paper>

                  {
                    (Array.isArray(lienData.data) && lienData.data.length > 0) && (
                      <PaginationComponent data={lienData} currentPage={currentPageLienData} handlePageChange={handlePageChangeLienData} />
                    )
                  }

                </Grid>
                )
                }

              </Grid>
            )}


            {tabvalue === 2 && (
              <Grid container spacing={2}>
              
                <HeaderComponent tokenCount={tokenCount} commodityCount={commodityCount} tokenQuantity={tokenQuantity} />


                <Grid item xs={3}>
                <FilterComponent 
                handleClearFilters={handleClearFilters} handleApplyFilters={handleApplyFilters}
                warehouseOptions={warehouseOptions} selectedWarehouse={selectedWarehouse} handleWarehouseChange={handleWarehouseChange} 
                commodityOptions={commodityOptions} selectedCommodity={selectedCommodity} handleCommodityChange={handleCommodityChange}
                locations={locations} selectedLocation={selectedLocation} handleLocationSelect={handleLocationSelect}
                setInwardDateFrom={setInwardDateFrom} setInwardDateTo={setInwardDateTo} inwardDateFrom={inwardDateFrom} inwardDateTo={inwardDateTo}/>
                </Grid>

                {
                  loading2 ? 
                  (
                  <Box sx={{ mx: "auto"}}>
                    <Box sx={{ my:"auto", mx:"auto", display: 'flex' }}>
                      <CircularProgress 
                        sx={{ mt:"5rem", mx:"auto", display: 'flex' }}
                          size={45} 
                      />
                    </Box>
                    <Grid sx={{ mx: "auto" }}>
                      <Typography variant="h5" sx={{ mt:"1rem", mx:"auto", display: 'flex' }} >
                      Fetching tokens ...
                      </Typography>
                    </Grid>
                  </Box>
                ) : 
                ( 
                <Grid item xs={9}>
                  <Paper elevation={0} sx={{ m: 2, px: 3 }}>
                    {Array.isArray(fundedData.data) &&
                    fundedData.data.map((loanRequest, index) => (
                        <Link key={index} to={`/view-funded/${index}`} state={{ data: loanRequest }} style={linkStyle}>
                          <Box>
                            <StatusCard status={loanRequest.attributes.status} tokenFunded={true} data={loanRequest} />
                          </Box>
                        </Link>
                      ))}
                  </Paper>

                  {
                    (Array.isArray(fundedData.data) && fundedData.data.length > 0) && (
                      <PaginationComponent data={fundedData} currentPage={currentPageFundedData} handlePageChange={handlePageChangeFundedData} />
                    )
                  }

                </Grid>
                ) 
              }

              </Grid>
            )}
        
        

          </Box>

        </div>
      );
};

export default BankerScreen;
