import React, {useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { MenuList, ButtonBase, MenuItem, Divider, TextField, Switch, Button, Chip, FormControlLabel, Checkbox } from "@mui/material";
import HawkEyeAppBar from "../../components/AppBar";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import TaskAltIcon from '@mui/icons-material/TaskAlt';


import instance from "../../globals/axios";
import dayjs from 'dayjs';


const useStyles = makeStyles({
    centeredHeader: {
      textAlign: 'center',
      mx: 'auto'
    },
    chip: {
        cursor: 'pointer',
        color: "#fff"
    },
    acceptedStatus: {
        color: '#214383', // Change to your desired color
        fontWeight: 'bold',
    },
      rejectedStatus: {
        color: 'red', // Change to your desired color
        fontWeight: 'bold',
    },
    approvedStatus: {
        color: 'green', // Change to your desired color
        fontWeight: 'bold',
    },
      pendingStatus: {
        color: 'gray', // Change to your desired color
        fontWeight: 'bold',
    },
  });


const NBFCTransactions = () => {
    const getRowClassName = (params) => {
        return 'centered-row'; // Apply the CSS class to center row content
    };
    const classes = useStyles();
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const handleChipClick = (row) => {
      navigate(`/wr-details/${row.wr_reciept_no}`);
    };

    function formatDate({ inputDate }) {
        // const formattedDate = dayjs(inputDate).format('DD MMM YYYY');
        const formattedDate = dayjs(inputDate).format('hh:mm A DD MMM YYYY');
        return formattedDate;
    }

    const generateAuditTrailLink = (wr_reciept_no) => {return (`/audit-trail/${wr_reciept_no}`)};

    useEffect(() => {
        const fetchTransactions = async () => {
          try {
            const response = await instance.get('nbfc/getTransactions');
            const transactionsWithIds = response.data.data.map((transaction, index) => ({
                ...transaction,
                created_at: formatDate(transaction.created_at),
                id: index + 1, // You can use a more sophisticated method to generate unique IDs
            }));
      
            setTransactions(transactionsWithIds);
            console.log(transactionsWithIds)
            setLoading(false);
          } catch (error) {
            console.error('Error fetching transactions:', error);
            setLoading(false);
          }
        };
    
        fetchTransactions();
      }, []);


    const columns = [
        { field: 'token_code', headerName: 'Token Code', width: 160, align: 'center', headerAlign: 'center', editable: false },
        { field: 'wr_reciept_no', headerName: 'WR no.', 
            renderCell: (params) => (
                <Chip
                    label={params.value}
                    className={classes.chip}
                    onClick={() => handleChipClick(params.row)}
                    clickable
                    color="primary"
                    variant="outlined"
                />
            ),
        width: 120, align: 'center', headerAlign: 'center', editable: false },
        { field: 'loan_offer_id', headerName: 'Loan Offer Id', width: 100, align: 'center', headerAlign: 'center', editable: false },
        { field: 'loan_offer_status', headerName: 'Loan Offer Status',
            renderCell: (params) => (
                <span
                className={params.value === 'Accepted' ? classes.acceptedStatus :
                    params.value === 'Rejected' ? classes.rejectedStatus :
                    params.value === 'Approved' ? classes.approvedStatus :
                    classes.pendingStatus}
                >
                {params.value}
                </span>
            ),
        width: 140, align: 'center', headerAlign: 'center', editable: false },
        { field: 'qty', headerName: 'Quantity', width: 100, align: 'center', headerAlign: 'center', editable: false },
        { field: 'created_at', headerName: 'Created at', width: 180, align: 'center', headerAlign: 'center', editable: false },
        { field: 'created_by', headerName: 'Created by', width: 150, align: 'center', headerAlign: 'center', editable: false },
        { field: 'accepted_by', headerName: 'Accepted by', width: 150, align: 'center', headerAlign: 'center', editable: false },
        { field: 'rejected_by', headerName: 'Rejected by', width: 150, align: 'center', headerAlign: 'center', editable: false },
        { field: 'approval_status', headerName: 'Approval Status', width: 150, align: 'center', headerAlign: 'center', editable: false },
        {
            field: 'audti_trail',
            headerName: 'Audit Trail',
            width: 175,
            align: 'center',
            renderCell: (params) => (
                <Button variant="contained" sx={{ mx:2, color:"#fff"}} color='success' 
                startIcon={<TaskAltIcon />}
                onClick={() => {
                    window.location.href = `/audit-trail/${params.row.wr_reciept_no}`;
                }}> 
                    Audit Trail
                </Button>
            ),
            headerAlign: 'center',
          },
      ];


    return (
        <div> 
        <HawkEyeAppBar />
        <Grid sx={{borderRadius:"1rem", border:"2px solid #d8daed", backgroundColor: "#fff", p:2, m:"7rem 7rem"}} >
        <Typography variant="h5" sx={{ px:2, py:1, textAlign: "center",fontWeight: 500}}>
                My Transactions
        </Typography> 
        <Box sx={{ height: 600, m:4 }}>
                <DataGrid
                    rows={transactions} 
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 10,
                        },
                    },
                    }}
                    pageSizeOptions={[10]}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    disableRowSelectionOnClick
                />
        </Box>
        </Grid>
        </div>
    )
}

export default NBFCTransactions