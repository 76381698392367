import React, { useState } from "react";
import HawkEyeAppBar from "../../components/AppBar";
import { Box, Divider, Typography, Grid, Chip, Link, Button, ImageList, Card, CardContent, ImageListItem } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { GiGrain, GiWeight } from "react-icons/gi";
import { TbCertificate } from "react-icons/tb";
import Token from "../../components/Popup/TokenAccepted";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const DashboardDetails = () => {
  const [tabvalue, setTabvalue] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [tokenStatus, setTokenStatus] = useState("");
  const handleTokenVerification = (status) => {
    // Here you would add your code to handle the token verification
    setTokenStatus(status);
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };
  const handleTokenAccept = () => {
    // Here you would add your code to handle the token acceptance
    setTokenStatus("accepted");
    setIsPopupOpen(false);
  };
  const handleTokenReject = () => {
    // Here you would add your code to handle the token rejection
    setTokenStatus("rejected");
    setIsPopupOpen(false);
  };

  let popupMessage = "";
  if (tokenStatus === "accepted") {
    popupMessage = "Token Accepted";
  } else if (tokenStatus === "rejected") {
    popupMessage = "Token Rejected";
  }

  const handleChange = (event, newValue) => {
    setTabvalue(newValue);
  };

  const data = {
    "cargo_gross_wt": 10,
    "truck_gross_wt": 11,
    "truck_no": "HR 37 OG 5463",
    "truck_tare_wt": 1,
    "bag_details": {
      "type": "string",
      "weight": 100,
      "number": 20,
      "created_at": "2023-05-23T10:32:49.820Z",
      "gdn_no": "string",
      "stack_no": "HRINS132"
     },
    "weighbridge_receipt": 
      {
       "name": "Sitaram Kumar, Kolad",
       "receipt_hash": "0xalvnsasavdsa516451",
       "slip_no": "string"
      }
  };

  return (
    <Box fullWidth>
      <HawkEyeAppBar />
      <Box mx='auto' sx={{ bgcolor: "background.paper", padding: "1.5rem 8rem" }}>
        <Grid m={1}>
          <Link href='/dashboard'>
            <Chip icon={<KeyboardBackspaceIcon />} style={{ cursor: "pointer" }} label='Dashboard' variant='outlined' />
          </Link>
        </Grid>
        <Divider />
        <Grid fullWidth>
          <Grid container mx='auto'>
            <Grid xs={12} p={6} md={6} sx={{ borderRadius: "5px" }}>
              <ImageList cols={1} sx={{ width: "500px", borderRadius: "15px" }}>
                <ImageListItem>
                  <img
                    src='https://images.unsplash.com/photo-1615485020475-ba867eb72d7f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80'
                    alt={"details"}
                    loading='lazy'
                    style={{ width: "50%" }}
                  />
                </ImageListItem>
              </ImageList>
            </Grid>
            <Grid xs={12} md={6} py={4}>
              <Card sx={{ w: "100%", my: 4, backgroundColor: "#E8E5CE" }}>
                <CardContent sx={{ p: 3 }}>
                  <Grid container>
                    <Grid xs={10}>
                      <Typography item xs={4} sx={{ fontWeight: "500" }} variant='h5' gutterBottom>
                        Wheat, Sharbati
                      </Typography>
                    </Grid>
                    {/* <Grid ml="55%" > */}
                    <Box elevation={1} sx={{ border: "1px solid #f5a113", backgroundColor: "#f5a113", borderRadius: "10px", textAlign: "center", pt: 1, px: 1 }}>
                      <Typography item xs={4} variant='body1' color='white' gutterBottom>
                        Fundable
                      </Typography>
                    </Box>
                    {/* </Grid> */}
                  </Grid>

                  <hr />
                  <Grid>
                    <Grid container p={0.5}>
                      <Grid xs={4}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "400" }}>Commodity type:</Typography>
                      </Grid>
                      <Grid xs={8}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>Grain</Typography>
                      </Grid>
                    </Grid>
                    <Grid container p={0.5}>
                      <Grid xs={4}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "400" }}>Warehouse Details:</Typography>
                      </Grid>
                      <Grid xs={8}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>
                          Godown: G1, Stack: 1 <br />
                          Godown Of Gaurav Jindal S/O Bharat Bhushan
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container p={0.5}>
                      <Grid xs={4}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "400" }}>Location:</Typography>
                      </Grid>
                      <Grid xs={8}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>Cheeka, Haryana</Typography>
                      </Grid>
                    </Grid>
                    <Grid container p={0.5}>
                      <Grid xs={4}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "400" }}>Quantity:</Typography>
                      </Grid>
                      <Grid xs={8}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>26.1 MT</Typography>
                      </Grid>
                    </Grid>
                    <Grid container p={0.5}>
                      <Grid xs={4}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "400" }}>Date:</Typography>
                      </Grid>
                      <Grid xs={8}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>22 August, 2023</Typography>
                      </Grid>
                    </Grid>
                    <Grid container p={0.5}>
                      <Grid xs={4}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "400" }}>Owner:</Typography>
                      </Grid>
                      <Grid xs={8}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>09faa3e54550e6966ccfd5....</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          
          <Typography variant='h4'>Additional Details</Typography>
          <Grid xs={12}>
            <Card sx={{ w: "100%", my: 4, backgroundColor: "#E8E5CE" }}>
              <CardContent sx={{ p: 3 }}>
                <Grid mb={1} container>
                  <Grid xs={10}>
                    <Typography item xs={4} variant='h6' gutterBottom>
                      General Details
                    </Typography>
                  </Grid>
                </Grid>

                <Divider />
                <Grid container fullWidth mt={2}>
                  <Grid xs={12} md={6}>
                    <Grid ml={4} container p={0.5}>
                      <Grid xs={4}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "400" }}>CAD Number:</Typography>
                      </Grid>
                      <Grid xs={8}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>HTD615656</Typography>
                      </Grid>
                    </Grid>
                    <Grid ml={4} container p={0.5}>
                      <Grid xs={4}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "400" }}>GRN Number:</Typography>
                      </Grid>
                      <Grid xs={8}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>BMKFSBANVIDSKV51515</Typography>
                      </Grid>
                    </Grid>
                    <Grid ml={4} container p={0.5}>
                      <Grid xs={4}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "400" }}>Remarks:</Typography>
                      </Grid>
                      <Grid xs={8}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>N.A.</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Grid container p={0.5}>
                      <Grid xs={4}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "400" }}>Gate Pass Number:</Typography>
                      </Grid>
                      <Grid xs={8}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>584341</Typography>
                      </Grid>
                    </Grid>
                    <Grid container p={0.5}>
                      <Grid xs={4}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "400" }}>Weighbridge Name:</Typography>
                      </Grid>
                      <Grid xs={8}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>Shree Kanta Dharam Kanta</Typography>
                      </Grid>
                    </Grid>
                    <Grid container p={0.5}>
                      <Grid xs={4}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "400" }}>Warehouse:</Typography>
                      </Grid>
                      <Grid xs={8}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>
                          Godown G1245 Stack 254 <br />
                          Gangaram Warehouse
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid xs={12}>
            <Card sx={{ w: "100%", my: 4, backgroundColor: "#E8E5CE" }}>
              <CardContent sx={{ p: 3 }}>
                <Grid mb={1} container>
                  <Grid xs={10}>
                    <Typography item xs={4} variant='h6' gutterBottom>
                      Truck Details
                    </Typography>
                  </Grid>
                </Grid>

                <Divider />
                <Grid sx={{ mx: "auto", width: "70%"}} flex mt={2}>
                  <Accordion sx={{ mx: "auto" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>Truck Number: HR 16 MN 1566</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="subtitle1">
                      Cargo Weight: {data.cargo_gross_wt} MT
                      </Typography>
                      <Typography variant="subtitle1">
                      Truck Gross Weight: {data.truck_gross_wt} MT
                      </Typography>
                      <Typography variant="subtitle1">
                      Truck Tare Weight: {data.truck_tare_wt} MT
                      </Typography>
                      <Typography variant="subtitle1">
                      Weight Bridge Receipt Name: {data.weighbridge_receipt.name} 
                      </Typography>
                      <Typography variant="subtitle1">
                      Truck Tare Weight: {data.truck_tare_wt} MT
                      </Typography>
                      
                      
                    </AccordionDetails>
                  </Accordion>

                  <Accordion sx={{ mx: "auto" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography sx={{ fontSize: "1.35rem", fontWeight: "500" }}>Truck Number: HR 32 KR 5610</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="subtitle1">
                      Cargo Weight: {data.cargo_gross_wt} MT
                      </Typography>
                      <Typography variant="subtitle1">
                      Truck Gross Weight: {data.truck_gross_wt} MT
                      </Typography>
                      <Typography variant="subtitle1">
                      Truck Tare Weight: {data.truck_tare_wt} MT
                      </Typography>
                      <Typography variant="subtitle1">
                      Weight Bridge Receipt Name: {data.weighbridge_receipt.name} 
                      </Typography>
                      <Typography variant="subtitle1">
                      Truck Tare Weight: {data.truck_tare_wt} MT
                      </Typography>
                      
                      
                    </AccordionDetails>
                  </Accordion>

                  <Accordion sx={{ mx: "auto" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography sx={{ fontSize: "1.35rem", fontWeight: "500" }}>Truck Number: {data.truck_no}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="subtitle1">
                      Cargo Weight: {data.cargo_gross_wt} MT
                      </Typography>
                      <Typography variant="subtitle1">
                      Truck Gross Weight: {data.truck_gross_wt} MT
                      </Typography>
                      <Typography variant="subtitle1">
                      Truck Tare Weight: {data.truck_tare_wt} MT
                      </Typography>
                      <Typography variant="subtitle1">
                      Weight Bridge Receipt Name: {data.weighbridge_receipt.name} 
                      </Typography>
                      <Typography variant="subtitle1">
                      Truck Tare Weight: {data.truck_tare_wt} MT
                      </Typography>
                      
                      
                    </AccordionDetails>
                  </Accordion>
                
                
                 
                </Grid>
              </CardContent>
            </Card>
          </Grid>



          <Grid mt={4} xs={12}>
            <Card elevation={0} sx={{ w: "100%", mb:8, backgroundColor: "#fff" }}>
            <Typography mt="1rem" variant='h4'>
              <TbCertificate /> Average quality parameters
            </Typography>
            <Typography>Last updated at 06/07/2022</Typography>
              <CardContent sx={{ p: 3 }}>
                <Grid container fullWidth>
                  <Grid elevation={2} sx={{ borderRadius:"1rem", mx:"auto", textAlign: "center", p:2, backgroundImage: "linear-gradient(to right bottom, #fff, #ececf6, #ececf6, #d8daed, #ececf6)"}} 
                  xs={2}>
                      <Typography sx={{ fontSize: "1.35rem", fontWeight: "500" }}>
                        Moisture
                      </Typography>
                      <Typography sx={{ fontSize: "1.35rem", fontWeight: "500" }}>
                        16%
                      </Typography>
                  </Grid>
                  <Grid elevation={2} sx={{ borderRadius:"1rem", mx:"auto", textAlign: "center", p:2, backgroundImage: "linear-gradient(to right bottom, #fff, #ececf6, #ececf6, #d8daed, #ececf6)"}} 
                  xs={2}>
                      <Typography sx={{ fontSize: "1.35rem", fontWeight: "500" }}>
                        Live Insect
                      </Typography>
                      <Typography sx={{ fontSize: "1.35rem", fontWeight: "500" }}>
                        0.09%
                      </Typography>
                  </Grid>
                  <Grid elevation={2} sx={{ borderRadius:"1rem", mx:"auto", textAlign: "center", p:2, backgroundImage: "linear-gradient(to right bottom, #fff, #ececf6, #ececf6, #d8daed, #ececf6)"}} 
                  xs={2}>
                      <Typography sx={{ fontSize: "1.35rem", fontWeight: "500" }}>
                        Weight Gain or Loss 
                      </Typography>
                      <Typography sx={{ fontSize: "1.35rem", fontWeight: "500" }}>
                        0.34%
                      </Typography>
                  </Grid>
                  <Grid elevation={4} sx={{ borderRadius:"1rem", mx:"auto", textAlign: "center", p:2, backgroundImage: "linear-gradient(to right bottom, #fff, #ececf6, #ececf6, #d8daed, #ececf6)"}} 
                  xs={2}>
                      <Typography sx={{ fontSize: "1.35rem", fontWeight: "500" }}>
                        Temperature
                      </Typography>
                      <Typography sx={{ fontSize: "1.35rem", fontWeight: "500" }}>
                        34 C
                      </Typography>
                  </Grid>
                  <Grid elevation={2} sx={{ borderRadius:"1rem", mx:"auto", textAlign: "center", p:2, backgroundImage: "linear-gradient(to right bottom, #fff, #ececf6, #ececf6, #d8daed, #ececf6)"}} 
                  xs={2}>
                      <Typography sx={{ fontSize: "1.35rem", fontWeight: "500" }}>
                        Foreign Matter
                      </Typography>
                      <Typography sx={{ fontSize: "1.35rem", fontWeight: "500" }}>
                        0.12%
                      </Typography>
                  </Grid>
                 
                  

                  {/* <Grid ml={4} container p={0.5}>
                    <Grid xs={8}>
                      <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>
                        <u>Parameter</u>
                      </Typography>
                    </Grid>
                    <Grid xs={4}>
                      <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>
                        <u>Value</u>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid ml={4} container p={0.5}>
                    <Grid xs={8}>
                      <Typography my={1} sx={{ fontSize: "1.25rem", fontWeight: "400" }}>
                        Moisture
                      </Typography>
                    </Grid>
                    <Grid xs={4}>
                      <Typography my={1} sx={{ fontSize: "1.25rem", fontWeight: "500" }}>
                        16%
                      </Typography>
                    </Grid>
                    <Grid my={1} xs={8}>
                      <Typography sx={{ fontSize: "1.25rem", fontWeight: "400" }}>Live Insect</Typography>
                    </Grid>
                    <Grid my={1} xs={4}>
                      <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>1.6%</Typography>
                    </Grid>
                    <Grid my={1} xs={8}>
                      <Typography sx={{ fontSize: "1.25rem", fontWeight: "400" }}>Foreign Matter</Typography>
                    </Grid>
                    <Grid my={1} xs={4}>
                      <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>0.38%</Typography>
                    </Grid>
                  </Grid> */}
                </Grid>
              </CardContent>
            </Card>

            <Box elevation={2} sx={{ borderRadius: "5px", mb:12, textAlign: "center", pt: 0.5 }}>
              {/* <Typography item xs={4} sx={{ fontSize: "1.25rem", fontWeight: "400" }} color='#33af4c' gutterBottom></Typography> */}

              <Button style={{ background: "#FD531D", color: "white", width: "200px", fontSize:"1.15rem" }} onClick={() => handleTokenVerification("rejected")}>
                Rejected
              </Button>
              <Button style={{ background: "#5A9A07", color: "white", width: "200px", fontSize:"1.15rem", marginLeft: "2rem" }} onClick={() => handleTokenVerification("accepted")}>
                Accepted
              </Button>
            </Box>
            {/* Popup component */}
            <Token isOpen={isPopupOpen} message={popupMessage} onClose={handlePopupClose} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DashboardDetails;
