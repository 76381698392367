import React, { useEffect, useState, useRef } from "react";
import HawkEyeAppBar from "../../components/AppBar";
import { styled } from '@mui/material/styles';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { MenuList, ButtonBase, MenuItem, Divider, Container, TextField, Button, Chip, CircularProgress } from "@mui/material";
import { Card, CardContent} from '@mui/material';
import { Link } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Autocomplete from '@mui/material/Autocomplete';
import instance from "../../globals/dashboard_instance";
import { Chart } from "react-google-charts";
import { convertToMillion, convertToCommaSeperated } from "../../globals/utils"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Stack from '@mui/material/Stack';


import { makeStyles } from "@mui/styles";


    const StyledDataCell = styled('div')({
        textAlign: 'right',
        paddingRight: '20px', 
    });

    const StyledHeaderCell = styled('div')({
        textAlign: 'right',
        paddingRight: '15px', 
    });

    const top5Columns1 = [
        { field: 'warehouse_name', headerName: 'Warehouse Name', flex: 1, headerClassName: 'styledHeader', headerAlign:"center", renderCell: (params) => <StyledDataCell>{params.value}</StyledDataCell> },
        { field: 'inward_count', headerName: 'Inward Count', flex: 1, align: 'right', headerAlign:"center", headerClassName: 'styledHeader', renderCell: (params) => <StyledDataCell>{params.value}</StyledDataCell> },
    ];

    const top5Columns2 = [
        { field: 'warehouse_name', headerName: 'Warehouse Name', flex: 1, headerClassName: 'styledHeader', headerAlign:"center", renderCell: (params) => <StyledDataCell>{params.value}</StyledDataCell> },
        { field: 'aum', headerName: 'AUM (in INR)', flex: 1, align: 'right', headerAlign:"center", headerClassName: 'styledHeader', renderCell: (params) => <StyledDataCell>{params.value}</StyledDataCell> },
    ];

    const top5Columns3 = [
        { field: 'warehouse_name', headerName: 'Warehouse Name', flex: 1, headerClassName: 'styledHeader', headerAlign:"center", renderCell: (params) => <StyledDataCell>{params.value}</StyledDataCell> },
        { field: 'inward_count', headerName: 'Quantity (in MT)', flex: 1, align: 'right', headerAlign:"center", headerClassName: 'styledHeader', renderCell: (params) => <StyledDataCell>{params.value}</StyledDataCell> },
    ];

    const Legend = ({ data, colors }) => (
        <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
            {data.map((status, index) => (
            <Box key={status} display="flex" alignItems="center" >
                <Box
                    width={16}
                    height={16}
                    marginRight={1}
                    borderRadius="50%"
                    bgcolor={colors[index]}
                />
                <Typography variant="body2">{status}</Typography>
            </Box>
            ))}
        </Stack>
    );


    const StakeholderDashboard = () => {
        const useStyles = makeStyles(() => ({
            chipCustom: {
            margin: "0 1rem",
            borderRadius: "9px", //some style
            "& .MuiChip-label": { fontSize: 14, fontWeight: "bold" }, // sub-selector
            },
            chartContainer: {
                position: 'relative',
            },
            chart: {
                position: 'absolute',
                top: 0,
                left: 0,
            },
            glassCard: {
                background: 'rgba(255, 255, 255, 0.25)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255, 255, 255, 0.125)',
                boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
                backdropFilter: 'blur(4px)',
                borderRadius: '10px',
                border: '1px solid rgba(255, 255, 255, 0.18)',
              },
              appContainer: {
                padding: '20px',
              },
        }));

        const classes = useStyles();

        const [warehouse_location, setWarehouse_location] = React.useState('');
        const [commodity, setCommodity] = React.useState('');


        const [stockInward, setStockInward] = useState({});
        const [stockOutward, setStockOutward] = useState({});
        const [stateStockInward, setStateStockInward] = useState({});
        const [stateStockOutward, setStateStockOutward] = useState({});
        const [stateStockQuantity, setStateStockQuantity] = useState({});
        const [stateStockLien, setStateStockLien] = useState({});
        


        const [tokenCount, setTokenCount] = useState(null);
        const [rejectedCount, setRejectedCount] = useState(null);
        const [pendingCount, setPendingCount] = useState(null);
        const [acceptedCount, setAcceptedCount] = useState(null);
        const [autoAcceptCount, setAutoAcceptCount] = useState(null);
        const [activeLienMarkCount, setActiveLienMarkCount] = useState(null);
        const [fundedTokenCount, setFundedTokenCount] = useState(null);
        const [totalGoodsManaged, setTotalGoodsManaged] = useState(null);
        const [noCommoditiesManaged, setNoCommoditiesManaged] = useState(null);
        const [lastUpdated, setLastUpdated] = useState(null);

        const [pieData1, setPieData1] = useState([
            ["Status", "Tokens"],
            ["Accepted", 0],
            ["Rejected", 0],
            ["Pending", 0],
            ["Auto Accept", 0],
        ]);
    
        const pieOptions1 = {
            // title: "Loans Distribution",
            pieHole: 0.45,
            is3D: true,
            colors: ['#4caf50','#e74c3c', '#78909c', '#00e676'],
            legend: 'none',
                                    chartArea: {
                                      width: '90%',
                                      height: '70%',
                                    },
        };

        const [ aumData, setAumData ] = useState(null)
        const [selectedAUMCommodity, setselectedAUMCommodity] = useState('');
        const [aumChartData, setAumChartData] = useState([]);
        const [commodityNames, setCommodityNames] = useState([]);

        const [selectedTab, setSelectedTab] = useState(0);
        const [selectedTop5Commodity, setSelectedTop5Commodity] = useState('');
        const [selectedTop5Location, setSelectedTop5Location] = useState('');
        const [selectedTop5State, setSelectedTop5State] = useState('');
        const [locationData, setLocationData] = useState([]);
        const [stateData, setStateData] = useState([]);

        const [top5CommAUMData, setTop5CommAUMData] = useState('');
        const [top5CommInwardData, setTop5CommInwardData] = useState('');
        const [top5CommLienAUMData, setTop5CommLienAUMData] = useState('');
        const [top5CommLienQtyData, setTop5CommLienQtyData] = useState('');

        const [top5LocationAUMData, setTop5LocationAUMData] = useState('');
        const [top5LocationInwardData, setTop5LocationInwardData] = useState('');
        const [top5LocationLienAUMData, setTop5LocationLienAUMData] = useState('');
        const [top5LocationLienQtyData, setTop5LocationLienQtyData] = useState('');
        

        const [top5StateAUMData, setTop5StateAUMData] = useState('');
        const [top5StateInwardData, setTop5StateInwardData] = useState('');
        const [top5StateLienAUMData, setTop5StateLienAUMData] = useState('');
        const [top5StateLienQtyData, setTop5StateLienQtyData] = useState('');

        const aumPeriodOptions = ['daily', 'weekly', 'monthly', 'quarterly', 'yearly'];
        const [selectedAUMOption, setSelectedAUMOption] = useState('daily');
      
        for (const item in aumData) {

                aumData[item].map(commodity => {
                  
                    commodityNames.push(commodity.commodity_name);
                
                  return null; 
                });
        }
      
        const uniqueCommodityNames = [...new Set(commodityNames)];
        
        
        const [loading1, setLoading1] = useState(false);
        const [loading2, setLoading2] = useState(false);
        const [loading3, setLoading3] = useState(false);
        const [loading4, setLoading4] = useState(false);
        const [loading5, setLoading5] = useState(false);
        const [loading6, setLoading6] = useState(false);
        const [loading7, setLoading7] = useState(false);

        const [fromDate, setFromDate] = useState('');
        const [toDate, setToDate] = useState('');
      
        const handleAUMCommodityChange = (event, newValue) => {
            setselectedAUMCommodity(newValue);
            setSelectedAUMOption('daily');
            const newData = [];
        
            for (const item in aumData) {
                aumData[item].map(commodity => {
                    if (commodity.commodity_name === newValue) {
                        const valueInCrores = (commodity.CommodityValue / 10000000).toFixed(2);
                        let formattedKey;
        
                        if (selectedAUMOption === 'daily') {
                            const date = new Date(item);
                            const day = date.getDate().toString().padStart(2, '0');
                            const month = (date.getMonth() + 1).toString().padStart(2, '0');
                            const year = date.getFullYear();
                            formattedKey = `${day}-${month}-${year}`;
                        } else {
                            formattedKey = item;
                        }
        
                        newData.push([formattedKey, parseFloat(valueInCrores)]);
                    }
        
                    return null;
                });
            }
        
            setAumChartData(newData);
        };

        const handleTop5CommodityChange = async (event, newValue) => {
            setSelectedTop5Commodity(newValue);
            const params = {};
            if (toDate && fromDate) {
                params.to_date = toDate;
                params.from_date = fromDate;
            }
          
            try {
                setLoading1(true)
                const response1 = await instance.get('top-5/commodity-inward', { params });
                const data1 = response1.data[newValue].map((item, index) => ({
                    ...item,
                    id: index + 1, 
                }));
                setLoading1(false)
                setTop5CommInwardData(data1);
            } catch (error) {
                setLoading1(false)
                console.error('Error fetching data:', error);
            }
          
            try {
                setLoading1(true)
                const response2 = await instance.get('top-5/commodity-aum', { params });
                const data2 = response2.data[newValue].map((item, index) => ({
                    ...item,
                    aum: `₹${(item.aum / 10000000).toFixed(2)} Crore`,
                    id: index + 1, 
                }));
                setLoading1(false)
                setTop5CommAUMData(data2);
                } catch (error) {
                setLoading1(false)
                console.error('Error fetching data:', error);
            }

            try {
                const response3 = await instance.get('top-5/commodity-lien-aum', { params });
                const data3 = response3.data[newValue] || [];
                const mappedData3 = data3.map((item, index) => ({
                    ...item,
                    aum: `₹${(item.aum / 10000000).toFixed(2)} Crore`,
                    id: index + 1,
                }));
        
                setTop5CommLienAUMData(mappedData3);
            } catch (error) {
                console.error('Error fetching top 5 commodity AUM data:', error);
            }
        
            try {
                const response4 = await instance.get('top-5/commodity-lien-qty', { params });
                const data4 = response4.data[newValue] || [];
                const mappedData4 = data4.map((item, index) => ({
                    ...item,
                    inward_count: `${convertToCommaSeperated(item.inward_count)}`,
                    id: index + 1,
                }));
        
                setTop5CommLienQtyData(mappedData4);
            } catch (error) {
                console.error('Error fetching top 5 commodity AUM data:', error);
            } finally {
                setLoading1(false);
            }


        };

        const handleTop5LocationChange = async (event, newValue) => {
            setSelectedTop5Location(newValue);
            const params = {};
            if (toDate && fromDate) {
                params.to_date = toDate;
                params.from_date = fromDate;
            }
        
            setLoading2(true)
            try {
                const response1 = await instance.get('top-5/location-inward', { params });
                const data1 = response1.data[newValue].map((item, index) => ({
                    ...item,
                    id: index + 1,
                }));
                setLoading2(false)
                setTop5LocationInwardData(data1)
            } catch (error) {
                setLoading2(false)
                console.error('Error fetching top 5 commodity inward data:', error);
            }
        
            try {
                setLoading2(true)
                const response2 = await instance.get('top-5/location-aum', { params });
                const data2 = response2.data[newValue].map((item, index) => ({
                    ...item,
                    aum: `₹${(item.aum / 10000000).toFixed(2)} Crore`,
                    id: index + 1, 
                }));
                setLoading2(false)
                setTop5LocationAUMData(data2)         
            } catch (error) {
                setLoading2(false)
                console.error('Error fetching top 5 location AUM data:', error);
            }

            try {
                const response3 = await instance.get('top-5/location-lien-aum', { params });
                const data3 = response3.data[newValue] || [];
                const mappedData3 = data3.map((item, index) => ({
                    ...item,
                    aum: `₹${(item.aum / 10000000).toFixed(2)} Crore`,
                    id: index + 1,
                }));
        
                setTop5LocationLienAUMData(mappedData3);
            } catch (error) {
                console.error('Error fetching top 5 location AUM data:', error);
            }
        
            try {
                const response4 = await instance.get('top-5/location-lien-qty', { params });
                const data4 = response4.data[newValue] || [];
                const mappedData4 = data4.map((item, index) => ({
                    ...item,
                    inward_count: `${convertToCommaSeperated(item.inward_count)}`,
                    id: index + 1,
                }));
        
                setTop5LocationLienQtyData(mappedData4);
            } catch (error) {
                console.error('Error fetching top 5 commodity AUM data:', error);
            } finally {
                setLoading2(false);
            }
        };
        
        const handleTop5StateChange = async (event, newValue) => {
            setSelectedTop5State(newValue);
            const params = {};
            if (toDate && fromDate) {
                params.to_date = toDate;
                params.from_date = fromDate;
            }
        
            try {
                setLoading3(true);
        
                const response1 = await instance.get('top-5/state-inward', { params });
                const data1 = response1.data[newValue] || [];
                const mappedData1 = data1.map((item, index) => ({
                    ...item,
                    inward_count: `${convertToCommaSeperated(item.inward_count)}`,
                    id: index + 1,
                }));
        
                setTop5StateInwardData(mappedData1);
            } catch (error) {
                console.error('Error fetching top 5 commodity inward data:', error);
            }
        
            try {
                const response2 = await instance.get('top-5/state-aum', { params });
                const data2 = response2.data[newValue] || [];
                const mappedData2 = data2.map((item, index) => ({
                    ...item,
                    aum: `₹${(item.aum / 10000000).toFixed(2)} Crore`,
                    id: index + 1,
                }));
        
                setTop5StateAUMData(mappedData2);
            } catch (error) {
                console.error('Error fetching top 5 commodity AUM data:', error);
            }
        
            try {
                const response3 = await instance.get('top-5/state-lien-aum', { params });
                const data3 = response3.data[newValue] || [];
                const mappedData3 = data3.map((item, index) => ({
                    ...item,
                    aum: `₹${(item.aum / 10000000).toFixed(2)} Crore`,
                    id: index + 1,
                }));
        
                setTop5StateLienAUMData(mappedData3);
            } catch (error) {
                console.error('Error fetching top 5 commodity AUM data:', error);
            }
        
            try {
                const response4 = await instance.get('top-5/state-lien-qty', { params });
                const data4 = response4.data[newValue] || [];
                const mappedData4 = data4.map((item, index) => ({
                    ...item,
                    inward_count: `${convertToCommaSeperated(item.inward_count)}`,
                    id: index + 1,
                }));
        
                setTop5StateLienQtyData(mappedData4);
            } catch (error) {
                console.error('Error fetching top 5 commodity AUM data:', error);
            } finally {
                setLoading3(false);
            }
        };
        
        const fetchGeneralData = async (fromDate, toDate) => {

            const params = {};
            if (toDate && fromDate) {
                params.to_date = toDate;
                params.from_date = fromDate;
            }

            try {
              const response = await instance.get('dashboard-data/', { params });
              const {
                token_count,
                rejected_count,
                pending_count,
                accepted_count,
                auto_accept_count,
                active_lien_mark_count,
                funded_token_count,
                total_goods_managed,
                no_commodities_managed,
                last_updated
              } = response.data;
      
              setTokenCount(token_count);
              setRejectedCount(rejected_count);
              setPendingCount(pending_count);
              setAcceptedCount(accepted_count);
              setAutoAcceptCount(auto_accept_count);
              setActiveLienMarkCount(active_lien_mark_count);
              setFundedTokenCount(funded_token_count);
              setTotalGoodsManaged(total_goods_managed.quantity__sum);
              setNoCommoditiesManaged(no_commodities_managed);
              setLastUpdated(last_updated)

              setPieData1([
                ["Status", "Tokens"],
                ["Accepted", accepted_count],
                ["Rejected", rejected_count],
                ["Pending", pending_count],
                ["Auto Accept", auto_accept_count],
              ]);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
        };

        const getStateStockInward = async (fromDate, toDate) => {
            
            const params = {};

            if (toDate && fromDate) {
                params.to_date = toDate;
                params.from_date = fromDate;
            }
        
            try {
                setLoading4(true)
                const response = await instance.get('state-stock-inward/', { params });
                setStateStockInward(response.data);
                setLoading4(false)
            } catch (error) {
                setLoading4(false)
                console.error(error);
            }
        };
        
        const getStateStockOutward = async (fromDate, toDate) => {
            const params = {};
            if (toDate && fromDate) {
                params.to_date = toDate;
                params.from_date = fromDate;
            }
        
            try {
                setLoading5(true)
                const response = await instance.get('state-stock-outward/', { params });
                setStateStockOutward(response.data);
                setLoading5(false)

            } catch (error) {
                setLoading5(false)
                console.error(error);
            }
        };

        const getStateStockQuantity = async (fromDate, toDate) => {
            
            const params = {};

            if (toDate && fromDate) {
                params.to_date = toDate;
                params.from_date = fromDate;
            }
        
            try {
                setLoading6(true)
                const response = await instance.get('state-qty-inward/', { params });
                setStateStockQuantity(response.data);
                setLoading6(false)
            } catch (error) {
                setLoading6(false)
                console.error(error);
            }
        };

        const getStateStockAUM = async (fromDate, toDate) => {
            
            const params = {};

            if (toDate && fromDate) {
                params.to_date = toDate;
                params.from_date = fromDate;
            }
        
            try {
                setLoading7(true)
                const response = await instance.get('/state-stock-lien/', { params });
                setStateStockLien(response.data);
                setLoading7(false)
            } catch (error) {
                setLoading7(false)
                console.error(error);
            }
        };
        

        const isFirstRender = useRef(true);

        const formatMMDate = (dateString) => {
            const [year, month, day] = dateString.split('-');
            const formattedDate = `${day}-${month}-${year}`;
            return formattedDate;
        };

      

        const handleUpdateClick = () => {
            if (!toDate || !fromDate) {
                alert('Please provide both "To Date" and "From Date"');
              } else if (toDate < fromDate) {
                alert('To date cannot be less than from date');
              } else {
                handleUpdateDateClick(toDate, fromDate);
            }
        };

        const handleUpdateDateClick = (to, from) => {
            handleTop5CommodityChange(null, selectedTop5Commodity);
            handleTop5LocationChange(null, selectedTop5Location)
            handleTop5StateChange(null, selectedTop5State)
            fetchGeneralData(from, to)
            getStateStockInward(from, to)
            getStateStockOutward(from, to)
            getStateStockQuantity(from, to)
            getStateStockAUM(from, to)
        };


        useEffect(() => {
            
          
            const fetchLocationData = async () => {
                try {
                  const response = await instance.get('/top-5/state-location');
                  setLocationData(response.data.location);
                } catch (error) {
                  console.error('Error fetching location data:', error);
                }
            };
          
            const fetchStateData = async () => {
                try {
                  const response = await instance.get('/top-5/state-location');
                  setStateData(response.data.state);
                } catch (error) {
                  console.error('Error fetching state data:', error);
                }
            };

            const fetchAUMData = async () => {
                instance.get('commodity-aum-7/')
                .then(response => {
                    setAumData(response.data);
                })
                .catch(error => {
                    console.error(error);
                });
            };      

            fetchAUMData();
            fetchLocationData();
            fetchStateData();
            fetchGeneralData();
            getStateStockInward();
            getStateStockOutward();
            getStateStockAUM();
            getStateStockQuantity();

        }, []);


        const formatDate = (dateString) => {
            const date = new Date(dateString);

            const options = {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true, 
            };
          
            const formattedDateTime = date.toLocaleString('en-GB', options).replace(/\//g, '-'); // Display format as 'DD_MM_YYYY HH:mm AM/PM'

            return formattedDateTime;
        };

        const [loading8, setLoading8] = useState(false);

        const fetchAUMTrackerData = async () => {
            try {
                setLoading8(true)
                const response = await instance.get(`commodity-aum-7/?duration=${selectedAUMOption.toLocaleUpperCase()}`);
                const newData = [];

                const monthMapping = {
                    '01': "Jan", '02': "Feb", '03': "Mar", '04': "Apr", '05': "May", '06': "Jun",
                    '07': "Jul", '08': "Aug", '09': "Sep", '10': "Oct", '11': "Nov", '12': "Dec",
                };
                
                for (const [key, values] of Object.entries(response.data)) {
                    let formattedKey;

                    if (selectedAUMOption === 'monthly') {
                        formattedKey = `${monthMapping[key.slice(5, 7)]}'${key.slice(2, 4)}`;
                    } else if (selectedAUMOption === 'quarterly') {
                        formattedKey = `Q${key.slice(5, 7)}-${key.slice(2, 4)}`;
                    } else if (selectedAUMOption === 'yearly') {
                        formattedKey = key.slice(0, 4);
                    } else if (selectedAUMOption === 'weekly') {
                        formattedKey = `${key.slice(5, 7)}-${key.slice(2, 4)}`;
                    } else if (selectedAUMOption === 'daily') {
                        const date = new Date(key);
                        const day = date.getDate().toString().padStart(2, '0');
                        const month = (date.getMonth() + 1).toString().padStart(2, '0');
                        const year = date.getFullYear();
                        formattedKey = `${day}-${month}-${year}`;
                    }

                    values.forEach((commodity) => {
                        if (commodity.commodity_name === selectedAUMCommodity) {
                            const valueInCrores = (commodity.CommodityValue / 10000000).toFixed(2);
                            newData.push([formattedKey, parseFloat(valueInCrores)]);
                        }
                    });
                }
                setAumChartData(newData);
                setLoading8(false)
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading8(false)
            }
        };
        

        useEffect(() => {
            fetchAUMTrackerData(); 
        }, [selectedAUMOption]);


        const [WSStateData, setWSStateData] = useState([]);
        const [WSLocationData, setWSLocationData] = useState([]);
        const [WSWarehouseData, setWSWarehouseData] = useState([]);
      
        const [selectedWSState, setSelectedWSState] = useState(null);
        const [selectedWSLocation, setSelectedWSLocation] = useState(null);
        const [selectedWSWarehouse, setSelectedWSWarehouse] = useState(null);
      
        const [loading10, setLoading10] = useState(false); // loading for WSStateData
        const [loading11, setLoading11] = useState(false); // loading for WSLocationData
        const [loading12, setLoading12] = useState(false); // loading for WSWarehouseData


        const getTitle = () => {
            switch (selectedAUMOption) {
              case 'daily':
                return 'Date';
              case 'monthly':
                return 'Month';
              case 'weekly':
                return 'Weekly';
              case 'quarterly':
                return 'Quarter';
              case 'yearly':
                return 'Year';
              default:
                return '';
            }
          };
      
        const fetchWSStateData = async () => {
          try {
            setLoading10(true);
            const response = await instance.get('dashboard/wh-selector');
            setWSStateData(response.data.state);
          } catch (error) {
            console.error('Error fetching WSStateData:', error);
          } finally {
            setLoading10(false);
          }
        };
      
        const fetchWSLocationData = async (stateId) => {
          try {
            setLoading11(true);
            const response = await instance.get(`dashboard/wh-selector?state=${stateId}`);
            setWSLocationData(response.data.location);
          } catch (error) {
            console.error('Error fetching WSLocationData:', error);
          } finally {
            setLoading11(false);
          }
        };
      
        const fetchWSWarehouseData = async (stateId, locationId) => {
          try {
            setLoading12(true);
            const response = await instance.get(`dashboard/wh-selector?state=${stateId}&location=${locationId}`);
            setWSWarehouseData(response.data.warehouse);
          } catch (error) {
            console.error('Error fetching WSWarehouseData:', error);
          } finally {
            setLoading12(false);
          }
        };

        const [stateId, setStateId] = useState(null);
        const [locationId, setLocationId] = useState(null);
        const [warehouseId, setWarehouseId] = useState(null);
      
        const handleWSStateChange = (event, newValue) => {
          setSelectedWSState(newValue);
          fetchWSLocationData(newValue[1]);
          setSelectedWSLocation(null); // Reset location selection when state changes
          setSelectedWSWarehouse(null); // Reset warehouse selection when state changes
          setStateId(newValue[1]);
        };
      
        const handleWSLocationChange = (event, newValue) => {
          setSelectedWSLocation(newValue);
          fetchWSWarehouseData(selectedWSState[1], newValue[1]);
          setSelectedWSWarehouse(null); // Reset warehouse selection when location changes
          setLocationId(newValue[1]);
        };
      
        const handleWSWarehouseChange = (event, newValue) => {
          setSelectedWSWarehouse(newValue);
          setWarehouseId(newValue[1]);
        };
      
        useEffect(() => {
          fetchWSStateData();
        }, []);
     

        return (
            <Box fullWidth>
                <HawkEyeAppBar />

               

                <Paper sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px", borderRadius:"20px", mt:18, mb:6, mx:8, p:2, backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)'}}>
            
                    <Typography variant="h4" sx={{ px:2, py:1, fontWeight: 500}}>
                        Stakeholder Dashboard 
                    </Typography>

                    <Grid container mx="auto" mt={3}>
                        <Grid item xs={2}>

                        </Grid>
                        <Grid item xs={2} textAlign="center" alignItems="center" justifyContent="center">
                            <Typography color="secondary" variant="body1" sx={{  my:"auto", fontWeight: 400 }}>
                            Last Updated On: 
                            </Typography>
                            <Chip 
                                    label={formatDate(lastUpdated)}
                                    variant="outlined"
                                    color="primary"
                                    sx={{ color: "#214383", borderColor: "#214383", fontWeight: 600}} 
                                />
                        </Grid>
                        <Grid item xs={6}>
                        <Grid container mx="auto" spacing={2} alignItems="center" justifyContent="center">
                        <Grid item xs={4}>
                            <TextField
                            label="From Date"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                inputProps: {
                                max: new Date().toISOString().split('T')[0],
                                },
                            }}
                            value={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                            fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                            label="To Date"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                inputProps: {
                                max: new Date().toISOString().split('T')[0],
                                },
                            }}
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                            fullWidth
                            />
                        </Grid>
                            <Grid item xs={3}>
                                <Button variant="contained" onClick={handleUpdateClick} fullWidth>
                                Update Data
                                </Button>
                            </Grid>
                        </Grid>
                        </Grid>
                    </Grid>

                    <Divider sx={{ mt:2, mb:3, pr:2 }}/>

                    <Grid container mx="auto" spacing={4} sx={{ pl:2, py:2 }}>

                        <Grid xs={5} my="auto">
                            <Grid sx={{ p:2, borderRadius:"1rem", border:"2px solid #d8daed", m:2, backgroundColor: "#fff", }}>
                            <Typography textAlign="center" sx={{ mx:"auto", fontSize: "1.25rem", fontWeight: "500" }}>
                            Token Distribution 
                            <br/>
                            </Typography>
                                <Chart
                                    chartType="PieChart"
                                    // width="400px"
                                    // height="350px"
                                    width="19rem"
                                    height="20rem"
                                    
                                    
                                    data={pieData1}
                                    options={pieOptions1}
                                />
                            
                            <Grid fullwidth p={2}>
                             <Legend data={["Accepted", "Rejected", "Pending", "Auto Accept"]} colors={['#4caf50','#e74c3c', '#78909c', '#00e676']} />
                            </Grid>

                        </Grid>
                        </Grid>

                    
                        <Grid container sx={{ p:2 }} spacing={4} xs={7} >

                        <Grid sx={{ textAlign: "center"}} item xs={12} sm={6}>
                            <Card sx={{ borderRadius: "22px"}} elevation={3} className={classes.glassCard}>
                                <CardContent>
                                <Typography variant="h6" component="div">
                                Total Goods Managed:
                                </Typography>
                                <Chip sx={{ fontWeight: 700,  marginTop: "10px"}} label={`${convertToMillion(totalGoodsManaged)} Million MT`}/>
                                
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid sx={{ textAlign: "center"}} item xs={12} sm={6}>
                            <Card sx={{ borderRadius: "22px"}} elevation={3} className={classes.glassCard}>
                                <CardContent>
                                <Typography variant="h6" component="div">
                                Commodities Managed:
                                </Typography>
                                <Chip sx={{ fontWeight: 700,  marginTop: "10px"}} label={convertToCommaSeperated(noCommoditiesManaged)}/>

                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid sx={{ textAlign: "center"}} item xs={12} sm={6}>
                            <Card sx={{ borderRadius: "22px"}} elevation={3} className={classes.glassCard}>
                                <CardContent>
                                <Typography variant="h6" component="div">
                                Total Tokens:
                                </Typography>
                                
                                <Chip sx={{ fontWeight: 700,  marginTop: "10px"}} label={convertToCommaSeperated(tokenCount)}/>

                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid sx={{ textAlign: "center"}} item xs={12} sm={6}>
                            <Card sx={{ borderRadius: "22px"}} elevation={3} className={classes.glassCard}>
                                <CardContent>
                                <Typography variant="h6" component="div">
                                Lien Marked Tokens:
                                </Typography>
                                
                                <Chip sx={{ fontWeight: 700,  marginTop: "10px"}} label={convertToCommaSeperated(activeLienMarkCount)} />
                                
                                </CardContent>
                            </Card>
                        </Grid>


                        <Grid item xs={12}>
                        <Card className={classes.glassCard}>
                            <CardContent>
                            <Grid container justifyContent="space-between" alignItems="center">
                            <Typography fontWeight={600} variant="body1" component="div">
                            Accepted Tokens
                            </Typography>
                            <Typography fontWeight={600} variant="body1" component="div">
                                <Chip sx={{ color:"#fff", marginTop: "10px", fontWeight: 600 }} variant="contained" color="success" label={convertToCommaSeperated(acceptedCount+autoAcceptCount)} />
                            </Typography>
                            </Grid>
                            </CardContent>
                        </Card>
                        </Grid> <Grid item xs={12}>
                        <Card className={classes.glassCard}>
                            <CardContent>
                            <Grid container justifyContent="space-between" alignItems="center">
                            <Typography fontWeight={600} variant="body1" component="div">
                            Pending Tokens
                            </Typography>
                            <Typography fontWeight={600} variant="body1" component="div">
                            <Chip sx={{ marginTop: "10px", fontWeight: 600, color:"#fff", backgroundColor: '#78909c' }} variant="contained" label={convertToCommaSeperated(pendingCount)} />
                            </Typography>
                            </Grid>
                            </CardContent>
                        </Card>
                        </Grid> <Grid item xs={12}>
                        <Card className={classes.glassCard}>
                            <CardContent>
                            <Grid container justifyContent="space-between" alignItems="center">
                            <Typography fontWeight={600} variant="body1" component="div">
                            Rejected Tokens
                            </Typography>
                            <Typography fontWeight={600} variant="body1" component="div">
                            <Chip sx={{ marginTop: "10px", fontWeight: 600 }} variant="contained" color="error" label={convertToCommaSeperated(rejectedCount)} />
                            </Typography>
                            </Grid>
                            </CardContent>
                        </Card>
                        </Grid> <Grid item xs={12}>
                        <Card className={classes.glassCard}>
                            <CardContent>
                            <Grid container justifyContent="space-between" alignItems="center">
                            <Typography fontWeight={600} variant="body1" component="div">
                            Total tokens marked funded on EWR portal
                            </Typography>
                            <Typography fontWeight={600} variant="body1" component="div">
                                <Chip sx={{ fontWeight: 700 }} color="primary" label={convertToCommaSeperated(fundedTokenCount)}/>
                            </Typography>
                            </Grid>
                            </CardContent>
                        </Card>
                        </Grid> 
                     
                        </Grid>
                    </Grid>
                    
                </Paper>

                {/* AUM tracker */}
                <Paper sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px", borderRadius:"20px", mt:12, mb:6, mx:8, p:2, backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)'}}>

                    <Grid container sx={{ mx: 'auto', alignItems: "center", p:2 }}>
                        <Grid sx={{ p:2, borderRadius:"1rem", border:"2px solid #d8daed", my:2, backgroundColor: "#fff", }} mx="auto" xs={10}>
                            <Typography sx={{ mx:"auto", fontSize: "1.15rem", fontWeight: "500" }}>
                            Commodity AUM Value 
                            <br/>
                            </Typography>
                          

                            <Box my={2}>
                                
                                <Autocomplete
                                    value={selectedAUMCommodity}
                                    onChange={handleAUMCommodityChange}
                                    options={[...uniqueCommodityNames]} 
                                    renderInput={(params) => <TextField {...params} label="Commodity" />}
                                />
                            </Box>
                     
                                { aumChartData.length>0 && 
                                (
                                    <React.Fragment>
                                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                                    {aumPeriodOptions.map((option) => (
                                        <Grid item key={option}>
                                        <Button
                                            // variant="contained"
                                            variant={selectedAUMOption === option ? 'contained' : 'outlined'}
                                            color={selectedAUMOption === option ? 'primary' : 'secondary'}
                                            style={{ borderRadius: 22 }}
                                            onClick={() => setSelectedAUMOption(option)}
                                        >
                                            {option.charAt(0).toUpperCase() + option.slice(1)}
                                        </Button>
                                        </Grid>
                                    ))}
                                    </Grid>

                                    <Box mt={2}>
                                    {loading8 ? (
                                        <Grid sx={{ mx:"auto", my:4, alignItems:"center", justifyContent: "center"}} container>
                                            <CircularProgress />
                                        </Grid>
                                    ) : (
                                    <Chart
                                        width={'100%'}
                                        height={'400px'}
                                        chartType="LineChart"
                                        loader={<div>Loading Chart</div>}
                                        data={[
                                        ['Date', 'CommodityValue'],
                                        ...aumChartData
                                        ]}
                                        options={{
                                            title: 'Commodity Value',
                                            hAxis: {
                                              title: getTitle(),
                                              scrollable: true,
                                            },
                                            vAxis: {
                                              title: 'Commodity Value (in INR (₹) Crores)',
                                            },
                                            legend: 'none',
                                            chartArea: {
                                              width: '85%',
                                              height: '70%',
                                            },
                                        }}
                                        errorHandler={(error) => {
                                        console.error('An error occurred:', error);
                                        }}
                                    />
                                    )}
                                </Box>
                                    </React.Fragment>
                                )
                               
                              
                                }

                        </Grid>

                    </Grid>

                </Paper>           
                
                {/* Top 5 data */}
                <Grid sx={{  px:"4rem", my:4, alignItems:"center", justifyContent: "center"}} container>
                        
                        <Grid xs={12} fullWidth>


                            <Grid item xs={6} mx="auto" sx={{ textAlign: "center"}}>

                                <Tabs sx={{ textAlign: "center"}}
                                    value={selectedTab} 
                                    onChange={(event, newValue) => setSelectedTab(newValue)}
                                    >
                                    <Tab label="State" />
                                    <Tab label="Location" />
                                    <Tab label="Commodity" />
                                    <Tab label="Select Warehouse" />
                                    </Tabs>
                            </Grid>
                        </Grid>

                        {selectedTab === 0 && (
                        <Grid container >
                            <Grid fullWidth xs={12}>
                                {
                                    toDate!=='' && (
                                        <Divider sx={{ mt:2, mb:2}}>
                                        <Typography>
                                            From {formatMMDate(fromDate)} To {formatMMDate(toDate)}
                                        </Typography>
                                    </Divider>
                                    )
                                }
                            </Grid>

                            <Grid xs={12} textAlign="center" alignItems="center">
                            <Box my={2}>
                                <Autocomplete
                                    value={selectedTop5State}
                                    onChange={handleTop5StateChange}
                                    options={stateData}
                                    disableClearable={true}
                                    renderInput={(params) => <TextField {...params} label="State" />}
                                />
                            </Box>
                            </Grid>

                            {
                                loading3 && (
                                    <Grid sx={{ mx:"auto", my:4, alignItems:"center", justifyContent: "center"}} container>
                                        <CircularProgress />
                                    </Grid>
                                )
                            }

                            {
                                !loading3 && top5StateAUMData.length>0 && top5StateInwardData.length>0 && (
                                    <Grid columns={13} container spacing={2} mx={2} my={2} sx={{ overflow: 'auto' }} >

                                    <Grid item sx={{borderRadius:"1rem", border:"2px solid #d8daed", backgroundColor: "#fff", p:2, m:2 }} xs={6}>
                                                                        <Typography variant="h5" sx={{ px:2, py:3, textAlign: "center",fontWeight: 500}}>
                                                                            Inward-wise Top 5 warehouses
                                                                        </Typography> 
                                                                        <Box sx={{ height: 400, width: '100%' }}>
                                                                            <DataGrid
                                                                            rows={top5StateInwardData}
                                                                            columns={top5Columns1}
                                                                            disableRowSelectionOnClick
                                                                            hideFooterPagination={true}
                                                                            components={{
                                                                                Footer: () => (
                                                                                <div style={{ textAlign: 'center', marginY: '12px' }}>
                                                                                    <Button
                                                                                        sx={{ marginTop: "10px", marginBottom: "10px"}}
                                                                                        variant="outlined"
                                                                                        onClick={() => {
                                                                                            const url = `/all-commodity/state/${selectedTop5State}`;
                                                                                            window.open(url, '_blank');
                                                                                        }}
                                                                                    >
                                                                                    View All Warehouses Data
                                                                                    </Button>
                                                                                </div>
                                                                                ),
                                                                                Toolbar: GridToolbar,
                                                                            }}
                                                                            />
                                                                        </Box>
                                    </Grid>

                                    <Grid item sx={{borderRadius:"1rem", border:"2px solid #d8daed", backgroundColor: "#fff", p:2, m:2 }} xs={6}>
                                        <Typography variant="h5" sx={{ px:2, py:3, textAlign: "center",fontWeight: 500}}>
                                            AUM-wise Top 5 warehouse 
                                        </Typography> 
                                        <Box sx={{ height: 400, width: '100%' }}>
                                            <DataGrid
                                            rows={top5StateAUMData}
                                            columns={top5Columns2}
                                            disableRowSelectionOnClick
                                            hideFooterPagination={true}
                                            components={{
                                                Footer: () => (
                                                  <div style={{ textAlign: 'center', marginY: '12px' }}>
                                                    <Button
                                                        sx={{ marginTop: "10px", marginBottom: "10px"}}
                                                        variant="outlined"
                                                        onClick={() => {
                                                            const url = `/all-commodity/state/${selectedTop5State}`;
                                                            window.open(url, '_blank');
                                                        }}
                                                    >
                                                      View All Warehouses Data
                                                    </Button>
                                                  </div>
                                                ),
                                                Toolbar: GridToolbar,
                                              }}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item sx={{borderRadius:"1rem", border:"2px solid #d8daed", backgroundColor: "#fff", p:2, m:2 }} xs={6}>
                                        <Typography variant="h5" sx={{ px:2, py:3, textAlign: "center",fontWeight: 500}}>
                                            Lien Marked AUM - Top 5 warehouses
                                        </Typography> 
                                        <Box sx={{ height: 400, width: '100%' }}>
                                            <DataGrid
                                            rows={top5StateLienAUMData}
                                            columns={top5Columns2}
                                            disableRowSelectionOnClick
                                            hideFooterPagination={true}
                                            components={{
                                                Toolbar: GridToolbar,
                                              }}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item sx={{borderRadius:"1rem", border:"2px solid #d8daed", backgroundColor: "#fff", p:2, m:2 }} xs={6}>
                                        <Typography variant="h5" sx={{ px:2, py:3, textAlign: "center",fontWeight: 500}}>
                                            Lien Marked Quantity - Top 5 warehouses
                                        </Typography> 
                                        <Box sx={{ height: 400, width: '100%' }}>
                                            <DataGrid
                                            rows={top5StateLienQtyData}
                                            columns={top5Columns3}
                                            disableRowSelectionOnClick
                                            hideFooterPagination={true}
                                            components={{
                                                Toolbar: GridToolbar,
                                              }}
                                            />
                                        </Box>
                                    </Grid>


                                    </Grid>
                                )
                            }

                        </Grid>
                        )}

                        
                        {selectedTab === 1 && (
                            <Grid container>
                                 <Grid fullWidth xs={12}>
                                    {
                                        toDate!=='' && (
                                            <Divider sx={{ mt:2, mb:2}}>
                                            <Typography>
                                                From {formatMMDate(fromDate)} To {formatMMDate(toDate)}
                                            </Typography>
                                        </Divider>
                                        )
                                    }
                                </Grid>

                                <Grid xs={12} textAlign="center" alignItems="center">
                                <Box my={2}>
                                <Autocomplete
                                        value={selectedTop5Location}
                                        onChange={handleTop5LocationChange}
                                        options={locationData}
                                        disableClearable={true}
                                        renderInput={(params) => <TextField {...params} label="Location" />}
                                    />
                                </Box>
                                </Grid>

                                {
                                loading2 && (
                                    <Grid sx={{ mx:"auto", my:4, alignItems:"center", justifyContent: "center"}} container>
                                        <CircularProgress />
                                    </Grid>
                                )
                                }

                                {
                                !loading2 && top5LocationAUMData.length>0 && top5LocationInwardData.length>0 && (
                                    <Grid columns={13} container spacing={2} mx={2} my={2} sx={{ overflow: 'auto' }} >

                                    <Grid sx={{borderRadius:"1rem", border:"2px solid #d8daed", backgroundColor: "#fff", p:2, m:2 }} xs={6}>
                                    <Typography variant="h5" sx={{ px:2, py:3, textAlign: "center",fontWeight: 500}}>
                                        Inward-wise Top 5 warehouses
                                    </Typography> 
                                    <Box sx={{ height: 400, width: '100%' }}>
                                        <DataGrid
                                        rows={top5LocationInwardData}
                                        columns={top5Columns1}
                                        disableRowSelectionOnClick
                                        hideFooterPagination={true}
                                        components={{
                                            Footer: () => (
                                              <div style={{ textAlign: 'center', marginY: '12px' }}>
                                                <Button
                                                    sx={{ marginTop: "10px", marginBottom: "10px"}}
                                                    variant="outlined"
                                                    onClick={() => {
                                                        const url = `/all-commodity/location/${selectedTop5Location}`;
                                                        window.open(url, '_blank');
                                                    }}
                                                >
                                                  View All Warehouses Data
                                                </Button>
                                              </div>
                                            ),
                                            Toolbar: GridToolbar,
                                          }}
                                        />
                                    </Box>
                                    </Grid>

                                    <Grid sx={{borderRadius:"1rem", border:"2px solid #d8daed", backgroundColor: "#fff", p:2, m:2 }} xs={6}>
                                        <Typography variant="h5" sx={{ px:2, py:3, textAlign: "center",fontWeight: 500}}>
                                            AUM-wise Top 5 warehouse 
                                        </Typography> 
                                        <Box sx={{ height: 400, width: '100%' }}>
                                            <DataGrid
                                            rows={top5LocationAUMData}
                                            columns={top5Columns2}
                                            disableRowSelectionOnClick
                                            hideFooterPagination={true}
                                            components={{
                                            Footer: () => (
                                              <div style={{ textAlign: 'center', marginY: '12px' }}>
                                                <Button
                                                    sx={{ marginTop: "10px", marginBottom: "10px"}}
                                                    variant="outlined"
                                                    onClick={() => {
                                                        const url = `/all-commodity/location/${selectedTop5Location}`;
                                                        window.open(url, '_blank');
                                                    }}
                                                >
                                                  View All Warehouses Data
                                                </Button>
                                              </div>
                                            ),
                                            Toolbar: GridToolbar,
                                          }}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item sx={{borderRadius:"1rem", border:"2px solid #d8daed", backgroundColor: "#fff", p:2, m:2 }} xs={6}>
                                        <Typography variant="h5" sx={{ px:2, py:3, textAlign: "center",fontWeight: 500}}>
                                            Lien Marked AUM - Top 5 warehouses
                                        </Typography> 
                                        <Box sx={{ height: 400, width: '100%' }}>
                                            <DataGrid
                                            rows={top5LocationLienAUMData}
                                            columns={top5Columns2}
                                            disableRowSelectionOnClick
                                            hideFooterPagination={true}
                                            components={{
                                                Toolbar: GridToolbar,
                                              }}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item sx={{borderRadius:"1rem", border:"2px solid #d8daed", backgroundColor: "#fff", p:2, m:2 }} xs={6}>
                                        <Typography variant="h5" sx={{ px:2, py:3, textAlign: "center",fontWeight: 500}}>
                                            Lien Marked Quantity - Top 5 warehouses
                                        </Typography> 
                                        <Box sx={{ height: 400, width: '100%' }}>
                                            <DataGrid
                                            rows={top5LocationLienQtyData}
                                            columns={top5Columns3}
                                            disableRowSelectionOnClick
                                            hideFooterPagination={true}
                                            components={{
                                                Toolbar: GridToolbar,
                                              }}
                                            />
                                        </Box>
                                    </Grid>

                                    </Grid>
                                    )
                                }

                            </Grid>
                        )}


                        {selectedTab === 2 && (
                            <Grid container>
                            <Grid fullWidth xs={12}>
                                {
                                    toDate!=='' && (
                                        <Divider sx={{ mt:2, mb:2}}>
                                        <Typography>
                                            From {formatMMDate(fromDate)} To {formatMMDate(toDate)}
                                        </Typography>
                                    </Divider>
                                    )
                                }
                            </Grid>
                            <Grid xs={12} textAlign="center" alignItems="center">
                            <Box my={2}>
                                <Autocomplete
                                loader={<div>Loading Chart</div>}
                                value={selectedTop5Commodity}
                                onChange={handleTop5CommodityChange}
                                options={[...uniqueCommodityNames]}
                                disableClearable={true}
                                renderInput={(params) => <TextField {...params} label="Commodity" />}
                                />
                            </Box>
                            </Grid>
                           

                            {
                            loading1 && (
                                <Grid sx={{ mx:"auto", my:4, alignItems:"center", justifyContent: "center"}} container>
                                    <CircularProgress />
                                </Grid>
                            )
                            }
                            {
                                !loading1 && top5CommAUMData.length>0 && top5CommInwardData.length>0 && (
                                    <Grid columns={13} container spacing={2} mx={2} my={2} sx={{ overflow: 'auto' }} >

                                    <Grid sx={{borderRadius:"1rem", border:"2px solid #d8daed", backgroundColor: "#fff", p:2, m:2 }} xs={6}>
                                    <Typography variant="h5" sx={{ px:2, py:3, textAlign: "center",fontWeight: 500}}>
                                        Inward-wise Top 5 warehouses
                                    </Typography> 
                                    <Box sx={{ height: 400, width: '100%' }}>
                                        <DataGrid
                                        rows={top5CommInwardData}
                                        columns={top5Columns1}
                                        disableRowSelectionOnClick
                                        hideFooterPagination={true}
                                        components={{
                                            Footer: () => (
                                              <div style={{ textAlign: 'center', marginY: '12px' }}>
                                                <Button
                                                    sx={{ marginTop: "10px", marginBottom: "10px"}}
                                                    variant="outlined"
                                                    onClick={() => {
                                                        const url = `/all-commodity/commodity/${selectedTop5Commodity}`;
                                                        window.open(url, '_blank');
                                                    }}
                                                >
                                                  View All Warehouses Data
                                                </Button>
                                              </div>
                                            ),
                                            Toolbar: GridToolbar,
                                          }}
                                        />
                                    </Box>
                                    </Grid>

                                    <Grid sx={{borderRadius:"1rem", border:"2px solid #d8daed", backgroundColor: "#fff", p:2, m:2 }} xs={6}>
                                        <Typography variant="h5" sx={{ px:2, py:3, textAlign: "center",fontWeight: 500}}>
                                            AUM-wise Top 5 warehouse 
                                        </Typography> 
                                        <Box sx={{ height: 400, width: '100%' }}>
                                            <DataGrid
                                            rows={top5CommAUMData}
                                            columns={top5Columns2}
                                            disableRowSelectionOnClick
                                            hideFooterPagination={true}
                                            components={{
                                            Footer: () => (
                                              <div style={{ textAlign: 'center', marginY: '12px' }}>
                                                <Button
                                                    sx={{ marginTop: "10px", marginBottom: "10px"}}
                                                    variant="outlined"
                                                    onClick={() => {
                                                        const url = `/all-commodity/commodity/${selectedTop5Commodity}`;
                                                        window.open(url, '_blank');
                                                    }}
                                                >
                                                  View All Warehouses Data
                                                </Button>
                                              </div>
                                            ),
                                            Toolbar: GridToolbar,
                                          }}
                                            />
                                        </Box>
                                    </Grid>

                                    
                                    <Grid item sx={{borderRadius:"1rem", border:"2px solid #d8daed", backgroundColor: "#fff", p:2, m:2 }} xs={6}>
                                        <Typography variant="h5" sx={{ px:2, py:3, textAlign: "center",fontWeight: 500}}>
                                            Lien Marked AUM - Top 5 warehouses
                                        </Typography> 
                                        <Box sx={{ height: 400, width: '100%' }}>
                                            <DataGrid
                                            rows={top5CommLienAUMData}
                                            columns={top5Columns2}
                                            disableRowSelectionOnClick
                                            hideFooterPagination={true}
                                            components={{
                                                Toolbar: GridToolbar,
                                              }}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item sx={{borderRadius:"1rem", border:"2px solid #d8daed", backgroundColor: "#fff", p:2, m:2 }} xs={6}>
                                        <Typography variant="h5" sx={{ px:2, py:3, textAlign: "center",fontWeight: 500}}>
                                            Lien Marked Quantity - Top 5 warehouses
                                        </Typography> 
                                        <Box sx={{ height: 400, width: '100%' }}>
                                            <DataGrid
                                            rows={top5CommLienQtyData}
                                            columns={top5Columns3}
                                            disableRowSelectionOnClick
                                            hideFooterPagination={true}
                                            components={{
                                                Toolbar: GridToolbar,
                                              }}
                                            />
                                        </Box>
                                    </Grid>
                                    
                                    </Grid>
                                )
                            }
                            </Grid>

                        )}

                      
                        {selectedTab === 3 && (
                            <Grid container>

                            <Grid container justifyContent="center" alignItems="center">
                                <Typography mt={2} fontStyle="italic" display="flex" alignItems="center">
                                    Please Select State <ArrowForwardIcon sx={{ mx: 1}} fontSize="small" /> Then Location <ArrowForwardIcon sx={{ mx: 1}} fontSize="small" /> Then the Warehouse to view In-Depth Warehouse Statistics
                                </Typography>
                            </Grid>

                            <Grid xs={12} textAlign="center" alignItems="center">
                              <Box my={2}>
                                <Autocomplete
                                  value={selectedWSState}
                                  onChange={handleWSStateChange}
                                  options={WSStateData}
                                  getOptionLabel={(option) => option[0]} 
                                  disableClearable={true}
                                  renderInput={(params) => <TextField {...params} label="Select State" />}
                                />
                              </Box>
                            </Grid>
                      
                            {selectedWSState && (
                              <Grid xs={12} textAlign="center" alignItems="center">
                                <Box my={2}>
                                  <Autocomplete
                                    value={selectedWSLocation}
                                    onChange={handleWSLocationChange}
                                    options={WSLocationData}
                                    disableClearable={true}
                                    getOptionLabel={(option) => option[0]} 
                                    renderInput={(params) => <TextField {...params} label="Select Location" />}
                                  />
                                </Box>
                              </Grid>
                            )}
                      
                            {selectedWSLocation && (
                              <Grid xs={12} textAlign="center" alignItems="center">
                                <Box my={2}>
                                  <Autocomplete
                                    value={selectedWSWarehouse}
                                    onChange={handleWSWarehouseChange}
                                    options={WSWarehouseData}
                                    disableClearable={true}
                                    getOptionLabel={(option) => option[0]} 
                                    renderInput={(params) => <TextField {...params} label="Select Warehouse" />}
                                  />
                                </Box>
                              </Grid>
                            )}

                            {selectedWSWarehouse && (
                                    <Grid xs={12} textAlign="center" alignItems="center">
                                    <Box my={2}>
                                    <Link
                                        to={`/warehouse-stats/${stateId}/${locationId}/${warehouseId}`}
                                        target="_blank" 
                                        >
                                            <Button variant="outlined" >
                                            View Warehouse Stats
                                            </Button>
                                        </Link>
                                    </Box>
                                    </Grid>
                            )}
                      
                            {(loading10 || loading11 || loading12) && (
                              <Grid sx={{ mx: 'auto', my: 4, alignItems: 'center', justifyContent: 'center' }} container>
                                <CircularProgress />
                              </Grid>
                            )}
                          </Grid>
                        )}


                </Grid> 

                {/* Token State wise distibution */}
                <Paper sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px", borderRadius:"20px", mt:4, mb:6, mx:8, p:2, backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)'}}>
                        
                        <Typography variant="h4" sx={{ px:2, py:1, fontWeight: 500}}>
                            Token Distribution State-wise Data
                        </Typography>
                        <Divider sx={{ mt:2, mb:3, pr:2 }}>
                            {
                                toDate!=='' && (

                                    <Typography>
                                        From {formatMMDate(fromDate)} To {formatMMDate(toDate)}
                                    </Typography>
                                )
                            }
                        </Divider>
                        {
                            (!loading4 && !loading5 && !loading6 && !loading7 && stateStockInward.length>0 && stateStockOutward.length>0) 
                            ? 

                            <Grid sx={{ mx:"auto", my:4, alignItems:"center", justifyContent: "center"}} container>
                                 <Grid sx={{borderRadius:"1rem", border:"2px solid #d8daed", backgroundColor: "#fff", p:2, m:2}} xs={5}>
                                    <Typography variant="h6" sx={{ px:2, py:1, textAlign: "center",fontWeight: 500}}>
                                        Lien Marked Data 
                                    </Typography> 
                                    <Box sx={{ width: '100%' }}>
                                        <Chart
                                            width={'100%'}
                                            chartType="GeoChart" 
                                            data={stateStockLien}
                                            options={{
                                                region: 'IN',
                                                displayMode: 'regions',
                                                resolution: 'provinces',
                                                colorAxis: {
                                                    colors: ['#fff', '#214383'] 
                                                }
                                            }}
                                            />
                                        </Box>
                                </Grid>

                                <Grid sx={{borderRadius:"1rem", border:"2px solid #d8daed", backgroundColor: "#fff", p:2, m:2}} xs={5}>
                                    <Typography variant="h6" sx={{ px:2, py:1, textAlign: "center",fontWeight: 500}}>
                                        Stock Quantity
                                    </Typography> 
                                    <Box sx={{ width: '100%' }}>
                                        <Chart
                                            width={'100%'}
                                            chartType="GeoChart" 
                                            data={stateStockQuantity}
                                            options={{
                                                region: 'IN',
                                                displayMode: 'regions',
                                                resolution: 'provinces',
                                                colorAxis: {
                                                    colors: ['#fff', '#214383'] 
                                                }
                                            }}
                                            />
                                        </Box>
                                </Grid>

                                <Grid sx={{borderRadius:"1rem", border:"2px solid #d8daed", backgroundColor: "#fff", p:2, m:2}} xs={5}>
                                    <Typography variant="h6" sx={{ px:2, py:1, textAlign: "center",fontWeight: 500}}>
                                        Stock Inward
                                    </Typography> 
                                    <Box sx={{ width: '100%' }}>
                                        <Chart
                                            width={'100%'}
                                            chartType="GeoChart" 
                                            data={stateStockInward}
                                            options={{
                                                region: 'IN',
                                                displayMode: 'regions',
                                                resolution: 'provinces',
                                            }}
                                            />
                                        </Box>
                                </Grid>

                                <Grid sx={{borderRadius:"1rem", border:"2px solid #d8daed", backgroundColor: "#fff", p:2, m:2}} xs={5}>
                                <Typography variant="h6" sx={{ px:2, py:1, textAlign: "center",fontWeight: 500}}>
                                        Stock Outward
                                </Typography> 
                                <Box sx={{ width: '100%' }}>
                                    <Chart
                                        width={'100%'}
                                        chartType="GeoChart" 
                                        data={stateStockOutward}
                                        options={{
                                            region: 'IN',
                                            displayMode: 'regions',
                                            resolution: 'provinces',
                                            colorAxis: {
                                                colors: ['#fff', '#214383'] 
                                            }
                                        }}
                                        />
                                </Box>
                                </Grid>

                            </Grid>

                            : 
                            (
                                <Grid sx={{ mx:"auto", my:4, alignItems:"center", justifyContent: "center"}} container>
                                    <CircularProgress />
                                </Grid>
                            )
                        }

                </Paper>

            </Box>
        );
    };

export default StakeholderDashboard;
