import React, { useState, useEffect, useRef } from "react";
import HawkEyeAppBar from "../../components/AppBar";
import DashboardCard from "../../components/DashboardCard";
import AcceptRejectCard from "../../components/AcceptRejectCard";
import StatusCard from "../../components/StatusCard";
import Autocomplete from '@mui/material/Autocomplete';


import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { MenuList, MenuItem, Divider, TextField, Switch, Button, Chip, FormControlLabel, Checkbox } from "@mui/material";
import Check from "@mui/icons-material/Check";
import ListItemIcon from "@mui/material/ListItemIcon";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Pagination } from '@mui/material';
import { convertToMillion, convertToCommaSeperated } from "../../globals/utils"



import instance from "../../globals/axios";
import { makeStyles } from "@mui/styles";

import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';

const FilterComponent = (props) => (  
  <Paper elevation={2} sx={{ ml: 3, p: 3, mt: 8 }} style={{ borderRadius: "10px", top: "65px", bottom: "0px", width: "20%", position: "fixed", overflow: "scroll" }}>
    <Box fullWidth>
      <MenuList dense>
          <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant='h6'>Filters</Typography>
          <Button
            color='error'
            variant='outlined'
            onClick={() => {
              props.handleClearFilters();
            }}
          >
            Clear
          </Button>
        </Grid>
        <Divider sx={{ my: 2 }} />
       
        <Typography variant='subtitle1' sx={{ fontWeight: "bold" }}>
          Inward period
        </Typography>
       
        <Box m={1}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div>
              <Typography ml={1} variant='subtitle1' sx={{ fontWeight: "bold" }}>
                From
              </Typography>
              <Box m={1}>
                <DatePicker
                  value={props.inwardDateFrom}
                  onChange={(date) => props.setInwardDateFrom(date)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Typography ml={1} variant='subtitle1' sx={{ fontWeight: "bold" }}>
                to
              </Typography>
              <Box m={1} mb={2}>
                <DatePicker
                  value={props.inwardDateTo}
                  onChange={(date) => props.setInwardDateTo(date)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </div>
          </LocalizationProvider>
        </Box>

        <Divider />
        
        <Box my={2}>
          <Autocomplete
            fullWidth
            options={props.warehouseOptions}
            getOptionLabel={(option) => option.wh_name}
            value={props.selectedWarehouse?.wh_pid === null ? null : props.selectedWarehouse}
            onChange={props.handleWarehouseChange}
            disableClearable
            renderInput={(params) => <TextField {...params} label="Warehouse Name" variant="outlined" />}
          />
        </Box>

        <Divider />
      

        <Box my={2}>
          <Autocomplete
            fullWidth
            options={props.commodityOptions}
            getOptionLabel={(option) => option.commodity_name}
            value={props.selectedCommodity?.com_id === null ? null : props.selectedCommodity}
            onChange={props.handleCommodityChange}
            disableClearable
            renderInput={(params) => <TextField {...params} label="Commodity" variant="outlined" />}
          />
        </Box>

        <Divider />

        <Box my={2}>
          <Autocomplete
            fullWidth
            options={props.locations}
            getOptionLabel={(option) => option.location}
            value={props.selectedLocation?.location === null ? null : props.selectedLocation}
            onChange={props.handleLocationSelect}
            disableClearable
            renderInput={(params) => (
              <TextField 
                {...params} 
                label="Location" 
                variant="outlined" 
                defaultValue={props.selectedLocation ? props.selectedLocation.location : "Select a Location"} // Set the placeholder here
              />
            )}
          />
        </Box>

        <Button variant='contained' fullWidth color='primary' onClick={props.handleApplyFilters}>
          <Typography variant='subtitle1' color='white'>
            Apply Filters
          </Typography>
        </Button>

      </MenuList>
    </Box>
  </Paper>
);

const HeaderComponent = ({ data, tokenCount, tokenQuantity, commodityCount }) => 
  {
    const customChipClass = useStyles();
  
    return (
      <Grid container justifyContent="center">
      <Grid md={4}></Grid>
      {/* {commodityCount !== null && commodityCount !== "" && tokenQuantity !== null && tokenQuantity !== "" && ( */}
        <Grid container xs={12} md={8} mx='auto' sx={{ textAlign: "center" }} mb={0}>
          <Grid container mx="auto" sx={{ textAlign: "center" }}>
            <Grid item xs={2}>
              <Typography variant='body1' sx={{ textAlign: "right", fontWeight: "700" }}>
                Recently Added:
              </Typography>
            </Grid>
            <Grid item ml={2} sx={{ borderRight: "1px solid black" }} xs={2}>
              <Typography variant='body1'>
                Commodities
              </Typography>
              <Chip color='success' sx={{ color: "#fff" }} className={customChipClass.chipCustom} component='box' mx={2} variant='h6' label={convertToCommaSeperated(commodityCount)} />
            </Grid>
            <Grid item ml={2} sx={{ borderRight: "1px solid black" }} xs={3}>
              <Typography variant='body1'>
                Quantity
              </Typography>
              <Chip color='success' sx={{ color: "#fff" }} className={customChipClass.chipCustom} component='box' mx={2} variant='h6' label={`${(tokenQuantity / 1000).toLocaleString('en-IN', { maximumFractionDigits: 2 })} MT`} />
            </Grid>
            <Grid item ml={2} xs={2}>
              <Typography variant='body1'>
                Tokens 
              </Typography>
              <Chip color='success' sx={{ color: "#fff" }} className={customChipClass.chipCustom} component='box' mx={2} variant='h6' label={convertToCommaSeperated(tokenCount)} />
            </Grid>
          </Grid>
        </Grid>
      {/* )} */}
      </Grid>
    );
  }

const useStyles = makeStyles(() => ({
  chipCustom: {
    margin: "0 1rem",
    borderRadius: "9px", //some style
    "& .MuiChip-label": { fontSize: 14, fontWeight: "bold" }, // sub-selector
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.6em",
    },
    "*::-webkit-scrollbar-track": {
      borderRadius: "15px",
      backgroundColor: "#f8f9fd",
    },
    "*::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "#c1c1c1",
    },
    "*::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#214383",
    },
  },
}));



const AdminScreen = () => {
  const linkStyle = {
    textDecoration: "none", 
  };


  const customChipClass = useStyles();

  const [tabvalue, setTabvalue] = useState(0);


  const verifyTokenBody = {
    status: [0],
    page: 0,
    limit: 10,
  };

  const applyLoanBody = {
    loan_status: 1,
    status: [1],
    page: 0,
    limit: 10,
    fundable: [1],
    lien_status: [0]
  };

  const loanStatusBody = {
    page: 0,
    limit: 10,
    fundable: [1],
  };

  const lienStatusBody = {
    page: 0,
    limit: 10,
    // fundable: [1],
    lien_status: [1]
  };

  const viewLoanOffers = {
    "status": [
      "pending",
      "countered",
    ],
    page: 0,
    initiated_by_only: 1,
    limit: 10,
  };

  const handleChange = (event, newValue) => {
    setTabvalue(newValue);
  };

    const [verifyData, setVerifyData] = useState({});
    const [applyLoanData, setApplyLoanData] = useState({});
    const [loanStatusData, setLoanStatusData] = useState({});
    const [lienStatusData, setLienStatusData] = useState({});
    const [viewLoanOfferData, setViewLoanOfferData] = useState({});
    const [loading, setLoading] = useState(true);


    // Filters
    const [inwardDateFrom, setInwardDateFrom] = useState(null);
    const [inwardDateTo, setInwardDateTo] = useState(null);
    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [commodityOptions, setCommodityOptions] = useState([]);
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState({ location: null});
    const [selectedWarehouse, setSelectedWarehouse] = useState({ wh_pid: null });
    const [selectedCommodity, setSelectedCommodity] = useState({ com_id: null });



    const handleWarehouseChange = (_, value) => setSelectedWarehouse(value);
    const handleCommodityChange = (_, value) => setSelectedCommodity(value);
    const handleLocationSelect = (_, value) => setSelectedLocation(value)

 


    // useEffect(() => {

    //   const fetchData = async () => {
    //     try {
    //       const response = await instance.post("getTokenDataForUsers", verifyTokenBody);
    //       setVerifyData(response.data);
    //       setTotalPages(response.data.pages);
    //       setLoading(false);
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   };
    //   fetchData();

    //   const fetchRequestData = async () => {
    //     try {
    //       const res = await instance.post("getTokenDataForUsers", applyLoanBody);
    //       setApplyLoanData(res.data);
    //       setLoading(false)
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   };
    //   fetchRequestData();

    //   const fetchLoanData = async () => {
    //     try {
    //       const res = await instance.post("/loan/cm/getLoanOffer", loanStatusBody);
    //       setLoanStatusData(res.data);
    //       setLoading(false);
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   };
    //   fetchLoanData();

    //   const fetchLienData = async () => {
    //     try {
    //       const res = await instance.post("getTokenDataForUsers", lienStatusBody);
    //       setLienStatusData(res.data);
    //       setLoading(false);
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   };
    //   fetchLienData();

    //   const fetchOfferData = async () => {
    //     try {
    //       const res = await instance.post("/loan/cm/getLoanOffer", viewLoanOffers);
    //       setViewLoanOfferData(res.data);
    //       setLoading(false);
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   };
    //   fetchOfferData();
    // }, []);



    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await instance.get('/wr/getHeaderData');
            setCommodityCount(response.data.data[0].commodity_count);
            setTokenCount(response.data.data[0].token_count);
            setTokenQuantity(response.data.data[0].token_quantity);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        
  
        const fetchDataFilter = async () => {
          try {
            const response = await instance.get('wr/getFilterData');
            const { warehouse_data, commodity_data, location } = response.data.data[0];
            setWarehouseOptions(warehouse_data);
            setCommodityOptions(commodity_data);
            setLocations(location);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };

        fetchData();
        fetchDataFilter();

      return;


    }, []);



    // View WR
    const [currentPage, setCurrentPage] = useState(0);

    const fetchRequestData = async (page) => {
      try {
        setLoading(true)
        const res = await instance.post("getTokenDataForUsers", {
          ...applyLoanBody,
          page: page,
          ...(inwardDateFrom && inwardDateTo && { inward_date_from: inwardDateFrom, inward_date_to: inwardDateTo }),
          ...(selectedWarehouse.wh_pid && { wh_pid: [parseInt(selectedWarehouse.wh_pid)] }),
          ...(selectedCommodity.com_id && { commodity_id: [selectedCommodity.com_id] }),
          ...(selectedLocation.location && { location: [selectedLocation.location] } )
        });
        setApplyLoanData(res.data);
        setLoading(false)
      } catch (error) {
        console.error(error);
      }
    };


    // View Lien marked
    const [currentPageLienStatus, setCurrentPageLienStatus] = useState(0);

    const fetchLienStatusData = async (page) => {
      try {
        setLoading(true)
        const res = await instance.post("getTokenDataForUsers", {
          ...lienStatusBody,
          page: page,
          ...(inwardDateFrom && inwardDateTo && { inward_date_from: inwardDateFrom, inward_date_to: inwardDateTo }),
          ...(selectedWarehouse.wh_pid && { wh_pid: [parseInt(selectedWarehouse.wh_pid)] }),
          ...(selectedCommodity.com_id && { commodity_id: [selectedCommodity.com_id] }),
          ...(selectedLocation.location && { location: [selectedLocation.location] } )
        });
        setLienStatusData(res.data);
        setLoading(false)
      } catch (error) {
        console.error(error);
      }
    };

    // useEffect(() => {
    //   fetchRequestData(currentPage); 
    // }, [currentPage]);
    
    useEffect(() => {
      if (currentPage===0) {
        setCurrentPage(1)
        return;
      }
      fetchRequestData(currentPage)
    }, [currentPage]);

    const handlePageChange = (event, page) => {
      setCurrentPage(page);
    };

    // useEffect(() => {
    //   fetchLienStatusData(currentPageLienStatus);
    // }, [currentPageLienStatus]);

    useEffect(() => {
 
      if (currentPageLienStatus===0) {
        setCurrentPageLienStatus(1)
        return;
      }
      fetchLienStatusData(currentPageLienStatus);
    }, [currentPageLienStatus]);

    const handlePageChangeLienStatus = (event, page) => {
      setCurrentPageLienStatus(page);
    };


    // Filters
    const handleApplyFilters = () => {

      fetchLienStatusData(currentPageLienStatus);
      fetchRequestData(currentPage); 
 
    };


    const handleClearFilters = () => {
      setInwardDateFrom(null);
      setInwardDateTo(null);
      setSelectedWarehouse({ wh_pid: null });
      setSelectedCommodity({ com_id: null });
      setSelectedLocation({ location: null });
    }

    const isFirstRender = useRef(true);

    useEffect(() => {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }
    
      if (selectedWarehouse.wh_pid === null && selectedCommodity.com_id === null && selectedLocation.location === null) {
        fetchLienStatusData(currentPageLienStatus);
        fetchRequestData(currentPage);
      }
    }, [selectedWarehouse, selectedCommodity, selectedLocation]);



    const [tokenCount, setTokenCount] = useState("");
    const [commodityCount, setCommodityCount] = useState("");
    const [tokenQuantity, setTokenQuantity] = useState("");

    const fetchHeaderData = (reqBody) => {
      instance.post('/wr/getHeaderData', reqBody)
        .then((response) => {
          const { token_count, commodity_count, token_quantity } = response.data.data[0];
    
          setTokenCount(token_count);
          setCommodityCount(commodity_count);
          setTokenQuantity(token_quantity);

        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    };


    // to update
    const markedFundedDataReqBody = {
      fundable: [0],
      lien_status: [1],
      page: 0,
      limit: 10,
    };

    const fundedDataReqBody = {
      fundable: [1],
      lien_status: [1],
      page: 0,
      limit: 10,
    };


    useEffect(() => {
      let reqBody;

      if (tabvalue === 0) {
        reqBody = verifyTokenBody;
      } else if (tabvalue === 1) {
        reqBody = applyLoanBody;
      } else if (tabvalue === 2) {
        reqBody = fundedDataReqBody;
      } else if (tabvalue === 3) {
        reqBody = lienStatusBody;
      } else if (tabvalue === 4) {
        reqBody = markedFundedDataReqBody;
      }

      fetchHeaderData(reqBody);
    }, [tabvalue]);
  




    return (
      <div>
        <HawkEyeAppBar />
        <Grid container style={{ marginTop: "5rem" }}>
          <Grid xs={12} md={3}></Grid>
          <Grid xs={12} md={9} mx='auto'>
            <Box sx={{ bgcolor: "background.paper", padding: "2.5rem 0px" }}>
              <Tabs value={tabvalue} onChange={handleChange} centered outlined>
              {/* 
                <Tab sx={{ fontSize: "1.15rem" }} label='View Loan Offer' />
                */}
                <Tab sx={{ fontSize: "1.15rem" }} label='View Warehouse Receipts' />
                {/*
                <Tab sx={{ fontSize: "1.15rem" }} label='Loan Status' />
                 */}
                <Tab sx={{ fontSize: "1.15rem" }} label='Lien Status' />
              </Tabs>
            </Box>
          </Grid>
        </Grid>

        { loading &&
          (
            <div>
              <Box sx={{ my:"auto", mx:"auto", display: 'flex' }}>
                <CircularProgress 
                  sx={{ mt:"5rem", mx:"auto", display: 'flex' }}
                    size={60} 
                />
              </Box>
              <Grid sx={{ ml:"45%"}}>
                <Typography variant="h5" sx={{ mt:"1rem", mx:"auto", display: 'flex' }} >
                Fetching tokens ...
                </Typography>
              </Grid>
            </div>
            )
        }

        {
          loading ? null : 
          (
          <Box mb="3rem">

          {
            tabvalue === 0 && (
            <Grid container spacing={2}>
            
            <HeaderComponent tokenCount={tokenCount} commodityCount={commodityCount} tokenQuantity={tokenQuantity} />


              <Grid item xs={3}>
              <FilterComponent 
              handleClearFilters={handleClearFilters} handleApplyFilters={handleApplyFilters}
              warehouseOptions={warehouseOptions} selectedWarehouse={selectedWarehouse} handleWarehouseChange={handleWarehouseChange} 
              commodityOptions={commodityOptions} selectedCommodity={selectedCommodity} handleCommodityChange={handleCommodityChange}
              locations={locations} selectedLocation={selectedLocation} handleLocationSelect={handleLocationSelect}
              setInwardDateFrom={setInwardDateFrom} setInwardDateTo={setInwardDateTo} inwardDateFrom={inwardDateFrom} inwardDateTo={inwardDateTo}/>
              </Grid>
           

              <Grid item xs={9}>
                <Paper elevation={0} sx={{ m: 2, px: 3 }}>
                    {Array.isArray(applyLoanData.data) &&
                    applyLoanData.data.map((item, index) => (
                      <Link key={index} to={`/apply-loan/${index}`} state={{ data: item }} style={linkStyle}>
                        <Box>
                          <StatusCard status={item.attributes.status} data={item} />
                        </Box>
                      </Link>
                    ))}
                </Paper>

                { 
                  (Array.isArray(applyLoanData.data) && applyLoanData.data.length > 0) && (

                    <Grid container justifyContent="center">
                        <Grid item>
                          <Box textAlign="center">
                            <Pagination
                              count={applyLoanData.pages} 
                              page={currentPage} 
                              onChange={handlePageChange}
                            />
                          </Box>
                        </Grid>
                    </Grid>
                  )
                }


              </Grid>
            </Grid>
          )}


          {
            tabvalue === 1 && (
            <Grid container spacing={2}>
            
            <HeaderComponent tokenCount={tokenCount} commodityCount={commodityCount} tokenQuantity={tokenQuantity} />


              <Grid item xs={3}>
              <FilterComponent 
              handleClearFilters={handleClearFilters} handleApplyFilters={handleApplyFilters}
              warehouseOptions={warehouseOptions} selectedWarehouse={selectedWarehouse} handleWarehouseChange={handleWarehouseChange} 
              commodityOptions={commodityOptions} selectedCommodity={selectedCommodity} handleCommodityChange={handleCommodityChange}
              locations={locations} selectedLocation={selectedLocation} handleLocationSelect={handleLocationSelect}
              setInwardDateFrom={setInwardDateFrom} setInwardDateTo={setInwardDateTo} inwardDateFrom={inwardDateFrom} inwardDateTo={inwardDateTo}/>
              </Grid>
              
              <Grid item xs={9}>
                <Paper elevation={0} sx={{ m: 2, px: 3 }}>
                    {Array.isArray(lienStatusData.data) &&
                    lienStatusData.data.map((item, index) => (
                      <Link key={index} to={`/lien-status/${index}`} state={{ data: item }} style={linkStyle}>
                        <Box>
                          <StatusCard lien_status="Lien Marked" data={item} />
                        </Box>
                      </Link>
                    ))}
                </Paper>

                {
                  (Array.isArray(lienStatusData.data) && lienStatusData.data.length > 0) && (
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Box textAlign="center">
                          <Pagination
                            count={lienStatusData.pages}
                            page={currentPageLienStatus}
                            onChange={handlePageChangeLienStatus}
                          />
                         
                        </Box>
                      </Grid>
                    </Grid>

                  )
                }
              </Grid>

            </Grid>
          )}
          </Box>

          )
        }
        
      </div>
    );
};

export default AdminScreen;
