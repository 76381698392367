import React, { useState, useEffect } from "react";
import HawkEyeAppBar from "../../components/AppBar";
import { Box, Divider, Typography, Grid, Chip, Button, ImageList, Card, CardContent, ImageListItem } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ListIcon from '@mui/icons-material/List';
import GetAppIcon from '@mui/icons-material/GetApp';

import { useNavigate, useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
import { Link } from 'react-router-dom';
import Token from "../../components/Popup/TokenAccepted";
import WarehouseReciept from "../../components/WarehouseReciept";
import TestCertificate from "../../components/TestCertificate"

import { makeStyles } from "@mui/styles";
import instance from "../../globals/axios";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  rootVideo: {
    minWidth: 275,
  },
  titleVideo: {
    fontSize: 20,
  },
  buttonVideo: {
    marginTop: theme.spacing(2),
  },
  cardHeader: {
    backgroundColor: "#0073b1",
    color: "#fff",
    fontWeight: "bold",
  },
  cardBody: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dropdownWrapper: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  dropdown: {
    textDecoration: "underline",
    marginRight: 8,
  },
  expandIcon: {
    transform: "rotate(0deg)",
    transition: "transform 0.2s ease-in-out",
  },
  expandIconOpen: {
    transform: "rotate(180deg)",
  },
  dropdownContent: {
    marginTop: 16,
    paddingLeft: 16,
    // borderLeft: "1px solid #ccc",
  },
  outline: {
    borderRadius: "1rem",
    border: "2px solid #d8daed",
    backgroundColor: "#fff",
  },
  customCard: {
    backgroundImage: "linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)",
    boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
    borderRadius: "1rem",
    border: "2px solid #d8daed",
  },
  subtitleFont: {
    fontSize: "1.5rem",
    fontWeight: "600",
  },
}));

const DashboardDetailsAdmin = () => {
  const classes = useStyles();
  const [tabvalue, setTabvalue] = useState(0);
  const location = useLocation();

  const [popupMessage, setPopupMessage] = useState("");

  const [verifyData, setVerifyData] = useState({});

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [qrcode, setQrCode] = useState(false);
  const history = useNavigate();
  const { data } = location.state;
  const [passData, setPassData] = useState({});
  const [showMoreDetails, setShowMoreDetails] = useState(false);

  const handleTokenVerification = (status) => {

    setTokenStatus(status);
    setIsPopupOpen(true);
  };

  // Function to toggle dropdown state
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleScan = () => {
    setQrCode(!qrcode);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setPassData(data)
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [tokenStatus, setTokenStatus] = useState("");

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };
  const handleTokenAccept = () => {
    // Here you would add your code to handle the token acceptance
    setTokenStatus("accepted");
    setIsPopupOpen(false);
  };
  const handleTokenReject = () => {
    // Here you would add your code to handle the token rejection
    setTokenStatus("rejected");
    setIsPopupOpen(false);
  };

  // let popupMessage = "";

  const requestBodyReject = {
    token_code: `${data.token_code}`,
    action: 2,
  };
  const requestBodyAccept = {
    token_code: `${data.token_code}`,
    action: 1,
  };


  const handleChange = (event, newValue) => {
    setTabvalue(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let requestBody;
        // let popupMessage;
        let updatedPopupMessage = "";

        if (tokenStatus === "accepted") {
          requestBody = requestBodyAccept;
          updatedPopupMessage = "Token Accepted";
        } else if (tokenStatus === "rejected") {
          requestBody = requestBodyReject;
          updatedPopupMessage = "Token Rejected";
        }

        if (requestBody) {
          const response = await instance.post("customerSignOff", requestBody);
          console.log("ResponseData", response);
          setVerifyData(response.data);
          setTimeout(function () {
            window.location.replace("/operations");
          }, 1000);
        }

    
        if (updatedPopupMessage) {
          setPopupMessage(updatedPopupMessage); // Update the popupMessage variable
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [tokenStatus]);

  const [digitalCopyLink, setDigitalCopyLink] = useState(null);

  const handleClick = async () => {

    // const formattedLink = ("https:\/\/prod-agrigates-in.s3.amazonaws.com\/DigitalWarehouseReceipts\/WR_2069198_260364a3abee9b986.pdf").replace(/\\\//g, '/');
    // console.log(formattedLink)
    // const response = await fetch(`http://novademo.agribazaar.com/gtsEntryForBlockChain.php?url=url_link&wr_no=2069198`);

    const wrNo = data.attributes.inward_details.wr_reciept_no;
    const url = `https://www.agrigates.com/gtsEntryForBlockChain.php?url=url_link&wr_no=${wrNo}`;

    axios.get(url)
      .then(response => {
        if (response.data && response.data.data && response.data.data.length > 0) {
          const rawLink = response.data.data[0].digital_copy_link;
          const formattedLink = rawLink.replace(/\\\//g, '/');
          setDigitalCopyLink(formattedLink);
          window.open(formattedLink, '_blank');

        } else {
          alert('Warehouse Receipt Digital Copy not found.');
        }
      })
      .catch(error => {
        console.error('Error:', error.message);
      });
  };


  return (
    <Box fullWidth>
      <HawkEyeAppBar />
      <Box mx='auto' sx={{ bgcolor: "background.paper", padding: "1.5rem 8rem", marginTop: "3rem" }}>
        <Grid mt={4}>
          <Link to='/operations'>
            <Chip icon={<KeyboardBackspaceIcon />} style={{ cursor: "pointer" }} label='Operations' variant='outlined' />
          </Link>
        </Grid>
        <Divider sx={{my:4}} />

        {
          showMoreDetails ? (
            <TestCertificate data={data} />
          ) :
          (
            <WarehouseReciept data={data} setShowMoreDetails={setShowMoreDetails} />
          )
        }

        <Grid mx="auto" mt={2} mb={8} sx={{ display: "flex", justifyContent: "center"}}>
            
              <Button variant="outlined" sx={{ mx:2, width:"15rem", fontSize: "1rem"}} color='primary' 
                  startIcon={<GetAppIcon />} 
                  onClick={handleClick}
                >
                  Download WR
                </Button>
              

            <Button color="error" variant="contained" onClick={() => handleTokenVerification("rejected")} sx={{ mx:2, width:"15rem", fontSize: "1.1rem"}} fullWidth outlined >
            Reject
            </Button>
            <Button onClick={() => handleTokenVerification("accepted")} variant="contained" sx={{ mx:2, color:"#fff", width:"15rem", fontSize: "1.1rem"}} fullWidth outlined color='success'>  
            Accept
            </Button>
            {/* Popup component */}
            <Token isOpen={isPopupOpen} message={popupMessage} onClose={handlePopupClose} />

            {
              showMoreDetails ? (
                <Button onClick={() => setShowMoreDetails(false)} variant="outlined" sx={{ mx:2, width:"15rem", fontSize: "1rem"}} outlined color='primary' startIcon={<KeyboardBackspaceIcon sx={{ fontSize: '1.75rem' }}/>}>
                Show WR
                </Button>
              ) : (
              <Button onClick={() => setShowMoreDetails(true)} variant="outlined" sx={{ mx:2, width:"15rem", fontSize: "1rem"}} outlined color='primary' endIcon={<ListIcon sx={{ fontSize: '1.75rem' }}/>}>
                More Details
              </Button>
              )
            }
            
        </Grid>


      </Box>
    </Box>
  );
};

export default DashboardDetailsAdmin;
