import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.js';
import * as serviceWorker from './serviceWorker';

// For the plugin to work kindly add a file .env.production
//  .env.production
//   > INLINE_RUNTIME_CHUNK=false ( Content of the file)


ReactDOM.createRoot(document.getElementById('root')).render(
  <App />
)
// ReactDOM.render(
//   <App />,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register();
