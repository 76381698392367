import React, { useState, useEffect } from "react";
import HawkEyeAppBar from "../../components/AppBar";
import {
  Box,
  Divider,
  Typography,
  Grid,
  Chip,
  Button,
  ImageList,
  Card,
  CardContent,
  Radio,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ListIcon from '@mui/icons-material/List';
import GetAppIcon from '@mui/icons-material/GetApp';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useNavigate, useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
import { Link } from 'react-router-dom';
import WarehouseReciept from "../../components/WarehouseReciept";
import TestCertificate from "../../components/TestCertificate"


const useStyles = makeStyles(() => ({
  cardHeader: {
    backgroundColor: "#0073b1",
    color: "#fff",
    fontWeight: "bold",
  },
  cardBody: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dropdownWrapper: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  dropdown: {
    textDecoration: "underline",
    marginRight: 8,
  },
  expandIcon: {
    transform: "rotate(0deg)",
    transition: "transform 0.2s ease-in-out",
  },
  expandIconOpen: {
    transform: "rotate(180deg)",
  },
  dropdownContent: {
    marginTop: 16,
    paddingLeft: 16,
    // borderLeft: "1px solid #ccc",
  },
  outline: {
    borderRadius: "1rem",
    border: "2px solid #d8daed",
    backgroundColor: "#fff",
  },
  customCard: {
    backgroundImage: "linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)",
    boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
    borderRadius: "1rem",
    border: "2px solid #d8daed",
  },
  subtitleFont: {
    fontSize: "1.5rem",
    fontWeight: "600",
  },
}));

const ApplyLoansDetails = (props) => {
  const [tabvalue, setTabvalue] = useState(0);
  const classes = useStyles();
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [qrcode, setQrCode] = useState(false);
  const history = useNavigate();
  const location = useLocation();
  const { data } = location.state;
  const [passData, setPassData] = useState({});
  const [showMoreDetails, setShowMoreDetails] = useState(false);

  // Function to toggle dropdown state
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleScan = () => {
    setQrCode(!qrcode);
  };

  const handleDownload = () => {
    const pdfUrl = `http://novademo.agribazaar.com/gtsEntryForBlockChain.php?url=url_link&wr_no=${data.attributes.inward_details.wr_reciept_no}`
    window.open(pdfUrl, '_blank');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setPassData(data)
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [tokenStatus, setTokenStatus] = useState("");
  const handleTokenVerification = (status) => {
    // Here you would add your code to handle the token verification
    setTokenStatus(status);
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  let popupMessage = "";
  if (tokenStatus === "accepted") {
    popupMessage = "Token Accepted";
  } else if (tokenStatus === "rejected") {
    popupMessage = "Token Rejected";
  }

  const [digitalCopyLink, setDigitalCopyLink] = useState(null);

  const handleClick = async () => {

    // const formattedLink = ("https:\/\/prod-agrigates-in.s3.amazonaws.com\/DigitalWarehouseReceipts\/WR_2069198_260364a3abee9b986.pdf").replace(/\\\//g, '/');
    // console.log(formattedLink)
    // const response = await fetch(`http://novademo.agribazaar.com/gtsEntryForBlockChain.php?url=url_link&wr_no=2069198`);


    const response = await fetch(`http://novademo.agribazaar.com/gtsEntryForBlockChain.php?url=url_link&wr_no=${data.attributes.inward_details.wr_reciept_no}`);
    const res_data = await response.json();
    const formattedLink = res_data.data[0].digital_copy_link.replace(/\\\//g, '/');
    console.log(formattedLink)
    setDigitalCopyLink(formattedLink);
  };

  return (
    <Box fullWidth>
      <HawkEyeAppBar />
      <Box mx='auto' sx={{ bgcolor: "background.paper", padding: "1.5rem 8rem", marginTop: "50px" }}>
      <Grid mt="2rem" mb="1rem">
      <Link to='/admin'>
        <Chip icon={<KeyboardBackspaceIcon />} style={{ cursor: "pointer" }} label='Admin' variant='outlined' />
      </Link>
    </Grid>
        <Divider/>
      
        {
          showMoreDetails ? (
            <TestCertificate data={data} />
          ) :
          (
            <WarehouseReciept data={data} setShowMoreDetails={setShowMoreDetails} />
          )
        }

        <Grid mx="auto" mt={2} mb={8} sx={{ display: "flex", justifyContent: "center"}}>
            
              {/* <Button variant="outlined" sx={{ mx:2, width:"15rem", fontSize: "1rem"}} color='primary' 
              startIcon={<GetAppIcon />} 
              onClick={() => window.open(`http://novademo.agribazaar.com/gtsEntryForBlockChain.php?url=url_link&wr_no=${data.attributes.inward_details.wr_reciept_no}`, '_blank')}
              >
                Download WR
              </Button> */}
              
              <Button variant="outlined" sx={{ mx:2, width:"15rem", fontSize: "1rem"}} color='primary' 
                startIcon={<GetAppIcon />} 
                onClick={handleClick}
              >
                Download WR
              </Button>
              {digitalCopyLink && (
                <a href={digitalCopyLink} target="_blank" rel="noopener noreferrer">Download Digital Copy</a>
              )}

              <Button variant="contained" sx={{ mx:2, color:"#fff", width:"17rem", fontSize: "1rem"}} color='success' 
                  startIcon={<TaskAltIcon />}
                  onClick={() => {
                    window.location.href = `/audit-trail/${data.attributes.inward_details.wr_reciept_no}`;
                  }}> 
                  Transactions Trail
              </Button>
           
           
            {/* 
            <Link to='/make-loan' state={{ data: passData }}>
              <Button variant="contained" sx={{ mx:2, width:"15rem", fontSize: "1.1rem"}} fullWidth outlined color='primary'>
              <Typography fontSize="1.1rem" color="#fff">Apply Loan</Typography>
              </Button>
            </Link>
            <Link to='/mark-lien' state={{ data: passData }}>
              <Button variant="contained" sx={{ mx:2, width:"15rem", fontSize: "1.1rem"}} fullWidth outlined color='success'>
              <Typography fontSize="1.1rem" color="#fff">Mark Lien</Typography>
              </Button>
            </Link>
            */}
            {
              showMoreDetails ? (
                <Button onClick={() => setShowMoreDetails(false)} variant="outlined" sx={{ mx:2, width:"15rem", fontSize: "1rem"}} outlined color='primary' startIcon={<KeyboardBackspaceIcon sx={{ fontSize: '1.75rem' }}/>}>
                Show WR
                </Button>
              ) : (
              <Button onClick={() => setShowMoreDetails(true)} variant="outlined" sx={{ mx:2, width:"15rem", fontSize: "1rem"}} outlined color='primary' endIcon={<ListIcon sx={{ fontSize: '1.75rem' }}/>}>
                More Details
              </Button>
              )
            }
            
        </Grid>


        


        
      </Box>
    </Box>
  );
};

export default ApplyLoansDetails;
