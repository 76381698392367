import React, { useState } from "react";
import HawkEyeAppBar from "../../components/AppBar";
import DashboardCard from "../../components/DashboardCard";
import StatusCard from "../../components/StatusCard";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { MenuList, MenuItem, Divider, Switch, TextField, ListItemText, Button, Chip, Link, FormControlLabel, Checkbox } from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { makeStyles } from "@mui/styles";

const DepositorDashboard = () => {
  const myProp = "Hello from ParentComponent!";
  const useStyles = makeStyles(() => ({
    chipCustom: {
      margin: "0 1rem",
      borderRadius: "9px", //some style
      "& .MuiChip-label": { fontSize: 14, fontWeight: "bold" }, // sub-selector
    },
  }));

  const customChipClass = useStyles();

  const [tabvalue, setTabvalue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabvalue(newValue);
  };

  return (
    <div>
      <HawkEyeAppBar />
      <Grid container>
        <Grid xs={12} md={3}></Grid>
        <Grid xs={12} md={8} mx='auto'>
          <Box sx={{ bgcolor: "background.paper", padding: "2.5rem 0px", marginTop: "3rem" }}>
            <Tabs value={tabvalue} onChange={handleChange} centered outlined>
              <Tab sx={{ fontSize: "1.15rem" }} label='My Token' />
              <Tab sx={{ fontSize: "1.15rem" }} label='Apply Loan' />
              <Tab sx={{ fontSize: "1.15rem" }} label='Loan Status' />
            </Tabs>
          </Box>
        </Grid>
      </Grid>

      {tabvalue === 0 && (
        <Grid container spacing={2}>
          <Grid md={3}></Grid>
          <Grid container xs={12} md={8} mx='auto' sx={{ textAlign: "center" }} mt={2} mb={0}>
            <Grid item xs={4}>
              <Typography variant='body1' sx={{ textAlign: "right", fontWeight: "700" }}>
                Recently Added:
              </Typography>
            </Grid>
            <Grid item ml={2} sx={{ borderRight: "1px solid black" }} xs={2}>
              <Typography variant='body1'>
                Tokens
                <Chip color="success" sx={{color:"#fff"}}  className={customChipClass.chipCustom} component='box' mx={2} variant='h6' label='532' />
              </Typography>
            </Grid>
            <Grid item ml={2} sx={{ borderRight: "1px solid black" }} xs={2}>
              <Typography variant='body1'>
                Quantity
                <Chip color="success" sx={{color:"#fff"}} className={customChipClass.chipCustom} component='box' mx={2} variant='h6' label='250 MT' />
              </Typography>
            </Grid>
            <Grid item ml={2} xs={2}>
              <Typography variant='body1'>
                Commodities
                <Chip color="success" sx={{color:"#fff"}} className={customChipClass.chipCustom} component='box' mx={2} variant='h6' label='12' />
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={3}>
            <Paper elevation={2} sx={{ ml: 3, p: 3, mt: 10 }} style={{ borderRadius: "10px", top: "65px", bottom: "0px", width: "20%", position: "fixed", overflow: "scroll" }}>
              <Box fullWidth>
                <MenuList dense>
                  <Grid container flex>
                    <Typography variant='h6'>Filters</Typography>
                    <Button sx={{ ml: "35%"}} color="error" variant="outlined">Clear filters</Button>
                  </Grid>
                  <Divider sx={{ my:2 }} />
                  <Typography variant='subtitle1' sx={{ fontWeight: "bold" }}>
                    Status
                  </Typography>
                  <MenuItem>
                    <FormControlLabel control={<Switch defaultChecked color="success" />} label='Fundable' />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel control={<Switch color="success" />} label='Non Fundable' />
                  </MenuItem>

                  <Divider />
                  <Typography variant='subtitle1' sx={{ fontWeight: "bold" }}>
                    Inward period
                  </Typography>
                  <Typography ml={1} variant='subtitle1' sx={{ fontWeight: "bold" }}>
                    From
                  </Typography>
                  <Box m={1}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker/>
                    </LocalizationProvider>
                  </Box>
                  <Typography ml={1} variant='subtitle1' sx={{ fontWeight: "bold" }}>
                    to
                  </Typography>
                  <Box m={1} mb={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker />
                    </LocalizationProvider>
                  </Box>
                  <Divider />

                  <Typography mt={2} variant='subtitle1' sx={{ fontWeight: "bold" }}>
                    Commmodity
                  </Typography>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox defaultChecked />} label='Wheat' />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox />} label='Rice' />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox />} label='Maize' />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox />} label='Cotton' />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox />} label='Soyabean' />
                  </MenuItem>
                  <Divider />
                  <Typography variant='subtitle1' sx={{ fontWeight: "bold" }}>
                    Depositor type
                  </Typography>
                  <MenuItem>
                    <FormControlLabel control={<Switch defaultChecked color="success" />} label='Farmer' />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel control={<Switch color="success" />} label='Trader' />
                  </MenuItem>

                  <Divider />
                  <Typography variant='subtitle1' sx={{ fontWeight: "bold" }}>
                    Location
                  </Typography>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox defaultChecked />} label='Maharashtra' />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox />} label='Gujarat' />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox />} label='Goa' />
                  </MenuItem>

                  <Button variant='contained' fullWidth color='primary'>
                    <Typography variant='subtitle1' color='white'>
                      Apply Filters
                    </Typography>
                  </Button>
                </MenuList>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={9}>
            <Paper elevation={0} sx={{ m: 2, px: 3 }}>
              <Link href='farmer-details' underline='none'>
                <StatusCard status='Fundable' />
              </Link>
              <Link href='farmer-details' underline='none'>
                <StatusCard status='Non Fundable' />
              </Link>
              <Link href='farmer-details' underline='none'>
                <StatusCard status='Fundable' />
              </Link>
              <Link href='farmer-details' underline='none'>
                <StatusCard status='Fundable' />
              </Link>
              <Link href='farmer-details' underline='none'>
                <StatusCard status='Non Fundable' />
              </Link>
            </Paper>
          </Grid>
        </Grid>
      )}

      {tabvalue === 1 && (
        <Grid container spacing={2}>
          <Grid md={3}></Grid>
          <Grid container xs={12} md={8} mx='auto' sx={{ textAlign: "center" }} mt={2} mb={0}>
            <Grid item xs={4}>
              <Typography variant='body1' sx={{ textAlign: "right", fontWeight: "700" }}>
                Recently Added:
              </Typography>
            </Grid>
            <Grid item ml={2} sx={{ borderRight: "1px solid black" }} xs={2}>
              <Typography variant='body1'>
                Tokens
                <Chip className={customChipClass.chipCustom} component='box' mx={2} variant='h6' label='532' />
              </Typography>
            </Grid>
            <Grid item ml={2} sx={{ borderRight: "1px solid black" }} xs={2}>
              <Typography variant='body1'>
                Quantity
                <Chip className={customChipClass.chipCustom} component='box' mx={2} variant='h6' label='250 MT' />
              </Typography>
            </Grid>
            <Grid item ml={2} xs={2}>
              <Typography variant='body1'>
                Commodities
                <Chip className={customChipClass.chipCustom} component='box' mx={2} variant='h6' label='12' />
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={3}>
            <Paper elevation={2} sx={{ ml: 3, p: 3, mt: 10 }} style={{ borderRadius: "10px", top: "65px", bottom: "0px", width: "20%", position: "fixed", overflow: "scroll" }}>
              <Box fullWidth>
                <MenuList dense>
                  <MenuItem>
                    <Typography variant='h6'>Filters</Typography>
                    <Typography color='red' ml='35%'>
                      Clear Filters
                    </Typography>
                  </MenuItem>
                  <Divider />
                  <Typography variant='subtitle1' sx={{ fontWeight: "bold" }}>
                    Status
                  </Typography>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox defaultChecked />} label='Fundable' />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox />} label='Non Fundable' />
                  </MenuItem>

                  <Divider />
                  <Typography variant='subtitle1' sx={{ fontWeight: "bold" }}>
                    Inward period
                  </Typography>
                  <Typography ml={1} variant='subtitle1' sx={{ fontWeight: "bold" }}>
                    From
                  </Typography>
                  <Box m={1}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker />
                    </LocalizationProvider>
                  </Box>
                  <Typography ml={1} variant='subtitle1' sx={{ fontWeight: "bold" }}>
                    to
                  </Typography>
                  <Box m={1} mb={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker />
                    </LocalizationProvider>
                  </Box>
                  <Divider />

                  <Typography mt={2} variant='subtitle1' sx={{ fontWeight: "bold" }}>
                    Commmodity
                  </Typography>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox defaultChecked />} label='Wheat' />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox />} label='Rice' />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox />} label='Maize' />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox />} label='Cotton' />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox />} label='Soyabean' />
                  </MenuItem>
                  <Divider />
                  <Typography variant='subtitle1' sx={{ fontWeight: "bold" }}>
                    Depositor type
                  </Typography>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox defaultChecked />} label='Farmer' />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox />} label='Trader' />
                  </MenuItem>

                  <Divider />
                  <Typography variant='subtitle1' sx={{ fontWeight: "bold" }}>
                    Location
                  </Typography>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox defaultChecked />} label='Maharashtra' />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox />} label='Gujarat' />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox />} label='Goa' />
                  </MenuItem>

                  <Button variant='contained' fullWidth color='primary'>
                    <Typography variant='subtitle1' color='white'>
                      Apply Filters
                    </Typography>
                  </Button>
                </MenuList>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={9}>
            <Paper elevation={0} sx={{ m: 2, px: 3 }}>
              <Link href='apply-loan' underline='none'>
                <StatusCard status='Fundable' />
              </Link>
              <Link href='apply-loan' underline='none'>
                <StatusCard status='Fundable' />
              </Link>
              <Link href='apply-loan' underline='none'>
                <StatusCard status='Fundable' />
              </Link>
            </Paper>
          </Grid>
        </Grid>
      )}

      {tabvalue === 2 && (
        <Grid container spacing={2}>
          <Grid md={3}></Grid>
          <Grid container xs={12} md={8} mx='auto' sx={{ textAlign: "center" }} mt={2} mb={0}>
            <Grid item xs={4}>
              <Typography variant='body1' sx={{ textAlign: "right", fontWeight: "700" }}>
                Recently Added:
              </Typography>
            </Grid>
            <Grid item ml={2} sx={{ borderRight: "1px solid black" }} xs={2}>
              <Typography variant='body1'>
                Tokens
                <Chip className={customChipClass.chipCustom} component='box' mx={2} variant='h6' label='532' />
              </Typography>
            </Grid>
            <Grid item ml={2} sx={{ borderRight: "1px solid black" }} xs={2}>
              <Typography variant='body1'>
                Quantity
                <Chip className={customChipClass.chipCustom} component='box' mx={2} variant='h6' label='250 MT' />
              </Typography>
            </Grid>
            <Grid item ml={2} xs={2}>
              <Typography variant='body1'>
                Commodities
                <Chip className={customChipClass.chipCustom} component='box' mx={2} variant='h6' label='12' />
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={3}>
            <Paper elevation={2} sx={{ ml: 3, p: 3, mt: 10 }} style={{ borderRadius: "10px", top: "65px", bottom: "0px", width: "20%", position: "fixed", overflow: "scroll" }}>
              <Box fullWidth>
                <MenuList dense>
                  <MenuItem>
                    <Typography variant='h6'>Filters</Typography>
                    <Typography color='red' ml='35%'>
                      Clear Filters
                    </Typography>
                  </MenuItem>
                  <Divider />
                  <Typography variant='subtitle1' sx={{ fontWeight: "bold" }}>
                    Status
                  </Typography>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox defaultChecked />} label='Fundable' />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox />} label='Non Fundable' />
                  </MenuItem>

                  <Divider />
                  <Typography variant='subtitle1' sx={{ fontWeight: "bold" }}>
                    Inward period
                  </Typography>
                  <Typography ml={1} variant='subtitle1' sx={{ fontWeight: "bold" }}>
                    From
                  </Typography>
                  <Box m={1}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker />
                    </LocalizationProvider>
                  </Box>
                  <Typography ml={1} variant='subtitle1' sx={{ fontWeight: "bold" }}>
                    to
                  </Typography>
                  <Box m={1} mb={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker />
                    </LocalizationProvider>
                  </Box>
                  <Divider />

                  <Typography mt={2} variant='subtitle1' sx={{ fontWeight: "bold" }}>
                    Commmodity
                  </Typography>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox defaultChecked />} label='Wheat' />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox />} label='Rice' />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox />} label='Maize' />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox />} label='Cotton' />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox />} label='Soyabean' />
                  </MenuItem>
                  <Divider />
                  <Typography variant='subtitle1' sx={{ fontWeight: "bold" }}>
                    Depositor type
                  </Typography>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox defaultChecked />} label='Farmer' />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox />} label='Trader' />
                  </MenuItem>

                  <Divider />
                  <Typography variant='subtitle1' sx={{ fontWeight: "bold" }}>
                    Location
                  </Typography>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox defaultChecked />} label='Maharashtra' />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox />} label='Gujarat' />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel control={<Checkbox />} label='Goa' />
                  </MenuItem>

                  <Button variant='contained' fullWidth color='primary'>
                    <Typography variant='subtitle1' color='white'>
                      Apply Filters
                    </Typography>
                  </Button>
                </MenuList>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={9}>
            <Paper elevation={0} sx={{ m: 2, px: 3 }}>
              <Link href='loan-status' underline='none'>
                <StatusCard status={"Accepted"} />
              </Link>
              <Link href='loan-status' underline='none'>
                <StatusCard status={"Pending"} />
              </Link>
              <Link href='loan-status' underline='none'>
                <StatusCard status={"Rejected"} />
              </Link>
              <Link href='farmer-details' underline='none'>
                <StatusCard status={"Accepted"} />
              </Link>
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default DepositorDashboard;
