import React, { useState, useEffect } from 'react';
import { Grid, TextField,Typography, CircularProgress, Button } from '@mui/material';
import { Chart } from 'react-google-charts';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import api from '../globals/dashboard_instance'; // assuming you have an Axios instance named 'api'
import dayjs from 'dayjs';

const RepaymentsAndDisbursementGraphs = () => {
    const today = dayjs();
    const lastWeek = today.subtract(7, 'day');

    const [startDate, setStartDate] = useState(lastWeek);
    const [endDate, setEndDate] = useState(today);
    const [data, setData] = useState({ counts: [], values: [] });
    const [loading, setLoading] = useState(false);
    const [dataNotFound, setDataNotFound] = useState(false);

    useEffect(() => {
        fetchData();
    }, [startDate, endDate]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const formattedStartDate = startDate.format('YYYY-MM-DD');
            const formattedEndDate = endDate.format('YYYY-MM-DD');
    
            const [repaymentsResponse, disbursementResponse] = await Promise.all([
                api.get(`/dashboard/repayment_data?from_date=${formattedStartDate}&end_date=${formattedEndDate}`),
                api.get(`/dashboard/disbursement_data?from_date=${formattedStartDate}&end_date=${formattedEndDate}`),
            ]);

            if (repaymentsResponse.data.status === 'success' && disbursementResponse.data.status === 'success') {
                const repaymentsData = repaymentsResponse.data.data;
                const disbursementData = disbursementResponse.data.data;

                console.log("Repayments Data: ", repaymentsData);
                console.log("Disbursement Data: ", disbursementData);

                if (Object.keys(repaymentsData).length === 0 && Object.keys(disbursementData).length === 0) {
                    setDataNotFound(true);
                } else {
                    setDataNotFound(false);

                    const dates = new Set([
                        ...Object.keys(repaymentsData),
                        ...Object.keys(disbursementData)
                    ]);

                    const countsChartData = [
                        ['Date', 'Repayments Count', 'Disbursement Count'],
                        ...Array.from(dates).map(date => [
                            date,
                            repaymentsData[date]?.count || 0,
                            disbursementData[date]?.count || 0
                        ])
                    ];

                    const valuesChartData = [
                        ['Date', 'Repayments Value (INR)', 'Disbursement Value (INR)'],
                        ...Array.from(dates).map(date => [
                            date,
                            repaymentsData[date]?.total_paid_amount || 0,
                            disbursementData[date]?.total_loan_amount || 0
                        ])
                    ];

                    setData({ counts: countsChartData, values: valuesChartData });
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    

    const handleUpdateClick = () => {
        fetchData();
    };

    return (
        <Grid container spacing={2} p={2}>
            <Grid item xs={12}>
                <Typography variant="h6" align="center" gutterBottom>   
                    Repayments and Disbursement Graphs
                </Typography>
                <Typography variant="body1" align="center" gutterBottom>
                    Select the start and end date to view the counts and values of repayments and disbursements.
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid mx="auto"  my={1} justifyContent="center" alignItems="center" container spacing={4} fullWidth>
                        <Grid item xs={3}>
                        <DatePicker
                        label="Start Date"
                        format="DD-MM-YYYY"
                        value={startDate}
                        onChange={(newValue) => setStartDate(dayjs(newValue))}
                        renderInput={(params) => <TextField {...params} />}
                    />
                        </Grid>
                        <Grid item xs={3}>
                        <DatePicker
                        label="End Date"
                        format="DD-MM-YYYY"
                        value={endDate}
                        onChange={(newValue) => setEndDate(dayjs(newValue))}
                        renderInput={(params) => <TextField {...params} />}
                    />
                        </Grid>
                        <Grid item xs={3}>
                        <Button variant="contained" color="primary" onClick={handleUpdateClick}>
                        Update
                    </Button>
                        </Grid>

                    </Grid>
                </LocalizationProvider>
            </Grid>

            <Grid item xs={6}>
                <Typography variant="h6" align="center" gutterBottom>
                    Counts
                </Typography>
                
                {loading ? (
                    <CircularProgress />
                ) : dataNotFound ? (
                    <Typography textAlign="center" variant="body1">Data not found</Typography>
                ) : (
                    <Chart
                        width={'100%'}
                        height={'400px'}
                        chartType="LineChart"
                        data={data.counts}
                        options={{
                            title: 'Repayments and Disbursement Counts',
                            hAxis: { title: 'Date' },
                            vAxis: { title: 'Count' },
                        }}
                    />
                )}
            </Grid>
            <Grid item xs={6}>
                <Typography variant="h6" align="center" gutterBottom>
                    Values (INR)
                </Typography>
                {loading ? (
                    <CircularProgress />
                ) : dataNotFound ? (
                    <Typography textAlign="center" variant="body1">Data not found</Typography>
                ) : (
                    <Chart
                        width={'100%'}
                        height={'400px'}
                        chartType="LineChart"
                        data={data.values}
                        options={{
                            title: 'Repayments and Disbursement Values (INR)',
                            hAxis: { title: 'Date' },
                            vAxis: { title: 'Value (INR)' },
                        }}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default RepaymentsAndDisbursementGraphs;