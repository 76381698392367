import React, { useEffect, useState, useRef } from "react";
import HawkEyeAppBar from "../../components/AppBar";
import { styled } from '@mui/material/styles';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { MenuList, ButtonBase, MenuItem, Divider, TextField, Button, Chip, CircularProgress } from "@mui/material";
import { Card, CardContent} from '@mui/material';
import { Link } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Autocomplete from '@mui/material/Autocomplete';
import instance from "../../globals/dashboard_instance";
import { Chart } from "react-google-charts";
import { convertToMillion, convertToCommaSeperated } from "../../globals/utils"
import { useParams } from 'react-router-dom';


const WarehouseStats = () => {
        const { selectedOption, search } = useParams();

       
        const formatDate = (dateString) => {
            const date = new Date(dateString);
            const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
            
            const formattedDate = date.toLocaleDateString('en-US', options);
          
            return formattedDate.replace(/(\d+)\/(\d+)\/(\d+)/, '$1-$2-$3');
        };
          

        const [top5CommInwardData, setTop5CommInwardData] = useState([]);
        const [page, setPage] = useState(1);
        const [pageSize, setPageSize] = useState(10); 

        const [loading3, setLoading3] = useState(false);
        const [top5StateAUMData, setTop5StateAUMData] = useState([]);
        const [top5StateInwardData, setTop5StateInwardData] = useState([]);
        const [stateData, setStateData] = useState([]);
        const [selectedTop5State, setSelectedTop5State] = useState(null);

        useEffect(() => {
            const fetchData = async () => {
            try {
                setLoading3(true);

                console.log(selectedOption)
                console.log(search)

                if (selectedOption) {
                    let aumEndpoint, inwardEndpoint;
            
                    if (selectedOption === 'commodity') {
                      inwardEndpoint = `top-all/commodity-inward?commodity_name=${search}`;
                    } else if (selectedOption === 'location') {
                      inwardEndpoint = `top-all/location-inward?location=${search}`;
                    } else if (selectedOption === 'state') {
                      inwardEndpoint = `top-all/state-inward?state=${search}`;
                    }
            
                    // Fetch  data
                    const inwardResponse = await instance.get(inwardEndpoint);
                    const filteredInwardData = inwardResponse.data[search] || [];
                    const processedInwardData = filteredInwardData.map((item, index) => ({ ...item, id: index + 1 }));
                    setTop5StateInwardData(processedInwardData);
                }
            

                setLoading3(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading3(false);
            }
            };

            fetchData();
        }, []);

        let top5Columns1;
        
        top5Columns1 = [
            selectedOption === 'commodity' && { field: 'commodity_name', headerName: 'Commodity', align: 'center', headerAlign: 'center', editable: false, flex: 1 },
            selectedOption === 'location' && { field: 'location_wise', headerName: 'Location', align: 'center', headerAlign: 'center', editable: false, flex: 1 },
            selectedOption === 'state' && { field: 'state_name', headerName: 'State', align: 'center', headerAlign: 'center', editable: false, flex: 1 },
            { field: 'warehouse_name', headerName: 'Warehouse Name', align: 'center', headerAlign: 'center', editable: false, flex: 1 },
            { field: 'inward_count', headerName: 'Inwards', align: 'center', headerAlign: 'center', editable: false, flex: 1, valueFormatter: (params) => convertToCommaSeperated(params.value), },
            { field: 'aum', headerName: 'AUM', align: 'center', headerAlign: 'center', editable: false, flex: 1,valueFormatter: (params) => {
                const formattedValue = (params.value / 10000000).toFixed(2);
                return `₹ ${convertToCommaSeperated(formattedValue)} Crores`;
              }, },
        ].filter(column => column);


        return (
            <Box fullWidth>
                <HawkEyeAppBar />

               
                <Paper sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px", borderRadius:"20px", mt:14, mb:6, mx:8, p:2, backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)'}}>
                    
                    <Grid sx={{ borderRadius: '1rem', border: '2px solid #d8daed', backgroundColor: '#fff', p: 4 }} >
                     
                        <Grid container>
                        {/* <Grid fullWidth xs={12}>
                            {toDate !== '' && (
                            <Divider sx={{ mt: 2, mb: 2 }}>
                                <Typography>
                                From {formatMMDate(fromDate)} To {formatMMDate(toDate)}
                                </Typography>
                            </Divider>
                            )}
                        </Grid> */}

                        

                        {loading3 && (
                            <Grid sx={{ mx: 'auto', my: 4, alignItems: 'center', justifyContent: 'center' }} container>
                            <CircularProgress />
                            </Grid>
                        )}

                        {/* {!loading3 && top5StateAUMData.length > 0 && top5StateInwardData.length > 0 && ( */}
                        {!loading3 && (

                            <Grid textAlign="center" sx={{ borderRadius: '1rem', mx:"auto", backgroundColor: '#fff', p: 2 }} xs={11}>
                                <Typography variant="h5" sx={{ px: 2, py: 3, textAlign: 'center', fontWeight: 500 }}>
                                Warehouse Data
                                </Typography>
                                <Box sx={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={top5StateInwardData}
                                    columns={top5Columns1}
                                    disableRowSelectionOnClick
                                    pagination
                                    components={{
                                        Toolbar: GridToolbar, // Enable toolbar for export, filter, and sort options
                                    }}
                                />
                                </Box>
                            </Grid>

                        )}
                        </Grid>
                                        
                    </Grid>
               
                </Paper>


            </Box>
        );
    };

export default WarehouseStats;
