import React, { useState, useEffect } from "react";
import HawkEyeAppBar from "../../components/AppBar";
import {
  Box,
  Divider,
  Typography,
  Grid,
  Container,
  Chip,
} from "@mui/material";
import { Stepper, Step, StepLabel } from '@mui/material';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import instance from "../../globals/axios";
import QRCode from "react-qr-code";
import StarAgriLogo from '../../assets/staragri-logo.png'
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    // backgroundImage: 'url("https://static.vecteezy.com/system/resources/thumbnails/008/854/270/original/abstract-colorful-gradient-animation-background-free-video.jpg")',
    backgroundImage: `url(${require('../../assets/StaragriCertificate.png')})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
    // opacity: 0.8,
  },
  borderRight: {
    border:"1px solid #000", 
    borderLeft: 'none',
  },
  borderLeft: {
    border:"1px solid #000", 
    borderRight: 'none',
  },
  borderCenter: {
    border:"1px solid #000", 
  }
}));



const NewDetails = (props) => {
    const classes = useStyles();

    const steps = ['WR Generated', 'WR Accepted', 'Lien Marked', 'Lien Unmarked','Outward'];

    const [activeStep, setActiveStep] = useState(3);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    


    return (
        <Box fullWidth>
        <HawkEyeAppBar />
        <Box mx='auto' sx={{ backgroundImage: "../../assets/StaragriCertificate.png", padding: "2rem 8rem", marginTop: "4rem" }}>
            <Grid my={2} >
            <Link to='/banker'>
                <Chip icon={<KeyboardBackspaceIcon />} style={{ cursor: "pointer" }} label='All Tokens' variant='outlined' />
            </Link>
            </Grid>
            <Divider />
        </Box>

        <Box>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
                ))}
            </Stepper>
           
        </Box>
        
        
        <Container >
        
          
            <Grid className={classes.gridContainer} mb="2rem" mt="3.5rem" py={8} px={10} container fullWidth>
                <Grid fullWidth mx="auto" textAlign="center" alignItems="center">
                    <Box flex>
                        <img mx="auto" fontWeight={500} alt='StarAgri logo' mt={6} src={StarAgriLogo} style={{ width: "20%" }} />
                        <Typography variant="h6">
                        Star Agriwarehousing and Collateral Management Limited
                        </Typography>
                        <Typography>
                        A 601-604, Bonanza Building, Sahar Plaza Complex, Near J.B. Nagar Metro Station, J.B. Nagar, Andheri (East), Mumbai – 400 059
                        </Typography>
                        <Typography mt={2} mb={4} fontWeight={500} variant="h6">
                        WAREHOUSE RECEIPT(WR)
                        </Typography>
                    </Box>

                </Grid>
                <Grid container fullWidth>
                    <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
                    WR No.
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
                    WR-2091614
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
                    Date of issue
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
                    05-Jul-2023
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
                    Base Receipt No.
                    </Grid>
                    <Grid item className={classes.borderRight} xs={12} md={2} px={1} py={1} textAlign="center">
                    
                    </Grid>
                </Grid>
                <Grid container fullWidth>
                    <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
                    CIS No.
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
                    IN-A433499
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
                    Date of Deposit
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
                    05-Jul-2023
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
                    Staragri Branch Name
                    </Grid>
                    <Grid item className={classes.borderRight} xs={12} md={2} px={1} py={1} textAlign="center">
                    ALIPUR
                    </Grid>
                </Grid>
                <Grid container fullWidth>
                    <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
                    Warehouse Name
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={10} px={1} py={1} textAlign="center">
                    Atharva Utekar WAREHOUSE (Godown No: 4)
                    </Grid>    
                </Grid>
                <Grid container fullWidth>
                    <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
                    Warehouse Address
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={10} px={1} py={1} textAlign="center">
                    KHASRA NO-36--/3(1-04)(,4(3-17) VILLAGE-KHERA KALAN,, DELHI-110082
                    </Grid>    
                </Grid>
                <Grid container fullWidth>
                    <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
                    Depositor Name
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={10} px={1} py={1} textAlign="center">
                    Shree Balaji Rice Mills
                    </Grid>    
                </Grid>
                <Grid container fullWidth>
                    <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
                    Depositor Address
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={10} px={1} py={1} textAlign="center">
                    KHASRA NO 55/15, BAWANA ROAD, NARELA
                    </Grid>    
                </Grid>
                <Grid container fullWidth>
                    <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
                    Commodity
                    </Grid>
                    <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
                    No. of Units
                    </Grid>
                    <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
                    Net Weight
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={4} px={1} py={1} textAlign="center">
                    Grade
                    </Grid> 
                    <Grid item className={classes.borderRight} xs={12} md={2} px={1} py={1} textAlign="center">
                    Remarks
                    </Grid>   
                </Grid>
                <Grid container fullWidth>
                    <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
                    Rice
                    </Grid>
                    <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
                    550
                    </Grid>
                    <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
                    30.450 MT
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={4} px={1} py={1} textAlign="center">
                    Average
                    </Grid> 
                    <Grid item className={classes.borderRight} xs={12} md={2} px={1} py={1} textAlign="center">
                    1121 RAW
                    </Grid>   
                </Grid>
                <Grid container fullWidth>
                    <Grid item className={classes.borderLeft} xs={12} md={6} px={1} py={1} textAlign="center">
                    Validity
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={3} px={1} py={1} textAlign="center">
                    From 05-Jul-2023
                    </Grid>
                    <Grid item className={classes.borderRight} xs={12} md={3} px={1} py={1} textAlign="center">
                    To 10-Jun-2024
                    </Grid>   
                </Grid>
                <Grid container fullWidth>
                    <Grid item className={classes.borderLeft} xs={12} md={4} px={1} py={1} textAlign="center">
                    Market Rate of Commodity(₹)
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
                    92000.000/MT
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={3} px={1} py={1} textAlign="center">
                    Value of Commodity(₹)
                    </Grid>
                    <Grid item className={classes.borderRight} xs={12} md={3} px={1} py={1} textAlign="center">
                    2801400.000
                    </Grid>   
                </Grid>
                <Grid container fullWidth>
                    <Grid item className={classes.borderCenter} xs={12} md={4} px={1} py={1} textAlign="center">
                    Value of Commodity(In words)
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={8} px={1} py={1} textAlign="center">
                    Rupees Twenty Eight Lakhs One Thousand Four Hundred Only
                    </Grid>       
                </Grid>
                <Grid container fullWidth>
                    <Grid item className={classes.borderLeft} xs={12} md={4} px={1} py={1} textAlign="center">
                    Storage Charges (₹ Exclsive of Taxes)
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
                    
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={3} px={1} py={1} textAlign="center">
                    Total Amount(₹ Per Month)
                    </Grid>
                    <Grid item className={classes.borderRight} xs={12} md={3} px={1} py={1} textAlign="center">
                    
                    </Grid>   
                </Grid>
                <Grid container fullWidth>
                    <Grid item className={classes.borderCenter} xs={12} md={4} px={1} py={1} textAlign="center">
                    Insurance Declaration
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={8} px={1} py={1} textAlign="center">
                    Insurance managed by Staragri, United India Insurance Co. Ltd.
                    </Grid>
                </Grid>
                <Grid container fullWidth>
                    <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
                    Policy Type
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={4} px={1} py={1} textAlign="center">
                    Policy No.
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={3} px={1} py={1} textAlign="center">
                    Valid From
                    </Grid>
                    <Grid container cols={12} xs={12} md={3} textAlign="center">
                        <Grid item className={classes.borderCenter} xs={12} md={6} px={1} py={1} textAlign="center">
                        Valid To
                        </Grid>
                        <Grid item className={classes.borderRight} xs={12} md={6} px={1} py={1} textAlign="center">
                        Sum Insured
                        </Grid>
                    <Grid>
                

                </Grid>
            </Grid>
            

            
                </Grid>
                <Grid container fullWidth>
                    <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
                    Fire
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={4} px={1} py={1} textAlign="center">
                    5001001123P102467799
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={3} px={1} py={1} textAlign="center">
                    11-Jun-2023
                    </Grid>
                    <Grid container cols={12} xs={12} md={3} textAlign="center">
                        <Grid item className={classes.borderCenter} xs={12} md={6} px={1} py={1} textAlign="center">
                        10-Jun-2024
                        </Grid>
                        <Grid item className={classes.borderRight} xs={12} md={6} px={1} py={1} textAlign="center">
                        48000000000
                        </Grid>
                    <Grid>
                

                    </Grid>
                    </Grid>
                </Grid>
                <Grid container fullWidth>
                    <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
                    Burglary
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={4} px={1} py={1} textAlign="center">
                    5001001223P102475097
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={3} px={1} py={1} textAlign="center">
                    11-Jun-2023
                    </Grid>
                    <Grid container cols={12} xs={12} md={3} textAlign="center">
                        <Grid item className={classes.borderCenter} xs={12} md={6} px={1} py={1} textAlign="center">
                        10-Jun-2024
                        </Grid>
                        <Grid item className={classes.borderRight} xs={12} md={6} px={1} py={1} textAlign="center">
                        56000000000
                        </Grid>
                    <Grid>
            

                    </Grid>
                </Grid>
                
        
            

            
                </Grid>
                <Grid container fullWidth>
                    <Grid item className={classes.borderCenter} xs={12} md={12} px={1} py={1} textAlign="center">
                    <Typography variant="bold">Lien Details</Typography>
                    </Grid>
                </Grid>
                <Grid container fullWidth>
                    <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
                    Date
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={10} px={1} py={1} textAlign="center">
                    Name of lender
                    </Grid>    
                </Grid>
                <Grid container fullWidth>
                    <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
                    05-Jul-2023
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={10} px={1} py={1} textAlign="center">
                    AXIS BANK LTD.-Bazidpur Saboli
                    </Grid>    
                </Grid>
                <Grid container fullWidth>
                    <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
                    <br/>
                    QR Code

                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
                    
                        <QRCode
                        size={18}
                        style={{ height: "auto", maxWidth: "75%", width: "75%" }}
                        value={"http://15.206.209.247:8421/get-token?token_code=7d23943d-6a23-4919-a6a6-56df876ed686" }
                        viewBox={`0 0 256 256`}
                        />
                    </Grid>   
                    <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
                    Digital Signature of authorized signatory
                    </Grid>    
                    <Grid item className={classes.borderCenter} xs={12} md={6} px={2} py={1} textAlign="left">
                        Digitally Signed by Karuna Rajesh Mahamulkar, <br />
                        Date: 2023-07-05 13:45:09, <br />
                        Location: Mumbai, <br />
                        Reason: Warehouse Receipt Approval <br />
                    </Grid>     
                </Grid>
                <Grid container fullWidth>
                    <Grid item className={classes.borderLeft} xs={12} md={3} px={1} py={2} textAlign="center">
                    
                    Withdrawn History
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={9} px={1} py={1} textAlign="center">
                    <Typography fontSize={14}>
                    The goods mentioned below are hereby released for delivery from warehouse. <br/>
                    The unreleased balance is subjected to lien for unpaid charges and borrowing from lending institution.
                    </Typography>
                    </Grid>    
                </Grid>
                <Grid container fullWidth>
                    <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
                        Date
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
                    Released Weight
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
                    Released No of Bags
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
                    Balance Weight
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
                    Balance No of Bags
                    </Grid>
                    <Grid item className={classes.borderRight} xs={12} md={2} px={1} py={1} textAlign="center">
                    Signature 

                    </Grid>
                    
                </Grid>
                <Grid container fullWidth>
                    <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={2} textAlign="center">
                        
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={2} textAlign="center">
                    
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={2} textAlign="center">
                
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={2} textAlign="center">
            
                    </Grid>
                    <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={2} textAlign="center">
                    
                    </Grid>
                    <Grid item className={classes.borderRight} xs={12} md={2} px={1} py={2} textAlign="center">
                    

                    </Grid>
                    
                </Grid>

            </Grid>

            
            {/*
            <Box elevation={1} sx={{ borderRadius: "5px", textAlign: "center", pt: 0.5, mb: "15rem" }}>

                <Button style={{ background: "#FD531D", color: "white", width: "150px" }}>
                Reject Offer
                </Button>
                <Button style={{ background: "#5A9A07", color: "white", width: "150px", marginLeft: "2rem" }}>   
                Accept Offer
                </Button>
                
                <Button style={{ background: "#214383", color: "white", width: "150px", marginLeft: "2rem" }}>   
                Counter Offer
                </Button>
                
            </Box>
            */}

        </Container>
        </Box>
    );
};

export default NewDetails;
