import React, { useState, useEffect } from "react";
import HawkEyeAppBar from "./AppBar";
import {
  Box,
  Divider,
  Typography,
  Grid,
  Tooltip,
  Container,
  Button,
  Chip,
} from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Popover from '@mui/material/Popover';
import { Stepper, Step, StepLabel } from '@mui/material';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CircularProgress from '@mui/material/CircularProgress';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import { BsInfoCircle } from "react-icons/bs";
import { SERVER_BASE_ADDRESS, FRONTEND_ADDRESS } from "../globals/const";


import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import instance from "../globals/axios";
import StarAgriLogo from '../assets/staragri-logo.png'
import { makeStyles } from "@mui/styles";
import dayjs from 'dayjs';
import Number2Words from 'react-number2words';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import QRCode from "react-qr-code";
import DocumentPreviewGrid from './DocumentPreviewGrid';
import InsuranceGrid from "./InsuranceGrid";
import WeighBridgeReceiptGrid from "./WeighBridgeReceiptGrid"
import LocationComponent from './LocationComponent';
import { styled } from '@mui/material/styles';


import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;



const useStyles = makeStyles((theme) => ({
  gridContainer: {
    backgroundImage: `url(${require('../assets/StaragriCertificate.png')})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
    // opacity: 0.8,
  },
  borderRight: {
    border:"1px solid #000", 
    borderLeft: 'none',
  },
  borderLeft: {
    border:"1px solid #000", 
    borderRight: 'none',
  },
  borderCenter: {
    border:"1px solid #000", 
  },
  gridItem: {
    padding: theme.spacing(2),
    cursor: 'pointer',
    transition: 'border 0.3s, background 0.3s',
    '&:hover': {
      border: '1px solid #ccc',
      background: '#f5f5f5',
    },
  },
  pdfContainer: {
    // maxWidth: 300,
    // maxHeight: 300,
    width: 500,
    height: 700, 
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '45%',
    width: '100%',
    height: '100%',
  },
  popoverContainer: {
    background: 'rgba(255, 255, 255, 0.35)', // Semi-transparent white background
    backdropFilter: 'blur(1px)', // Blur effect
    boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.2)', // Box shadow
    transition: 'background-color 0.3s, box-shadow 0.3s, backdrop-filter 0.3s', // Transitions
  },
}));


const CapitalizedNumberToWords = styled(Number2Words)`
  text-transform: capitalize;
`;


const WarehouseReciept = ({data,setShowMoreDetails}) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const [hoveredPolicy, setHoveredPolicy] = useState(283498); 
    const [policyNumPages, setPolicyNumPages] = useState(0);

    const steps = ['WR Token Generated', 'WR Token Accepted', 'Lien Marked', 'WR Funded', 'Outward'];

    const [activeStep, setActiveStep] = useState(data.stepper_stage);

    const handleMouseEnter = async (event) => {
        setAnchorEl(event.currentTarget);
        const doc = await pdfjs.getDocument(require('../assets/wr_283498.pdf')).promise;
        setPolicyNumPages(doc.policyNumPages);
    };

    const handleMouseLeave = () => {
        setAnchorEl(null);
    };

    
    function formatDate(inputDate) {
      const formattedDate = dayjs(inputDate).format('DD MMM YYYY');
      return formattedDate;
    }

    const [wa_anchorEl, setWa_AnchorEl] = useState(null);

    const wa_handlePopoverOpen = (event) => {
        setWa_AnchorEl(event.currentTarget);
    };

    const wa_handlePopoverClose = () => {
        setWa_AnchorEl(null);
    };

    const wa_open = Boolean(wa_anchorEl);
    const wa_id = wa_open ? 'warehouse-address-popover' : undefined;

    const wa_handleViewAddressClick = () => {
      window.open('/view-land-nft/?token_code=LAND15588946157385671895', '_blank');

      // window.location.href = `https://land.newrl.net/view-land-nft/?token_code=${data.attributes.warehouse.token_code}`;
    };

    const [da_anchorEl, setDa_AnchorEl] = useState(null);

    const da_handlePopoverOpen = (event) => {
      setDa_AnchorEl(event.currentTarget);
    };
  
    const da_handlePopoverClose = () => {
      setDa_AnchorEl(null);
    };
  
    const da_open = Boolean(da_anchorEl);
    const da_id = da_open ? 'depositor-address-popover' : undefined;

    const da_handleViewAddressClick = () => {
      window.open('/view-land-nft/?token_code=LAND12913697887545604335', '_blank');
      // window.location.href = `https://land.newrl.net/view-land-nft/?token_code=${data.attributes.warehouse.token_code}`;
    };

    const [mr_anchorEl, setMr_AnchorEl] = useState(null);

    const handleMr_PopoverOpen = (event) => {
      setMr_AnchorEl(event.currentTarget);
    };
  
    const handleMr_PopoverClose = () => {
      setMr_AnchorEl(null);
    };
  
    const mr_open = Boolean(mr_anchorEl);
    const mr_id = mr_open ? 'market-rate-popover' : undefined;

    const [remarks_PopupAnchorEl, setRemarks_PopupAnchorEl] = useState(null);

    const handleRemarksMouseEnter = (event) => {
      setRemarks_PopupAnchorEl(event.currentTarget);
    };
  
    const handleRemarksMouseLeave = () => {
      setRemarks_PopupAnchorEl(null);
    };
  
    const remarks_PopupOpen = Boolean(remarks_PopupAnchorEl);
  
    const remarks = data.attributes.inward_details.remarks;
    const remarks_Truncated = remarks.length > 15 ? `${remarks.slice(0, 15)}...` : remarks;

    



    return (
    

    <Container>
    <Box mt="3rem">
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((label, index) => (
        <Step key={label}>
          <StepLabel StepIconProps={{ style: { color: index === activeStep ? '#214383' : index < activeStep ? '#3b9b49' : '#808080' } }}>
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  </Box>

    <Grid className={classes.gridContainer} mb="2rem" mt="2rem" py={8} px={8} container fullWidth>
    <Grid fullWidth mx="auto" textAlign="center" alignItems="center">
        <Box flex>
            <img mx="auto" fontWeight={500} alt='StarAgri logo' mt={6} src={StarAgriLogo} style={{ width: "20%" }} />
            <Typography variant="h6">
            Star Agriwarehousing and Collateral Management Limited
            </Typography>
            <Typography>
            A 601-604, Bonanza Building, Sahar Plaza Complex, Near J.B. Nagar Metro Station, J.B. Nagar, Andheri (East), Mumbai – 400 059
            </Typography>
            {
              data.receipt_type==="sr" ? (
              <Typography mt={2} mb={4} fontWeight={500} variant="h6">
              STORAGE RECEIPT(SR)
              </Typography>
              ) : (

              <Typography mt={2} mb={4} fontWeight={500} variant="h6">
               WAREHOUSE RECEIPT(WR)
              </Typography>
              )
            }
        </Box>

    </Grid>
 
    <Grid container fullWidth>
        <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
        WR No.
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
        {data.attributes.inward_details.wr_reciept_no}
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
        Date of issue
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
        {formatDate(data.attributes.inward_details.inward_date)}
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
        Base Receipt No.
        </Grid>
        <Grid item className={classes.borderRight} xs={12} md={2} px={1} py={1} textAlign="center">
        
        </Grid>
    </Grid>
    
    <Grid container fullWidth>
        <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
        CIS No.
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
        {data.attributes.inward_details.inward_number}
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
        Date of Deposit
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
        {formatDate(data.attributes.inward_details.inward_date)}
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
        Staragri Branch Name
        </Grid>
        <Grid item className={classes.borderRight} xs={12} md={2} px={1} py={1} textAlign="center">
        {data.attributes.warehouse.branch_name}
        </Grid>

    </Grid>
    <Grid container fullWidth>
        <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
        Warehouse Name
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={10} px={1} py={1} textAlign="center">
        {data.attributes.warehouse.name} (Godown No: {data.attributes.truck_details[0].bag_details[0].gdn_no})
        </Grid>    
    </Grid>
    <Grid container fullWidth
        sx={{
            cursor: 'pointer',
        }}
        clickable
        // onClick={wa_handlePopoverOpen(event)}
        onClick={(event) => {
          if (!wa_open) {
            wa_handlePopoverOpen(event);
          } else {
            wa_handlePopoverClose();
          }
        }}
        >
        <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
        <Typography color="primary" fontWeight={600}>
        Warehouse Address
        </Typography>
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={10} px={1} py={1} textAlign="center">
        <Typography color="primary" fontWeight={600}>
        {data.attributes.warehouse.warehouse_address}
        <span style={{ marginLeft: '10px' }}>
          <BsInfoCircle />
        </span>
        </Typography>
        </Grid>   
        <Popover
          className={classes.popoverContainer}
          id={wa_id}
          open={wa_open}
          anchorEl={wa_anchorEl}
          onClose={wa_handlePopoverClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div style={{ padding: '10px' }}>
          {/*  To include
          <Button
            variant="outlined"
            color="primary"
            onClick={wa_handleViewAddressClick}
            startIcon={<WarehouseOutlinedIcon/>}
          >
            View Warehouse Location
          </Button>
        */}
        <LocationComponent lat={data.wh_lat} lng={data.wh_long} />
          </div>
        </Popover>

    </Grid>
    <Grid container fullWidth >
        <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
        Depositor Name
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={10} px={1} py={1} textAlign="center">
        {data.owner_name}
        </Grid>    
    </Grid>
    <Grid container fullWidth>
        <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
          <Typography>
            Depositor Address
          </Typography>
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={10} px={1} py={1} textAlign="center">
          <Typography>
                {data.owner_address !== null && (
              <div>{data.owner_address}</div>
            )}
          </Typography>
        </Grid>
    </Grid>
    {/* 
      <Popover
        className={classes.popoverContainer}
        id={da_id}
        open={da_open}
        anchorEl={da_anchorEl}
        onClose={da_handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: '10px' }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={da_handleViewAddressClick}
            startIcon={<LocationOnIcon />}
          >
            View Depositor Location
          </Button>
        </div>
      </Popover>
      */}
    <Grid container fullWidth>
        <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
        Commodity
        </Grid>
        <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
        No. of Units
        </Grid>
        <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
        Net Weight
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={4} px={1} py={1} textAlign="center">
        Grade
        </Grid> 
        <Grid item className={classes.borderRight} xs={12} md={2} px={1} py={1} textAlign="center">
        Remarks
        </Grid>   
    </Grid>
    <Grid container fullWidth>
        
        <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
        {data.attributes.commodity.name}
        </Grid>
        <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
        {data.attributes.truck_details[0]?.bag_details[0]?.number}
        </Grid>
        <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
        {(data.attributes.inward_details.inward_weight)/1000} MT
        </Grid>
         <Grid item clickable  
        sx={{
          cursor: 'pointer',
        }} 
        onClick={() => setShowMoreDetails(true)} className={classes.borderCenter} 
        xs={12} md={4} px={1} py={1} textAlign="center">
        <Typography color="primary" fontWeight={600}>
        Average
          <span style={{ marginLeft: '10px' }}>
                <BsInfoCircle />
          </span>
        </Typography>
        </Grid> 
        <Grid item className={classes.borderRight} xs={12} md={2} px={1} py={1} textAlign="center" clickable onMouseEnter={handleRemarksMouseEnter} onMouseLeave={handleRemarksMouseLeave}>
          {remarks_Truncated}
          <Popover
            open={remarks_PopupOpen}
            anchorEl={remarks_PopupAnchorEl}
            onClose={handleRemarksMouseLeave}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography sx={{ p: 2 }}>{remarks}</Typography>
          </Popover>
        </Grid>
        
        
        {/* 
        <WeighBridgeReceiptGrid data={data} setShowMoreDetails={setShowMoreDetails}/>
        */}
        
    </Grid>
    <Grid container fullWidth>
        <Grid item className={classes.borderLeft} xs={12} md={6} px={1} py={1} textAlign="center">
        Validity
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={3} px={1} py={1} textAlign="center">
        From {formatDate(data.attributes.inward_details.validity_from)}
        </Grid>
        <Grid item className={classes.borderRight} xs={12} md={3} px={1} py={1} textAlign="center">
        To {formatDate(data.attributes.inward_details.validity_to)}
        </Grid>   
    </Grid>
    <Grid container fullWidth>
        <Grid item className={classes.borderLeft} xs={12} md={4} px={1} py={1} textAlign="center">
        Market Rate of Commodity(₹)
        </Grid>

        {/* clickable onClick={handleMr_PopoverOpen} */}
        <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
        {data.attributes.inward_details.market_rate}/MT
        </Grid>
  
        <Grid item className={classes.borderCenter} xs={12} md={3} px={1} py={1} textAlign="center">
        Value of Commodity(₹)
        </Grid>
        <Grid item className={classes.borderRight} xs={12} md={3} px={1} py={1} textAlign="center">
        {data.attributes.inward_details.market_value}
        </Grid>   
     
    </Grid>
    <Grid container fullWidth>
        <Grid item className={classes.borderCenter} xs={12} md={4} px={1} py={1} textAlign="center">
        Value of Commodity(In words)
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={8} px={1} py={1} textAlign="center">
          <Typography style={{ textTransform: 'capitalize' }}>
            <Number2Words value={data.attributes.inward_details.market_value}  system="hinduArabic" />     

          </Typography>
        
      </Grid>       
    </Grid>
    <Grid container fullWidth>
        <Grid item className={classes.borderLeft} xs={12} md={4} px={1} py={1} textAlign="center">
        Storage Charges (₹ Exclsive of Taxes)
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
        
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={3} px={1} py={1} textAlign="center">
        Total Amount(₹ Per Month)
        </Grid>
        <Grid item className={classes.borderRight} xs={12} md={3} px={1} py={1} textAlign="center">
        
        </Grid>   
    </Grid>
    <Grid container fullWidth>
        <Grid item className={classes.borderCenter} xs={12} md={4} px={1} py={1} textAlign="center">
        Insurance Declaration
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={8} px={1} py={1} textAlign="center">
        {data.attributes.insurance_details[0].insurance_declaration}
        </Grid>
    </Grid>

    {/*
    <Grid container fullWidth>
      
       
        <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
        Policy Type
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={4} px={1} py={1} textAlign="center">
        Policy No.
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={3} px={1} py={1} textAlign="center">
        Valid From
        </Grid>

    <Grid container cols={12} xs={12} md={3} textAlign="center">
            <Grid item className={classes.borderCenter} xs={12} md={6} px={1} py={1} textAlign="center">
            Valid To
            </Grid>
            <Grid item className={classes.borderRight} xs={12} md={6} px={1} py={1} textAlign="center">
            Sum Insured
            </Grid>
            <Grid>
            </Grid>

      
    </Grid>
    </Grid>
    <Grid container fullWidth>
       
          <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
          Fire
          </Grid>
          <Grid item className={classes.borderCenter} xs={12} md={4} px={1} py={1} textAlign="center">
          {data.attributes.insurance_details[0].insurance_policy_no}
          </Grid>
          <Grid item className={classes.borderCenter} xs={12} md={3} px={1} py={1} textAlign="center">
          {formatDate(data.attributes.insurance_details[0].insurance_validity_from)}
          </Grid>
          <Grid container cols={12} xs={12} md={3} textAlign="center">
            <Grid item className={classes.borderCenter} xs={12} md={6} px={1} py={1} textAlign="center">
                {formatDate(data.attributes.insurance_details[0].insurance_validity_to)}
            </Grid>
            <Grid item className={classes.borderRight} xs={12} md={6} px={1} py={1} textAlign="center">
                {data.attributes.insurance_details[0].insurance_sum_assured}
            </Grid>
            
          </Grid>
    </Grid>
    <Grid container fullWidth>
        <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
        Burglary
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={4} px={1} py={1} textAlign="center">
        5001001223987097
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={3} px={1} py={1} textAlign="center">
        11 Jun 2023
        </Grid>
        <Grid container cols={12} xs={12} md={3} textAlign="center">
            <Grid item className={classes.borderCenter} xs={12} md={6} px={1} py={1} textAlign="center">
            10 Jun 2024
            </Grid>
            <Grid item className={classes.borderRight} xs={12} md={6} px={1} py={1} textAlign="center">
            5600000
            </Grid>
        <Grid>


        </Grid>
    </Grid>

    </Grid>
     */}

    <InsuranceGrid data={data}/>

    <Grid container fullWidth>
        <Grid item className={classes.borderCenter} xs={12} md={12} px={1} py={1} textAlign="center">
        <Typography variant="bold">Lien Details</Typography>
        </Grid>
    </Grid>
    <Grid container fullWidth>
        <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
        Date
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={10} px={1} py={1} textAlign="center">
        Name of lender
        </Grid>    
    </Grid>
    <Grid container fullWidth>
        <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
        {formatDate(data.attributes.inward_details.lien_details[0].date)}
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={10} px={1} py={1} textAlign="center">
        {data.attributes.inward_details.lien_details[0].lender_bank} - {data.attributes.inward_details.lien_details[0].lender_branch}
        </Grid>    
    </Grid>
    
    <Grid container fullWidth>
        <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} justifyContent="center" textAlign="center">
        <br/>
        <br/>
        <br/>
        QR Code

        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
        
            <QRCode
            size={18}
            style={{ height: "auto", maxWidth: "75%", width: "75%" }}
            value={`https://${FRONTEND_ADDRESS}/qr-scan/${data.token_code}` }
            viewBox={`0 0 256 256`}
            />
        </Grid>   
        <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
        <br/>
        <br/>
        <br/>
        Token Details
        </Grid>    
        <Grid item className={classes.borderCenter} xs={12} md={6} px={2} py={1} textAlign="left">
            <br/>
            Token Code: {data.token_code} <br />
            Entry Data: {formatDate(data.entry_date)} <br />
            <Link to={`https://${FRONTEND_ADDRESS}/qr-scan/${data.token_code}` }>
                  <Typography style={{ textAlign: "left", marginTop: "10px" }}>View Token Details</Typography>
            </Link>
        </Grid>     
    </Grid>
    
    <Grid container fullWidth>
        <Grid item className={classes.borderLeft} xs={12} md={3} px={1} py={2} textAlign="center">
        
        Withdrawn History
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={9} px={1} py={1} textAlign="center">
        <Typography fontSize={14}>
        The goods mentioned below are hereby released for delivery from warehouse. <br/>
        The unreleased balance is subjected to lien for unpaid charges and borrowing from lending institution.
        </Typography>
        </Grid>    
    </Grid>
    <Grid container fullWidth>
        <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
            Date
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
        Released Weight
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
        Released No of Bags
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
        Balance Weight
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
        Balance No of Bags
        </Grid>
        <Grid item className={classes.borderRight} xs={12} md={2} px={1} py={1} textAlign="center">
        Signature 

        </Grid>
        
    </Grid>
    <Grid container fullWidth>
        <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={2} textAlign="center">
            
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={2} textAlign="center">
        
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={2} textAlign="center">
    
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={2} textAlign="center">

        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={2} textAlign="center">
        
        </Grid>
        <Grid item className={classes.borderRight} xs={12} md={2} px={1} py={2} textAlign="center">
        

        </Grid>
        
    </Grid>

    </Grid>
    </Container>
  )
}

export default WarehouseReciept