import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';

import HawkEyeAppBar from "../../components/AppBar";
import { Box, Divider, Typography, Grid, Chip, Button, Card, CardContent, FormControl, FormLabel, FormControlLabel, Checkbox, TextField, Snackbar } from "@mui/material";
import { RadioGroup, Radio, FormGroup } from '@mui/material';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {  getUserName, getUserEmail } from "../../globals/const";

import { makeStyles } from "@mui/styles";
import instance from "../../globals/axios";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { MuiOtpInput } from 'mui-one-time-password-input'

import icici_icon from "../../assets/icici_icon.png";
import boi_icon from "../../assets/boi_icon.png";
import hdfc_icon from "../../assets/hdfc_icon.png";
import sbi_icon from "../../assets/sbi_icon.png";
import bob_icon from "../../assets/bob_icon.png";

const useStyles = makeStyles((theme) => ({
  rootVideo: {
    minWidth: 275,
  },
  titleVideo: {
    fontSize: 20,
  },
  buttonVideo: {
    marginTop: theme.spacing(2),
  },
  cardHeader: {
    backgroundColor: "#0073b1",
    color: "#fff",
    fontWeight: "bold",
  },
  cardBody: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dropdownWrapper: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  dropdown: {
    textDecoration: "underline",
    marginRight: 8,
  },
  expandIcon: {
    transform: "rotate(0deg)",
    transition: "transform 0.2s ease-in-out",
  },
  expandIconOpen: {
    transform: "rotate(180deg)",
  },
  dropdownContent: {
    marginTop: 16,
    paddingLeft: 16,
    // borderLeft: "1px solid #ccc",
  }, 
  outline: {
    borderRadius:"1rem",
    border:"2px solid #d8daed", 
    backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)',

  },
  customCard: {
    backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)',
    boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
    borderRadius: '1rem',
    border:"2px solid #d8daed", 
  },
}));

const MarkLien = () => {
  const classes = useStyles();
  const location = useLocation();
  const { data } = location.state;

  const [funding, setFunding] = useState();
  const [rate, setRate] = useState();
  const [mktValue, setMktValue] = useState(180000);
  const [loanAmount, setLoanAmount] = useState(0);
  const [wr_reciept_no, setWr_reciept_no] = useState(data.attributes.inward_details.wr_reciept_no);
  const [qty, setQty] = useState(data.balance);
  const [owner, setOwner] = useState(data.owner_email);
  const [gdn_no, setGdn_no] = useState(data.attributes.truck_details[0].bag_details[0].gdn_no);
  const [stack_no, setStack_no] = useState(data.attributes.truck_details[0].bag_details[0].stack_no);
  const [bags, setBags] = useState(data.attributes.truck_details[0].bag_details[0].number);
  const [weight, setWeight] = useState(data.attributes.inward_details.inward_weight)


  useEffect(() => {
    setLoanAmount(funding*0.01*mktValue);
  }, [funding])

  const [selectedBank, setSelectedBank] = useState('');
  const [ userName, setUserName ] = useState(getUserEmail())
  const [ depositorNumber, setDepositorNumber ] = useState(data.owner_phone_number)

  const currentDate = new Date().toISOString();

  const [lienDetails, setLienDetails] = useState({
    date: new Date().toISOString(), 
    lender_branch: '',
    lender_bank: '',
  });


  const requestBody = {
    "wr_reciept_no": wr_reciept_no,
    "lien_details": {
      "date": currentDate, 
      "lender_branch": lienDetails.lender_branch || '', 
      "lender_bank": lienDetails.lender_bank || '', 
    },
    "no_of_bags": bags,
    "no_of_spillage_bags": 0,
    "weight": weight,
    "gdn_no": gdn_no,
    "stack_no": stack_no,
    "remarks": "Lien Marked",
    "client": owner,
    "lender": selectedBank,
    "from_web": true,
    "approver": userName,
    "creator": userName,
    "action": "pledge"
  }

  

  const [ verified, setVerified ] = useState(false)
  const [ otpSent, setOtpSent ] = useState(false);
  const [ verifyOtp, setVerifyOtp ] = useState(false)
  const [ countdown, setCountdown ] = useState(60);
  const [ otp, setOtp ] = useState('');
  const [ responseData, setResponseData ] = useState(null)
  const [ ownerEmail, setOwnerEmail ] = useState(data.owner_email)
  const [ selectedBankBranch, setSelectedBankBranch ] = useState("")

  const handleChange = (newValue) => {
    setOtp(newValue)
  }


  const handleBankChange = (event) => {
    setSelectedBank(event.target.value);
  
    const selectedBankDetails = responseData.find(item => item.banker === event.target.value);
  
    setLienDetails({
      date: new Date().toISOString(),
      lender_branch: selectedBankDetails.banker_branch,
      lender_bank: selectedBankDetails.banker_bank,
    });
  };

  const handleConsoleLog = () => {
    console.log(lienDetails);
  };

  const fetchData = async () => {
    try {
      const response = await instance.get('depositor/getConsentData', {
        params: {
          depositor: ownerEmail
        }
      });
      setResponseData(response.data.data)
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); 


  const handleVerify = async () => {
    instance.get(`otp/verify-opt?depositor=${depositorNumber}&otp=${otp}`)
    .then(() => {
      setVerified(true)
    })
    .catch((error) => {
      console.error('Error sending OTP:', error);
    });
  };

  useEffect(() => {
    let interval;

    if (otpSent && countdown > 0) {
      interval = setInterval(() => {
        setCountdown(prev => prev - 1);
      }, 1000);
    } else if (countdown === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [otpSent, countdown]);

  const handleSendOtp = () => {
    setVerifyOtp(true)
    setOtpSent(true);

    instance.get(`otp/generate-opt?depositor=${depositorNumber}`)
    .then(() => {
      setCountdown(60);

      setTimeout(() => {
        setOtpSent(false);
      }, 60000);
    })
    .catch((error) => {
      console.error('Error sending OTP:', error);
    });
  };

  const handleResendOtp = () => {
    setCountdown(60);
    handleSendOtp();
  };

  const handleSubmit = async () => {

    try {
      console.log(requestBody)

      const response = await instance.post("cm/lienmark", requestBody);
      console.log("ResponseData", response);
      alert("Lien Marked Succesfully!");
      setTimeout(function () {
        window.location.replace("/operations");
      }, 500);

    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div>
    <Box fullWidth bgcolor='#fff'>
      <HawkEyeAppBar />
      <Box mx='auto' className={classes.outline} sx={{ padding: "1.5rem 8rem", marginTop: "4rem" }}>
        <Grid mt="2rem" mb="1rem">
        <Link to='/operations'>
        <Chip icon={<KeyboardBackspaceIcon />} style={{ cursor: "pointer" }} label='Operations' variant='outlined' />
      </Link>
        </Grid>
        <Divider />

        <Grid fullWidth mb="5rem">
            <Box className={classes.outline} mt="3rem" p={6}>
              
                <Grid mb={1} container>
                  <Grid xs={10} md={6}>
                    <Typography item xs={4} variant='h5' gutterBottom>
                      Mark Lien
                    </Typography>
                    <Divider my={1} />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item md={6} mr={4}>
                    <Card style={{ marginTop: "0.9rem" }}>
                        
                          <Grid fullWidth container p={4}>
                            
                                  
                                  <Typography variant="h6" my={1}>
                                    Token Code:<br/>
                                    {data.token_code}
                                  </Typography>
                                  <Typography variant="h6" my={1}>
                                    Token Name:<br/>
                                    {data.token_name}
                                  </Typography>
                                  <Grid xs={12}>

                                  <Typography variant="h6" my={1}>
                                    Commodity: {data.attributes.commodity.name}<br/>
                                  </Typography>
                                  </Grid>
                                  <Grid xs={12}>

                                  <Typography variant="h6" my={1}>
                                    Warehouse Reciept Number: 
                                    {data.attributes.inward_details.wr_reciept_no}
                                    <br/>
                                  </Typography>
                                  </Grid>
                                  
                                  <Typography variant="h6" my={1}>
                                    Quantity: {(data.balance)/1000} MT<br/>
                                  </Typography>
                                  <br/>

                                
                            
                          </Grid>
                        
                    </Card>
                    
                    
                  </Grid>
                  

                  <Grid item md={5} my={1} mx="auto">
                   
                      <Grid container md={12} p={4}>

                        {/* <Grid item md={5}>
                          <Typography variant="h6" mx="auto">Rate of Interest</Typography>
                          <TextField fillWidth style={{ background: "white", marginTop: "10px" }} 
                            value={rate}
                            onChange={(event) => setRate(event.target.value)}
                            label='Enter Rate' />
                        </Grid> */}
                        

                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">Select Bank</FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            value={selectedBank}
                            onChange={handleBankChange}
                          >
                          {
                            responseData &&
                            responseData.map((item, index) => {
                        
                          
                              return (
                                <FormControlLabel
                                  key={index}
                                  control={<Radio value={item.banker} />}
                                  label={
                                    <Box>
                                      <Typography>{item.banker_branch} - {item.banker_bank}</Typography>
                                    </Box>
                                  }
                                />
                              );
                            })
                          }
                          
                          </RadioGroup>
                        
                        </FormControl>

                        {/* <Grid item md={6} mx="auto">
                          <Typography variant="h6" mx="auto">Funding Percentage</Typography>
                          <TextField fillWidth style={{ background: "white", marginTop: "10px" }} 
                            value={funding}
                            onChange={(event) => setFunding(event.target.value)}
                            label='Enter Funding Percentage' />
                        </Grid>
                        <Grid item md={12}>
                        <Typography variant="h6" mt="1rem">Market Value(in Rs/Mt)</Typography>
                        <Card style={{ marginTop: "10px", height: "50px" }}>
                          <Typography style={{ padding: "10px" }}>₹ {mktValue}</Typography>
                        </Card>
                        </Grid>
                        <Grid item md={12}>
                          <Typography variant="h6" mt="1rem">Loan Amount(in Rs)</Typography>
                          <Card style={{ marginTop: "10px", height: "50px" }}>
                            <Typography style={{ padding: "10px" }}>₹ {loanAmount}</Typography>
                          </Card>
                            
                        </Grid> */}
                        
                      </Grid>
                      <Grid item md={12}>
                      <Typography vairant="h6" style={{ marginTop: "1.5rem" }}>Other Details</Typography>
                    
                      <TextField multiline fullWidth rows={3} variant='outlined' style={{ background: "white", marginTop: "10px" }} />
                    </Grid>
                    
                    
                  </Grid>
                
                </Grid>

                

              
              
              <Grid container mx="auto" xs={6} mt={6} sx={{ display: "flex", justifyContent: "center"}}>
               
                <Grid item fullWidth>
                  {otpSent ? (
                    <Box>
                      
                      <Button variant="outlined" sx={{ width:"15rem", my:2, fontSize: "1.15rem"}} color='success'>
                        OTP Sent
                      </Button>
                      <Box mx="auto">
                        <Typography>You will be able to Resend in {countdown} seconds</Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      <Button onClick={handleSendOtp} variant="outlined" sx={{ width:"15rem", my:2, fontSize: "1.15rem"}} color='primary'>
                        Send OTP
                      </Button>
                    </Box>
                  )}
                </Grid>

                <Grid item px={6} fullWidth>
                  {
                    verifyOtp && (
                      <Box mx="auto" mt={2}>
                        <MuiOtpInput length={6} autoFocus value={otp} onChange={handleChange} />

                        
                          <Box display="flex" mt={3} justifyContent="center">
                            <Button variant="contained" color="primary" onClick={handleVerify}>
                              Verify OTP
                            </Button>
                          </Box>


                        
                      </Box>
                    )
                  }
                  <Grid mx="auto" mt={2} sx={{ display: "flex", justifyContent: "center"}}>
                  { 
                      verified ?
                      (
                        <Button onClick={handleSubmit} variant="contained" sx={{ width:"15rem", my:2, fontSize: "1.15rem"}} outlined color='primary'>Mark Lien</Button>
                      ) :
                      (
                        <Button disabled variant="contained" sx={{ width:"15rem", fontSize: "1.15rem"}}  color='primary'>Mark Lien</Button>
                      )
                    }
                  </Grid>
                </Grid>
          
                {/* 
                {showResend && (
                  <Button onClick={handleResendOtp} variant="outlined" sx={{ width:"15rem", my:2, fontSize: "1.15rem"}} color='primary'>
                    Resend OTP
                  </Button>
                )}
              */}
              
              </Grid>
              
             
            </Box>
        </Grid>

        {/* <Snackbar open={offerSubmitted} autoHideDuration={6000} message='Submitted' /> */}
      </Box>
    </Box>
    </div>
  );
};

export default MarkLien;
