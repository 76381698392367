import React, { useState, useEffect } from "react";
import HawkEyeAppBar from "../../components/AppBar";
import {
  Box,
  Divider,
  Typography,
  Grid,
  Container,
  Button,
  Chip,
} from "@mui/material";
import { Stepper, Step, StepLabel } from '@mui/material';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import VerifiedIcon from '@mui/icons-material/Verified';
import ListIcon from '@mui/icons-material/List';
import GetAppIcon from '@mui/icons-material/GetApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';



import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import wr from "../../assets/wr_283498.pdf";
import { makeStyles } from "@mui/styles";

import WarehouseReciept from "../../components/WarehouseReciept";
import TestCertificate from "../../components/TestCertificate"
import MarkUnfundedPopup from "../../components/Popup/MarkUnfunded"
import EmailPopup from "../../components/Popup/EmailPopup";

import instance from "../../globals/axios";
import axios from "axios";


const useStyles = makeStyles((theme) => ({
  gridContainer: {
    // backgroundImage: 'url("https://static.vecteezy.com/system/resources/thumbnails/008/854/270/original/abstract-colorful-gradient-animation-background-free-video.jpg")',
    backgroundImage: `url(${require('../../assets/StaragriCertificate.png')})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
  },
  borderRight: {
    border:"1px solid #000", 
    borderLeft: 'none',
  },
  borderLeft: {
    border:"1px solid #000", 
    borderRight: 'none',
  },
  borderCenter: {
    border:"1px solid #000", 
  }
}));

const MarkFunded = (props) => {

  const classes = useStyles();

  const steps = ['WR Generated', 'WR Accepted', 'Lien Marked', 'Lien Unmarked','Outward'];

  const [activeStep, setActiveStep] = useState(2);
  const [showMoreDetails, setShowMoreDetails] = useState(false);

  const [passData, setPassData] = useState({});
  const location = useLocation();
  const { data } = location.state;
  const [ downloadUrl, setDownloadUrl] = ("https://drive.google.com/uc?export=download&id=1ISCcsYmi1fU3TlKEbXSsfYGFxeHlwowx")
  // https://drive.google.com/uc?export=download&id=1ISCcsYmi1fU3TlKEbXSsfYGFxeHlwowx

  const handleDownload = (wr_no) => {
    const link = document.createElement('a');
    console.log(downloadUrl)
    link.href = wr;
    link.download = `WR_${wr_no}.pdf`;
    // link.download = `WR_8923749.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        setPassData(data)
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);


  const fundedDataReqBody = {
    "wr_reciept_no": data.attributes.inward_details.wr_reciept_no,
    "action": "funded"
  }


  const [digitalCopyLink, setDigitalCopyLink] = useState(null);

  const handleClick = async () => {

    const wrNo = data.attributes.inward_details.wr_reciept_no;
    const url = `https://www.agrigates.com/gtsEntryForBlockChain.php?url=url_link&wr_no=${wrNo}`;

    axios.get(url)
      .then(response => {
        if (response.data && response.data.data && response.data.data.length > 0) {
          const rawLink = response.data.data[0].digital_copy_link;
          const formattedLink = rawLink.replace(/\\\//g, '/');
          setDigitalCopyLink(formattedLink);
          window.open(formattedLink, '_blank');

        } else {
          alert('Warehouse Receipt Digital Copy not found.');
        }
      })
      .catch(error => {
        console.error('Error:', error.message);
      });
  };

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [ccEmails, setCcEmails] = useState([]);

  const sendEmail = async ( ccEmails ) => {
    const emailData = {
      to_email: "shivalik@staragri.com ",
      cc_emails: ccEmails,
      whr_number: data.attributes.inward_details.wr_reciept_no,
    };
  
    try {
      const response = await instance.post('banker/send_email', emailData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        alert('Email sent successfully!');
      }
    } catch (error) {
      alert('Error sending email: ' + error.message);
    }
  };

  const handlePopupOpen = () => {
    setPopupOpen(true);
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  const handleSendEmails = (emails) => {
    setCcEmails(emails);
    setPopupOpen(false);
    sendEmail(emails);
  };



  return (
    <Container>
      <HawkEyeAppBar />
      <Box mx='auto' sx={{ bgcolor: "background.paper", padding: "1rem 2rem", marginTop: "4rem" }}>
        <Grid my={2}>
          <Link to='/banker'>
            <Chip icon={<KeyboardBackspaceIcon />} style={{ cursor: "pointer" }} label='Dashboard' variant='outlined' />
          </Link>
        </Grid>
        <Divider />


        {
          showMoreDetails ? (
            <TestCertificate data={data} />
          ) :
          (
            <WarehouseReciept data={data} setShowMoreDetails={setShowMoreDetails} />
          )
        }

      </Box>

      <Grid mx={2} mb={2} sx={{ display: "flex", justifyContent: "center"}}>
            
            <Button variant="contained" sx={{ mx:1, color:"#fff", width:"15rem", fontSize: "1rem"}} color='success' 
                startIcon={<TaskAltIcon />}
                onClick={() => {
                  window.location.href = `/audit-trail/${data.attributes.inward_details.wr_reciept_no}`;
                }}> 
                Transactions Trail
            </Button>
            
            <Button variant="contained" sx={{ mx:1, color:"#fff", width:"15rem", fontSize: "1rem"}} color='primary' startIcon={<VerifiedIcon />} onClick={() => setPopoverOpen(true)}>
              Mark Unfunded             
            </Button>
          
      </Grid>

      <Grid mx={2} mb={8} sx={{ display: "flex", justifyContent: "center"}}>
      <Button variant="outlined" sx={{ mx:1, width:"15rem", fontSize: "1rem"}} color='primary' 
                startIcon={<GetAppIcon />} 
                onClick={handleClick}
              >
                Download WR
      </Button>
      <Button
                variant="outlined"
                sx={{ mx: 1, width: "15rem", fontSize: "1rem" }}
                color='primary'
                startIcon={<MailOutlineIcon />}
                onClick={handlePopupOpen}
              >
                Ask For WR Copy
      </Button>

          
      {
            showMoreDetails ? (
            
              <Button onClick={() => setShowMoreDetails(false)} variant="outlined" sx={{ mx:1, width:"15rem", fontSize: "1rem"}} outlined color='primary' startIcon={<KeyboardBackspaceIcon sx={{ fontSize: '1.75rem' }}/>}>
              Show WR
              </Button>
            ) : (
            <Button onClick={() => setShowMoreDetails(true)} variant="outlined" sx={{ mx:1, width:"15rem", fontSize: "1rem"}} outlined color='primary' endIcon={<ListIcon sx={{ fontSize: '1.75rem' }}/>}>
              More Details
            </Button>
            )
      }

      </Grid>

      <EmailPopup
        open={popupOpen}
        handleClose={handlePopupClose}
        handleSend={handleSendEmails}
      />
      
      <MarkUnfundedPopup data={data} open={popoverOpen} onClose={() => setPopoverOpen(false)} />

    </Container>
  );
};

export default MarkFunded;
