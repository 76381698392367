import React, { useState } from "react";
import HawkEyeAppBar from "../../components/AppBar";
import {
  Box,
  Divider,
  Typography,
  Grid,
  Chip,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ListIcon from '@mui/icons-material/List';
import GetAppIcon from '@mui/icons-material/GetApp';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import instance from "../../globals/axios";


import WarehouseReciept from "../../components/WarehouseReciept";
import TestCertificate from "../../components/TestCertificate"

const useStyles = makeStyles(() => ({
  cardHeader: {
    backgroundColor: "#0073b1",
    color: "#fff",
    fontWeight: "bold",
  },
  cardBody: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dropdownWrapper: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  dropdown: {
    textDecoration: "underline",
    marginRight: 8,
  },
  expandIcon: {
    transform: "rotate(0deg)",
    transition: "transform 0.2s ease-in-out",
  },
  expandIconOpen: {
    transform: "rotate(180deg)",
  },
  dropdownContent: {
    marginTop: 16,
    paddingLeft: 16,
    // borderLeft: "1px solid #ccc",
  },
  outline: {
    borderRadius:"1rem",
    border:"2px solid #d8daed", 
    backgroundColor: "#fff"
  },
  customCard: {
    backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)',
    boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
    borderRadius: '1rem',
    border:"2px solid #d8daed", 
  },
  subtitleFont: {
    fontSize: '1.5rem',
    fontWeight: '600',
  }
}));

const OffersMade = () => {
  const [tabvalue, setTabvalue] = useState(0);
  const classes = useStyles();
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [qrcode, setQrCode] = useState(false);
  const location = useLocation();
  const { data } = location.state;
  const [showMoreDetails, setShowMoreDetails] = useState(false);


  // Function to toggle dropdown state
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleScan = () => {
    setQrCode(!qrcode);
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [tokenStatus, setTokenStatus] = useState("");
  const handleTokenVerification = (status) => {
    // Here you would add your code to handle the token verification
    setTokenStatus(status);
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };


  const handleChange = (event, newValue) => {
    setTabvalue(newValue);
  };

  return (
    <Box fullWidth>
      <HawkEyeAppBar />
      <Box mx='auto' sx={{ bgcolor: "background.paper", padding: "1.5rem 8rem", marginTop: "50px" }}>
        <Grid  my="1rem">
          <Link to='/nbfc'>
            <Chip icon={<KeyboardBackspaceIcon />} style={{ cursor: "pointer" }} label='Tokens' variant='outlined' />
          </Link>
        </Grid>
        <Divider />
        <Grid fullWidth>

        {
          showMoreDetails ? (
            <TestCertificate data={data} />
          ) :
          (
            <WarehouseReciept data={data} setShowMoreDetails={setShowMoreDetails} />
          )
        }
          

          <Grid className={classes.customCard} xs={12}>
                  <Box p={4}>
                    <Grid mb={1} container style={{ marginTop: "30px" }}>
                      <Grid xs={10} md={10}>
                        <Typography item xs={4} variant='h6' gutterBottom>
                          Loan Offer
                        </Typography>
                      </Grid>
                    </Grid>
                    <Card onClick={toggleDropdown}>
                      <CardContent>
                        <div>
                          <Grid container>
                            <Grid item md={8}>
                              <Typography style={{ fontWeight: 400, fontSize: "18px" }}>Quantity </Typography>
                            </Grid>
                            <Grid item md={4}>
                              <Typography style={{ fontWeight: 500, fontSize: "18px" }}>{data.balance} MT</Typography>
                            </Grid>
                          </Grid>
                          <Grid container style={{ marginTop: "10px" }}>
                            <Grid item md={8}>
                              <Typography style={{ fontWeight: 400, fontSize: "18px" }}>Rate of Interest </Typography>
                            </Grid>
                            <Grid item md={4}>
                              <Typography style={{ fontWeight: 500, fontSize: "18px" }}>{data.loan_offer_detail.interest_rate}%</Typography>
                            </Grid>
                          </Grid>
                          <Grid container style={{ marginTop: "10px" }}>
                            <Grid item md={8}>
                              <Typography style={{ fontWeight: 400, fontSize: "18px" }}>Market Value</Typography>
                            </Grid>
                            <Grid item md={4}>
                              <Typography style={{ fontWeight: 500, fontSize: "18px" }}>₹ {data.loan_offer_detail.market_value}</Typography>
                            </Grid>
                          </Grid>
                          <Grid container style={{ marginTop: "10px" }}>
                            <Grid item md={8}>
                              <Typography style={{ fontWeight: 400, fontSize: "18px" }}>Loan Amount</Typography>
                            </Grid>
                            <Grid item md={4}>
                              <Typography style={{ fontWeight: 500, fontSize: "18px" }}>₹ {data.loan_offer_detail.loan_amount}</Typography>
                            </Grid>
                          </Grid>
                        </div>
                        {/* <Divider /> */}
                      </CardContent>
                    </Card>
                    
                  </Box>
          </Grid>

          <Grid mx="auto" mt={4} mb={8} sx={{ display: "flex", justifyContent: "center"}}>
            
          <Button variant="outlined" sx={{ mx:2, width:"15rem", fontSize: "1rem"}} color='primary' 
          startIcon={<GetAppIcon />}
          >
            Download WR
          </Button>

          <Button variant="contained" sx={{ mx:2, color:"#fff", width:"15rem", fontSize: "1rem"}} color='success' 
          startIcon={<TaskAltIcon />}
          onClick={() => {
            // const newRoute = `${location.pathname}/new-route`;
            // Navigate to the new route
            window.location.href = `/audit-trail/${data.attributes.inward_details.wr_reciept_no}`;
          }}> 
          Check Audit Trail
      </Button>
       
        {
          showMoreDetails ? (
            <Button onClick={() => setShowMoreDetails(false)} variant="outlined" sx={{ mx:2, width:"15rem", fontSize: "1rem"}} outlined color='primary' startIcon={<KeyboardBackspaceIcon sx={{ fontSize: '1.75rem' }}/>}>
            Show WR
            </Button>
          ) : (
          <Button onClick={() => setShowMoreDetails(true)} variant="outlined" sx={{ mx:2, width:"15rem", fontSize: "1rem"}} outlined color='primary' endIcon={<ListIcon sx={{ fontSize: '1.75rem' }}/>}>
            More Details
          </Button>
          )
        }
        
          </Grid>
 


        </Grid>
      </Box>
    </Box>
  );
};

export default OffersMade;
