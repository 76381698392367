import React, {useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { MenuList, ButtonBase, MenuItem, Divider, TextField, Stack, Autocomplete, Button, Chip, Popover, List, ListItem, ListItemText } from "@mui/material";
import HawkEyeAppBar from "../../components/AppBar";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import TaskAltIcon from '@mui/icons-material/TaskAlt';


import instance from "../../globals/axios";
import dayjs from 'dayjs';


const useStyles = makeStyles({
    centeredHeader: {
      textAlign: 'center',
      mx: 'auto'
    },
    chip: {
        cursor: 'pointer',
        color: "#fff"
    },
    acceptedStatus: {
        color: '#214383', // Change to your desired color
        fontWeight: 'bold',
    },
      rejectedStatus: {
        color: 'red', // Change to your desired color
        fontWeight: 'bold',
    },
    approvedStatus: {
        color: 'green', // Change to your desired color
        fontWeight: 'bold',
    },
      pendingStatus: {
        color: 'gray', // Change to your desired color
        fontWeight: 'bold',
    },
  });


const SanctionLimit = () => {
    const getRowClassName = (params) => {
        return 'centered-row'; // Apply the CSS class to center row content
    };
    const classes = useStyles();
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [ bankers, setBankers ] = useState([]);
    const [ sanctionLimitData, setSanctionLimitData] = useState([]);
    const [ sanctionLimit, setSanctionLimit] = useState(0);
    const [selectedUser, setSelectedUser] = useState("");
    const [searchQuery, setSearchQuery] = useState('');
    const [bankerSearchQuery, setBankerSearchQuery] = useState('');
    const [ showDetails, setShowDetails ] = useState(false)
    const [authenticatedUser, setAuthenticatedUser] = useState(JSON.parse(sessionStorage.getItem('auth')));

    const VISIBLE_FIELDS = ['Depositor', ' Banker', 'Consent Status'];


    const navigate = useNavigate();

    const handleChipClick = (row) => {
      navigate(`/wr-details/${row.wr_reciept_no}`);
    };

    function formatDate({ inputDate }) {
        // const formattedDate = dayjs(inputDate).format('DD MMM YYYY');
        const formattedDate = dayjs(inputDate).format('hh:mm A DD MMM YYYY');
        return formattedDate;
    }

    const generateAuditTrailLink = (wr_reciept_no) => {return (`/audit-trail/${wr_reciept_no}`)};

    const userBody = {
      "role": 3,
      "page": 0,
    //   "limit": 10
    };

    const bankerUserBody = {
        "role": 4,
        "page": 0,
        // "limit": 10
    };


    const consentActionBody = {
        "sanction_limit": sanctionLimit,
        "depositor": searchQuery,
        "banker": authenticatedUser.authenticatedUser.email
    }



    useEffect(() => {
        const fetchUsers = async () => {
        
            try {
              const response = await instance.post('/user/getUser', userBody);
              setUsers(response.data.data); 
            } catch (error) {
              console.error('Error fetching users:', error);
            }

            try {
                const response = await instance.post('/user/getUser', bankerUserBody);
                setBankers(response.data.data); 
              } catch (error) {
                console.error('Error fetching users:', error);
              }
          };
    
        fetchUsers();

        const getSanctionData = async () => {
        
            try {
                const response = await instance.get('/depositor/getSancLimit');
                const dataWithId = response.data.data.map((data, index) => ({
                        ...data,
                        updated_date: formatDate(data.updated_date),
                        id: index + 1, // You can use a more sophisticated method to generate unique IDs
                }));
        
                console.log(dataWithId)
                setLoading(false);
                setSanctionLimitData(dataWithId); 
                setFilteredData(dataWithId)
            } catch (error) {
              console.error('Error fetching users:', error);
            }
        };
    
        getSanctionData();

    }, []);

    const columns = [
        { field: 'sanction_limit', headerName: 'Sanction Limit', width: 160, align: 'center', headerAlign: 'center', editable: false,
                renderCell: (params) => (
                    <Box>
                        <Chip
                            label={params.value}
                            color="primary"
                            variant="outlined"
                        />
 
                    </Box>
                    ),
        },
        { field: 'current_sanction_limit', headerName: 'Current Sanction Limit', width: 160, align: 'center', headerAlign: 'center', editable: false,
                renderCell: (params) => (
                    <Box>
                        <Chip
                            label={params.value}
                            color="primary"
                            variant="outlined"
                        />
 
                    </Box>
                    ),
        },
        { field: 'depositor_name', headerName: 'Depositor Name', width: 180, align: 'center', headerAlign: 'center', editable: false, filterable: true, },
        { field: 'depositor', headerName: 'Depositor Email', width: 180, align: 'center', headerAlign: 'center', editable: false, filterable: true, },
        { field: 'updated_date', headerName: 'Updated at', width: 180, align: 'center', headerAlign: 'center', editable: false },
        { field: 'created_by', headerName: 'Created by', width: 180, align: 'center', headerAlign: 'center', editable: false },
        { field: 'updated_by', headerName: 'Updated by', width: 180, align: 'center', headerAlign: 'center', editable: false },

    ];

    const [filteredData, setFilteredData] = useState(sanctionLimitData);
    const handleSearch = () => {
        setShowDetails(true)
        const filtered = sanctionLimitData.filter((row) =>
          columns
            .filter((col) => col.filterable)
            .every((col) => {
            //   if (col.field === 'depositor') {
            //     return row[col.field]?.toString().toLowerCase().includes(searchQuery.toLowerCase());
            //   } else {
            //     return true;
            //   }
            if (col.field === 'depositor') {
                return row[col.field]?.toString().toLowerCase() === searchQuery.toLowerCase();
            } else {
                return true;
            }
            })
        );
        setFilteredData(filtered);
    };

    console.log("filtered ",filteredData)

    const [ showAddConsent, setShowAddConsent ] = useState(false)

    const handleAdd = () => {
       
        console.log(consentActionBody)
          instance.post("/depositor/updateSancLimit", consentActionBody)
            .then(response => {
                alert("Limit added successfully!")
                window.location.reload();
            })
            .catch(error => {
                console.error("Error in request:", error);
                alert("Error occured")
            });
    };


    return (
        <div> 
        <HawkEyeAppBar />
        <Grid sx={{borderRadius:"1rem", border:"2px solid #d8daed", backgroundColor: "#fff", p:2, m:"7rem 7rem"}} >
        <Typography variant="h5" sx={{ px:2, py:1, textAlign: "center",fontWeight: 500}}>
                Update Sanction Limit 
        </Typography> 

        <Grid container mx="auto" mt={4} xs={6}>
        
            <Grid xs={8}>
                <Stack fullWidth centered >
                      
                    <Autocomplete
                        freeSolo
                        id="free-solo-2-demo"
                        disableClearable
                        options={users.map((option) => option.email)}
                        inputValue={searchQuery}
                        onInputChange={(event, newInputValue) => {
                            setSearchQuery(newInputValue);
                        }}

                        renderInput={(params) => (
                        <TextField
                            {...params}
                            // label="Search input"
                            InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            }}
                        />
                        )}
                    />
                    
                </Stack>
            </Grid>
            <Grid xs={4} my="auto" px={2}>
                <Button p={2} sx={{ fontSize: 18}} fullWidth variant='outlined' onClick={handleSearch}>
                    Search User
                </Button>
            </Grid>
        </Grid>

        {
            showDetails && (

            <Box> 
            <Typography mt={3} variant="h5" sx={{ px:2, py:1, textAlign: "center",fontWeight: 500}}>
                Update Sanction Limit
            </Typography> 

            <Popover
                    open={showAddConsent}
                    onClose={() => setShowAddConsent(false)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    PaperProps={{
                        style: {
                            width: '500px', // Set the desired width
                        },
                    }}
                >
                    <Grid container fullWidth p={3} display="flex" justifyContent="center" alignItems="center">
                    <Box>
                        <Typography mt={2} variant="h5" sx={{ textAlign: "center",fontWeight: 500}}>
                            Update Sanction Limit <br/>
                        </Typography> 
                        <Typography mt={2} variant="h6" sx={{ textAlign: "center",fontWeight: 400}}>
                            Depositor: <b>{searchQuery}</b> <br/>
                        </Typography> 
                        <Typography mt={1} variant="h6" sx={{ textAlign: "center",fontWeight: 400}}>
                            Banker: <b>{authenticatedUser.authenticatedUser.email}</b>
                        </Typography> 
                    </Box>
                    <Grid mt={2} xs={8}>
                        <TextField
                            fullWidth
                            type="number"
                            value={sanctionLimit}
                            onChange={(event) => setSanctionLimit(parseFloat(event.target.value))}
                            inputProps={{ min: 0 }}
                        />
                    </Grid>
                    <Grid xs={3} mx={2}>
                        <Button fullWidth variant="outlined" color="success" onClick={handleAdd} p={2} sx={{ mt: 1, fontSize: 18 }}>
                        Update
                        </Button>
                    </Grid>
                    </Grid>
            </Popover>
    
            <Grid container mx="auto" mt={2} px={2} xs={10} py={2} borderRadius="1.5rem" bgcolor="#f5f5f5">

            <div style={{ display: 'flex', alignItems: 'center', width: '100%', margin: '12px 0' }}>
                <div style={{ flex: 1, borderBottom: '1px solid #ccc' }}></div>
                <Typography variant="body2" style={{ margin: '0 16px' }}>
                Depositor: {searchQuery}
                </Typography>
               
                <div style={{ flex: 1, borderBottom: '1px solid #ccc' }}></div>
            </div>
            {
                filteredData && 
                (
                filteredData.map((sanctionLimitData, index) => (
                        <Grid xs={12} fullWidth container >
                            <Grid xs={3}>
                                <Typography pt={1} mx={2}>
                                    Sanction Limit: 
                                    <span>
                                    <Chip
                                        sx={{ ml: 2, mt: 1 }}
                                        label={sanctionLimitData.sanction_limit}
                                        color="primary"
                                        variant="outlined"
                                    />
                                </span>
                                    
                                </Typography>
                            </Grid>
                            <Grid xs={3}>
                                <Typography pt={1} mx={2}>
                                    Bank Associated: {sanctionLimitData.banker}
                                </Typography>
                            </Grid>
                            <Grid xs={4}>
                                <Typography pt={1} mx={2}>
                                    Created at: {formatDate(sanctionLimitData.created_date)}
                                </Typography>
                            </Grid>
                            <Grid xs={2}>
                                <Button variant="outlined" color="error" sx={{ ml: 2}}>
                                    Remove
                                </Button>         
                            </Grid>
                        </Grid>
        
                        
                ))
                )
            }

            <Grid xs={12}>
                            <Box display="flex" justifyContent="center" alignItems="center" mt={2} mb={1} mx="auto">
                                <Button variant="contained" onClick={() => setShowAddConsent(!showAddConsent)} sx={{ color: "#fff", mx: "auto"}} color="success">
                                    Update Sanction Limit
                                </Button>
                            </Box>
                        </Grid>
        
            </Grid>
                
            </Box>

            )
        }


        
       
      



        <Typography mt={3} variant="h5" sx={{ px:2, py:1, textAlign: "center",fontWeight: 500}}>
                Available Sanction Limits
        </Typography> 


        
        <Box sx={{ height: 400, my:2, mx:4 }}>
                <DataGrid
                    rows={filteredData} 
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 5,
                        },
                    },
                    }}
                    pageSizeOptions={[5]}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    filterModel={{
                        items: columns
                          .filter((col) => col.filterable)
                          .map((col) => ({
                            columnField: col.field,
                            operatorValue: 'contains',
                            value: searchQuery,
                          })),
                      }}
                    disableRowSelectionOnClick
                />
        </Box>
        </Grid>
        </div>
    )
}

export default SanctionLimit