import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Box } from '@mui/material';
import { Alert } from '@mui/material';

const EmailPopup = ({ open, handleClose, handleSend }) => {
  const [emails, setEmails] = useState('');
  const [error, setError] = useState('');

  const handleEmailsChange = (event) => {
    setEmails(event.target.value);
  };

  const validateEmails = (emails) => {
    const emailArray = emails.split(',').map(email => email.trim());
    const valid = emailArray.every(email => 
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
    );
    return valid;
  };

  const handleSendClick = () => {
    if (validateEmails(emails)) {
      setError('');
      handleSend(emails.split(',').map(email => email.trim()));
    } else {
      setError('Invalid email address(es)');
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Enter Email Addresses to CC</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Email Addresses"
          type="email"
          fullWidth
          variant="outlined"
          helperText="Enter multiple email addresses separated by commas"
          value={emails}
          onChange={handleEmailsChange}
          error={!!error}
        />
        {error && <Alert severity="error">{error}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSendClick} variant="contained">Ask for WR Copy</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailPopup;
