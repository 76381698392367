import React, { useEffect, useState } from "react";
import { MenuList, ButtonBase, MenuItem, Divider, Grid, Box, Typography, AppBar, TextField, Switch, Button, Chip, Link, FormControlLabel, Checkbox } from "@mui/material";
import HawkEyeAppBar from "../../components/AppBar";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import instance from "../../globals/dashboard_instance";
import Tooltip from '@mui/material/Tooltip';


const AnalysisDashboard = () => {
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const [data4, setData4] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await instance.get('/analysis/Inward');
                const dataWithId1 = response.data.map((item, index) => ({
                    ...item,
                    id: index + 1, 
                }));
                setData1(dataWithId1);
              } catch (error) {
                console.error('Error fetching data:', error);
              }
            try {
                const response = await instance.get('/analysis/Outward');
                const dataWithId2 = response.data.map((item, index) => ({
                    ...item,
                    id: index + 1, 
                }));
                setData2(dataWithId2);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            try {
                const response = await instance.get('/analysis/Pledge');
                const dataWithId2 = response.data.map((item, index) => ({
                    ...item,
                    id: index + 1, 
                }));
                setData3(dataWithId2);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            try {
                const response = await instance.get('/analysis/Depledge');
                const dataWithId2 = response.data.map((item, index) => ({
                    ...item,
                    id: index + 1, 
                }));
                setData4(dataWithId2);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
       
        };
    
        fetchData();
    }, []);


    console.log(data1)
    console.log(data2)

    const columns = [
        { field: 'row_identifier', headerName: 'Row Identifier',  width: 150, align: 'center', headerAlign: 'center', editable: false,  renderCell: (params) => params.value ? params.value : 'N.A.' },
        { field: 'trans_type', headerName: 'Transaction Type',  width: 150, align: 'center', headerAlign: 'center', editable: false,  renderCell: (params) => params.value ? params.value : 'N.A.' },
        { field: 'token', headerName: 'Token',  width: 150, align: 'center', headerAlign: 'center', editable: false,  renderCell: (params) => params.value ? params.value : 'N.A.' },
        { field: 'created_date', headerName: 'Created Date',  width: 180, align: 'center', headerAlign: 'center', editable: false,  renderCell: (params) => params.value ? params.value : 'N.A.' },
        { field: 'status', headerName: 'Status',  width: 120, align: 'center', headerAlign: 'center', editable: false,  renderCell: (params) => params.value ? params.value : 'N.A.' },
        { field: 'remark', headerName: 'Remark',  width: 150, align: 'center', headerAlign: 'center', editable: false,  renderCell: (params) => params.value ? params.value : 'N.A.' },
        { field: 'fk_wr_sr_no', headerName: 'FK WR SR No',  width: 150, align: 'center', headerAlign: 'center', editable: false,  renderCell: (params) => params.value ? params.value : 'N.A.' },
        { field: 'gateway_api_response', headerName: 'Gateway API Response',  width: 220, align: 'center', headerAlign: 'center', editable: false,  renderCell: (params) => params.value ? params.value : 'N.A.' },
        { field: 'blockchain_api_response', headerName: 'Blockchain API Response',  width: 150, align: 'center', headerAlign: 'center', editable: false,  renderCell: (params) => params.value ? params.value : 'N.A.' },
        { field: 'trans_id', headerName: 'Transaction ID',  width: 150, align: 'center', headerAlign: 'center', editable: false,  renderCell: (params) => params.value ? params.value : 'N.A.' },
        { field: 'trans_created_date', headerName: 'Transaction Created Date',  width: 180, align: 'center', headerAlign: 'center', editable: false,  renderCell: (params) => params.value ? params.value : 'N.A.' },
        { field: 'retry_flag', headerName: 'Retry Flag',  width: 120, align: 'center', headerAlign: 'center', editable: false,  renderCell: (params) => params.value ? params.value : 'N.A.' },
        { field: 'api_response', headerName: 'API Response',  width: 150, align: 'center', headerAlign: 'center', editable: false,  
        renderCell: (params) => (
            <Tooltip title={params.value} placement="top-start">
              <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '100%' }}>
                {params.value ? params.value : 'N.A.'}
              </div>
            </Tooltip>
          ) },
    ];
    const generateColumn = (field, headerName, width) => ({
        field,
        headerName,
        
        width: width,
        align: 'center',
        headerAlign: 'center',
        editable: false,  renderCell: (params) => params.value ? params.value : 'N.A.',
        renderCell: (params) => params.value ? params.value : 'N.A.'
      });
    


    return (
        <div> 
        <HawkEyeAppBar />
        <Grid sx={{borderRadius:"1rem", border:"2px solid #d8daed", backgroundColor: "#fff", p:2, marginTop:"120px", mx:"4rem"}} >
        <Typography variant="h5" sx={{ px:2, py:1, textAlign: "center",fontWeight: 500}}>
            Analysis Dashboard 
        </Typography> 

        <Grid container p={2} >
            <Grid xs={12}>
                <Typography variant="h5" my={2} sx={{ px:2, py:1, textAlign: "center",fontWeight: 500}}>
                        Inward Data
                </Typography>
            </Grid>
            <Grid xs={12}>
                <DataGrid
                    rows={data1} 
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 10,
                        },
                    },
                    }}
                    pageSizeOptions={[10]}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    disableRowSelectionOnClick
                />
            </Grid>
        </Grid>

        <Grid container p={2} >
            <Grid xs={12}>
                <Typography variant="h5" my={2} sx={{ px:2, py:1, textAlign: "center",fontWeight: 500}}>
                        Outward Data
                </Typography>
            </Grid>
            <Grid xs={12}>
                <DataGrid
                    rows={data2} 
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 10,
                        },
                    },
                    }}
                    pageSizeOptions={[10]}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    disableRowSelectionOnClick
                />
            </Grid>
        </Grid>

        <Grid container p={2} >
            <Grid xs={12}>
                <Typography variant="h5" my={2} sx={{ px:2, py:1, textAlign: "center",fontWeight: 500}}>
                        Pledge Data
                </Typography>
            </Grid>
            <Grid xs={12}>
                <DataGrid
                    rows={data3} 
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 10,
                        },
                    },
                    }}
                    pageSizeOptions={[10]}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    disableRowSelectionOnClick
                />
            </Grid>
        </Grid>

        <Grid container p={2} >
            <Grid xs={12}>
                <Typography variant="h5" my={2} sx={{ px:2, py:1, textAlign: "center",fontWeight: 500}}>
                        Depledge Data
                </Typography>
            </Grid>
            <Grid xs={12}>
                <DataGrid
                    rows={data4} 
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 10,
                        },
                    },
                    }}
                    pageSizeOptions={[10]}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    disableRowSelectionOnClick
                />
            </Grid>
        </Grid>


       
        </Grid>
        </div>
    )
}

export default AnalysisDashboard