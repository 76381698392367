import React, { useState, useEffect } from "react";
import HawkEyeAppBar from "../../components/AppBar";
import {
  Box,
  Divider,
  Typography,
  Grid,
  Chip,
  Link,
  Button,
  ImageList,
  Card,
  CardContent,
  ImageListItem,
  CardHeader,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Select,
  InputLabel,
  MenuItem,
  Switch,
  Checkbox,
  TextField,
  Snackbar,
} from "@mui/material";
import QRCodePic from "../../assets/QRCode.png";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { GiGrain, GiWeight } from "react-icons/gi";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TbCertificate } from "react-icons/tb";
import { makeStyles } from "@mui/styles";
import { useLocation } from 'react-router-dom';
import instance from "../../globals/axios";
import QRCode from "react-qr-code";



const useStyles = makeStyles((theme) => ({
  rootVideo: {
    minWidth: 275,
  },
  titleVideo: {
    fontSize: 20,
  },
  buttonVideo: {
    marginTop: theme.spacing(2),
  },
  cardHeader: {
    backgroundColor: "#0073b1",
    color: "#fff",
    fontWeight: "bold",
  },
  cardBody: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dropdownWrapper: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  dropdown: {
    textDecoration: "underline",
    marginRight: 8,
  },
  expandIcon: {
    transform: "rotate(0deg)",
    transition: "transform 0.2s ease-in-out",
  },
  expandIconOpen: {
    transform: "rotate(180deg)",
  },
  dropdownContent: {
    marginTop: 16,
    paddingLeft: 16,
    // borderLeft: "1px solid #ccc",
  },
  outline: {
    borderRadius:"1rem",
    border:"2px solid #d8daed", 
    backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)',

  },
  customCard: {
    backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)',
    boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
    borderRadius: '1rem',
    border:"2px solid #d8daed", 
  },
  subtitleFont: {
    fontSize: '1.5rem',
    fontWeight: '600',
  }
  
}));

const MakeLoanOffer = () => {
  const classes = useStyles();
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);

  const location = useLocation();
  const { data } = location.state;

  const [funding, setFunding] = useState();
  const [rate, setRate] = useState();
  const [mktValue, setMktValue] = useState(180000);
  const [loanAmount, setLoanAmount] = useState(0);
  const [wr_reciept_no, setWr_reciept_no] = useState(data.attributes.inward_details.wr_reciept_no);
  const [qty, setQty] = useState(data.balance);
  const [owner, setOwner] = useState(data.owner);
  const [loanId, setLoanId] = useState(data.loan_id);


  useEffect(() => {
    setLoanAmount(funding*0.01*mktValue);
  }, [funding])

  console.log(data);

  const [loanCheck, setLoancheck] = useState(false);
  const [offerSubmitted, setOfferSubmitted] = useState(false);


  const requestBody = {
    "wr_reciept_no": wr_reciept_no,
    "qty": qty,
    "rate_of_interest": rate,
    "market_value": mktValue,
    "loan_amount": loanAmount,
    "other_fields": "N.A.",
    "borrower": owner
  };

  const acceptRequestBody = {
    "wr_reciept_no": wr_reciept_no,
    "qty": qty,
    "rate_of_interest": rate,
    "market_value": mktValue,
    "loan_amount": loanAmount,
    "other_fields": "N.A.",
    "borrower": owner,
    "loan_offer_id": loanId,
    "action": "accept",
  };

  const handleSubmit = async () => {
      setOfferSubmitted(true);

      try {
        const response = await instance.post("loan/banker/makeLoanOffer", requestBody);
        console.log("ResponseData", response);
        alert("Offer made succesfully!");
        setTimeout(function () {
          window.location.replace("/nbfc");
        }, 500);
      } 
      catch (error) {
        try {
          const response = await instance.post("loan/acceptRejectLoanOffer", acceptRequestBody);
          console.log("ResponseData", response);
          alert("Offer made succesfully!");
          setTimeout(function () {
            window.location.replace("/nbfc");
          }, 500);
        } 
        catch (error) {
          // Handle errors from both API requests
          console.error('Error:', error);
        }
      }
  };

  
  return (
    <Box fullWidth>
      <HawkEyeAppBar />
      <Box mx='auto' sx={{ bgcolor: "background.paper", padding: "1.5rem 8rem", marginTop: "4rem" }}>
        <Grid mt="2rem" mb="1rem">
          <Link href='/banker-details'>
            <Chip icon={<KeyboardBackspaceIcon />} style={{ cursor: "pointer" }} label='Make Loan Offer' variant='outlined' />
          </Link>
        </Grid>
        <Divider />

        <Grid fullWidth mb="5rem">
            <Box className={classes.outline} mt="3rem" p={6}>
              
                <Grid mb={1} container>
                  <Grid xs={10} md={6}>
                    <Typography item xs={4} variant='h5' gutterBottom>
                      Loan Offer
                    </Typography>
                    <Divider my={1} />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item md={6} mr={4}>
                    <Card style={{ marginTop: "0.9rem" }}>
                        
                          <Grid fullWidth container p={4}>
                            
                                  
                                  <Typography variant="h6" my={1}>
                                    Token Code:<br/>
                                    {data.token_code}
                                  </Typography>
                                  <Typography variant="h6" my={1}>
                                    Token Name:<br/>
                                    {data.token_name}
                                  </Typography>
                                  <Grid xs={12}>

                                  <Typography variant="h6" my={1}>
                                    Commodity: {data.attributes.commodity.name}<br/>
                                  </Typography>
                                  </Grid>
                                  <Grid xs={12}>

                                  <Typography variant="h6" my={1}>
                                    Warehouse Reciept Number: 
                                    {data.attributes.inward_details.wr_reciept_no}
                                    <br/>
                                  </Typography>
                                  </Grid>
                                  
                                  <Typography variant="h6" my={1}>
                                    Quantity: {data.balance} MT<br/>
                                  </Typography>
                                  <br/>

                                
                            
                          </Grid>
                        
                    </Card>
                    
                    <Grid item md={12}>
                      <Typography vairant="h6" style={{ marginTop: "1.5rem" }}>Other Details</Typography>
            
                      <TextField multiline fullWidth rows={3} variant='outlined' style={{ background: "white", marginTop: "10px" }} />
                    </Grid>
                  </Grid>
                  

                  <Grid item md={5} my={1} mx="auto">
                  <Card>
                    <Grid container p={4}>

                      <Grid item md={5}>
                        <Typography variant="h6" mx="auto">Rate of Interest</Typography>
                        <TextField fillWidth style={{ background: "white", marginTop: "10px" }} 
                          value={rate}
                          onChange={(event) => setRate(event.target.value)}
                          label='Enter Rate (%)' />
                      </Grid>
                      <Grid item md={6} mx="auto">
                        <Typography variant="h6" mx="auto">Funding Percentage</Typography>
                        <TextField fillWidth style={{ background: "white", marginTop: "10px" }} 
                          value={funding}
                          onChange={(event) => setFunding(event.target.value)}
                          label='Enter Funding Percentage (%)' />
                      </Grid>
                      <Grid item md={12}>
                      <Typography variant="h6" mt="1rem">Market Value(in Rs/Mt)</Typography>
                      <Card style={{ marginTop: "10px", height: "50px" }}>
                        <Typography style={{ padding: "10px" }}>₹ {mktValue}</Typography>
                      </Card>
                      </Grid>

                      <Grid item md={12}>
                        <Typography variant="h6" mt="1rem">Loan Amount(in Rs)</Typography>
                        <Card style={{ marginTop: "10px", height: "50px" }}>
                          <Typography style={{ padding: "10px" }}>₹ {loanAmount}</Typography>
                        </Card>
                          
                      </Grid>
                      
                    </Grid>
                  </Card>
                    
                  </Grid>
                </Grid>

              
              
              <Grid mx="auto" mt={6} sx={{ display: "flex", justifyContent: "center"}}>
                  <Link>
                      <Button onClick={handleSubmit} variant="contained" sx={{ width:"15rem", fontSize: "1.15rem"}} outlined color='primary'>Make Loan offer</Button>
                  </Link>
              </Grid>
             
            </Box>
        </Grid>

        {/* <Snackbar open={offerSubmitted} autoHideDuration={6000} message='Submitted' /> */}
      </Box>
    </Box>
  );
};

export default MakeLoanOffer;
