
import axios from "axios";
import { SERVER_BASE_ADDRESS } from "./const";
import { getToken, saveAuth } from "./utils";
const instance = axios.create({
  baseURL: SERVER_BASE_ADDRESS,
  headers: {
    "Content-Type": "application/json",
    // Authorization: `JWT ${token}`,
  },
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `JWT ${token}`;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response !== undefined && error.response.status === 401) {
      saveAuth(undefined);
    } else {
      if (error.response.data.error) {
        error.message = error.response.data.error;
      } else {
        for (let x in error.response.data) {
          error.message = error.response.data[`${x}`][0];
        }
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
