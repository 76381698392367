import React, { useState } from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import instance from "../globals/axios";

const ReportDownloader = () => {
  const [inwardDateFrom, setInwardDateFrom] = useState(dayjs().subtract(1, 'month'));
  const [inwardDateTo, setInwardDateTo] = useState(dayjs());
  const [reportType, setReportType] = useState('to_be_funded');
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState('');
  const [showDownloadButton, setShowDownloadButton] = useState(false);

  const handleDownload = () => {
    const data = {
      inward_date_from: inwardDateFrom.toISOString(),
      inward_date_to: inwardDateTo.toISOString(),
      report_type: reportType
    };

    setLoading(true);

    instance.post('/banker/download_report', data)
      .then(response => {
        console.log(response.data);
        setCsvData(response.data);
        setLoading(false);
        setShowDownloadButton(true);
      })
      .catch(error => {
        console.error('There was an error making the request:', error);
        setLoading(false);
        setShowDownloadButton(false);
      });
  };

  const downloadCSV = () => {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'report.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleReportTypeChange = (event) => {
    setReportType(event.target.value);
    setShowDownloadButton(false);  // Hide the download button when report type changes
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2} p={3} >
      <Typography variant="h5" textAlign="center" gutterBottom>
        Report Downloader
      </Typography>
      <Grid container item spacing={4} xs={8} justifyContent="center">
        <Grid item xs={12} sm={4}>
          <DatePicker
            fullWidth
            label="Inward Date From"
            value={inwardDateFrom}
            onChange={(newValue) => setInwardDateFrom(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePicker
            fullWidth
            label="Inward Date To"
            value={inwardDateTo}
            onChange={(newValue) => setInwardDateTo(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Report Type</InputLabel>
          <Select
            label="Report Type"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={reportType}
            onChange={handleReportTypeChange}
            fullWidth
          >
            <MenuItem value="to_be_funded">To Be Funded</MenuItem>
            <MenuItem value="repayment_report">Repayment Report</MenuItem>
            <MenuItem value="funded_report">Funded Report</MenuItem>
          </Select>
        </FormControl>
        </Grid>
      </Grid>
      <Grid container item xs={8} justifyContent="center" sx={{ mt: 2 }}>
        <Button variant="contained" color="primary" onClick={handleDownload} fullWidth>
          Download Report
        </Button>
      </Grid>
      {loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            my: 3
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!loading && showDownloadButton && csvData && (
        <Grid container item xs={8} justifyContent="center" sx={{ mt: 2 }}>
          <Button variant="outlined" color="success" onClick={downloadCSV} fullWidth>
            Download CSV
          </Button>
        </Grid>
      )}
    </Grid>
  </LocalizationProvider>
  );
};

export default ReportDownloader;