import React, { Fragment, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { List, ListItem, ListItemText, ListItemIcon, Grid, Avatar, Typography, Box } from "@mui/material";
import { isLoggedIn } from "../globals/utils";
import { useNavigate, useLocation } from "react-router-dom";
import { Drawer, Divider } from "@mui/material";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import ViewDayIcon from "@mui/icons-material/ViewDay";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Transport from "@mui/icons-material/EmojiTransportation";
import { changeBlockChain, getBlockchain, getUserName, getUserEmail, HOME_PAGE, SERVER_BASE_ADDRESS } from "../globals/const";
import { logout } from "../globals/utils";
import UpdateIcon from '@mui/icons-material/Update';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ReceiptIcon from '@mui/icons-material/Receipt';
import InsightsIcon from '@mui/icons-material/Insights';
import AssessmentIcon from '@mui/icons-material/Assessment';


import instance from "../globals/axios";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    alignContent: "right",
    width: theme.spacing(36),
  },
  drawerOpen: {
    width: 240,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
  },
}));

export default function HawkEyeAppBar() {
  const location = useLocation();
  const classes = useStyles();
  const history = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [blockchain, setBlockchain] = useState(getBlockchain());
  const [role, setRole] = useState(null);
  const [isQualityDI, setIsQualityDI] = useState(false);

  const [alertCount, setAlertCount] = useState(null);



  const onChainToggle = (val) => {
    const chain = val ? "Mainnet" : "Testnet";
    setBlockchain(chain);
    // changeBlockChain -> window reload removed
    changeBlockChain(chain);
  };

  useEffect(() => {
    if ("auth" in window.sessionStorage) {
      let HQ = JSON.parse(window.sessionStorage.getItem("auth"));
      if (HQ.is_Quality_DI) {
        setIsQualityDI(HQ.is_Quality_DI);
      }
    }

    let user = JSON.parse(window.sessionStorage.getItem("user_role"));
    setRole(user)

  }, []);

  return (
    <>
     <AppBar component='nav' sx={{ backgroundColor: "#214383" }} position='sticky' style={{ p: 0, position: "fixed", top: 0, left: 0, width: "100%" }}>
        <Toolbar sx={{ p: 1 }}>
          <Box>
            <img src='https://www.staragri.com/wp-content/themes/agri/images/logo-staragri.png' style={{ borderRadius: "6px" }} width='125px' height={45} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
            <Typography edge='end' variant='h6' className={classes.title}>
              Welcome,&nbsp;{getUserName()}
            </Typography>
            <AccountCircleIcon size='large' edge='end' sx={{ mx: "0.5rem", fontSize: 38 }} />
            <IconButton edge='end' sx={{ mx: "0.5rem" }} className={classes.menuButton} color='inherit' aria-label='menu' onClick={setDrawerOpen}>
              <MenuIcon sx={{ fontSize: 35 }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>













      {/* 
      
      <AppBar component='nav' sx={{ backgroundColor: "#214383" }} position='sticky' style={{ p: 0, position: "fixed", top: 0, left: 0, width: "100%" }}>
        <Toolbar sx={{ p: 1 }}>
        
          <Box sx={{ position: "fixed", p: 1, top: 4, left: 40, width: "100%" }}>
            <img src='https://www.staragri.com/wp-content/themes/agri/images/logo-staragri.png' style={{ borderRadius: "6px" }} width='150px' height={55} />
          </Box>


          <Box ml='70%' sx={{ mr: 2, flexDirection: "row-reverse", textAlign: "right", alignItems: "right" }}>
            <Typography edge='end' variant='h6' className={classes.title}>
            Welcome,&nbsp;{getUserName()}
            </Typography>
          </Box>
          <AccountCircleIcon size='large' edge='end' sx={{ mx: "0.5rem", fontSize: 38 }} />
          <IconButton edge='end' sx={{ mx: "0.5rem" }} className={classes.menuButton} color='inherit' aria-label='menu' onClick={setDrawerOpen}>
            <MenuIcon sx={{ fontSize: 35 }} />
          </IconButton>
        </Toolbar>
      </AppBar>
    */}

      <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)} classes={{ paper: classes.drawerPaper }}>
        <Grid edge='end' item container>
          <Grid item container spacing={2} alignItems='center'>
            <Grid item style={{ padding: "1rem", margin: "1rem auto" }}>
              {/* <img src={HOME_PAGE + "/hyfun_logo.png"} width="150px" alt="" /> */}
              <img src='https://www.staragri.com/wp-content/themes/agri/images/logo-staragri-footer.png' width='150px' alt='' />
            </Grid>
          </Grid>
          <Grid item container>
            <Grid xs item>
              <Divider />
            </Grid>
          </Grid>

          <Grid item container>
            <Grid xs item>
              <List>
                
                {role === "2" && (
                  <>
                    <ListItem
                      button
                      onClick={() => {
                        history(HOME_PAGE + "/nbfc-dashboard");
                        setDrawerOpen(false);
                      }}>
                      <ListItemIcon>
                        <AssessmentIcon />
                      </ListItemIcon>
                      <ListItemText primary='My Dashboard' />
                    </ListItem>

                    <ListItem
                      button
                      onClick={() => {
                        history(HOME_PAGE + "/my-transactions");
                        setDrawerOpen(false);
                      }}>
                      <ListItemIcon>
                        <ReceiptLongIcon />
                      </ListItemIcon>
                      <ListItemText primary='My Transactions' />
                    </ListItem>

                    <ListItem
                      button
                      onClick={() => {
                        history(HOME_PAGE + "/nbfc");
                        setDrawerOpen(false);
                      }}>
                      <ListItemIcon>
                        <ReceiptIcon />
                      </ListItemIcon>
                      <ListItemText primary='View WRs' />
                    </ListItem>
                       
                  </>
                )}

                {role === "5" && (
                    <>
                        <ListItem
                                  button
                                  onClick={() => {
                                    history(HOME_PAGE + "/operations-dashboard");
                                    setDrawerOpen(false);
                                  }}>
                                  <ListItemIcon>
                                    <AssessmentIcon />
                                  </ListItemIcon>
                                  <ListItemText primary='My Dashboard' />
                        </ListItem>


                        <ListItem
                                      button
                                      onClick={() => {
                                        history(HOME_PAGE + "/operations");
                                        setDrawerOpen(false);
                                      }}>
                                      <ListItemIcon>
                                        <ReceiptIcon />
                                      </ListItemIcon>
                                      <ListItemText primary='View WRs' />
                        </ListItem>
                    </>

                )}

                {role === "6" && (
                    <>
                        <ListItem
                                  button
                                  onClick={() => {
                                    history(HOME_PAGE + "/superuser-dashboard");
                                    setDrawerOpen(false);
                                  }}>
                                  <ListItemIcon>
                                    <AssessmentIcon />
                                  </ListItemIcon>
                                  <ListItemText primary='My Dashboard' />
                        </ListItem>


                        <ListItem
                                      button
                                      onClick={() => {
                                        history(HOME_PAGE + "/analysis-dashboard");
                                        setDrawerOpen(false);
                                      }}>
                                      <ListItemIcon>
                                        <InsightsIcon />
                                      </ListItemIcon>
                                      <ListItemText primary='Analysis Dashboard' />
                        </ListItem>
                    </>

                )}

                {role === "1" && (
                  <>
                  {/* <ListItem
                  button
                  onClick={() => {
                    history(HOME_PAGE + "/admin-dashboard");
                    setDrawerOpen(false);
                  }}>
                  <ListItemIcon>
                    <AssessmentIcon />
                  </ListItemIcon>
                  <ListItemText primary='My Dashboard' />
                  </ListItem> */}

                  <ListItem
                      button
                      onClick={() => {
                        history(HOME_PAGE + "/update-consent");
                        setDrawerOpen(false);
                      }}>
                      <ListItemIcon>
                        <UpdateIcon />
                      </ListItemIcon>
                      <ListItemText primary='Update Depositor Consent' />
                    </ListItem>

                  <ListItem
                      button
                      onClick={() => {
                        history(HOME_PAGE + "/admin");
                        setDrawerOpen(false);
                      }}>
                      <ListItemIcon>
                        <ReceiptIcon />
                      </ListItemIcon>
                      <ListItemText primary='View WRs' />
                    </ListItem>
                    </>

                )}

                {role === "4" && (
                  <>
                  <ListItem
                        button
                        onClick={() => {
                          history(HOME_PAGE + "/sanction-limit");
                          setDrawerOpen(false);
                        }}>
                        <ListItemIcon>
                          <UpdateIcon />
                        </ListItemIcon>
                        <ListItemText primary='Update Sanction limit' />
                  </ListItem>
                  <ListItem
                  button
                  onClick={() => {
                    history(HOME_PAGE + "/banker-dashboard");
                    setDrawerOpen(false);
                  }}>
                  <ListItemIcon>
                    <AssessmentIcon />
                  </ListItemIcon>
                  <ListItemText primary='Dashboard' />
                  </ListItem>
                  <ListItem
                  button
                  onClick={() => {
                    history(HOME_PAGE + "/csv-upload");
                    setDrawerOpen(false);
                  }}>
                  <ListItemIcon>
                    <ReceiptLongIcon />
                  </ListItemIcon>
                  <ListItemText primary='Upload Data via CSV' />
                  </ListItem>
                  <ListItem
                        button
                        onClick={() => {
                          history(HOME_PAGE + "/banker");
                          setDrawerOpen(false);
                        }}>
                        <ListItemIcon>
                          <ReceiptIcon />
                        </ListItemIcon>
                        <ListItemText primary='View WRs' />
                  </ListItem>
                  </>
                )}

                <ListItem
                  button
                  onClick={() => {
                    window.open("mailto:info@staragri.com?subject=StarAgri App - ");
                  }}>
                  <ListItemIcon>
                    <FeedbackOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Support' />
                </ListItem>
                <Divider />
                <ListItem
                  button
                  onClick={() => {
                    logout();
                  }}>
                  <ListItemIcon>
                    <ExitToAppOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Logout' />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
}
