import React, { useEffect, useState, useRef } from "react";
import HawkEyeAppBar from "../components/AppBar";
import { styled } from '@mui/material/styles';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { MenuList, ButtonBase, MenuItem, Divider, TextField, Button, Chip, CircularProgress } from "@mui/material";
import { Card, CardContent} from '@mui/material';
import { Link } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Autocomplete from '@mui/material/Autocomplete';
import instance from "../globals/dashboard_instance";
import { Chart } from "react-google-charts";
import { useParams } from 'react-router-dom';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DescriptionIcon from '@mui/icons-material/Description';
import { convertToMillion, convertToCommaSeperated } from "../globals/utils"

const WarehouseStats = () => {
        const { stateId, locationId, warehouseId } = useParams();
       
        const formatDate = (dateString) => {
            const date = new Date(dateString);
            const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
            
            const formattedDate = date.toLocaleDateString('en-US', options);
          
            return formattedDate.replace(/(\d+)\/(\d+)\/(\d+)/, '$1-$2-$3');
        };
          

        const [top5CommInwardData, setTop5CommInwardData] = useState([]);
        const [page, setPage] = useState(1);
        const [pageSize, setPageSize] = useState(10); 

        const getStatusColor = (status) => {
            switch (status) {
              case 'accepted':
                return '#4caf50';
              case 'rejected':
                return '#e74c3c';
              case 'pending':
                return '#78909c';
              case 'autoaccept':
                return '#00e676';
              default:
                return '#000'; // Default color
            }
        };
      
        const fetchData = async () => {
          try {
            const response = await instance.get(
                `dashboard/wh-selector?state=${stateId}&location=${locationId}&warehouse=${warehouseId}&limit=${pageSize}&page=${page}`
              );
        
            const formattedData = response.data.data.map((item, index) => ({
                ...item,
                id: index + 1,
                inward_date: formatDate(item.inward_date),
            }));
      
            setTop5CommInwardData(formattedData);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
      
        useEffect(() => {
          fetchData();
        }, [ page, pageSize]);

        const top5Columns = [
            // { field: 'id', headerName: 'ID', align: 'center', headerAlign: 'center', editable: false, flex: 1 },
            { field: 'wr_reciept_no', headerName: 'WR Receipt No', align: 'center', headerAlign: 'center', editable: false, flex: 1 },
            {
              field: 'view_wr',
              headerName: 'View WR',
              align: 'center',
              headerAlign: 'center',
              editable: false,
              flex: 1,
              renderCell: (params) => (
                <Link to={`/wr-details/${params.row.wr_reciept_no}`} target="_blank" rel="noopener noreferrer">
                  <Button variant="outlined" startIcon={<DescriptionIcon />}>
                    View WR
                  </Button>
                </Link>
              ),
            },
            { field: 'commodity', headerName: 'Commodity', align: 'center', headerAlign: 'center', editable: false, flex: 1 },
            {
              field: 'inward_date',
              headerName: 'Inward Date',
              align: 'center', headerAlign: 'center', editable: false, flex: 1,
              valueFormatter: (params) => formatDate(params.value),
            },
            {
              field: 'status',
              headerName: 'Status',
              align: 'center', headerAlign: 'center', editable: false, flex: 1,
              renderCell: (params) => (
                <Chip
                  label={params.value}
                  variant="outlined"
                  style={{ borderColor: getStatusColor(params.value), color: getStatusColor(params.value) }}
                />
              ),
            },
            { 
              field: 'qty', headerName: 'Quantity ( in MT )', align: 'center', headerAlign: 'center', editable: false, flex: 1,
              valueFormatter: (params) => convertToCommaSeperated((params.value)/1000),
            },
        ];

     

        return (
            <Box fullWidth>
                <HawkEyeAppBar />

               
                <Paper sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px", borderRadius:"20px", mt:14, mb:6, mx:8, p:2, backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)'}}>
                    
                    <Grid sx={{ borderRadius: '1rem', border: '2px solid #d8daed', backgroundColor: '#fff', p: 4 }} >
                        <Typography variant="h5" sx={{ px: 2, py: 3, textAlign: 'center', fontWeight: 500 }}>
                            Warehouse Data
                        </Typography>
                        <Box sx={{ height: 400, width: '100%' }}>
                            <DataGrid
                                        rows={top5CommInwardData}
                                        columns={top5Columns}
                                        disableRowSelectionOnClick
                                        pageSize={pageSize}
                                        pagination
                                        page={page}
                                        onPageChange={(newPage) => setPage(newPage)}
                                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                        components={{
                                            Toolbar: GridToolbar, 
                                        }}
                              />
                        </Box>
                    </Grid>
               
                </Paper>


            </Box>
        );
    };

export default WarehouseStats;
