
import sha256 from "crypto-js/sha256";
import { getPassWord } from "./authentication";
import CryptoJS from 'crypto-js';

var indexedDB = window.indexedDB ||
window.webkitIndexedDB ||
window.mozIndexedDB;

class DB {
    constructor(name){
        this.name = name;
    }

    init(version, upgrade, done) {
        console.log('init');
        var openReq = indexedDB.open(this.name, version);
        openReq.onsuccess = function(e) {
            var db = e.target.result;
            // Chrome 23 still has setVersion so don't upgrade
            // unless the version is still old.
            if ('setVersion' in db && db.version < version) {
                var setVerReq = db.setVersion(version);
                setVerReq.onsuccess = function(e) {
                    console.log('upgrading');
                    upgrade(e.target.result.db);
                    done();
                };
            } else {
                done();
            }
        };

        openReq.onupgradeneeded = function(e) {
            // Never gets raised before Chrome 23.
            console.log('upgrading');
            upgrade(e.target.result);
        };
        openReq.onerror = function(e) {
            console.log('init error');
        };
        openReq.onblocked = function(e) {
            console.log('init blocked');
        };
    };

    read(stores, fn, done) {
        return this.transaction('readonly', stores, fn, done);
    };

    readWrite(stores, fn, done) {
        return this.transaction('readwrite', stores, fn, done);
    };

    transaction(mode, stores, fn, done) {
        var openReq = indexedDB.open(this.name);
        openReq.onsuccess = function(e) {
            var db = e.target.result;
            var tx = db.transaction(stores, mode);
            tx.oncomplete = function(e) {
                if (done) {
                    done();
                }
            };
            tx.onabort = function(e) {
                console.log('tx abort');
            };
            tx.onerror = function(e) {
                console.log('tx error');
            };
            fn(tx);
        };
        openReq.onerror = function(e) {
            console.log('open tx error');
        };
    };
}

DB.deleteDatabase = function(name, done) {
    var delReq = indexedDB.deleteDatabase(name);
    delReq.onsuccess = function(e) {
        // Not triggered before Chrome 23.
        done();
    };
    delReq.onerror = function(e) {
        console.log('delete error');
    };
    delReq.onblocked = function(e) {
        console.log('delete blocked');
    };
};

const tableName = "Wallets";
const passTable = "PassTable";
let encPass = '';
var walletDB;
var start = undefined;
export function initDB(fn){
    start = fn;
    walletDB = new DB("NewrlWallet");
    walletDB.init(2,function(db){
    db.createObjectStore(tableName, { autoIncrement: true});
    db.createObjectStore(passTable, { autoIncrement: true});
    }, function() {
    console.log('ready');
    readPassWord();
    //loadContactsTable();
    });
}


export function storeWalletToDB(wallet){
    //console.log("store to wallet called wallet:" + JSON.stringify(wallet));
    var pass = getPassWord();
    var aespass = CryptoJS.AES.encrypt(JSON.stringify(wallet),pass);
    //console.log("aes ecryption is:" + aespass);
    //var wa = CryptoJS.AES.decrypt(aespass.toString(), pass).toString(CryptoJS.enc.Utf8);
    //console.log("decrypted wallet: " + wa);

    walletDB.readWrite([ tableName ], function(tx) {
      tx.objectStore(tableName).put(aespass.toString());
  
      //addContactToTable(contact);
    }, function() {
      console.log('added wallet ' + JSON.stringify(wallet) );
    });
}

export function getWalletFromDB(cb){
    var pass = getPassWord();
    walletDB.read([ tableName ], function(tx) {
        var cursor = tx.objectStore(tableName).openCursor();
        cursor.onsuccess = function(e) {
          if (e.target.result) {
            var wallStr = CryptoJS.AES.decrypt(e.target.result.value, pass).toString(CryptoJS.enc.Utf8);
            var wallet = JSON.parse(wallStr);
            cb(wallet); 
          }
          else{
            return cb(null);
          }
        };
        cursor.onerror = function(e) {
            console.log('cursor error');
            cb(null);
        };
      });
}


export function storePassWordToDB(pass){
    encPass = sha256(pass).toString();
    console.log("enc while storing " + encPass);
    walletDB.readWrite([ passTable ], function(tx) {
      tx.objectStore(passTable).put(encPass);
  
      //addContactToTable(contact);
    }, function() {
      console.log('added wallet ' + JSON.stringify(pass) );
    });
}

function readPassWord(){
    walletDB.read([ passTable ], function(tx) {
        var cursor = tx.objectStore(passTable).openCursor();
        cursor.onsuccess = function(e) {
          console.log("called once");
          if (e.target.result) {
              encPass = e.target.result.value;
              //console.log("encpass at getPass:" + encPass )
          }
          else{
            //cb('');
          }
          start();
        };
        cursor.onerror = function(e) {
            console.log('cursor error');
            //cb('');
        };
      });    
}

export function getPassWordFromDB (){
    return encPass;
}


export function runTest(){
    // let msg = "mynameisrinshad";
    // let key = "hanan";
    // let cipher = cryptoJs.AES.encrypt(msg,key).toString();
    // console.log("cipher:"+cipher);
    // let decipher = cryptoJs.AES.decrypt(cipher,key).toString(cryptoJs.enc.Utf8);
    // console.log("msg:"+decipher);
    // let passHash = sha256("ILoveIndia");
    // console.log("Hashed password:"+passHash);

}

export function clearDBData(){
    indexedDB.deleteDatabase('NewrlWallet')
    // encPass = '';
    // walletDB.readWrite([tableName], function(tx){
    //     tx.objectStore(tableName).clear();
    // },
    // ()=>{
    //     console.log("wallet deleted");
    // }
    // );

    // walletDB.readWrite([passTable], function(tx){
    //     tx.objectStore(passTable).clear();
    // },
    // ()=>{
    //     console.log("password deleted");
    // }
    // );

    // start();
}