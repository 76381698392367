import React, { useState, useEffect } from 'react';
import { Popover, Grid, TextField, Button, Backdrop, Container ,Radio, RadioGroup, FormControlLabel,} from '@mui/material';
import { MdClose, MdVerified } from 'react-icons/md';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import instance from '../../globals/axios';
import { convertToCommaSeperated } from '../../globals/utils';

const apiEndpoint = 'https://ewrapi.staragri.com/api/agri/ft/mark_funded';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backdropFilter: 'blur(5px)',
  },
  popover: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
}));

const FundingPopover = ({ open, onClose, data }) => {
  const classes = useStyles();
  const [roi, setRoi] = useState('');
  const [loanAmount, setLoanAmount] = useState(data.disbursement_amount);
  const [fundingPart, setFundingPart] = useState('unfunded'); 

  const handleFundingPartChange = (event) => {
    setFundingPart(event.target.value);
    if (event.target.value === 'unfunded') {
      setLoanAmount(data.disbursement_amount);
    } else {
      setLoanAmount(''); // Clear loan amount for partial funding
    }
  };

  const handleMarkunfunded = async () => {
    try {
      if(loanAmount>data.disbursement_amount){
        alert("Please Check Disbursement Amount!");
        return;
      }

      const response = instance.post('/ft/mark_unfunded', {
        wr_reciept_no: data.attributes.inward_details.wr_reciept_no,
        action: fundingPart,
        loan_amount: loanAmount,
      });


      alert("Marked Unfunded succesfully!");
      setTimeout(function () {
        window.location.replace("/banker");
      }, 500);
      onClose(); 
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  const initialNumBags = data.attributes.truck_details[0]?.bag_details[0]?.number || 0;
  const initialQuantity = data.pledged_amount ? data.pledged_amount / 1000 : data.attributes.inward_details.inward_weight / 1000;
  
  const [inputType, setInputType] = useState('quantity');
  const [numBags, setNumBags] = useState(initialNumBags);
  const [quantity, setQuantity] = useState(initialQuantity);
  const [avgBagSize, setAvgBagSize] = useState((initialQuantity * 1000) / initialNumBags);

  useEffect(() => {
    if (inputType === 'bags' && avgBagSize > 0) {
      setQuantity((numBags * avgBagSize * 0.001).toFixed(3));
    }
  }, [numBags, avgBagSize, inputType]);

  useEffect(() => {
    if (inputType === 'quantity' && avgBagSize > 0) {
      setNumBags((quantity * 1000) / avgBagSize);
    }
  }, [quantity, avgBagSize, inputType]);

  const handleChange = (setter) => (e) => setter(e.target.value);

  return (
    <Container>
      <Backdrop open={open} onClick={onClose} />
      <Popover
        open={open}
        sx={{
          mt: '15%',
          mx:"auto",
          width: '80%', 
          maxWidth: '600px', 
        }}
        anchorReference="anchorPosition"
        anchorPosition={{ top: '50%', left: '50%' }}
        transformOrigin={{ vertical: 'center', horizontal: 'center' }}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      >
        <Grid container p={4} spacing={2} className={classes.popover}>
        <Grid item xs={12}>
            <TextField
              label={`Token Code: ${data.token_code}`}
              variant="outlined"
              fullWidth
              disabled
            />
        </Grid>

        <Grid item xs={12}>
            <TextField
              id="loanAmount"
              label="Repayment Amount"
              type="number"
              fullWidth
              value={loanAmount}
              onChange={(e) => setLoanAmount(e.target.value)}
              // disabled={fundingPart === 'unfunded'}
            />
        </Grid>

        <Grid item xs={12} container justifyContent="center">
          <RadioGroup row value={inputType} onChange={(e) => setInputType(e.target.value)}>
            <FormControlLabel value="quantity" control={<Radio />} label="Enter Quantity" />
            <FormControlLabel value="bags" control={<Radio />} label="Enter Number of Bags" />
          </RadioGroup>
        </Grid>
          {inputType === 'bags' && (
            <>
              <Grid item mx="auto" xs={8}>
                <TextField
                  label="Number of Bags"
                  variant="outlined"
                  fullWidth
                  type="number"
                  value={numBags}
                  onChange={handleChange(setNumBags)}
                />
              </Grid>
            </>
          )}
          {inputType === 'quantity' && (
            <Grid item xs={8} mx="auto">
              <TextField
                label="Quantity (in MT)"
                variant="outlined"
                fullWidth
                type="number"
                value={quantity}
                onChange={handleChange(setQuantity)}
              />
            </Grid>
          )}

          <Grid item xs={6}>
            <TextField
              label={`Average Bag Size: ${avgBagSize.toFixed(3)} kg`}
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={6}>
              <TextField
                label={`WR Number: ${data.attributes.inward_details.wr_reciept_no}`}
                variant="outlined"
                fullWidth
                disabled
              />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={`Number of Bags: ${numBags}`}
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField label={`Quantity: ${quantity} MT`} variant="outlined" fullWidth disabled />
          </Grid>

        {/* <Grid  my={1} xs={3} item justifyContent="center">
                <RadioGroup
                  aria-label="fundingPart"
                  name="fundingPart"
                  value={fundingPart}
                  onChange={handleFundingPartChange}
                  style={{ display: 'flex', justifyContent: 'center' }} // Center the radio buttons horizontally
                >
                  <FormControlLabel value="unfunded" control={<Radio />} label="Complete" />
                  <FormControlLabel value="partial" control={<Radio />} label="Partial" />
                </RadioGroup>
        </Grid> */}
    
        <Grid container spacing={3} my={1} xs={10} mx="auto">

            <Grid item xs={4}>
                <Button startIcon={<MdClose />} variant="outlined" color="secondary" onClick={onClose}>
                Close
                </Button>
            </Grid>
            <Grid item xs={7}>
                <Button endIcon={<MdVerified />} fullWidth variant="contained" color="primary" onClick={handleMarkunfunded}>
                Mark Unfunded
                </Button>
            </Grid>
        </Grid>
          
        </Grid>
      </Popover>
    </Container>
  );
};

export default FundingPopover;
