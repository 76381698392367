import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const useStyles = makeStyles((theme) => ({
  gridItem: {
    cursor: 'pointer',
    transition: 'border 0.3s, background 0.3s',
    '&:hover': {    
      cursor: 'pointer',
      border: '1px solid #ccc',
      background: '#f5f5f5',
    },
  },
  pdfContainer: {
    maxWidth: 250, // Adjust the width as needed
    maxHeight: 350, // Adjust the height as needed
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingTop: '50%'
  },
  outerContainer: {
    position: 'relative',
  },
  popoverContainer: {
    backgroundColor: '#f0f0f0', // Change to your desired background color
    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)', // Change to your desired elevation
  },
}));

const DocumentPreviewGrid = ({ pdfFileName, documentName }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [numPages, setNumPages] = useState(0);

  const handleMouseEnter = async (event) => {
    setAnchorEl(event.currentTarget);
    const doc = await pdfjs.getDocument(require(`../assets/${pdfFileName}`)).promise;
    setNumPages(doc.numPages);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        className={classes.gridItem}
      >
        <div
          className={classes.outerContainer}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleMouseLeave}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            disableRestoreFocus
            className="classes.popoverContainer" // Apply additional styles when open
          >
            <div className={classes.pdfContainer}>
            <Typography color="primary" variant="body1" textAlign="center">{documentName}</Typography>

              <Document
                file={require(`../assets/${pdfFileName}`)}
                loading={<div className={classes.loader}><CircularProgress /></div>}
              >
                {Array.from({ length: numPages }, (_, index) => (
                  <Page key={index} pageNumber={index + 1} width={250} height={350} />
                ))}
              </Document>
            </div>
          </Popover>
        </div>
      </Grid>
    </Grid>
  );
};

export default DocumentPreviewGrid;