import React, { useState, useEffect, useRef } from "react";
import HawkEyeAppBar from "../../components/AppBar";
import DashboardCard from "../../components/DashboardCard";
import AcceptRejectCard from "../../components/AcceptRejectCard";
import StatusCard from "../../components/StatusCard";
import { Autocomplete } from '@mui/material';


import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { MenuList, MenuItem, Divider, Switch, TextField, Button, Chip, FormControlLabel, Checkbox } from "@mui/material";
import Check from "@mui/icons-material/Check";
import ListItemIcon from "@mui/material/ListItemIcon";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Pagination } from '@mui/material';
import { convertToMillion, convertToCommaSeperated } from "../../globals/utils"



import instance from "../../globals/axios";
import { makeStyles } from "@mui/styles";

import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';

const PaginationComponent = ({ data, currentPage, handlePageChange }) => (
  <Grid container justifyContent="center">
      <Grid item>
          <Box textAlign="center">
              <Pagination
                  count={data.pages || 1} 
                  page={currentPage} 
                  onChange={handlePageChange}
              />
          </Box>
      </Grid>
  </Grid>
);

const FilterComponent = (props) => (  
  <Paper elevation={2} sx={{ ml: 3, p: 3, mt: 8 }} style={{ borderRadius: "10px", top: "65px", bottom: "0px", width: "20%", position: "fixed", overflow: "scroll" }}>
    <Box fullWidth>
      <MenuList dense>
          <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant='h6'>Filters</Typography>
          <Button
            color='error'
            variant='outlined'
            onClick={() => {
              props.handleClearFilters();
            }}
          >
            Clear
          </Button>
        </Grid>
        <Divider sx={{ my: 2 }} />
       
        <Typography variant='subtitle1' sx={{ fontWeight: "bold" }}>
          Inward period
        </Typography>
       
        <Box m={1}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div>
              <Typography ml={1} variant='subtitle1' sx={{ fontWeight: "bold" }}>
                From
              </Typography>
              <Box m={1}>
                <DatePicker
                  value={props.inwardDateFrom}
                  onChange={(date) => props.setInwardDateFrom(date)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Typography ml={1} variant='subtitle1' sx={{ fontWeight: "bold" }}>
                to
              </Typography>
              <Box m={1} mb={2}>
                <DatePicker
                  value={props.inwardDateTo}
                  onChange={(date) => props.setInwardDateTo(date)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </div>
          </LocalizationProvider>
        </Box>

        <Divider />
        

        <Box my={2}>
          <Autocomplete
            fullWidth
            options={props.warehouseOptions}
            getOptionLabel={(option) => option.wh_name}
            value={props.selectedWarehouse?.wh_pid === null ? null : props.selectedWarehouse}
            onChange={props.handleWarehouseChange}
            disableClearable
            renderInput={(params) => <TextField {...params} label="Warehouse Name" variant="outlined" />}
          />
        </Box>

        <Divider />
 

        <Box my={2}>
          <Autocomplete
            fullWidth
            options={props.commodityOptions}
            getOptionLabel={(option) => option.commodity_name}
            value={props.selectedCommodity?.com_id === null ? null : props.selectedCommodity}
            onChange={props.handleCommodityChange}
            disableClearable
            renderInput={(params) => <TextField {...params} label="Commodity" variant="outlined" />}
          />
        </Box>

        <Divider />

        <Box my={2}>
          <Autocomplete
            fullWidth
            options={props.locations}
            getOptionLabel={(option) => option.location}
            value={props.selectedLocation?.location === null ? null : props.selectedLocation}
            onChange={props.handleLocationSelect}
            disableClearable
            renderInput={(params) => (
              <TextField 
                {...params} 
                label="Location" 
                variant="outlined" 
                defaultValue={props.selectedLocation ? props.selectedLocation.location : "Select a Location"} // Set the placeholder here
              />
            )}
          />
        </Box>

        <Button variant='contained' fullWidth color='primary' onClick={props.handleApplyFilters}>
          <Typography variant='subtitle1' color='white'>
            Apply Filters
          </Typography>
        </Button>

      </MenuList>
    </Box>
  </Paper>
);

const HeaderComponent = ({ data, tokenCount, tokenQuantity, commodityCount }) => 
{
  const customChipClass = useStyles();

  return (
    <Grid container justifyContent="center">
    <Grid md={4}></Grid>
    {/* {commodityCount !== null && commodityCount !== "" && tokenQuantity !== null && tokenQuantity !== "" && ( */}
      <Grid container xs={12} md={8} mx='auto' sx={{ textAlign: "center" }} mb={0}>
        <Grid container mx="auto" sx={{ textAlign: "center" }}>
          <Grid item xs={2}>
            <Typography variant='body1' sx={{ textAlign: "right", fontWeight: "700" }}>
              Recently Added:
            </Typography>
          </Grid>
          <Grid item ml={2} sx={{ borderRight: "1px solid black" }} xs={2}>
            <Typography variant='body1'>
              Commodities
            </Typography>
            <Chip color='success' sx={{ color: "#fff" }} className={customChipClass.chipCustom} component='box' mx={2} variant='h6' label={convertToCommaSeperated(commodityCount)} />
          </Grid>
          <Grid item ml={2} sx={{ borderRight: "1px solid black" }} xs={3}>
            <Typography variant='body1'>
              Quantity
            </Typography>
            <Chip color='success' sx={{ color: "#fff" }} className={customChipClass.chipCustom} component='box' mx={2} variant='h6' label={`${(tokenQuantity / 1000).toLocaleString('en-IN', { maximumFractionDigits: 2 })} MT`} />
          </Grid>
          <Grid item ml={2} xs={2}>
            <Typography variant='body1'>
              Tokens 
            </Typography>
            <Chip color='success' sx={{ color: "#fff" }} className={customChipClass.chipCustom} component='box' mx={2} variant='h6' label={convertToCommaSeperated(tokenCount)} />
          </Grid>
        </Grid>
      </Grid>
    {/* )} */}
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  chipCustom: {
    margin: "0 1rem",
    borderRadius: "9px", //some style
    "& .MuiChip-label": { fontSize: 14, fontWeight: "bold" }, // sub-selector
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.6em",
    },
    "*::-webkit-scrollbar-track": {
      borderRadius: "15px",
      backgroundColor: "#f8f9fd",
    },
    "*::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "#c1c1c1",
    },
    "*::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#214383",
    },
  },
}));

const OperationsScreen = () => {
  const linkStyle = {
    textDecoration: "none", // Remove link decoration
    // Add any additional custom styling as needed
  };


    const customChipClass = useStyles();

    const [tabvalue, setTabvalue] = useState(0);
    const verifyTokenBody = {
      status: [0],
      page: 0,
      limit: 20,
    };

    const applyLoanBody = {
      loan_status: 1,
      status: [1],
      page: 0,
      limit: 10,
      fundable: [1],
      lien_status: [0]
    };

    const lienStatusBody = {
      lien_status: [1],
      page: 0,
      limit: 10,
    };

    const fundedDataReqBody = {
      fundable: [1],
      lien_status: [1],
      page: 0,
      limit: 10,
    };

    const markedFundedDataReqBody = {
      fundable: [0],
      lien_status: [1],
      page: 0,
      limit: 10,
    };

    const [inwardDateFrom, setInwardDateFrom] = useState(null);
    const [inwardDateTo, setInwardDateTo] = useState(null);
    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [commodityOptions, setCommodityOptions] = useState([]);
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState({ location: null});
    const [selectedWarehouse, setSelectedWarehouse] = useState({ wh_pid: null });
    const [selectedCommodity, setSelectedCommodity] = useState({ com_id: null });

    useEffect(() => {

        const fetchDataFilter = async () => {
          try {
            const response = await instance.get('wr/getFilterData');
            const { warehouse_data, commodity_data, location } = response.data.data[0];
            setWarehouseOptions(warehouse_data);
            setCommodityOptions(commodity_data);
            setLocations(location);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };

        fetchDataFilter();

      return;


    }, []);

    const handleWarehouseChange = (_, value) => setSelectedWarehouse(value);
    const handleCommodityChange = (_, value) => setSelectedCommodity(value);
    const handleLocationSelect = (_, value) => setSelectedLocation(value)


    const [verifyData, setVerifyData] = useState({});
    const [applyLoanData, setApplyLoanData] = useState({});
    const [lienStatusData, setLienStatusData] = useState({});
    const [fundedData, setFundedData] = useState({});
    const [markedFundedData, setMarkedFundedData] = useState({});

    const [loading, setLoading] = useState(true);
    const [loading1, setLoading1] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [loading3, setLoading3] = useState(true);
    const [loading4, setLoading4] = useState(true);
    const [loading5, setLoading5] = useState(true);


    
    // Fetching verify WR data
    const [currentPageVerifyData, setCurrentPageVerifyData] = useState(0);

    const fetchVerifyData = async (page) => {
      try {
        setLoading1(true)
        const res = await instance.post("getTokenDataForUsers", {
          ...verifyTokenBody,
          // page: page - 1, 
          page: page,
          ...(inwardDateFrom && inwardDateTo && { inward_date_from: inwardDateFrom, inward_date_to: inwardDateTo }),
          ...(selectedWarehouse.wh_pid && { wh_pid: [parseInt(selectedWarehouse.wh_pid)] }),
          ...(selectedCommodity.com_id && { commodity_id: [selectedCommodity.com_id] }),
          ...(selectedLocation.location && { location: [selectedLocation.location] } )
        });
        setVerifyData(res.data);
        setLoading1(false)
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      if (currentPageVerifyData===0) {
        setCurrentPageVerifyData(1)
        return;
      }
      fetchVerifyData(currentPageVerifyData);
    }, [currentPageVerifyData]);

    const handlePageChangeVerifyData = (event, page) => {
      setCurrentPageVerifyData(page);
    };

    // Fetch Un Lien marked WR data
    const [currentPageApplyLoanData, setCurrentPageApplyLoanData] = useState(0);

    const fetchApplyLoanData = async (page) => {
      try {
        setLoading(true)
        const res = await instance.post("getTokenDataForUsers", {
          ...applyLoanBody,
          // page: page - 1, 
          page: page,
          ...(inwardDateFrom && inwardDateTo && { inward_date_from: inwardDateFrom, inward_date_to: inwardDateTo }),
          ...(selectedWarehouse.wh_pid && { wh_pid: [parseInt(selectedWarehouse.wh_pid)] }),
          ...(selectedCommodity.com_id && { commodity_id: [selectedCommodity.com_id] }),
          ...(selectedLocation.location && { location: [selectedLocation.location] } )
        });
        setApplyLoanData(res.data);
        setLoading(false)
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      if (currentPageApplyLoanData===0) {
        setCurrentPageApplyLoanData(1)
        return;
      }
      fetchApplyLoanData(currentPageApplyLoanData);
    }, [currentPageApplyLoanData]);

    const handlePageChangeApplyLoanData = (event, page) => {
      setCurrentPageApplyLoanData(page);
    };

    // Fetch Lien marked WR
    const [currentPageLienStatusData, setCurrentPageLienStatusData] = useState(0);

    const fetchLienStatusData = async (page) => {
      try {
        setLoading(true)
        const res = await instance.post("getTokenDataForUsers", {
          ...lienStatusBody,
          // page: page - 1, 
          page: page,
          ...(inwardDateFrom && inwardDateTo && { inward_date_from: inwardDateFrom, inward_date_to: inwardDateTo }),
          ...(selectedWarehouse.wh_pid && { wh_pid: [parseInt(selectedWarehouse.wh_pid)] }),
          ...(selectedCommodity.com_id && { commodity_id: [selectedCommodity.com_id] }),
          ...(selectedLocation.location && { location: [selectedLocation.location] } )
        });
        setLienStatusData(res.data);
        setLoading(false)
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      if (currentPageLienStatusData===0) {
        setCurrentPageLienStatusData(1)
        return;
      }
      fetchLienStatusData(currentPageLienStatusData);
    }, [currentPageLienStatusData]);

    const handlePageChangeLienStatusData = (event, page) => {
      setCurrentPageLienStatusData(page);
    };

    // Fetch Funded data
    const [currentPageFundedData, setCurrentPageFundedData] = useState(0);

    const fetchFundedData = async (page) => {
      try {
        setLoading(true)
        const res = await instance.post("getTokenDataForUsers", {
          ...fundedDataReqBody,
          // page: page - 1, 
          page: page,
          ...(inwardDateFrom && inwardDateTo && { inward_date_from: inwardDateFrom, inward_date_to: inwardDateTo }),
          ...(selectedWarehouse.wh_pid && { wh_pid: [parseInt(selectedWarehouse.wh_pid)] }),
          ...(selectedCommodity.com_id && { commodity_id: [selectedCommodity.com_id] }),
          ...(selectedLocation.location && { location: [selectedLocation.location] } )
        });
        setFundedData(res.data);
        setLoading(false)
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      if (currentPageFundedData===0) {
        setCurrentPageFundedData(1)
        return;
      }
      fetchFundedData(currentPageFundedData);
    }, [currentPageFundedData]);

    const handlePageChangeFundedData = (event, page) => {
      setCurrentPageFundedData(page);
    };

    // Fetch data of WR marked as Funded
    const [currentPageMarkedFundedData, setCurrentPageMarkedFundedData] = useState(0);

    const fetchMarkedFundedData = async (page) => {
      try {
        const res = await instance.post("getTokenDataForUsers", {
          ...markedFundedDataReqBody,
          // page: page - 1, 
          page: page,
          ...(inwardDateFrom && inwardDateTo && { inward_date_from: inwardDateFrom, inward_date_to: inwardDateTo }),
          ...(selectedWarehouse.wh_pid && { wh_pid: [parseInt(selectedWarehouse.wh_pid)] }),
          ...(selectedCommodity.com_id && { commodity_id: [selectedCommodity.com_id] }),
          ...(selectedLocation.location && { location: [selectedLocation.location] } )
        });
        setMarkedFundedData(res.data);
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      if (currentPageMarkedFundedData===0) {
        setCurrentPageMarkedFundedData(1)
        return;
      }
      fetchMarkedFundedData(currentPageMarkedFundedData);
    }, [currentPageMarkedFundedData]);

    const handlePageChangeMarkedFundedData = (event, page) => {
      setCurrentPageMarkedFundedData(page);
    };

    const handleApplyFilters = () => {
      fetchVerifyData(currentPageVerifyData);
      fetchApplyLoanData(currentPageApplyLoanData);
      fetchLienStatusData(currentPageLienStatusData);
      fetchFundedData(currentPageFundedData);
      fetchMarkedFundedData(currentPageMarkedFundedData);
    };

    const handleClearFilters = () => {
      setInwardDateFrom(null);
      setInwardDateTo(null);
      setSelectedWarehouse({ wh_pid: null });
      setSelectedCommodity({ com_id: null });
      setSelectedLocation({ location: null });
    }

    const isFirstRender = useRef(true);

    useEffect(() => {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }
    
      if (selectedWarehouse.wh_pid === null && selectedCommodity.com_id === null && selectedLocation.location === null) {
        fetchVerifyData(currentPageVerifyData);
        fetchApplyLoanData(currentPageApplyLoanData);
        fetchLienStatusData(currentPageLienStatusData);
        fetchFundedData(currentPageFundedData);
        fetchMarkedFundedData(currentPageMarkedFundedData);
      }
    }, [selectedWarehouse, selectedCommodity, selectedLocation]);



    const [tokenCount, setTokenCount] = useState(null);
    const [commodityCount, setCommodityCount] = useState(null);
    const [tokenQuantity, setTokenQuantity] = useState(null);


    const fetchHeaderData = (reqBody) => {
      instance.post('/wr/getHeaderData', reqBody)
        .then((response) => {
          const { token_count, commodity_count, token_quantity } = response.data.data[0];
    
          setTokenCount(token_count);
          setCommodityCount(commodity_count);
          setTokenQuantity(token_quantity);

        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    };

    const handleChange = (event, newValue) => {
        setTabvalue(newValue);
    };

    useEffect(() => {
      let reqBody;

      if (tabvalue === 0) {
        reqBody = verifyTokenBody;
      } else if (tabvalue === 1) {
        reqBody = applyLoanBody;
      } else if (tabvalue === 2) {
        reqBody = fundedDataReqBody;
      } else if (tabvalue === 3) {
        reqBody = lienStatusBody;
      } else if (tabvalue === 4) {
        reqBody = markedFundedDataReqBody;
      }

      fetchHeaderData(reqBody);
    }, [tabvalue]);
  
    {/* <Tab sx={{ fontSize: "1.15rem" }} label='Mark Lien' /> */}


    return (
      <div>
        <HawkEyeAppBar />
        <Grid container style={{ marginTop: "5rem" }}>
          <Grid xs={12} md={3}></Grid>
          <Grid xs={12} md={9} mx='auto'>
            <Box sx={{ bgcolor: "background.paper", padding: "2.5rem 0px" }}>
              <Tabs value={tabvalue} onChange={handleChange} centered outlined>
                <Tab sx={{ fontSize: "1.15rem" }} label='Verify Tokens' />
                <Tab sx={{ fontSize: "1.15rem" }} label='Mark Funded' />
                <Tab sx={{ fontSize: "1.15rem" }} label='Lien Status' />
                <Tab sx={{ fontSize: "1.15rem" }} label='View Funded' />
              </Tabs>
            </Box>
          </Grid>
        </Grid>

        { loading &&
          (
            <div>
              <Box sx={{ my:"auto", mx:"auto", display: 'flex' }}>
                <CircularProgress 
                  sx={{ mt:"5rem", mx:"auto", display: 'flex' }}
                    size={60} 
                />
              </Box>
              <Grid sx={{ ml:"45%"}}>
                <Typography variant="h5" sx={{ mt:"1rem", mx:"auto", display: 'flex' }} >
                Fetching tokens ...
                </Typography>
              </Grid>
            </div>
            )
        }

        {
          loading ? null : 
          (
          <Box mb="3rem">

          {
          tabvalue === 0 && (
          <Grid container spacing={2}>
          
          <HeaderComponent tokenCount={tokenCount} commodityCount={commodityCount} tokenQuantity={tokenQuantity} />

            <Grid item xs={3}>
            <FilterComponent 
              handleClearFilters={handleClearFilters} handleApplyFilters={handleApplyFilters}
              warehouseOptions={warehouseOptions} selectedWarehouse={selectedWarehouse} handleWarehouseChange={handleWarehouseChange} 
              commodityOptions={commodityOptions} selectedCommodity={selectedCommodity} handleCommodityChange={handleCommodityChange}
              locations={locations} selectedLocation={selectedLocation} handleLocationSelect={handleLocationSelect}
              setInwardDateFrom={setInwardDateFrom} setInwardDateTo={setInwardDateTo} inwardDateFrom={inwardDateFrom} inwardDateTo={inwardDateTo}
            />
            </Grid>

            <Grid item xs={9}>
            { loading1 ?
              (
                <div>
                  <Box sx={{ my:"auto", mx:"auto", display: 'flex' }}>
                    <CircularProgress 
                      sx={{ mt:"5rem", mx:"auto", display: 'flex' }}
                        size={60} 
                    />
                  </Box>
                  <Grid sx={{ ml:"45%"}}>
                    <Typography variant="h5" sx={{ mt:"1rem", mx:"auto", display: 'flex' }} >
                    Fetching tokens ...
                    </Typography>
                  </Grid>
                </div>
              ) : (

              <React.Fragment>

                <Paper elevation={0} sx={{ m: 2, px: 3 }}>
                  {Array.isArray(verifyData.data) &&
                    verifyData.data.map((loanRequest, index) => (
                      <Link key={index} to={`/details/${index}`} state={{ data: loanRequest }} style={linkStyle}>
                      
                        <StatusCard status={loanRequest.attributes.status} data={loanRequest} />

                      </Link>
                    ))}
                </Paper>

                {(Array.isArray(verifyData.data) && verifyData.data.length > 0) && (

                  <PaginationComponent data={verifyData} currentPage={currentPageVerifyData} handlePageChange={handlePageChangeVerifyData} />

                )}
              </React.Fragment>
              )
            }
            


            </Grid>
          </Grid>
          )}

          {/* {
            tabvalue === 1 && (
            <Grid container spacing={2}>
              <HeaderComponent tokenCount={tokenCount} commodityCount={commodityCount} tokenQuantity={tokenQuantity} />


              <Grid item xs={3}>
              <FilterComponent 
                handleClearFilters={handleClearFilters} handleApplyFilters={handleApplyFilters}
                warehouseOptions={warehouseOptions} selectedWarehouse={selectedWarehouse} handleWarehouseChange={handleWarehouseChange} 
                commodityOptions={commodityOptions} selectedCommodity={selectedCommodity} handleCommodityChange={handleCommodityChange}
                locations={locations} selectedLocation={selectedLocation} handleLocationSelect={handleLocationSelect}
                setInwardDateFrom={setInwardDateFrom} setInwardDateTo={setInwardDateTo} inwardDateFrom={inwardDateFrom} inwardDateTo={inwardDateTo}
              />
              </Grid>
           

              <Grid item xs={9}>
                <Paper elevation={0} sx={{ m: 2, px: 3 }}>
                    {Array.isArray(applyLoanData.data) &&
                    applyLoanData.data.map((item, index) => (
                      <Link key={index} to={`/mark-lien-details/${index}`} state={{ data: item }} style={linkStyle}>
                        <Box>
                          <StatusCard status={item.attributes.status} fundable={true} applyLoan={false} data={item} />
                        </Box>
                      </Link>
                    ))}
                </Paper>

                { (Array.isArray(applyLoanData.data) && applyLoanData.data.length > 0) && (

                  <PaginationComponent
                      data={applyLoanData}
                      currentPage={currentPageApplyLoanData}
                      handlePageChange={handlePageChangeApplyLoanData}
                  />
                )}

              </Grid>
            </Grid>
          )} */}

          {tabvalue === 1 && (
            <Grid container spacing={2}>
              <HeaderComponent tokenCount={tokenCount} commodityCount={commodityCount} tokenQuantity={tokenQuantity} />


              <Grid item xs={3}>
                <FilterComponent 
                  handleClearFilters={handleClearFilters} handleApplyFilters={handleApplyFilters}
                  warehouseOptions={warehouseOptions} selectedWarehouse={selectedWarehouse} handleWarehouseChange={handleWarehouseChange} 
                  commodityOptions={commodityOptions} selectedCommodity={selectedCommodity} handleCommodityChange={handleCommodityChange}
                  locations={locations} selectedLocation={selectedLocation} handleLocationSelect={handleLocationSelect}
                  setInwardDateFrom={setInwardDateFrom} setInwardDateTo={setInwardDateTo} inwardDateFrom={inwardDateFrom} inwardDateTo={inwardDateTo}
                />
              </Grid>
    
              <Grid item xs={9}>
                <Paper elevation={0} sx={{ m: 2, px: 3 }}>
                  {Array.isArray(fundedData.data) &&
                    fundedData.data.map((loan, index) => (
                      <Link key={index} to={`/ops-mark-funded/${index}`} state={{ data: loan }} style={linkStyle}>
                        <Box>
                          <StatusCard status={loan.attributes.status} lienMarked={true} markFunded={true} data={loan} />
                        </Box>
                      </Link>
                    ))}
                </Paper>

                {(Array.isArray(fundedData.data) && fundedData.data.length > 0) && (

                  <PaginationComponent
                    data={fundedData}
                    currentPage={currentPageFundedData}
                    handlePageChange={handlePageChangeFundedData}
                  />              
                )}

              </Grid>
            </Grid>
          )}

          {
            tabvalue === 2 && (
            <Grid container spacing={2}>
              <HeaderComponent tokenCount={tokenCount} commodityCount={commodityCount} tokenQuantity={tokenQuantity} />


              <Grid item xs={3}>
              <FilterComponent 
                handleClearFilters={handleClearFilters} handleApplyFilters={handleApplyFilters}
                warehouseOptions={warehouseOptions} selectedWarehouse={selectedWarehouse} handleWarehouseChange={handleWarehouseChange} 
                commodityOptions={commodityOptions} selectedCommodity={selectedCommodity} handleCommodityChange={handleCommodityChange}
                locations={locations} selectedLocation={selectedLocation} handleLocationSelect={handleLocationSelect}
                setInwardDateFrom={setInwardDateFrom} setInwardDateTo={setInwardDateTo} inwardDateFrom={inwardDateFrom} inwardDateTo={inwardDateTo}
              />
              </Grid>

              <Grid item xs={9}>
                <Paper elevation={0} sx={{ m: 2, px: 3 }}>
                    {Array.isArray(lienStatusData.data) &&
                    lienStatusData.data.map((item, index) => (
                      <Link key={index} to={`/ops-details/${index}`} state={{ data: item }} style={linkStyle}>
                        <Box>
                          <StatusCard lien_status="Lien Marked" data={item} />
                        </Box>
                      </Link>
                    ))}
                </Paper>

                {(Array.isArray(lienStatusData.data) && lienStatusData.data.length > 0) && (
                  
                  <PaginationComponent
                    data={lienStatusData}
                    currentPage={currentPageLienStatusData}
                    handlePageChange={handlePageChangeLienStatusData}
                  />
                  )}
              </Grid>
            </Grid>
          )}

          {tabvalue === 3 && (
            <Grid container spacing={2}>
              <HeaderComponent tokenCount={tokenCount} commodityCount={commodityCount} tokenQuantity={tokenQuantity} />

    
              <Grid item xs={3}>
                <FilterComponent 
                  handleClearFilters={handleClearFilters} handleApplyFilters={handleApplyFilters}
                  warehouseOptions={warehouseOptions} selectedWarehouse={selectedWarehouse} handleWarehouseChange={handleWarehouseChange} 
                  commodityOptions={commodityOptions} selectedCommodity={selectedCommodity} handleCommodityChange={handleCommodityChange}
                  locations={locations} selectedLocation={selectedLocation} handleLocationSelect={handleLocationSelect}
                  setInwardDateFrom={setInwardDateFrom} setInwardDateTo={setInwardDateTo} inwardDateFrom={inwardDateFrom} inwardDateTo={inwardDateTo}
                />
              </Grid>
    
              <Grid item xs={9}>
                <Paper elevation={0} sx={{ m: 2, px: 3 }}>
                  {Array.isArray(markedFundedData.data) &&
                   markedFundedData.data.map((loanRequest, index) => (
                    // To change
                      <Link key={index} to={`/ops-details/${index}`} state={{ data: loanRequest }} style={linkStyle}>
                        <Box>
                          <StatusCard status={loanRequest.attributes.status} tokenFunded={true} data={loanRequest} />
                        </Box>
                      </Link>
                    ))}
                </Paper>

                {(Array.isArray(markedFundedData.data) && markedFundedData.data.length > 0) && (
                  
                  <PaginationComponent
                    data={markedFundedData}
                    currentPage={currentPageMarkedFundedData}
                    handlePageChange={handlePageChangeMarkedFundedData}
                  />
                  )}

              </Grid>
            </Grid>
          )}
          </Box>

          )
        }
        
      </div>
    );
};

export default OperationsScreen;
