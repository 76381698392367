import React from 'react';
import './App.css';
import AppScreen from './screens/AppScreen';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Colour codes captured via color picker from staragri main site
const theme = createTheme({
  // typography: {
  //  "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
  // //  "fontSize": 14,
  // //  "fontWeightLight": 300,
  // //  "fontWeightRegular": 400,
  // //  "fontWeightMedium": 500
  // },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      // main: '#0e4476'
      main: '#214383'
    },
    secondary: {
      main: '#9E9E9E'
    },
    error: {
      main: '#f44336'
    },
    success: {
      main: '#33af4c'
    },
    gradient: {
      main: 'linear-gradient(to right bottom, #ffffff, #f9f8fd, #f1f2fa, #eaecf8, #e1e6f6, #ced6ec, #bbc6e1, #a7b6d7, #8698c2, #657aad, #455e98, #214383)',
    }
  }
});


// For the plugin to work kindly add a file .env.production
//  .env.production
//   > INLINE_RUNTIME_CHUNK=false ( Content of the file)


function App() {
  return (
    
      <ThemeProvider theme={theme}>
        <AppScreen />
      </ThemeProvider>
  );
}

export default App;
