import React, { useState, useEffect } from 'react';
import { Popover, Grid, TextField, Button, Backdrop, Container, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { MdClose, MdVerified } from 'react-icons/md';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import instance from '../../globals/axios';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {AdapterDayjs}  from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers';
import dayjs from 'dayjs'; // Import dayjs


const apiEndpoint = 'https://ewrapi.staragri.com/api/agri/ft/mark_funded';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backdropFilter: 'blur(5px)',
  },
  popover: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
}));

const FundingPopover = ({ open, onClose, data, user }) => {
  const classes = useStyles();
  const [roi, setRoi] = useState(null);
  const [loanAmount, setLoanAmount] = useState(0);
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const handleLoanAmountChange = (e) => {
    const input = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    const formattedInput = formatIndianNumber(input); // Format input with commas
    setLoanAmount(formattedInput);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const formatIndianNumber = (number) => {
    const formattedNumber = Number(number)
      .toFixed(0)
      .replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    return formattedNumber;
  };

  const handleMarkFunded = async () => {
    try {
      // Sanction Limit logic Removed
      // if(loanAmount>data.current_sanction_limit[0].current_sanction_limit){
      //   alert("Amount exceeds Sanction Limit!");
      //   return;
      // }
      const isValidRoi = /^\d{1,3}(\.\d{1,3})?$/.test(roi.trim()) && parseFloat(roi.trim()) >= 1 && parseFloat(roi.trim()) <= 100;
      if (!isValidRoi) {
        alert("Please enter a valid Rate of Interest (a positive number with up to three decimal places between 1 and 100)!");
        return;
      }

      const formattedDate = selectedDate.format('YYYY-MM-DD');
      instance.post('/ft/mark_funded', {
        wr_reciept_no: data.attributes.inward_details.wr_reciept_no,
        action: 'funded',
        roi,
        loan_amount: loanAmount.replace(/\D/g, ''), 
        date: formattedDate,
      })
      .then((response) => {
        alert("Marked Funded succesfully!");
        if(user==="ops"){
          setTimeout(function () {
            window.location.replace("/operations");
          }, 500);
        }
        else{
          setTimeout(function () {
            window.location.replace("/banker");
          }, 500);
        }
        
        onClose(); 
      })
      .catch((error) => {
        console.error('API Error:', error);
      });

      
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  const initialNumBags = data.attributes.truck_details[0]?.bag_details[0]?.number || 0;
  const initialQuantity = data.pledged_amount ? data.pledged_amount / 1000 : data.attributes.inward_details.inward_weight / 1000;

  const [inputType, setInputType] = useState('quantity');
  const [numBags, setNumBags] = useState(initialNumBags);
  const [quantity, setQuantity] = useState(initialQuantity);
  const [avgBagSize, setAvgBagSize] = useState((initialQuantity * 1000) / initialNumBags);

  useEffect(() => {
    if (inputType === 'bags' && avgBagSize > 0) {
      setQuantity((numBags * avgBagSize * 0.001).toFixed(3));
    }
  }, [numBags, avgBagSize, inputType]);

  useEffect(() => {
    if (inputType === 'quantity' && avgBagSize > 0) {
      setNumBags((quantity * 1000) / avgBagSize);
    }
  }, [quantity, avgBagSize, inputType]);

  const handleChange = (setter) => (e) => setter(e.target.value);


  return (
    <Container>
      <Backdrop open={open} onClick={onClose} />
      <Popover
        open={open}
        sx={{
          mt: '15%',
          mx:"auto",
          width: '80%', 
          maxWidth: '600px', 
        }}
        anchorReference="anchorPosition"
        anchorPosition={{ top: '50%', left: '50%' }}
        transformOrigin={{ vertical: 'center', horizontal: 'center' }}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      >
        <Grid container p={4} spacing={2} className={classes.popover}>
        <Grid item xs={12}>
            <TextField
              label={`Token Code: ${data.token_code}`}
              variant="outlined"
              fullWidth
              disabled
            />
        </Grid>
        
        <Grid item xs={6}>
            <TextField
              label={`WR Number: ${data.attributes.inward_details.wr_reciept_no}`}
              variant="outlined"
              fullWidth
              disabled
            />
        </Grid>
        
          <Grid item xs={6}>
            <TextField
              label={`Average Bag Size: ${avgBagSize.toFixed(3)} kg`}
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={`Number of Bags: ${numBags}`}
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField label={`Quantity: ${quantity} MT`} variant="outlined" fullWidth disabled />
          </Grid>

          <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Select Date"
              value={selectedDate}
              onChange={handleDateChange}
              renderInput={(params) => <TextField fullWidth {...params} variant="outlined" />}
              className={classes.datePicker}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={6}>
            <TextField
              label="Rate of Interest ( in percentage per annum ) "
              variant="outlined"
              fullWidth
              type='number'
              value={roi}
              onChange={(e) => setRoi(e.target.value)}
            />
        </Grid>

          <Grid item xs={12}>
            <TextField
              label="Disbursed Amount (in INR)"
              variant="outlined"
              fullWidth
              type="text" // Change type to text to allow custom formatting
              value={loanAmount}
              onChange={handleLoanAmountChange}
            />
          </Grid>
         
        <Grid container spacing={3} my={1} xs={10} mx="auto">

            <Grid item xs={4}>
                <Button startIcon={<MdClose />} variant="outlined" color="secondary" onClick={onClose}>
                Close
                </Button>
            </Grid>
            <Grid item xs={7}>
                <Button endIcon={<MdVerified />} fullWidth variant="contained" color="primary" onClick={handleMarkFunded}>
                Mark Funded
                </Button>
            </Grid>
        </Grid>
          
        </Grid>
      </Popover>
    </Container>
  );
};

export default FundingPopover;
