import axios from "axios";
import { getBlockchainNodeUrl, SERVER_BASE_ADDRESS } from "./const";
import React from "react";
import { isAuthenticated } from "./authentication";

export const getFromBackend = (url, useAuth = true) => {
  // url = url.replace('&', '%26');
  // console.log(url);
  const token = window.localStorage.getItem("token");

  var headers;
  if (useAuth) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
    };
  }

  return axios.get(SERVER_BASE_ADDRESS + url, { headers: headers }).then((result) => result.data);
  // .catch(e => {if(e.response.status === 401){
  //   alert('Session expired. Please login again.');
  //   window.location.href='login';
  // }})
};

export const postToBackend = (url, data) => {
  const token = window.localStorage.getItem("token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Token " + token,
  };

  return axios.post(SERVER_BASE_ADDRESS + url, data, { headers: headers }).then((result) => result.data);
  // .catch(e => {if(e.response.status === 401){
  //   alert('Session expired. Please login again.');
  //   window.location.href='login';
  // }})
};

// export function logout() {
//   window.sessionStorage.removeItem("auth");
//   window.sessionStorage.removeItem("is_QC_ashish");
//   window.localStorage.removeItem("deliverChallenInward");
//   window.localStorage.removeItem("welcomeUser");
//   window.location = "/";
// }
export function logout() {
  window.sessionStorage.clear();
  window.localStorage.clear();
  window.open("/login", "_self");
}

export function ValidateEmail(mail) {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  alert("You have entered an invalid email address!");
  return false;
}

export function isLoggedIn() {
  return window.sessionStorage.getItem("auth") !== undefined && window.sessionStorage.getItem("auth") !== null;
}

export function saveAuth(token) {
  if (token !== undefined) saveToLocal("auth", token);
  else window.sessionStorage.removeItem("auth");
}

export function saveRole(role) {
  if (role !== undefined) saveToLocal("user_role", role);
  else window.sessionStorage.removeItem("user_role");
}

export function saveToLocal(key, value) {
  window.sessionStorage.setItem(key, JSON.stringify(value));
}

export function getToken() {
  let authJson = window.sessionStorage.getItem("auth");
  if (authJson !== null && authJson !== undefined) {
    let auth = JSON.parse(authJson);
    return auth["token"];
  }
  return null;
}

export function convertToMillion(value) {
  if (value == null) {
    return ''; // or any default value you prefer
  }

  const millionValue = value / 1000000;
  const formattedValue = millionValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return formattedValue;
}

export function convertToCommaSeperated(value) {
  if (value == null) {
    return ''; // or any default value you prefer
  }

  return value.toLocaleString('en-IN');
}