import React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import makeStyles from '@mui/styles/makeStyles';
import Container from "@mui/material/Container";
import { useState } from "react";
import Copyright from "../components/Copyright";
import { HOME_PAGE } from "../globals/const";
import { SERVER_BASE_ADDRESS } from "../globals/const";
import { logout, saveAuth, saveRole } from "../globals/utils";
import instance from "../globals/axios.js";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  row: {
    marginTop: theme.spacing(1),
  },
}));

export default function ChangePassword() {
  const classes = useStyles();
  const [password, setPassword] = useState(null);

  async function login(event) {
    event.preventDefault();
    if (!password) {
      alert("Enter details");
      return;
    }
    instance
      .post(`${SERVER_BASE_ADDRESS}/auth/change-pass`, {
        password: password,
      })
      .then((result) => {
        logout();
      })
      .catch((err) => {
        alert("Error - " + err.response.data.non_field_errors[0]);
      });
  }

  return (
    <Container
      component="main"
      maxWidth="xs"
      style={{ backgroundColor: "white" }}
    >
      <CssBaseline />
      <div className={classes.paper}>
        <img
          alt="asqi"
          src={HOME_PAGE + "/hyfun_logo.png"}
          style={{ width: "65%" }}
        />
        <form className={classes.form} noValidate onSubmit={login}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="Enter New Password"
            name="password"
            autoComplete="password"
            type={"password"}
            autoFocus
            onChange={(event) => setPassword(event.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Update Password
          </Button>
        </form>
      </div>

      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
