import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Grid, Box, Typography, Paper, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DescriptionIcon from '@mui/icons-material/Description';
import instance from "../globals/dashboard_instance";



const fetchDisbursementData = async (branch, page, limit) => {
    const branchEncoded = (branch);
    const response = await instance.get(`/dashboard/whr-details-disb-rep`, {
        params: {
            branch: branchEncoded,
            type: 'disbursement',
            page: page,
            limit: limit,
        },
    });
    return response.data;
};

const fetchRepaymentData = async (branch, page, limit) => {
    const branchEncoded = (branch);
    const response = await instance.get(`/dashboard/whr-details-disb-rep`, {
        params: {
            branch: branchEncoded,
            type: 'repayment',
            page: page,
            limit: limit,
        },
    });
    return response.data;
};

const BankerAdminViewWRs = ({ branchSelected }) => {
    const [disbursementData, setDisbursementData] = useState([]);
    const [repaymentData, setRepaymentData] = useState([]);
    const [loadingDisbursement, setLoadingDisbursement] = useState(true);
    const [loadingRepayment, setLoadingRepayment] = useState(true);
    const [disbursementPage, setDisbursementPage] = useState(0);
    const [disbursementPageSize, setDisbursementPageSize] = useState(20);
    const [disbursementRowCount, setDisbursementRowCount] = useState(0);
    const [repaymentPage, setRepaymentPage] = useState(0);
    const [repaymentPageSize, setRepaymentPageSize] = useState(20);
    const [repaymentRowCount, setRepaymentRowCount] = useState(0);

    useEffect(() => {
        const getDisbursementData = async () => {
            if (branchSelected !== "") {
                setLoadingDisbursement(true);
                try {
                    const result = await fetchDisbursementData(branchSelected, disbursementPage, disbursementPageSize);
                    const dataWithId = result.data.disbursement_data.map((item, index) => ({
                        id: `${item.wr_no}-${index}`,
                        ...item
                    }));
                    setDisbursementData(dataWithId);
                    setDisbursementRowCount(result.data.disbursement_data.length);
                } catch (error) {
                    console.error('Error fetching disbursement data:', error);
                    setDisbursementData([]);
                    setDisbursementRowCount(0);
                }
                setLoadingDisbursement(false);
            } else {
                setDisbursementData([]);
                setDisbursementRowCount(0);
            }
        };
        getDisbursementData();
    }, [branchSelected, disbursementPage, disbursementPageSize]);

    useEffect(() => {
        const getRepaymentData = async () => {
            if (branchSelected !== "") {
                setLoadingRepayment(true);
                try {
                    const result = await fetchRepaymentData(branchSelected, repaymentPage, repaymentPageSize);
                    const dataWithId = result.data.repayment_data.map((item, index) => ({
                        id: `${item.wr_no}-${index}`,
                        ...item
                    }));
                    setRepaymentData(dataWithId);
                    setRepaymentRowCount(result.data.repayment_data.length);
                } catch (error) {
                    console.error('Error fetching repayment data:', error);
                    setRepaymentData([]);
                    setRepaymentRowCount(0);
                }
                setLoadingRepayment(false);
            } else {
                setRepaymentData([]);
                setRepaymentRowCount(0);
            }
        };
        getRepaymentData();
    }, [branchSelected, repaymentPage, repaymentPageSize]);

    const disbursementColumns = [
        { field: 'wr_no', width: 170, headerName: 'WR Receipt No', align: 'center', headerAlign: 'center', editable: false },
        {
              field: 'view_wr',
              width: 180,
              headerName: 'View WR',
              align: 'center',
              headerAlign: 'center',
              editable: false,
              renderCell: (params) => (
                <Link to={`/wr-details/${params.row.wr_no}`} target="_blank" rel="noopener noreferrer">
                  <Button variant="outlined" startIcon={<DescriptionIcon />}>
                    View WR
                  </Button>
                </Link>
              ),
        },
        { field: 'loan_amount', align: 'center', headerAlign: 'center', headerName: 'Loan Amount', width: 150 },
        { field: 'disbursement_date', headerName: 'Disbursement Date', width: 200 },
        { field: 'roi', align: 'center', headerAlign: 'center', headerName: 'ROI', width: 100 },
    ];

    const repaymentColumns = [
        { field: 'wr_no', width: 170, headerName: 'WR Receipt No', align: 'center', headerAlign: 'center', editable: false },
        {
              field: 'view_wr',
              width: 180,
              headerName: 'View WR',
              align: 'center',
              headerAlign: 'center',
              editable: false,
              renderCell: (params) => (
                <Link to={`/wr-details/${params.row.wr_no}`} target="_blank" rel="noopener noreferrer">
                  <Button variant="outlined" startIcon={<DescriptionIcon />}>
                    View WR
                  </Button>
                </Link>
              ),
        },
        { field: 'paid_amount', align: 'center', headerAlign: 'center', headerName: 'Paid Amount', width: 150 },
        { field: 'partial_payment', align: 'center', headerAlign: 'center', headerName: 'Partial Payment', width: 150 },
        { field: 'repayment_date', headerName: 'Repayment Date', width: 200 },
        { field: 'completed', headerName: 'Completed', width: 150 },
    ];

    return (
        <React.Fragment>
            {(branchSelected !== "") && (
                <Paper sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px", borderRadius:"20px", mb:6, mx:8, p:8, backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)'}}>

                <Grid spacing={3} container>
                    <Grid>
                    <Typography variant="h6" gutterBottom>
                        Disbursement Overiew for {branchSelected} by Warehouse Receipts
                    </Typography>
                    </Grid>
                    <Grid item p={2} xs={12} sx={{ backgroundColor: "#fff" }}>
                        {disbursementData.length > 0 ? (
                            <Box sx={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={disbursementData}
                                    columns={disbursementColumns}
                                    pageSize={disbursementPageSize}
                                    onPageSizeChange={(newPageSize) => setDisbursementPageSize(newPageSize)}
                                    rowsPerPageOptions={[5, 10, 20, 50]}
                                    pagination
                                    paginationMode="server"
                                    rowCount={disbursementRowCount}
                                    onPageChange={(newPage) => setDisbursementPage(newPage)}
                                    loading={loadingDisbursement}
                                />
                            </Box>
                        ) : (
                            <Typography>No disbursement data available</Typography>
                        )}
                    </Grid>
                    <Typography mt={2} variant="h6" gutterBottom>
                        Repayment Overiew for {branchSelected} by Warehouse Receipts
                    </Typography>
                    <Grid item p={2} xs={12} sx={{ backgroundColor: "#fff" }}>
                        {repaymentData.length > 0 ? (
                            <Box sx={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={repaymentData}
                                    columns={repaymentColumns}
                                    pageSize={repaymentPageSize}
                                    onPageSizeChange={(newPageSize) => setRepaymentPageSize(newPageSize)}
                                    rowsPerPageOptions={[5, 10, 20, 50]}
                                    pagination
                                    paginationMode="server"
                                    rowCount={repaymentRowCount}
                                    onPageChange={(newPage) => setRepaymentPage(newPage)}
                                    loading={loadingRepayment}
                                />
                            </Box>
                        ) : (
                            <Typography>No repayment data available</Typography>
                        )}
                    </Grid>
                </Grid>

                </Paper>
            )}
        </React.Fragment>
    );
};

export default BankerAdminViewWRs;
