import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
// import Link from '@mui/material/Link';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import Copyright from "../components/Copyright";

import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { SERVER_BASE_ADDRESS } from "../globals/const";
import HawkEyeAppBar from "../components/AppBar";
import { InputLabel, Select, FormControl, MenuItem } from "@mui/material";
import instance from "../globals/axios";
import { saveAuth } from "../globals/utils";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(12),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: "20px",
  },
}));

export default function Register() {
  const classes = useStyles();
  const history = useNavigate();

  const [creds, setCreds] = useState({});

  function setCredField(key, value) {
    let _creds = creds;
    creds[key] = value;
    setCreds(_creds);
  }

  const [phoneNumber, setPhoneNumber] = useState('');
  // const [email, setEmail] = useState(creds.email);

  const addWallet = {
      "email": creds.email,
      "docs": { "kyc":"abc"}
  }

  const handlePhoneNumberChange = (formattedValue) => {
    setPhoneNumber(formattedValue);
  };

  async function submitHandler(event) {
    event.preventDefault();

    setCredField("phone_number", phoneNumber)
    console.log("creedd", creds);

    creds.username = creds.email;
    axios.defaults.headers = { "Content-Type": "application/json" };


      try {
        // First POST request
        const response1 = await axios.post(SERVER_BASE_ADDRESS + "/register", creds)
        console.log("Register",response1.data);

        alert("Register Successful");
    
        // Second POST request
        const response2 = await axios.post(SERVER_BASE_ADDRESS + "/addWalletToAccount", addWallet)
        console.log("Add",response2.data);

        history("/login");

      } catch (error) {
        console.log(error)
        alert("Unable to register. Email already in use.");
      }
  }

  return (
    <Container component='main' maxWidth='xs'>
      {/* <HawkEyeAppBar /> */}
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Sign up
        </Typography>
        <form className={classes.form} onSubmit={submitHandler}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete='fname'
                name='firstName'
                variant='outlined'
                required
                fullWidth
                id='firstName'
                label='First Name'
                onChange={(event) => setCredField("first_name", event.target.value)}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant='outlined'
                required
                fullWidth
                id='lastName'
                label='Last Name'
                name='lastName'
                onChange={(event) => setCredField("last_name", event.target.value)}
                autoComplete='lname'
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <TextField
                variant='outlined'
                // required
                fullWidth
                // id="email"
                label='Email Address'
                onChange={(event) => setCredField("email", event.target.value)}
                // name="email"
                // autoComplete="off"
              />
            </Grid>
            <Grid item fullWidth xs={12} style={{ marginTop: "10px" }}>
            <PhoneInput 
                country={'in'}
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                inputProps={{
                  required: true,
                }}
                inputStyle={{ width: '100%' }}
                containerStyle={{ width: '100%' }}
              />

             
            </Grid>
            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <InputLabel> Role</InputLabel>
              <FormControl variant='outlined' style={{ width: "400px" }}>
                <Select labelId='role' id='role-select' onChange={(event) => setCredField("role", event.target.value)}>
                  <MenuItem value='1'>Collateral Manager</MenuItem>
                  <MenuItem value='5'>Operations Team</MenuItem>
                  <MenuItem value='4'>Banker</MenuItem>
                  <MenuItem value='2'>NBFC</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                // required
                fullWidth
                // id="email"
                label='Doc'
                onChange={(event) => setCredField("docs", event.target.value)}
                // name="email"
                // autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                // name="password"
                label='Password'
                type='password'
                onChange={(event) => setCredField("password", event.target.value)}
                // id="password"
                // autoComplete="off"
              />
            </Grid>
            {/* <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid> */}
          </Grid>
          <Button type='submit' fullWidth variant='contained' color='primary' sx={{ mt: "1rem", mb: "1rem" }} className={classes.submit}>
            Sign Up
          </Button>
          <Grid container justifyContent='center'>
            <Grid item>
              <Link to='/login'>Already have an account? Sign in</Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}
