import React, {useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';

import { MuiOtpInput } from 'mui-one-time-password-input'

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { MenuList, ButtonBase, MenuItem, Divider, TextField, Stack, Autocomplete, Button, Chip, Popover, List, ListItem, ListItemText } from "@mui/material";
import HawkEyeAppBar from "../../components/AppBar";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import TaskAltIcon from '@mui/icons-material/TaskAlt';


import instance from "../../globals/axios";
import dayjs from 'dayjs';


const useStyles = makeStyles({
    centeredHeader: {
      textAlign: 'center',
      mx: 'auto'
    },
    chip: {
        cursor: 'pointer',
        color: "#fff"
    },
    acceptedStatus: {
        color: '#214383', // Change to your desired color
        fontWeight: 'bold',
    },
      rejectedStatus: {
        color: 'red', // Change to your desired color
        fontWeight: 'bold',
    },
    approvedStatus: {
        color: 'green', // Change to your desired color
        fontWeight: 'bold',
    },
      pendingStatus: {
        color: 'gray', // Change to your desired color
        fontWeight: 'bold',
    },
  });


const ConsentPage = () => {
    const getRowClassName = (params) => {
        return 'centered-row'; // Apply the CSS class to center row content
    };
    const classes = useStyles();
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [ bankers, setBankers ] = useState([]);
    const [consentData, setConsentData] = useState([]);
    const [selectedUser, setSelectedUser] = useState("");
    const [searchQuery, setSearchQuery] = useState('');
    const [bankerSearchQuery, setBankerSearchQuery] = useState('');
    const [ showDetails, setShowDetails ] = useState(false)

    const VISIBLE_FIELDS = ['Depositor', ' Banker', 'Consent Status'];


    // console.log("search",searchQuery)
    const navigate = useNavigate();

    const handleChipClick = (row) => {
      navigate(`/wr-details/${row.wr_reciept_no}`);
    };

    function formatDate({ inputDate }) {
        // const formattedDate = dayjs(inputDate).format('DD MMM YYYY');
        const formattedDate = dayjs(inputDate).format('hh:mm A DD MMM YYYY');
        return formattedDate;
    }

    const generateAuditTrailLink = (wr_reciept_no) => {return (`/audit-trail/${wr_reciept_no}`)};

    const userBody = {
      "role": 3,
      "page": 0,
        //  Make sure that if no limit given get all
        // "limit": 10
    };

    const bankerUserBody = {
        "role": 4,
        "page": 0,
        // "limit": 10
    };

    const consentActionBody = {
        "consent": 1,
        "banker": bankerSearchQuery,
        "depositor": searchQuery
    }


    useEffect(() => {
        const fetchUsers = async () => {
        
            try {
              const response = await instance.post('/user/getUser', userBody);
              setUsers(response.data.data); 
            } catch (error) {
              console.error('Error fetching users:', error);
            }

            try {
                const response = await instance.post('/user/getUser', bankerUserBody);
                setBankers(response.data.data); 
              } catch (error) {
                console.error('Error fetching users:', error);
              }
          };
    
        fetchUsers();

        const getConsentData = async () => {
        
            try {
                const response = await instance.get('/depositor/getConsentData');
                const dataWithId = response.data.data.map((data, index) => ({
                        ...data,
                        updated_date: formatDate(data.updated_date),
                        id: index + 1, // You can use a more sophisticated method to generate unique IDs
                }));
        
                console.log(dataWithId)
                setLoading(false);
                setConsentData(dataWithId); 
                setFilteredData(dataWithId)
            } catch (error) {
              console.error('Error fetching users:', error);
            }
        };
    
        getConsentData();

    }, []);

    const columns = [
        { field: 'consent', headerName: 'Consent Status', width: 160, align: 'center', headerAlign: 'center', editable: false,
                renderCell: (params) => (
                    <Box>
                    {params.value === true ? (
                        <Chip
                            label="Given"
                            color="primary"
                            variant="outlined"
                        />
                    ) : (
                        <Chip
                            label="Not Given"
                            color="error"
                            variant="outlined"
                        />
                    ) } 
                         
                    </Box>
                    ),
        },
        { field: 'depositor_name', headerName: 'Depositor Name', width: 180, align: 'center', headerAlign: 'center', editable: false, filterable: true, },
        { field: 'depositor_phone_number', headerName: 'Depositor Phone No.', width: 200, align: 'center', headerAlign: 'center', editable: false, filterable: true, },
        { field: 'depositor', headerName: 'Depositor Email', width: 180, align: 'center', headerAlign: 'center', editable: false, filterable: true, },
        { field: 'banker', headerName: 'Banker', width: 180, align: 'center', headerAlign: 'center', editable: false },
        { field: 'updated_date', headerName: 'Updated at', width: 180, align: 'center', headerAlign: 'center', editable: false },
        { field: 'created_by', headerName: 'Created by', width: 180, align: 'center', headerAlign: 'center', editable: false },
        { field: 'updated_by', headerName: 'Updated by', width: 180, align: 'center', headerAlign: 'center', editable: false },
        // { field: 'loan_offer_status', headerName: 'Loan Offer Status',
        //     renderCell: (params) => (
        //         <span
        //         className={params.value === 'Accepted' ? classes.acceptedStatus :
        //             params.value === 'Rejected' ? classes.rejectedStatus :
        //             params.value === 'Approved' ? classes.approvedStatus :
        //             classes.pendingStatus}
        //         >
        //         {params.value}
        //         </span>
        //     ),
        //     width: 140, align: 'center', headerAlign: 'center', editable: false },
    ];

    const [filteredData, setFilteredData] = useState(consentData);
    const handleSearch = () => {
        setShowDetails(true)
        const filtered = consentData.filter((row) =>
          columns
            .filter((col) => col.filterable)
            .every((col) => {
           
            if (col.field === 'depositor_phone_number') {
                return row[col.field]?.toString().toLowerCase() === searchQuery.toLowerCase();
            } else {
                return true;
            }
            })
        );
        setFilteredData(filtered);
    };

    const [ showAddConsent, setShowAddConsent ] = useState(false)

    const handleAdd = () => {

        console.log(consentActionBody)
          instance.post("/depositor/giveConsent", consentActionBody)
            .then(response => {
                console.log("POST request successful. Response:", response.data);
                alert("Consent added successfully!")
                window.location.reload();
            })
            .catch(error => {
                console.error("Error in request:", error);
                alert("Error occured")
            });
    };

    const handleRemove = ( banker ) => {

        const consentRemoveBody = {
            "consent": 0,
            "banker": banker,
            "depositor": searchQuery
        }
       
       
        instance.post("/depositor/giveConsent", consentRemoveBody)
            .then(response => {
                console.log("POST request successful. Response:", response.data);
                alert("Consent removed successfully!")
                window.location.reload();
            })
            .catch(error => {
                console.error("Error in request:", error);
                alert("Error occured")
            });
    };

    const [ verified, setVerified ] = useState(false)
    const [ otpSent, setOtpSent ] = useState(false);
    const [ verifyOtp, setVerifyOtp ] = useState(false)
    const [ countdown, setCountdown ] = useState(60);
    const [ otp, setOtp ] = useState('');
    const [ depositorNumber, setDepositorNumber ] = useState("")


    const handleChange = (newValue) => {
        setOtp(newValue)
    }

    const handleVerify = async () => {
        instance.get(`otp/verify-opt?depositor=${depositorNumber}&otp=${otp}`)
        .then(() => {
          setVerified(true)
        })
        .catch((error) => {
          console.error('Error sending OTP:', error);
        });
      };
    
      useEffect(() => {
        let interval;
    
        if (otpSent && countdown > 0) {
          interval = setInterval(() => {
            setCountdown(prev => prev - 1);
          }, 1000);
        } else if (countdown === 0) {
          clearInterval(interval);
        }
    
        return () => clearInterval(interval);
      }, [otpSent, countdown]);
    
      const handleSendOtp = () => {
        setVerifyOtp(true)
        setOtpSent(true);
    
        instance.get(`otp/generate-opt?depositor=${depositorNumber}`)
        .then(() => {
          setCountdown(60);
    
          setTimeout(() => {
            setOtpSent(false);
          }, 60000);
        })
        .catch((error) => {
          console.error('Error sending OTP:', error);
        });
    };
    
    const handleResendOtp = () => {
        setCountdown(60);
        handleSendOtp();
    };


    return (
        <div> 
        <HawkEyeAppBar />
        <Grid sx={{borderRadius:"1rem", border:"2px solid #d8daed", backgroundColor: "#fff", p:2, m:"7rem 7rem"}} >
        <Typography variant="h5" sx={{ px:2, py:1, textAlign: "center",fontWeight: 500}}>
                Update Depositor Consent
        </Typography> 
        <Typography textAlign="center" my={2} variant='body1'>
                        Search Depositor by Phone Number 
            </Typography>

        <Grid container mx="auto" mt={4} xs={6}>
        
          
            <Grid xs={8}>
                <Stack fullWidth centered >
                      
                     
                    <Autocomplete
                        freeSolo
                        id="free-solo-2-demo"
                        disableClearable
                        label="Seach by phone number"
                        options={users.map((option) => option.phone_number)}
                        inputValue={searchQuery}
                        onInputChange={(event, newInputValue) => {
                            setSearchQuery(newInputValue);
                            setDepositorNumber(newInputValue)
                        }}

                        renderInput={(params) => (
                        <TextField
                            {...params}
                            // label="Search input"
                            InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            }}
                        />
                        )}
                    />
                    
                </Stack>
            </Grid>
            <Grid xs={4} my="auto" px={2}>
                <Button p={2} sx={{ fontSize: 18}} fullWidth variant='outlined' onClick={handleSearch}>
                    Search 
                </Button>
            </Grid>
        </Grid>

        {
            showDetails && (

            <Box> 
            <Typography mt={3} variant="h5" sx={{ px:2, py:1, textAlign: "center",fontWeight: 500}}>
                Update Consent
            </Typography> 

            <Popover
                    open={showAddConsent}
                    onClose={() => setShowAddConsent(false)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    PaperProps={{
                        style: {
                            width: '500px', // Set the desired width
                        },
                    }}
                >
                    <Grid container fullWidth p={3} display="flex" justifyContent="center" alignItems="center">
                    <Box>
                        <Typography mt={2} variant="h5" sx={{ textAlign: "center",fontWeight: 500}}>
                            Add Consent <br/>
                        </Typography> 
                        <Typography mt={2} variant="h6" sx={{ textAlign: "center",fontWeight: 400}}>
                            Depositor: {searchQuery} <br/>
                        </Typography> 
                        <Typography mt={1} variant="h6" sx={{ textAlign: "center",fontWeight: 400}}>
                            Choose Banker:
                        </Typography> 
                    </Box>
                    <Grid mt={2} xs={12}>
                        <Autocomplete
                        freeSolo
                        id="free-solo-2-demo"
                        disableClearable
                        options={bankers.map((option) => option.email)}
                        inputValue={bankerSearchQuery}
                        onInputChange={(event, newInputValue) => {
                            setBankerSearchQuery(newInputValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                            }}
                            />
                        )}
                        />
                    </Grid>
                    <Grid container mx="auto" xs={6} mt={6} sx={{ display: "flex", justifyContent: "center"}}>
               
                    <Grid item fullWidth>
                        {otpSent ? (
                        <Box>
                            
                            <Button variant="outlined" sx={{ width:"15rem", my:2, fontSize: "1.15rem"}} color='success'>
                            OTP Sent
                            </Button>
                            <Box mx="auto">
                            <Typography>You will be able to Resend in {countdown} seconds</Typography>
                            </Box>
                        </Box>
                        ) : (
                        <Box>
                            <Button onClick={handleSendOtp} variant="outlined" sx={{ width:"15rem", my:2, fontSize: "1.15rem"}} color='primary'>
                            Send OTP
                            </Button>
                        </Box>
                        )}
                    </Grid>

                    <Grid item px={6} fullWidth>
                        {
                        verifyOtp && (
                            <Box mx="auto" mt={2}>
                            <MuiOtpInput length={6} autoFocus value={otp} onChange={handleChange} />

                            
                                <Box display="flex" mt={3} justifyContent="center">
                                <Button variant="contained" color="primary" onClick={handleVerify}>
                                    Verify OTP
                                </Button>
                                </Box>


                            
                            </Box>
                        )
                        }
                        <Grid mx="auto" mt={2} sx={{ display: "flex", justifyContent: "center"}}>
                        { 
                            verified ?
                            (
                                <Button fullWidth variant="outlined" color="success" onClick={handleAdd} p={2} sx={{ mt: 1, fontSize: 18 }}>
                                Add Consent
                                </Button>
                            ) :
                            (
                            <Button disabled variant="contained" sx={{ width:"15rem", fontSize: "1.15rem"}}  color='primary'> Add Consent</Button>
                            )
                        }
                        </Grid>
                    </Grid>
                
                    {/* 
                    {showResend && (
                        <Button onClick={handleResendOtp} variant="outlined" sx={{ width:"15rem", my:2, fontSize: "1.15rem"}} color='primary'>
                        Resend OTP
                        </Button>
                    )}
                    */}
                    
                    </Grid>
             
                   
                    </Grid>
            </Popover>
    
            <Grid container mx="auto" mt={2} px={2} xs={10} py={2} borderRadius="1.5rem" bgcolor="#f5f5f5">

            <div style={{ display: 'flex', alignItems: 'center', width: '100%', margin: '12px 0' }}>
                <div style={{ flex: 1, borderBottom: '1px solid #ccc' }}></div>
                <Typography variant="body2" style={{ margin: '0 16px' }}>
                Depositor: {searchQuery}
                </Typography>
                <div style={{ flex: 1, borderBottom: '1px solid #ccc' }}></div>
            </div>
            {
                filteredData && 
                (
                filteredData.map((consentData, index) => (
                        <Grid xs={12} fullWidth container >
                            <Grid xs={3}>
                                <Typography pt={1} mx={2}>
                                    Consent Status: 
                                    <span>
                                    <Chip
                                        sx={{ ml: 2, mt: 1 }}
                                        label={consentData.consent ? 'Given' : 'Not Given'}
                                        color="primary"
                                        variant="outlined"
                                    />
                                </span>
                                    
                                </Typography>
                            </Grid>
                            <Grid xs={3}>
                                <Typography pt={1} mx={2}>
                                    Banker Associated: {consentData.banker}
                                </Typography>
                            </Grid>
                            <Grid xs={4}>
                                <Typography pt={1} mx={2}>
                                    Created at: {formatDate(consentData.created_date)}
                                </Typography>
                            </Grid>
                            <Grid xs={2}>
                                <Button variant="outlined" color="error" onClick={()=>{handleRemove(consentData.banker)}} sx={{ ml: 2}}>
                                    Remove
                                </Button>         
                            </Grid>
                        </Grid>
        
                        
                ))
                )
            }

            <Grid xs={12}>
                            <Box display="flex" justifyContent="center" alignItems="center" mt={2} mb={1} mx="auto">
                                <Button variant="contained" onClick={() => setShowAddConsent(!showAddConsent)} sx={{ color: "#fff", mx: "auto"}} color="success">
                                    Add Consent
                                </Button>
                            </Box>
                        </Grid>
        
            </Grid>
                
            </Box>

            )
        }


        
       
      



        <Typography mt={3} variant="h5" sx={{ px:2, py:1, textAlign: "center",fontWeight: 500}}>
                Available Consents
        </Typography> 


        
        <Box sx={{ height: 400, my:2, mx:4 }}>
                <DataGrid
                    rows={filteredData} 
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 5,
                        },
                    },
                    }}
                    pageSizeOptions={[5]}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    filterModel={{
                        items: columns
                          .filter((col) => col.filterable)
                          .map((col) => ({
                            columnField: col.field,
                            operatorValue: 'contains',
                            value: searchQuery,
                          })),
                      }}
                    disableRowSelectionOnClick
                />
        </Box>
        </Grid>
        </div>
    )
}

export default ConsentPage