import React from 'react';
import { Grid, Typography, Card, CircularProgress, Box } from '@mui/material';
import { Chart } from 'react-google-charts';




const renderContent = (branchSelected, aumLoading, leinBalanceQtyChartData, closingPledgeChartData, geoChart) => {
        return (
            <React.Fragment>
        
                <Grid style={{ display: branchSelected !== "" ? 'block' : 'none' }} sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px", borderRadius:"20px", mt:12, mb:6, pb:4, mx:8, p:2, backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)'}}>
                        <Grid container sx={{ mx: 'auto', alignItems: "center", p:2 }}>
                            <Grid sx={{ p:2, borderRadius:"1rem", border:"2px solid #d8daed", my:2, backgroundColor: "#fff", }} mx="auto" xs={10}>
                                <Typography sx={{ mx:"auto", fontSize: "1.15rem", fontWeight: "500" }}>
                                    AUM Value 
                                    <br/>
                                </Typography>
                                {/* { 
                                aumLoading && branchSelected ?
                                (
                                    <Grid>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '3rem' }}>
                                        <CircularProgress />
                                    </Box>  

                                    </Grid>
                                ) : null
                                } */}
                                
                                <Grid container p={2} spacing={2}>
                                        <Grid style={{ display: closingPledgeChartData.length > 0 ? 'block' : 'none' }} item xs={12}>
                                            <Chart
                                                width={'100%'}
                                                height={'400px'}
                                                chartType="LineChart"
                                                data={closingPledgeChartData}
                                                options={{
                                                    title: 'Lein Balance Qty (in MT)',
                                                    hAxis: { title: 'Date' },
                                                    vAxis: { title: 'Lein Balance Qty (in MT)' },
                                                }}
                                            />
                                        </Grid>
                                        <Grid style={{ display: leinBalanceQtyChartData.length > 0 ? 'block' : 'none' }} item xs={12}>
                                            <Chart
                                                width={'100%'}
                                                height={'400px'}
                                                chartType="LineChart"
                                                data={leinBalanceQtyChartData}
                                                options={{
                                                    title: 'Closing Pledge Value (in ₹)',
                                                    hAxis: { title: 'Date' },
                                                    vAxis: { title: 'Closing Pledge Value (in ₹)' },
                                                }}
                                            />
                                        </Grid>
                                        <Grid style={{ display: leinBalanceQtyChartData.length > 0 ? 'none' : 'block' }} item xs={12}>
                                        <Typography color="secondary" textAlign="center">
                                                No Lein Balance Quantity data available for the selected branch.
                                            </Typography>
                                        </Grid>
                                        <Grid style={{ display: closingPledgeChartData.length > 0 ? 'none' : 'block' }} item xs={12}>
                                            <Typography color="secondary" textAlign="center">
                                                No Closing Pledge Value data available for the selected branch.
                                            </Typography>
                                        </Grid>
                                </Grid>
                                    
                            </Grid>
                        </Grid>
                

                    <Grid container justifyContent="center" alignItems="center" mb={4} px={4}>
                        <Card sx={{ borderRadius: "10px", borderColor: "#214383", p: 3, width: '90%',  textAlign: 'center' }} elevation={4}>
                            
                                <Grid>
                                    <Typography variant="h6" gutterBottom>
                                        State-wise Lein Balance Quantity
                                    </Typography>
                                    <Box>
                                        <Chart

                                            width={'100%'}
                                                    chartType="GeoChart" 
                                                    data={geoChart}
                                                    options={{
                                                        region: 'IN',
                                                        displayMode: 'regions',
                                                        resolution: 'provinces',
                                                        colorAxis: {
                                                            colors: ['#fff', '#214383'] 
                                                        }
                                                    }}
                                        />
                                    </Box>
                                </Grid>
                        
                        </Card>
                    </Grid>
                </Grid>

        </React.Fragment>
        );
};

const DashboardContent = ({ closingPledgeChartData, leinBalanceQtyChartData, geoChart, aumLoading, branchSelected }) => {
    return <React.Fragment>{renderContent(branchSelected, closingPledgeChartData, leinBalanceQtyChartData, aumLoading, geoChart)}</React.Fragment>;
};


export default DashboardContent;        