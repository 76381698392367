import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import HawkEyeAppBar from "../components/AppBar";
import { Box, Divider, Typography, Grid, FormControl, Chip, Link, Button, Card, CardContent, FormControlLabel, Checkbox, TextField, Snackbar } from "@mui/material";
import { RadioGroup, Radio, FormGroup } from '@mui/material';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CircularProgress from '@mui/material/CircularProgress';

import { makeStyles } from "@mui/styles";
import instance from "../globals/axios";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import QRCode from "react-qr-code";

import dayjs from 'dayjs';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
  } from '@mui/lab/TimelineOppositeContent';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import EditIcon from '@mui/icons-material/Edit';
import StarIcon from '@mui/icons-material/Star';
import StarsIcon from '@mui/icons-material/Stars';

import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import VerifiedIcon from '@mui/icons-material/Verified';

// import icici_icon from "../../assets/icici_icon.png";


const useStyles = makeStyles((theme) => ({
  outline: {
    borderRadius:"1rem",
    border:"2px solid #d8daed", 
    backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)',

  },
  customCard: {
    backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)',
    boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
    borderRadius: '1rem',
    border:"2px solid #d8daed", 
  },
  subtitleFont: {
    fontSize: "1.5rem",
    fontWeight: "600",
  },
}));

const AuditTrail = () => {
    const classes = useStyles();
    const location = useLocation();
    const { wr_reciept_no } = useParams();
    // const { data } = location.state;

    const [loading, setLoading] = useState(true);
    const [ data, setData] = useState({})
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const [qrcode, setQrCode] = useState(false);
    const toggleDropdown = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };
    const handleScan = () => {
      setQrCode(!qrcode);
    };


    const formatDate = (dateString) => {
          const options = { 
            hour: '2-digit',
            minute: '2-digit',
            day: '2-digit',
            month: 'short', 
            year: 'numeric',
            hour12: true, 
            timeZone: 'Asia/Kolkata' 
          };

        const formattedDate = new Date(dateString).toLocaleString('en-US', options);
        return formattedDate.replace(',', '');
    }

    useEffect(() => {
      const fetchData = async () => {
        try {
            const response = await instance.get(`getAuditDataFromWR?wr_reciept_no=${wr_reciept_no}`);
            setData(response.data.data);
        } catch (error) {
            console.error(error);
            alert('An error occurred while fetching data. Please try again later.');
        } finally {
            setLoading(false);
        }
    };
    fetchData();

    }, []);


    return (
        <div>
        <Box fullWidth bgcolor='#fff'>
        <HawkEyeAppBar />

        <Box mx='4rem' className={classes.outline} sx={{ padding: "1.5rem 8rem", marginTop: "10%" }}>

        <Grid m={1}>
            <Chip icon={<KeyboardBackspaceIcon />} style={{ cursor: "pointer", fontSize: "16px", fontWeight: "600" }} color="primary" label='Back' variant='outlined' onClick={() => window.history.back()} />
        </Grid>

            
        <Grid mt="1rem" mb="1rem">
            <Typography variant="h4" textAlign="center">
                Transactions Trail
            </Typography>
        </Grid>

        <Divider />

        {loading ? (
            <Grid textAlign="center" alignItems="center" mt="3rem">
            <Typography textAlign="center" sx={{ mb:4}}>
                Loading...
            </Typography>
            <CircularProgress size={54} thickness={4} />
            </Grid>
          ) : (
            <Grid>
            <Timeline
            sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: 0.2,
                },
            }} >
            {data.map((item, index) => (
               <TimelineItem key={index}>
               <TimelineOppositeContent
                 sx={{ m: 'auto 0', flex: 1 }} // Adjust flex property
                 align="right"
                 variant="body2"
                 color="text.secondary"
               >
                 <Typography fullWidth sx={{ width: "175px"}}>{formatDate(item.created_at)}</Typography>
               </TimelineOppositeContent>
             
               <TimelineSeparator>
                 <TimelineConnector sx={{ my: 0 }} /> {/* Ensure the connector connects properly */}
                 <TimelineDot color="primary" sx={{ mx: 1 }}>
                   {item.type === "1" && <AddBusinessIcon />}
                   {item.type === "2" && <RemoveCircleIcon />}
                   {item.type === "3" && <EditIcon />}
                   {item.type === "4" && <AutoFixHighIcon />}
                   {item.type === "5" && <StarsIcon />}
                   {item.type === "6" && <CheckCircleIcon />}
                   {item.type === "7" && <CheckCircleIcon />}
                   {item.type === "8" && <CheckCircleIcon />}
                   {item.type === "False" && <CheckCircleIcon />}

                 </TimelineDot>
                 <TimelineConnector sx={{ my: 0 }} /> {/* Ensure the connector connects properly */}
               </TimelineSeparator>
             
               <TimelineContent sx={{ py: '12px', flex: 1 }}> {/* Adjust flex property */}
                 <Typography variant="h6" component="span">
                   {item.type === "1" && 'Inward'}
                   {item.type === "2" && 'Outward'}
                   {item.type === "3" && 'Modify'}
                   {item.type === "4" && 'Pledge'}
                   {item.type === "5" && 'Depledge'}
                   {item.type === "6" && 'Quality'}
                   {item.type === "6" && 'Marked Funded'}
                   {item.type === "6" && 'Marked Unfunded'}
                 </Typography>
                 <Typography>
                   {item.trans_code && `Trans Code: ${item.trans_code}`}
                   <br />
                   {item.creator && `Creator: ${item.creator}`}
                   <br />
                   {item.approver && `Approver: ${item.approver}`}
                   <br />
                   {item.client && `Client: ${item.client}`}
                   <br />
                 </Typography>
               </TimelineContent>
             </TimelineItem>
             ))}

           

            
            </Timeline>
            </Grid>
        )}

        </Box>
        </Box>
        </div>
    );
};

export default AuditTrail;
