import React from 'react';

const LocationComponent = ({ lat, lng }) => {
  console.log(lat," ",lng)
  const mapUrl = `https://www.google.com/maps/embed/v1/view?key=AIzaSyBirStKK3UUoCRulbaKc1TawwSnuML1ips&center=${lat},${lng}&zoom=15`;

  return (
    <div>
      <h2>Location</h2>
      <iframe
        title="Location Map"
        width="600"
        height="450"
        loading="lazy"
        src={mapUrl}
      ></iframe>
    </div>
  );
};

export default LocationComponent;
