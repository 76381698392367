import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';

import HawkEyeAppBar from "../../components/AppBar";
import { Box, Divider, Typography, Grid, Chip, Link, Button, Card, CardContent, FormControlLabel, Checkbox, TextField, Snackbar } from "@mui/material";
import { RadioGroup, Radio, FormGroup } from '@mui/material';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { makeStyles } from "@mui/styles";
import instance from "../../globals/axios";

import icici_icon from "../../assets/icici_icon.png";
import boi_icon from "../../assets/boi_icon.png";
import dmi_icon from "../../assets/dmi_logo.jpeg";
import sbi_icon from "../../assets/sbi_icon.png";
import lti_icon from "../../assets/lti_icon.jpeg";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  rootVideo: {
    minWidth: 275,
  },
  titleVideo: {
    fontSize: 20,
  },
  buttonVideo: {
    marginTop: theme.spacing(2),
  },
  cardHeader: {
    backgroundColor: "#0073b1",
    color: "#fff",
    fontWeight: "bold",
  },
  cardBody: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dropdownWrapper: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  dropdown: {
    textDecoration: "underline",
    marginRight: 8,
  },
  expandIcon: {
    transform: "rotate(0deg)",
    transition: "transform 0.2s ease-in-out",
  },
  expandIconOpen: {
    transform: "rotate(180deg)",
  },
  dropdownContent: {
    marginTop: 16,
    paddingLeft: 16,
    // borderLeft: "1px solid #ccc",
  }, 
  outline: {
    borderRadius:"1rem",
    border:"2px solid #d8daed", 
    backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)',

  },
  customCard: {
    backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)',
    boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
    borderRadius: '1rem',
    border:"2px solid #d8daed", 
  },
}));

const AppLoan = () => {
  const classes = useStyles();
  const location = useLocation();
  const { data } = location.state;

  const [funding, setFunding] = useState();
  const [rate, setRate] = useState();
  const [mktValue, setMktValue] = useState(180000);
  const [loanAmount, setLoanAmount] = useState(0);
  const [wr_reciept_no, setWr_reciept_no] = useState(data.attributes.inward_details.wr_reciept_no);
  const [qty, setQty] = useState(data.balance);
  const [owner, setOwner] = useState(data.owner);

  useEffect(() => {
    setLoanAmount(funding*0.01*mktValue);
  }, [funding])

  const [selectedBanks, setSelectedBanks] = useState('');

  const addBanks = (event) => {
    const { value } = event.target;
    if (selectedBanks.includes(value)) {
      setSelectedBanks(selectedBanks.filter((logo) => logo !== value));
    } else {
      setSelectedBanks([...selectedBanks, value]);
    }
  };

  const requestBody = {
    "wr_reciept_no": wr_reciept_no,
    "qty": qty,
    "rate_of_interest": rate,
    // "rate_of_interest": 0,
    "market_value": mktValue,
    "loan_amount": loanAmount,
    "other_fields": "N.A.",
    "borrower": owner,
    "lenders": selectedBanks
  };
  
  
  const [banks, setBanks] = useState([]);

  // const handleClick = (bank) => {
  //   const newArray = [...banks, bank];
  //   setBanks(newArray);
  // };

  const handleSubmit = async () => {

    try {
      console.log(requestBody)

      const response = await instance.post("loan/cm/makeLoanOffer", requestBody);
      console.log("ResponseData", response);
      alert("Applied for Loan Succesfully!");
      setTimeout(function () {
        window.location.replace("/admin");
      }, 500);

    } catch (error) {
      console.log(error);
    }
  };

  const [digitalCopyLink, setDigitalCopyLink] = useState(null);

  const handleClick = async () => {

    const wrNo = data.attributes.inward_details.wr_reciept_no;
    const url = `https://www.agrigates.com/gtsEntryForBlockChain.php?url=url_link&wr_no=${wrNo}`;

    axios.get(url)
      .then(response => {
        if (response.data && response.data.data && response.data.data.length > 0) {
          const rawLink = response.data.data[0].digital_copy_link;
          const formattedLink = rawLink.replace(/\\\//g, '/');
          setDigitalCopyLink(formattedLink);
          window.open(formattedLink, '_blank');

        } else {
          alert('Warehouse Receipt Digital Copy not found.');
        }
      })
      .catch(error => {
        console.error('Error:', error.message);
      });
  };



  return (
    <div>
    <Box fullWidth bgcolor='#fff'>
      <HawkEyeAppBar />
      <Box mx='auto' className={classes.outline} sx={{ padding: "1.5rem 8rem", marginTop: "4rem" }}>
        <Grid mt="2rem" mb="1rem">
          <Link to='/admin'>
            <Chip icon={<KeyboardBackspaceIcon />} style={{ cursor: "pointer" }} label='Admin' variant='outlined' />
          </Link>
        </Grid>
        <Divider />

        <Grid fullWidth mb="5rem">
            <Box className={classes.outline} mt="3rem" p={6}>
              
                <Grid mb={1} container>
                  <Grid xs={10} md={6}>
                    <Typography item xs={4} variant='h5' gutterBottom>
                      Apply Loan
                    </Typography>
                    <Divider my={1} />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item md={6} mr={4}>
                    <Card style={{ marginTop: "0.9rem" }}>
                        
                          <Grid fullWidth container p={4}>
                            
                                  
                                  <Typography variant="h6" my={1}>
                                    Token Code:<br/>
                                    {data.token_code}
                                  </Typography>
                                  <Typography variant="h6" my={1}>
                                    Token Name:<br/>
                                    {data.token_name}
                                  </Typography>
                                  <Grid xs={12}>

                                  <Typography variant="h6" my={1}>
                                    Commodity: {data.attributes.commodity.name}<br/>
                                  </Typography>
                                  </Grid>
                                  <Grid xs={12}>

                                  <Typography variant="h6" my={1}>
                                    Warehouse Reciept Number: 
                                    {data.attributes.inward_details.wr_reciept_no}
                                    <br/>
                                  </Typography>
                                  </Grid>
                                  
                                  <Typography variant="h6" my={1}>
                                    Quantity: {data.balance} MT<br/>
                                  </Typography>
                                  <br/>

                                
                            
                          </Grid>
                        
                    </Card>
                    
                    
                  </Grid>
                  

                  <Grid item md={5} my={1} mx="auto">
                   
                      <Grid container md={12} p={4}>

                        <Grid item md={5}>
                          <Typography variant="h6" mx="auto">Rate of Interest</Typography>
                          <TextField fillWidth style={{ background: "white", marginTop: "10px" }} 
                            value={rate}
                            onChange={(event) => setRate(event.target.value)}
                            label='Enter Rate' />
                        </Grid> 
                        <Grid item md={6} mx="auto">
                        <Typography variant="h6" mx="auto">Funding Percentage</Typography>
                        <TextField fillWidth style={{ background: "white", marginTop: "10px" }} 
                          value={funding}
                          onChange={(event) => setFunding(event.target.value)}
                          label='Enter Funding Percentage' />
                      </Grid>

                      <Grid item md={12}>
                        <Typography variant="h6" mt="1rem">Market Value(in Rs/Mt)</Typography>
                        <Card style={{ marginTop: "10px", height: "50px" }}>
                          <Typography style={{ padding: "10px" }}>₹ {mktValue}</Typography>
                        </Card>
                        </Grid>
                        <Grid item md={12}>
                          <Typography variant="h6" mt="1rem">Loan Amount(in Rs)</Typography>
                          <Card style={{ marginTop: "10px", height: "50px" }}>
                            <Typography style={{ padding: "10px" }}>₹ {loanAmount}</Typography>
                          </Card>
                            
                        </Grid> 

                        <Typography variant="h6" style={{ marginTop: "2rem" }}>Select Lenders</Typography>

                        <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedBanks.includes('dmi@example.com')}
                                  onChange={addBanks}
                                  value="dmi@example.com"
                                />
                              }
                              label={ <div>
                                <img width="45rem" src={dmi_icon} alt={"dmi"} /> {/* Replace with the image path and alt text for each logo */}
                                <span>DMI Finance </span>
                              </div>} // Replace with the image path and alt text for Logo 1
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedBanks.includes('lti@example.com')}
                                  onChange={addBanks}
                                  value="lti@example.com"
                                />
                              }
                              label={
                                <div>
                                <img width="35rem" src={lti_icon} alt={"lti"} /> {/* Replace with the image path and alt text for each logo */}
                                <span>Larsen & Toubro Finance (LTF).</span>
                              </div>
                              } 
                            />
                            
                        </FormGroup>

                       
                        
                        
                      </Grid>
                     
                    
                    
                  </Grid>
                
                </Grid>

              
              
              <Grid mx="auto" mt={6} sx={{ display: "flex", justifyContent: "center"}}>
                  <Link>
                      <Button onClick={handleSubmit} variant="contained" sx={{ width:"15rem", fontSize: "1.15rem"}} outlined color='primary'>Apply Loan</Button>
                  </Link>
              </Grid>
             
            </Box>
        </Grid>

        {/* <Snackbar open={offerSubmitted} autoHideDuration={6000} message='Submitted' /> */}
      </Box>
    </Box>
    </div>
  );
};

export default AppLoan;
