import React, { useState, useEffect } from "react";
import HawkEyeAppBar from "../../components/AppBar";
import {
  Box,
  Divider,
  Typography,
  Grid,
  Chip,
  Button,
  Card,
  CardContent,

} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import instance from "../../globals/axios";


import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ListIcon from '@mui/icons-material/List';
import GetAppIcon from '@mui/icons-material/GetApp';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import WarehouseReciept from "../../components/WarehouseReciept";
import TestCertificate from "../../components/TestCertificate"


const useStyles = makeStyles(() => ({
  cardHeader: {
    backgroundColor: "#0073b1",
    color: "#fff",
    fontWeight: "bold",
  },
  cardBody: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dropdownWrapper: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  dropdown: {
    textDecoration: "underline",
    marginRight: 8,
  },
  expandIcon: {
    transform: "rotate(0deg)",
    transition: "transform 0.2s ease-in-out",
  },
  expandIconOpen: {
    transform: "rotate(180deg)",
  },
  dropdownContent: {
    marginTop: 16,
    paddingLeft: 16,
    // borderLeft: "1px solid #ccc",
  },
  outline: {
    borderRadius:"1rem",
    border:"2px solid #d8daed", 
    backgroundColor: "#fff"
  },
  customCard: {
    backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)',
    boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
    borderRadius: '1rem',
    border:"2px solid #d8daed", 
  },
  subtitleFont: {
    fontSize: '1.5rem',
    fontWeight: '600',
  }
}));

const NBFCLoanRequestDetails = () => {
    const [tabvalue, setTabvalue] = useState(0);
    const classes = useStyles();
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const [qrcode, setQrCode] = useState(false);
    const [passData, setPassData] = useState({});
    const location = useLocation();
    const { data } = location.state;
    const [showMoreDetails, setShowMoreDetails] = useState(false);

    // Function to toggle dropdown state
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const handleScan = () => {
        setQrCode(!qrcode);
    };

    const [tokenStatus, setTokenStatus] = useState("");
    const [loanId, setLoanId] = useState(data.loan_id)

    const requestBody = {
        "loan_offer_id": loanId,
        "action": tokenStatus,
        "qty": data.balance
    };

    console.log(data.loan_status)


    const [isPopupOpen, setIsPopupOpen] = useState(false);

    useEffect(() => {
      const fetchData = async () => {
        try {
          setPassData(data)
        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
    }, []);

    useEffect(() => {
        const submit = async () => {
            console.log("status:",tokenStatus)
            console.log("loan id",loanId)
            try {
            
            const response = await instance.post("loan/acceptRejectLoanOffer", requestBody);
            console.log("ResponseData", response);
            alert(`Offer ${tokenStatus}ed succesfully`);
            
            setTimeout(function () {
              window.location.replace("/nbfc");
            }, 500);
    
            } catch (error) {
            console.log(error);
            }
        };
    
        submit();
      }, [tokenStatus]);

    const handlePopupClose = () => {
        setIsPopupOpen(false);
    };


  return (
    <Box fullWidth>
      <HawkEyeAppBar />
      <Box mx='auto' sx={{ bgcolor: "background.paper", padding: "1.5rem 8rem", marginTop: "50px" }}>
        <Grid  mt="1rem" mb="1rem">
          <Link to='/nbfc'>
            <Chip icon={<KeyboardBackspaceIcon />} style={{ cursor: "pointer" }} label='Tokens' variant='outlined' />
          </Link>
        </Grid>
        <Divider />
        <Grid fullWidth>

        {
          showMoreDetails ? (
            <TestCertificate data={data} />
          ) :
          (
            <WarehouseReciept data={data} setShowMoreDetails={setShowMoreDetails} />
          )
        }

        <Grid mx="auto" mt={2} mb={8} sx={{ display: "flex", justifyContent: "center"}}>
            
            <Button variant="outlined" sx={{ mx:2, width:"15rem", fontSize: "1rem"}} color='primary' 
              startIcon={<GetAppIcon />}
              >
                Download WR
            </Button>

            <Button variant="contained" sx={{ mx:2, color:"#fff", width:"15rem", fontSize: "1rem"}} color='success' 
              startIcon={<TaskAltIcon />}
              onClick={() => {
                // const newRoute = `${location.pathname}/new-route`;
                // Navigate to the new route
                window.location.href = `/audit-trail/${data.attributes.inward_details.wr_reciept_no}`;
              }}> 
                  Check Audit Trail
            </Button>
           

            {  ( data.loan_status === "Pending" || data.loan_status === "Countered" ) ?
              (
                <>
                <Button onClick={() => setTokenStatus("reject")} variant="outlined" sx={{ width:"15rem", mr:2, fontSize: "1rem"}} outlined color='error'>Reject</Button>
  
                <Button onClick={() => setTokenStatus("accept")} variant="contained" sx={{ width:"15rem", color:"#fff", fontSize: "1rem"}} outlined color='success'>Accept</Button>
                </>
              ) : null
            }
            
            {
              showMoreDetails ? (
                <Button onClick={() => setShowMoreDetails(false)} variant="outlined" sx={{ mx:2, width:"15rem", fontSize: "1rem"}} outlined color='primary' startIcon={<KeyboardBackspaceIcon sx={{ fontSize: '1.75rem' }}/>}>
                Show WR
                </Button>
              ) : (
              <Button onClick={() => setShowMoreDetails(true)} variant="outlined" sx={{ mx:2, width:"15rem", fontSize: "1rem"}} outlined color='primary' endIcon={<ListIcon sx={{ fontSize: '1.75rem' }}/>}>
                More Details
              </Button>
              )
            }
            
        </Grid>
          


          
          {
              data.loan_status==="Countered" && (
                <Grid className={classes.customCard} xs={12}>
                        <Box p={4}>
                          <Grid mb={1} container style={{ marginTop: "30px" }}>
                            <Grid xs={10} md={10}>
                              <Typography item xs={4} variant='h6' gutterBottom>
                                Loan Offer
                              </Typography>
                            </Grid>
                          </Grid>
                          <Card onClick={toggleDropdown}>
                            <CardContent>
                              <div>
                                <Grid container>
                                  <Grid item md={8}>
                                    <Typography style={{ fontWeight: 400, fontSize: "18px" }}>Quantity </Typography>
                                  </Grid>
                                  <Grid item md={4}>
                                    <Typography style={{ fontWeight: 500, fontSize: "18px" }}>{data.balance} MT</Typography>
                                  </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: "10px" }}>
                                  <Grid item md={8}>
                                    <Typography style={{ fontWeight: 400, fontSize: "18px" }}>Rate of Interest </Typography>
                                  </Grid>
                                  <Grid item md={4}>
                                    <Typography style={{ fontWeight: 500, fontSize: "18px" }}>{data.loan_offer_detail.interest_rate}%</Typography>
                                  </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: "10px" }}>
                                  <Grid item md={8}>
                                    <Typography style={{ fontWeight: 400, fontSize: "18px" }}>Market Value</Typography>
                                  </Grid>
                                  <Grid item md={4}>
                                    <Typography style={{ fontWeight: 500, fontSize: "18px" }}>₹ {data.loan_offer_detail.market_value}</Typography>
                                  </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: "10px" }}>
                                  <Grid item md={8}>
                                    <Typography style={{ fontWeight: 400, fontSize: "18px" }}>Loan Amount</Typography>
                                  </Grid>
                                  <Grid item md={4}>
                                    <Typography style={{ fontWeight: 500, fontSize: "18px" }}>₹ {data.loan_offer_detail.loan_amount}</Typography>
                                  </Grid>
                                </Grid>
                              </div>
                              {/* <Divider /> */}
                            </CardContent>
                          </Card>
                          
                        </Box>
                </Grid>
              )
          }
          
          {/*
          <Box elevation={1} sx={{ borderRadius: "5px", textAlign: "center", pt: 0.5, mb:'3rem' }}>
            
            <Button style={{ background: "#FD531D", color: "white", width: "150px" }} onClick={() => setTokenStatus("reject")}>
              Reject
            </Button>
            <Button style={{ background: "#FD531D", color: "white", width: "150px" }} onClick={() => setTokenStatus("reject")}>
            Reject
            </Button>
            {
              data.loan_status==="Pending" && (
                <Link to='/make-loan-offer' state={{ data: passData }}>
                    <Button variant="contained" sx={{ width:"180px", color: "white", marginLeft: "2rem"}} outlined color='primary'>Make Loan offer</Button>
                </Link>
              )
            }
            {
              data.loan_status==="Countered" && (
                <Button style={{ background: "#5A9A07", color: "white", width: "150px", marginLeft: "2rem" }} onClick={() => setTokenStatus("accept")}>   
                  Accept Offer
                </Button>
              )
            }
            
          </Box>
           */}


        </Grid> 
      </Box>
    </Box>
  );
};

export default NBFCLoanRequestDetails;
