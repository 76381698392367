import React from "react";
import { Snackbar, Dialog, DialogActions, DialogTitle, Button, DialogContent, Box } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

function Token(props) {
  const { isOpen, message, onClose } = props;

  return (
    <Dialog open={isOpen} onClose={onClose} style={{ height: "500px" }}>
      <Box sx={{ width: "400px" }}>
        {message == "Token Accepted" ? (
          <div>
            <CheckCircleOutlineIcon style={{ color: "green", fontSize: "50px", margin: "auto", display: "flex", marginTop: "2rem" }} />
            <DialogTitle style={{ textAlign: "center" }}> Token Accepted Succesfully</DialogTitle>
          </div>
        ) : (
          <div>
            <CancelOutlinedIcon style={{ color: "red", fontSize: "50px", margin: "auto", display: "flex", marginTop: "2rem" }} />
            <DialogTitle style={{ textAlign: "center" }}> Token Reject</DialogTitle>
          </div>
        )}

        <DialogContent>{/* You can add additional content to the popup here */}</DialogContent>
        <DialogActions></DialogActions>
      </Box>
    </Dialog>
  );
}

export default Token;
