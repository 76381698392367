import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { GiGrain, GiWeight } from "react-icons/gi";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { MdLocationPin, MdWarehouse, MdCalendarToday } from "react-icons/md";
import ListAltIcon from "@mui/icons-material/ListAlt";
import VerifiedIcon from "@mui/icons-material/Verified";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { MdOutlineVerified } from "react-icons/md";


import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  customCard: {
    backgroundImage: "linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)",
    boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
  },
});

const StatusCard = (props) => {
  const [age, setAge] = React.useState("");
  const classes = useStyles();
  // const [data, setData] = useState(null);
  const { status, loan_status, data } = props;


  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <>
      <Card
        className={classes.customCard}
        elevation={3}
        sx={{
          w: "100%",
          my: 4,
          borderRadius: "10px",
          ":hover": {
            boxShadow: 15,
          },
        }}>
        <CardContent sx={{ p: 3 }}>
          <Box>
            <Grid mb={1} container>
              <Grid xs={10}>
                <Typography item xs={4} variant='h6' color='#214383' gutterBottom>
                  {/* <VerifiedIcon sx={{ mr: 1, pt: 1 }} size='42px' color='black' /> */}
                  Token Code: {data.token_code}
                </Typography>
              </Grid>
              <Grid xs={2}>
                {props.status === "1" && (
                  <Box elevation={1} sx={{ border: "1px solid #f44336", borderRadius: "5px", textAlign: "center", pt: 0.5 }}>
                    <Typography item xs={4} sx={{ fontSize: "1.25rem", fontWeight: "400" }} color='#f44336' gutterBottom>
                      Rejected
                    </Typography>
                  </Box>
                )}

                {props.status === "0" && (
                  <Box elevation={1} sx={{ border: "1px solid #33af4c", borderRadius: "5px", textAlign: "center", pt: 0.5 }}>
                    <Typography item xs={4} sx={{ fontSize: "1.25rem", fontWeight: "400" }} color='#33af4c' gutterBottom>
                      Accepted
                    </Typography>
                  </Box>
                )}

                {props.status === "2" && (
                  <Box elevation={1} sx={{ border: "1px solid #ff5722", borderRadius: "5px", textAlign: "center", pt: 0.5 }}>
                    <Typography item xs={4} sx={{ fontSize: "1.25rem", fontWeight: "400" }} color='#ff5722' gutterBottom>
                      Pending
                    </Typography>
                  </Box>
                )}

                {/* 
                {props.loan_status && (
                  <Box elevation={1} sx={{ border: "1px solid #214383", borderRadius: "5px", textAlign: "center", pt: 0.5 }}>
                    <Typography item xs={4} sx={{ fontSize: "1.25rem", fontWeight: "400" }} color='#214383' gutterBottom>
                      {data.loan_status}
                    </Typography>
                  </Box>
                )}
                */}
                {data.loan_status === "Approved" && (
                  <Box elevation={1} sx={{ border: "1px solid #33af4c", borderRadius: "5px", textAlign: "center", pt: 0.5 }}>
                    <Typography item xs={4} sx={{ fontSize: "1.25rem", fontWeight: "400" }} color='#33af4c' gutterBottom>
                      <span style={{ marginRight: '3px', marginTop: '3px' }}>
                        <MdOutlineVerified />
                      </span> 
                      Approved
                    </Typography>
                  </Box>
                )}

                {data.loan_status === "Accepted" && (
                  <Box elevation={1} sx={{ border: "1px solid #33af4c", borderRadius: "5px", textAlign: "center", pt: 0.5 }}>
                    <Typography item xs={4} sx={{ fontSize: "1.25rem", fontWeight: "400" }} color='#33af4c' gutterBottom>
                      Accepted
                    </Typography>
                  </Box>
                )}

                {data.loan_status === "Rejected" && (
                  <Box elevation={1} sx={{ border: "1px solid #f44336", borderRadius: "5px", textAlign: "center", pt: 0.5 }}>
                    <Typography item xs={4} sx={{ fontSize: "1.25rem", fontWeight: "400" }} color='#f44336' gutterBottom>
                      Rejected
                    </Typography>
                  </Box>
                )}

                {data.loan_status === "Pending" && (
                  <Box elevation={1} sx={{ border: "1px solid #ff5722", borderRadius: "5px", textAlign: "center", pt: 0.5 }}>
                    <Typography item xs={4} sx={{ fontSize: "1.25rem", fontWeight: "400" }} color='#ff5722' gutterBottom>
                      Pending
                    </Typography>
                  </Box>
                )}

                {props.lien_status && (
                  <Box elevation={1} sx={{ border: "1px solid #214383", borderRadius: "5px", textAlign: "center", pt: 0.5 }}>
                    <Typography item xs={4} sx={{ fontSize: "1.25rem", fontWeight: "400" }} color='#214383' gutterBottom>
                      {props.lien_status}
                    </Typography>
                  </Box>
                )}
                

                {props.lienMarked && (
                  <Box elevation={1} sx={{ border: "1px solid #214383", borderRadius: "5px", textAlign: "center", pt: 0.5 }}>
                    <Typography item xs={4} sx={{ fontSize: "1.25rem", fontWeight: "400" }} color='#214383' gutterBottom>
                      Lien Marked
                    </Typography>
                  </Box>
                )} 

                {props.tokenFunded && (
                  <Box elevation={1} sx={{ border: "1px solid #214383", borderRadius: "5px", textAlign: "center", pt: 0.5 }}>
                    <Typography item xs={4} sx={{ fontSize: "1.25rem", fontWeight: "400" }} color='#214383' gutterBottom>
                      Funded
                    </Typography>
                  </Box>
                )} 

                {props.funded && (
                  <Box elevation={1} sx={{ border: "1px solid #214383", borderRadius: "5px", textAlign: "center", pt: 0.5 }}>
                    <Typography item xs={4} sx={{ fontSize: "1.25rem", fontWeight: "400" }} color='#214383' gutterBottom>
                      Funded
                    </Typography>
                  </Box>
                )} 

                {props.fundable && (
                  <Box elevation={1} sx={{ border: "1px solid #33af4c", borderRadius: "5px", textAlign: "center", pt: 0.5 }}>
                    <Typography item xs={4} sx={{ fontSize: "1.25rem", fontWeight: "400" }} color='#33af4c' gutterBottom>
                      Free
                    </Typography>
                  </Box>
                )}

                {props.fundable === false && (
                  <Box elevation={1} xs={5} sx={{ border: "1px solid #ff5722", borderRadius: "5px", textAlign: "center", pt: "0.5rem" }}>
                    <Typography item color='#ff5722' sx={{ fontSize: "1.25rem", fontWeight: "400" }} gutterBottom>
                      Mortgaged
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>

          <Divider />

          <Grid mt={2} container>

            <Grid item xs={8}>


             

              
              <Grid container fullWidth style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <Grid item xs={4} alignItems="center">
                  <Typography sx={{ fontSize: "1.25rem", fontWeight: "400" }}>
                    <span style={{ marginRight: '0.3rem' }}>
                      <GiGrain size='26px' color='black' />
                    </span>
                    Commodity:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography sx={{ fontSize: "1.25rem", fontWeight: "500", mt: 0.8, ml: 1 }}>{data.attributes.commodity.name}</Typography>
                </Grid>
              </Grid>


              <Grid container fullWidth style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <Grid item xs={4} alignItems="center">
                  <Typography sx={{ fontSize: "1.25rem", fontWeight: "400" }}>
                    <span style={{ marginRight: '0.3rem' }}>
                      <LocationOnIcon size='26px' color='black' />
                    </span>
                    Location:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography sx={{ fontSize: "1.25rem", fontWeight: "500", mt: 0.8, ml: 1 }}>{data.attributes.warehouse.location}</Typography>
                </Grid>
              </Grid>

              <Grid container fullWidth style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <Grid item xs={4} alignItems="center">
                  <Typography sx={{ fontSize: "1.25rem", fontWeight: "400" }}>
                    <span style={{ marginRight: '0.3rem' }}>
                      <MdWarehouse size='26px' color='black' />
                    </span>
                    Warehouse:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography sx={{ fontSize: "1.25rem", fontWeight: "500", mt: 0.8, ml: 1 }}>{data.attributes.warehouse.name}</Typography>
                </Grid>
              </Grid>

            </Grid>

            <Grid item xs={4}>
              <Grid container p={0.5}>
                <Grid xs={2}>
                  <GiWeight size='26px' color='black' />
                </Grid>
                
                {
                  data.pledged_amount ? (
                    <Grid xs={8}>
                      <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>{(data.pledged_amount)/1000} MT</Typography>
                    </Grid>
                  ) : (
                    <Grid xs={8}>
                      <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>{(data.attributes.inward_details.inward_weight)/1000} MT</Typography>
                    </Grid>
                  )
                }
                
              </Grid>
              <Grid container p={0.5}>
                <Grid xs={2}>
                  <MdCalendarToday size='26px' color='black' />
                </Grid>
                
                <Grid xs={8}>
                  <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>{data.attributes.inward_details.inward_date.slice(0, 10)}</Typography>
                </Grid>
              </Grid>

              <Grid container p={0.5}>
                <Grid xs={2}>
                  <ListAltIcon size='26px' color='black' />
                </Grid>
                
                <Grid xs={8}>
                  <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>{data.attributes.inward_details.wr_reciept_no}</Typography>
                </Grid>
              </Grid>

              {props.makeOfferButton && (
                <Grid xs={6} mx='auto' mt={3}>
                  <Button variant='contained' fullWidth outlined color='primary'>
                    Make an offer
                  </Button>
                </Grid>
              )}
              {props.applyLoan && (
                <Grid container>
                <Grid item xs={6} mx='auto' pr={2} mt={3}>
                <Button variant='contained' fullWidth outlined color='primary'>
                Apply Loan
                </Button>
                </Grid>
                <Grid item xs={6} mx='auto' mt={3}>
                <Button variant='contained' sx={{ pl:2}} fullWidth outlined color='success'>
                <Typography color="#fff">Mark Lien</Typography>
                </Button>
                </Grid>
                </Grid>
              )}

              {props.markFunded && (
                <Grid xs={8} mx='auto' mt={3}>
                <Button variant='contained' fullWidth outlined color='primary'>
                Mark Funded
                </Button>
                </Grid>
              )}
              
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default StatusCard;
