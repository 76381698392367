import React, { useState, useEffect } from "react";
import HawkEyeAppBar from "./AppBar";
import {
  Box,
  Divider,
  Typography,
  Grid,
  Container,
  Button,
  Chip,
  Card,
  CardContent,
  Radio,
  FormControl,
  FormLabel,
  FormControlLabel
} from "@mui/material";
import { Stepper, Step, StepLabel } from '@mui/material';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { Link } from 'react-router-dom';
import { convertToMillion, convertToCommaSeperated } from "../globals/utils"

import StarAgriLogo from '../assets/staragri-logo.png'
import { makeStyles } from "@mui/styles";
import dayjs from 'dayjs';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import QRCode from "react-qr-code";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    backgroundColor: "#fff"
    // opacity: 0.8,
  },
  borderRight: {
    border:"1px solid #000", 
    borderLeft: 'none',
  },
  borderLeft: {
    border:"1px solid #000", 
    borderRight: 'none',
  },
  borderCenter: {
    border:"1px solid #000", 
  },
  customCard: {
    backgroundImage: "linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)",
    boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
    borderRadius: "1rem",
    border: "2px solid #d8daed",
  },
  subtitleFont: {
    fontSize: "1.5rem",
    fontWeight: "600",
  },
}));

const WarehouseReciept = ({data}) => {
    const classes = useStyles();
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const [qrcode, setQrCode] = useState(false);
    const toggleDropdown = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };

    const handleScan = () => {
      setQrCode(!qrcode);
    };

    const [dateOfTesting, setDateOfTesting] = useState(null);
    const [dateOfReceipt, setDateOfReceipt] = useState(null);

    useEffect(() => {
      const fetchData = () => {
          fetch(`https://www.agrigates.com/gtsEntryForBlockChain.php?status=%22Approved%22&WRNo=${data.attributes.inward_details.wr_reciept_no}`)
          // fetch(`https://www.agrigates.com/gtsEntryForBlockChain.php?status=%22Approved%22&WRNo=2126815`)
          .then(response => response.json())
          .then(data => {
            console.log(data.data[0].date_of_testing)
            if (data && data.data && data.data[0] && data.data[0].date_of_testing) {
              setDateOfTesting(formatDate(data.data[0].date_of_testing));
              setDateOfReceipt(formatDate(data.data[0].date_of_receipt))
            }
          })
          .catch(error => console.error('Error fetching data:', error));
      };
  
      fetchData();
    }, []);

    function formatDate(inputDate) {
        const formattedDate = dayjs(inputDate).format('DD MMM YYYY');
        return formattedDate;
    }

    return (
    
    <Grid className={classes.gridContainer} mb="2rem" mt="3.5rem" py={8} px={6} container fullWidth>
    <Grid fullWidth mx="auto" textAlign="center" alignItems="center">
        <Box flex>
            <img mx="auto" fontWeight={500} alt='StarAgri logo' mt={6} src={StarAgriLogo} style={{ width: "20%" }} />
            <Typography variant="h6">
            Star Agriwarehousing and Collateral Management Limited
            </Typography>
            
            <Typography mt={2} mb={4} fontWeight={500} variant="h6">
                Test Certificate
            </Typography>
        </Box>

    </Grid>
    <Grid container fullWidth>
        <Grid item className={classes.borderCenter} xs={12} px={1} py={1} textAlign="center">
            <Typography fontSize={14}>
            We hereby certify that the quality of above mentioned stock has being deposited at the above mentioned warehouse. 
            The sample was analyzed by us and the result are as follow
            </Typography>
        </Grid>    
    </Grid>
   
    <Grid container fullWidth>
        <Grid item className={classes.borderLeft} xs={12} md={3} px={1} py={1} textAlign="left">
        Depositor Name
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={9} px={1} py={1} textAlign="left">
        {data.owner_name}
        </Grid>  
        <Grid item className={classes.borderLeft} xs={12} md={3} px={1} py={1} textAlign="left">
        Commodity Name
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={9} px={1} py={1} textAlign="left">
        {data.attributes.commodity.name}  
        </Grid>       
        <Grid item className={classes.borderLeft} xs={12} md={3} px={1} py={1} textAlign="left">
        Warehouse Name
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={9} px={1} py={1} textAlign="left">
        {data.attributes.warehouse.name} (Godown No: {data.attributes.truck_details[0].bag_details.gdn_no})
        </Grid>  
        <Grid item className={classes.borderLeft} xs={12} md={3} px={1} py={1} textAlign="left">
        Warehouse Address
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={9} px={1} py={1} textAlign="left">
        {data.attributes.warehouse.warehouse_address}
        </Grid> 
        <Grid item className={classes.borderLeft} xs={12} md={3} px={1} py={1} textAlign="left">
        No. of Bags
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={9} px={1} py={1} textAlign="left">
        {data.attributes.truck_details[0]?.bag_details[0]?.number}
        </Grid>  
        <Grid item className={classes.borderLeft} xs={12} md={3} px={1} py={1} textAlign="left">
        CIS No.
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={9} px={1} py={1} textAlign="left">
        {data.attributes.inward_details.inward_id}
        </Grid>   
        <Grid item className={classes.borderLeft} xs={12} md={3} px={1} py={1} textAlign="left">
        WR no.
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={9} px={1} py={1} textAlign="left">
        {data.attributes.inward_details.wr_reciept_no}
        </Grid> 
        <Grid item className={classes.borderLeft} xs={12} md={3} px={1} py={1} textAlign="left">
        Date of Sampling
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={9} px={1} py={1} textAlign="left">
        {dateOfTesting}
        </Grid>   
        <Grid item className={classes.borderLeft} xs={12} md={3} px={1} py={1} textAlign="left">
        Date of Receipt
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={9} px={1} py={1} textAlign="left">
        {dateOfReceipt}
        </Grid>   
        <Grid item className={classes.borderLeft} xs={12} md={3} px={1} py={1} textAlign="left">
        Date of Testing
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={9} px={1} py={1} textAlign="left">
        {dateOfTesting}
        </Grid>      
        <Grid item className={classes.borderLeft} xs={12} md={3} px={1} py={1} textAlign="left">
        Remarks
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={9} px={1} py={1} textAlign="left">
        {data.attributes.inward_details.remarks}
        </Grid> 
    </Grid>

    <Grid container fullWidth>
        <Grid item className={classes.borderCenter} xs={12} px={1} py={1} textAlign="center">
            <Typography fontSize={16} textAlign="center">
                <b>Test Report</b>
            </Typography>
            <Typography fontSize={14} textAlign="center">
            The above sample was analyzed by us and the results are follows
            </Typography>
        </Grid>  
        <Grid item className={classes.borderLeft} xs={12} md={9} px={1} py={1} textAlign="left">
        <b>Quality parameters</b>
        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={3} px={1} py={1} textAlign="left">
        <b>Result</b>
        </Grid>   
        {data.attributes.commodity.qualities.map((quality, index) => (
          <React.Fragment key={index}>
            <Grid item className={classes.borderLeft} xs={12} md={9} px={1} py={1} textAlign="left">
              {quality.quality_parameters}
            </Grid>
            <Grid item className={classes.borderCenter} xs={12} md={3} px={1} py={1} textAlign="left">
              <b>{quality.result}</b>
            </Grid>
          </React.Fragment>
        ))}



        <Grid>

        <Typography my={1}>
        The quality of goods is <b>Average</b> 
        </Typography>
        </Grid>
    </Grid>
    <Grid md={4}>
    </Grid>
    {/* <Grid item xs={12} md={8} px={2} py={1} mb={2} textAlign="right">
        <Typography>
            Digitally Signed by Karuna Rajesh Mahamulkar, <br />
            Date: 2023-07-05 13:45:09, <br />
            Location: Mumbai, <br />
            Reason: Warehouse Receipt Approval <br />
            <b>Authorized Signatory</b>
        </Typography>
    </Grid>    */}

    <Grid mt={2}>
    <Typography fontSize={12} fontStyle="italic">
    This Report is given to you on the base of best tesing ability. Any discrepancy found in the report should be brought to our notice
    within 48 hours of Receipt of the report. The above results are valid for the date and time of sampling and testing only. Total
    liability or any claim arising out of this report is limited to the invoiced amount only.
    </Typography>
    </Grid>

    <Grid mb={4} className={classes.customCard} mt={6} xs={12}>
    <Box p={4}>
      {
        data.stepper_stage===4 && data.roi && data.disbursement_amount && (
          <Typography mb={2} item xs={4} variant='h6' gutterBottom>
            Disbursement amount: ₹ {convertToCommaSeperated(data.disbursement_amount)}
            <br/>
            Rate of Interest: {data.roi} % p.a.
          </Typography>
        )
      }
      <Typography mb={2} item xs={4} variant='h6' gutterBottom>
        Token Details
      </Typography>
      <Divider />
      <Grid my={2} container>
        <Grid xs={10} md={6}>
          <Typography item xs={4} variant='subtitle1' gutterBottom>
            Token Code: <b>{data.token_code}</b>
          </Typography>
        </Grid>

        <Grid item md={3}>
          <Typography variant='subtitle1'>Quantity</Typography>
        </Grid>
        <Grid item md={3}>
          <Typography variant='subtitle1'>Date of Inward</Typography>
        </Grid>
      </Grid>

      <Card onClick={toggleDropdown}>
        <CardContent>
          <div>
            <Grid container>
              <Grid item md={6}>
                <Grid container>
                  <ExpandMoreIcon className={`${classes.expandIcon} ${isDropdownOpen ? classes.expandIconOpen : ""}`} />

                  <Grid ml={2} item md={6}>
                    <Typography variant='h6'>Warehouse Reciept Number: </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Chip color='primary' label={data.attributes.inward_details.wr_reciept_no} />
                    <Typography variant='h6'></Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Typography variant='h6'></Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3}>
                <Grid container>
                {
                  data.pledged_amount ? (
                    <Typography variant='h5'> {(data.pledged_amount)/1000} MT</Typography>
                  ) : (
                    <Typography variant='h6'> {(data.attributes.inward_details.inward_weight)/1000} MT</Typography>
                  )
                }
                  
                </Grid>
              </Grid>
              <Grid item md={3}>
                <Typography variant='h6'>{data.attributes.inward_details.inward_date.slice(0, 10)}</Typography>
              </Grid>
            </Grid>
          </div>
          {/* <Divider /> */}
        </CardContent>
      </Card>
      {isDropdownOpen && (
        <Box fullWidth className={classes.dropdownContent}>
          <Card style={{ marginTop: "30px" }}>
            <FormControl>
              <div style={{ display: "flex", width: "100%", margin: "10px" }}>
                <FormControlLabel value='female' control={<Radio />} onClick={handleScan} />
                <Typography style={{ marginTop: "8px" }}>Click here to scan the QR and view Token details</Typography>
              </div>
            </FormControl>
            {qrcode ? (
              <Box fullWidth sx={{ marginTop: "20px", mx: "auto", marginBottom: "30px", display: 'flex', justifyContent: 'center' }}>
              <Box style={{ width: "50%", textAlign: "center" }}>
                <QRCode
                  size={128} // Adjust the size as needed
                  value={`https://mainnetgw.newrl.net/get-token?token_code=${data.token_code}`}
                  viewBox={`0 0 256 256`}
                />
                <Link to={`https://mainnetgw.newrl.net/get-token?token_code=${data.token_code}`}>
                  <Typography style={{ textAlign: "center", marginTop: "15px" }}>View Token Details</Typography>
                </Link>
              </Box>
            </Box>
            ) : null}
          </Card>
        </Box>
      )}
    </Box>
    </Grid>

    
    {/*

    <Grid container fullWidth>
        <Grid item className={classes.borderLeft} xs={12} md={2} px={1} py={1} textAlign="center">
        <br/>
        QR Code

        </Grid>
        <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
        
            <QRCode
            size={18}
            style={{ height: "auto", maxWidth: "75%", width: "75%" }}
            value={"http://15.206.209.247:8421/get-token?token_code=7d23943d-6a23-4919-a6a6-56df876ed686" }
            viewBox={`0 0 256 256`}
            />
        </Grid>   
        <Grid item className={classes.borderCenter} xs={12} md={2} px={1} py={1} textAlign="center">
        Digital Signature of authorized signatory
        </Grid>    
        <Grid item className={classes.borderCenter} xs={12} md={6} px={2} py={1} textAlign="left">
            Digitally Signed by Karuna Rajesh Mahamulkar, <br />
            Date: 2023-07-05 13:45:09, <br />
            Location: Mumbai, <br />
            Reason: Warehouse Receipt Approval <br />
        </Grid>     
    </Grid>
    */}
   

    </Grid>
  )
}

export default WarehouseReciept