//Global constants in this file

// export const HOME_PAGE = process.env.PUBLIC_URL;
export const 
HOME_PAGE = "";


export const ASQI_COLOR = "#0e4476";
//Dev
// var _SERVER_BASE_ADDRESS = "http://44.194.185.1:8920/api/agri";
// var _SERVER_BASE_ADDRESS = "http://43.204.99.19:8901/api/agri"

// // For testing
// var _FRONTEND_ADDRESS = "ewr.staragri.com"
// var _SERVER_BASE_ADDRESS = "https://staragri-gateway-api.newrl.net/api/agri";
// export const DASHBOARD_BASE_ADDRESS = "https://staragri-gateway-api.newrl.net/api/dashbaord";


// For prod
var _FRONTEND_ADDRESS = "ewr.staragri.com"
var _SERVER_BASE_ADDRESS = "https://ewrapi.staragri.com/api/agri"
export const DASHBOARD_BASE_ADDRESS = "https://ewrapi.staragri.com/api/dashbaord";





var _GOOGLE_OAUTH_CLIENT_ID = "978566420462-jsrnp54ovfn94q05551b3ge73q413h6m.apps.googleusercontent.com";

// if (process.env.NODE_ENV === "production") {
//   // Prod
//   // _SERVER_BASE_ADDRESS = "http://44.194.185.1:8920/api/agri";
//   // _SERVER_BASE_ADDRESS = "https://staragri-gateway-api.newrl.net/api/agri";
//   // Prod one
//   _SERVER_BASE_ADDRESS = "https://ewrapi.staragri.com/api/agri"
  
//   _GOOGLE_OAUTH_CLIENT_ID =
//     "978566420462-6g0ase60jqiqnta03q6aaoirnovk2e52.apps.googleusercontent.com";
// }


export const SERVER_BASE_ADDRESS = _SERVER_BASE_ADDRESS;
export const GOOGLE_OAUTH_CLIENT_ID = _GOOGLE_OAUTH_CLIENT_ID;
export const FRONTEND_ADDRESS=_FRONTEND_ADDRESS;

// export const getBlockchainNodeUrl() = 'https://devnetapi.newrl.net';
// export let getBlockchainNodeUrl() = 'http://archive1-mainnet.newrl.net:8456';

export const NEWRL_PAY_SERVICE = "https://newrl-pay-dev.herokuapp.com";
export const FAUCET_URL =
  "https://gsd8hwxzw9.execute-api.ap-south-1.amazonaws.com/default/NWRLFaucet";

export const NWRL_DECIMAL_MULTIPLIER = 1000000;

export const changeBlockChain = (chain) => {
  if (chain === "Mainnet") {
    // getBlockchainNodeUrl() = 'http://archive1-mainnet.newrl.net:8456';
    localStorage.setItem("blockchain", "Mainnet");
  } else {
    // getBlockchainNodeUrl() = 'https://devnetapi.newrl.net';
    localStorage.setItem("blockchain", "Testnet");
  }
  // window.location.reload()
};

export const BLOCKCHAINS = {
  Mainnet: {
    node: "https://mainnetgw.newrl.net",
    scanner: "https://newrl-mainnet-scan-backend.herokuapp.com",
    port: 8456,
  },
  Testnet: {
    node: "https://devnetapi.newrl.net",
    scanner: "https://newrl-testnet-scan-backend.herokuapp.com",
    port: 8421,
  },
};

export const getBlockchainNodeUrl = () => {
  let chain = localStorage.getItem("blockchain");
  if (!chain || chain === null) {
    chain = "Mainnet";
    localStorage.setItem("blockchain", "Mainnet");
  }

  return BLOCKCHAINS[chain]["node"];
};

export const getBlockchain = () => {
  let chain = localStorage.getItem("blockchain");
  if (!chain || chain === null) {
    chain = "Mainnet";
    localStorage.setItem("blockchain", "Mainnet");
  }
  return chain;
};

export const getBlockchainPort = () => {
  let chain = localStorage.getItem("blockchain");
  if (!chain || chain === null) {
    chain = "Mainnet";
    localStorage.setItem("blockchain", "Mainnet");
  }

  return BLOCKCHAINS[chain]["port"];
};

export const getBlockchainScanner = () => {
  let chain = localStorage.getItem("blockchain");
  if (!chain || chain === null) {
    chain = "Mainnet";
    localStorage.setItem("blockchain", "Mainnet");
  }

  return BLOCKCHAINS[chain]["scanner"];
};

export const getTransactionToSignFromLocal = () => {
  let txn = localStorage.getItem("transactionToSign");
  try {
    return txn;
  } catch {
    return "";
  }
};

export const convertToFormarDate = (rawDate, newformat = false) => {
  const d = new Date(rawDate);
  if (newformat) {
    let newformattedDate = d
      .toLocaleString("en-us", { timeZone: "UTC", hour12: true })
      .replace(",", "");
    return newformattedDate;
  }

  let formattedDate = d.toLocaleString("en-us", { hour12: true });
  return formattedDate;
};

export const getCurrentActiveScreen = (path) => {
  let re = /^(\/plant-entry-details\/)(?!id)/;
  if (re.test(path)) {
    path = "/plant-entry-details";
  } else {
    let re = /^(\/growers-slip-details\/)(?!id)/;
    if (re.test(path)) {
      path = "/growers-slip-details";
    } else {
      let re = /^(\/view-qc-report\/)(?!id)/;
      if (re.test(path)) {
        path = "/view-qc-report";
      }
    }
  }
  let name = "";
  switch (path) {
    case "/change-password":
      name = "Change Password";
      break;

    // Agri
    case "/add-new-delivery-challen":
      name = "New Delivery Challan";
      break;

    case "/my-delivery-challan":
      name = "My Challans";
      break;

    case "/new-grower-slip":
      name = "New Grower slip";
      break;

    case "/growers-slip-details":
      name = "Grower slip";
      break;

    case "/view-qc-report":
      name = "QC Report";
      break;

    case "/alerts":
      name = "Alerts";
      break;

    // Quality
    case "/confirm-plant-inward":
      name = "Plant Inward";
      break;

    case "/plant-entry-details":
      name = "Inward Details";
      break;

    case "/my-quality-check":
      name = "My Quality Reports";
      break;

    default:
      name = "Home";
      break;
  }

  return name;
};

export function getUserName() {
  let authJson = window.sessionStorage.getItem("auth");
  if (authJson !== null && authJson !== undefined) {
    let auth = JSON.parse(authJson);
    return `${auth.authenticatedUser.name}`;
  }
  return null;
}

export function getUserEmail() {
  let authJson = window.sessionStorage.getItem("auth");
  if (authJson !== null && authJson !== undefined) {
    let auth = JSON.parse(authJson);
    return `${auth.authenticatedUser.email}`;
  }
  return null;
}


export function getFirstName() {
  let authJson = window.sessionStorage.getItem("auth");
  if (authJson !== null && authJson !== undefined) {
    let auth = JSON.parse(authJson);
    return `${auth.user.first_name}`;
  }
  return null;
}
export function getUserId() {
  let authJson = window.sessionStorage.getItem("auth");
  if (authJson !== null && authJson !== undefined) {
    let auth = JSON.parse(authJson);
    return `${auth.user.id}`;
  }
  return null;
}
export function is_new_user() {
  let authJson = JSON.parse(window.sessionStorage.getItem("auth"));
  if (authJson !== null && authJson !== undefined) {
    if (authJson.is_new_user) {
      window.location = "/change-password";
    }
  }
}
