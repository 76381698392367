import React, { useState, useEffect } from "react";
import HawkEyeAppBar from "../../components/AppBar";
import {
  Box,
  Divider,
  Typography,
  Grid,
  Chip,
  Button,
} from "@mui/material";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ListIcon from '@mui/icons-material/List';
import GetAppIcon from '@mui/icons-material/GetApp';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import instance from "../../globals/axios";


import WarehouseReciept from "../../components/WarehouseReciept";
import TestCertificate from "../../components/TestCertificate"


import { makeStyles } from "@mui/styles";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  rootVideo: {
    minWidth: 275,
  },
  titleVideo: {
    fontSize: 20,
  },
  buttonVideo: {
    marginTop: theme.spacing(2),
  },
  cardHeader: {
    backgroundColor: "#0073b1",
    color: "#fff",
    fontWeight: "bold",
  },
  cardBody: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dropdownWrapper: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  dropdown: {
    textDecoration: "underline",
    marginRight: 8,
  },
  expandIcon: {
    transform: "rotate(0deg)",
    transition: "transform 0.2s ease-in-out",
  },
  expandIconOpen: {
    transform: "rotate(180deg)",
  },
  dropdownContent: {
    marginTop: 16,
    paddingLeft: 16,
    // borderLeft: "1px solid #ccc",
  },
  outline: {
    borderRadius:"1rem",
    border:"2px solid #d8daed", 
    backgroundColor: "#fff"
  },
  customCard: {
    backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)',
    boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
    borderRadius: '1rem',
    border:"2px solid #d8daed", 
  },
  subtitleFont: {
    fontSize: '1.5rem',
    fontWeight: '600',
  }
}));



const LienStatusDetails = (props) => {
  const [tabvalue, setTabvalue] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [qrcode, setQrCode] = useState(false);
  const classes = useStyles();
  const [showVideos, setShowVideos] = useState(false);
  // const location = useLocation();
  // const { tokenCode } = location.state;
  const [passData, setPassData] = useState({});
  const location = useLocation();
  const { data } = location.state;
  const [showMoreDetails, setShowMoreDetails] = useState(false);


  // console.log("id", tokenCode);
  const toggleVideos = () => {
    setShowVideos(!showVideos);
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleScan = () => {
    setQrCode(!qrcode);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setPassData(data)
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  
  const [digitalCopyLink, setDigitalCopyLink] = useState(null);

  const handleClick = async () => {

    const wrNo = data.attributes.inward_details.wr_reciept_no;
    const url = `https://www.agrigates.com/gtsEntryForBlockChain.php?url=url_link&wr_no=${wrNo}`;

    axios.get(url)
      .then(response => {
        if (response.data && response.data.data && response.data.data.length > 0) {
          const rawLink = response.data.data[0].digital_copy_link;
          const formattedLink = rawLink.replace(/\\\//g, '/');
          setDigitalCopyLink(formattedLink);
          window.open(formattedLink, '_blank');

        } else {
          alert('Warehouse Receipt Digital Copy not found.');
        }
      })
      .catch(error => {
        console.error('Error:', error.message);
      });
  };



  return (
    <Box fullWidth>
      <HawkEyeAppBar />
      <Box mx='auto' sx={{ bgcolor: "background.paper", padding: "2rem 8rem", marginTop: "4rem" }}>
        <Grid m={1}>
          <Link to='/admin'>
            <Chip icon={<KeyboardBackspaceIcon />} style={{ cursor: "pointer" }} label='Tokens' variant='outlined' />
          </Link>
        </Grid>
        <Divider />
        {
          showMoreDetails ? (
            <TestCertificate data={data} />
          ) :
          (
            <WarehouseReciept data={data} setShowMoreDetails={setShowMoreDetails} />
          )
        }

        <Grid mx="auto" mt={2} mb={8} sx={{ display: "flex", justifyContent: "center"}}>
            
                <Button variant="outlined" sx={{ mx:2, width:"15rem", fontSize: "1rem"}} color='primary' 
                  startIcon={<GetAppIcon />} 
                  onClick={handleClick}
                >
                  Download WR
                </Button>

              <Button variant="contained" sx={{ mx:2, color:"#fff", width:"17rem", fontSize: "1rem"}} color='success' 
                  startIcon={<TaskAltIcon />}
                  onClick={() => {
                    window.location.href = `/audit-trail/${data.attributes.inward_details.wr_reciept_no}`;
                  }}> 
                  Transactions Trail
              </Button>
    
           
            {
              showMoreDetails ? (
                <Button onClick={() => setShowMoreDetails(false)} variant="outlined" sx={{ mx:2, width:"15rem", fontSize: "1rem"}} outlined color='primary' startIcon={<KeyboardBackspaceIcon sx={{ fontSize: '1.75rem' }}/>}>
                Show WR
                </Button>
              ) : (
              <Button onClick={() => setShowMoreDetails(true)} variant="outlined" sx={{ mx:2, width:"15rem", fontSize: "1rem"}} outlined color='primary' endIcon={<ListIcon sx={{ fontSize: '1.75rem' }}/>}>
                More Details
              </Button>
              )
            }
            
        </Grid>

      </Box>
    </Box>
  );
};

export default LienStatusDetails;
