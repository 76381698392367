import React, { useState, useEffect } from "react";
import HawkEyeAppBar from "../../components/AppBar";
import {
  Box,
  Divider,
  Typography,
  Grid,
  Container,
  Button,
  Chip,
} from "@mui/material";
import { Stepper, Step, StepLabel } from '@mui/material';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ListIcon from '@mui/icons-material/List';
import GetAppIcon from '@mui/icons-material/GetApp';


import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import wr from "../../assets/wr_283498.pdf";
import { makeStyles } from "@mui/styles";
import dayjs from 'dayjs';
import WarehouseReciept from "../../components/WarehouseReciept";
import TestCertificate from "../../components/TestCertificate"
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    // backgroundImage: 'url("https://static.vecteezy.com/system/resources/thumbnails/008/854/270/original/abstract-colorful-gradient-animation-background-free-video.jpg")',
    backgroundImage: `url(${require('../../assets/StaragriCertificate.png')})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
    // opacity: 0.8,
  },
  borderRight: {
    border:"1px solid #000", 
    borderLeft: 'none',
  },
  borderLeft: {
    border:"1px solid #000", 
    borderRight: 'none',
  },
  borderCenter: {
    border:"1px solid #000", 
  }
}));

const OpsScreenDetails = (props) => {

  const classes = useStyles();

  const steps = ['WR Generated', 'WR Accepted', 'Lien Marked', 'Lien Unmarked','Outward'];

  const [activeStep, setActiveStep] = useState(2);
  const [showMoreDetails, setShowMoreDetails] = useState(false);

  const [passData, setPassData] = useState({});
  const location = useLocation();
  const { data } = location.state;
  const [ downloadUrl, setDownloadUrl] = ("https://drive.google.com/uc?export=download&id=1ISCcsYmi1fU3TlKEbXSsfYGFxeHlwowx")
  // https://drive.google.com/uc?export=download&id=1ISCcsYmi1fU3TlKEbXSsfYGFxeHlwowx

  useEffect(() => {
    const fetchData = async () => {
      try {
        setPassData(data)
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleDownload = (wr_no) => {
    const link = document.createElement('a');
    console.log(downloadUrl)
    link.href = wr;
    link.download = `WR_${wr_no}.pdf`;
    // link.download = `WR_8923749.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // const [apiUrl, setApiUrl] = useState(`https://staragri-gateway-api.newrl.net/api/agri/getAuditDataFromWR?wr_reciept_no=${data.attributes.inward_details.wr_reciept_no}`);


  function formatDate({ inputDate }) {
    const formattedDate = dayjs(inputDate).format('DD MMM YYYY');
    return formattedDate;
  }

  const [digitalCopyLink, setDigitalCopyLink] = useState(null);

  const handleClick = async () => {

    // const formattedLink = ("https:\/\/prod-agrigates-in.s3.amazonaws.com\/DigitalWarehouseReceipts\/WR_2069198_260364a3abee9b986.pdf").replace(/\\\//g, '/');
    // console.log(formattedLink)
    // const response = await fetch(`http://novademo.agribazaar.com/gtsEntryForBlockChain.php?url=url_link&wr_no=2069198`);


    const wrNo = data.attributes.inward_details.wr_reciept_no;
    const url = `https://www.agrigates.com/gtsEntryForBlockChain.php?url=url_link&wr_no=${wrNo}`;

    axios.get(url)
      .then(response => {
        if (response.data && response.data.data && response.data.data.length > 0) {
          const rawLink = response.data.data[0].digital_copy_link;
          const formattedLink = rawLink.replace(/\\\//g, '/');
          window.open(formattedLink, '_blank');
          setDigitalCopyLink(formattedLink);

        } else {
          alert('Warehouse Receipt Digital Copy not found.');
        }
      })
      .catch(error => {
        console.error('Error:', error.message);
      });
  };


  return (
    <Box fullWidth>
      <HawkEyeAppBar />
      <Box mx='auto' sx={{ bgcolor: "background.paper", padding: "2rem 8rem", marginTop: "4rem" }}>
        <Grid my={2}>
          <Link to='/operations'>
            <Chip icon={<KeyboardBackspaceIcon />} style={{ cursor: "pointer" }} label='Tokens' variant='outlined' />
          </Link>
        </Grid>
        <Divider />

      

        {
          showMoreDetails ? (
            <TestCertificate data={data} />
          ) :
          (
          <WarehouseReciept data={data} setShowMoreDetails={setShowMoreDetails} />
          )
        }

        <Grid mx="auto" mt={2} mb={8} sx={{ display: "flex", justifyContent: "center"}}>
            
                <Button variant="outlined" sx={{ mx:2, width:"15rem", fontSize: "1rem"}} color='primary' 
                  startIcon={<GetAppIcon />} 
                  onClick={handleClick}
                >
                  Download WR
                </Button>
             
              
              <Button variant="contained" sx={{ mx:2, color:"#fff", width:"15rem", fontSize: "1rem"}} color='success' 
                  startIcon={<TaskAltIcon />}
                  onClick={() => {
                    window.location.href = `/audit-trail/${data.attributes.inward_details.wr_reciept_no}`;
                  }}> 
                  Transactions Trail
              </Button>
            
              
           
            {
              showMoreDetails ? (
              
                <Button onClick={() => setShowMoreDetails(false)} variant="outlined" sx={{ mx:2, width:"15rem", fontSize: "1rem"}} outlined color='primary' startIcon={<KeyboardBackspaceIcon sx={{ fontSize: '1.75rem' }}/>}>
                Show WR
                </Button>
              ) : (
              <Button onClick={() => setShowMoreDetails(true)} variant="outlined" sx={{ mx:2, width:"15rem", fontSize: "1rem"}} outlined color='primary' endIcon={<ListIcon sx={{ fontSize: '1.75rem' }}/>}>
                More Details
              </Button>
              )
            }
            
        </Grid>

        
      </Box>
    </Box>
  );
};

export default OpsScreenDetails;
