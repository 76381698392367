import React, { useState, useEffect } from "react";
import HawkEyeAppBar from "../../components/AppBar";
import {
  Box,
  Divider,
  Modal,
  Typography,
  Grid,
  Chip,
  Button,
  Card,
  CardContent,
  TextField
} from "@mui/material";
import { MuiOtpInput } from 'mui-one-time-password-input'


import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import instance from "../../globals/axios";


import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ListIcon from '@mui/icons-material/List';
import GetAppIcon from '@mui/icons-material/GetApp';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import WarehouseReciept from "../../components/WarehouseReciept";
import TestCertificate from "../../components/TestCertificate"
import axios from "axios";



const useStyles = makeStyles(() => ({
  cardHeader: {
    backgroundColor: "#0073b1",
    color: "#fff",
    fontWeight: "bold",
  },
  cardBody: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dropdownWrapper: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  dropdown: {
    textDecoration: "underline",
    marginRight: 8,
  },
  expandIcon: {
    transform: "rotate(0deg)",
    transition: "transform 0.2s ease-in-out",
  },
  expandIconOpen: {
    transform: "rotate(180deg)",
  },
  dropdownContent: {
    marginTop: 16,
    paddingLeft: 16,
    // borderLeft: "1px solid #ccc",
  },
  outline: {
    borderRadius:"1rem",
    border:"2px solid #d8daed", 
    backgroundColor: "#fff"
  },
  customCard: {
    backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)',
    boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
    borderRadius: '1rem',
    border:"2px solid #d8daed", 
  },
  subtitleFont: {
    fontSize: '1.5rem',
    fontWeight: '600',
  }
}));

const AccepReject = () => {
    const [tabvalue, setTabvalue] = useState(0);
    const classes = useStyles();
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const [qrcode, setQrCode] = useState(false);

    const location = useLocation();
    const { data } = location.state;
    // Function to toggle dropdown state
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const handleScan = () => {
        setQrCode(!qrcode);
    };
    const [showMoreDetails, setShowMoreDetails] = useState(false);


    const [tokenStatus, setTokenStatus] = useState("");
    const [loanId, setLoanId] = useState(data.loan_id)

    const [rate, setRate] = useState();
    const [loanAmount, setLoanAmount] = useState(0);
    const [qty, setQty] = useState(data.balance);
    const [otherDetails, setOtherDetails] = useState("");

    const [showOtpField, setShowOtpField] = useState(false);
    const [otp, setOtp] = useState('');

    const requestBody = {
        "loan_offer_id": loanId,
        "action": tokenStatus,
        'qty': qty
    };

    const sendOTP = async () => {

      setTimeout(function () {
        setShowOtpField(true)
      }, 500);
  
    };
  
    const handleChange = (newValue) => {
      setOtp(newValue)
    }

    const counterBody = {
      "loan_offer_id": loanId,
      "qty": qty,
      "rate_of_interest": rate,
      "loan_amount": loanAmount,
      "other_fields": otherDetails
    };
  
    console.log(data)


    const [isPopupOpen, setIsPopupOpen] = useState(false);


    useEffect(() => {
        const submit = async () => {
            console.log("status:",tokenStatus)
            console.log("loan id",loanId)
            try {
            
            const response = await instance.post("loan/acceptRejectLoanOffer", requestBody);
            console.log("ResponseData", response);
            alert(`Offer ${tokenStatus}ed succesfully`);
            
            setTimeout(function () {
              window.location.replace("/admin");
            }, 1500);
    
            } catch (error) {
            console.log(error);
            }
        };
    
        submit();
      }, [tokenStatus]);

    const counterOffer = async () => {
        console.log("counter:",counterBody)

        try {      
          const response = await instance.post("loan/counterLoanOffer", counterBody);
          console.log("ResponseData", response);
          alert(`Offer Countered succesfully`);
          
          setTimeout(function () {
            window.location.replace("/admin");
          }, 500);
        } catch (error) {
        console.log(error);
        }
    };


    const [isOpen, setIsOpen] = useState(false);

    // Function to open the modal
    const openModal = () => {
      setIsOpen(true);
    };
  
    // Function to close the modal
    const closeModal = () => {
      setIsOpen(false);
    };

    const [digitalCopyLink, setDigitalCopyLink] = useState(null);

    const handleClick = async () => {
  
      const wrNo = data.attributes.inward_details.wr_reciept_no;
      const url = `https://www.agrigates.com/gtsEntryForBlockChain.php?url=url_link&wr_no=${wrNo}`;
  
      axios.get(url)
        .then(response => {
          if (response.data && response.data.data && response.data.data.length > 0) {
            const rawLink = response.data.data[0].digital_copy_link;
            const formattedLink = rawLink.replace(/\\\//g, '/');
            setDigitalCopyLink(formattedLink);
            window.open(formattedLink, '_blank');
  
          } else {
            alert('Warehouse Receipt Digital Copy not found.');
          }
        })
        .catch(error => {
          console.error('Error:', error.message);
        });
    };


    return (
      <Box fullWidth>
        <HawkEyeAppBar />
        <Box mx='auto' sx={{ bgcolor: "background.paper", padding: "1.5rem 8rem", marginTop: "50px" }}>
          <Grid  mt="1rem">
            <Link to='/admin'>
              <Chip icon={<KeyboardBackspaceIcon />} style={{ cursor: "pointer" }} label='Admin' variant='outlined' />
            </Link>
          </Grid>
          <Divider />
          <Grid fullWidth>
          {
            showMoreDetails ? (
              <TestCertificate data={data} />
            ) :
            (
              <WarehouseReciept data={data} setShowMoreDetails={setShowMoreDetails} />
            )
          }
            

            <Grid className={classes.customCard} xs={12}>
                    <Box p={4}>
                      <Grid mb={1} container style={{ marginTop: "30px" }}>
                        <Grid xs={10} md={10}>
                          <Typography item xs={4} variant='h6' gutterBottom>
                            Loan Offer
                          </Typography>
                        </Grid>
                      </Grid>
                      <Card onClick={toggleDropdown}>
                        <CardContent>
                          <div>
                            <Grid container>
                              <Grid item md={8}>
                                <Typography style={{ fontWeight: 400, fontSize: "18px" }}>Quantity </Typography>
                              </Grid>
                              <Grid item md={4}>
                                <Typography style={{ fontWeight: 500, fontSize: "18px" }}>{data.balance} MT</Typography>
                              </Grid>
                            </Grid>
                            <Grid container style={{ marginTop: "10px" }}>
                              <Grid item md={8}>
                                <Typography style={{ fontWeight: 400, fontSize: "18px" }}>Rate of Interest </Typography>
                              </Grid>
                              <Grid item md={4}>
                                <Typography style={{ fontWeight: 500, fontSize: "18px" }}>{data.loan_offer_detail.interest_rate}%</Typography>
                              </Grid>
                            </Grid>
                            <Grid container style={{ marginTop: "10px" }}>
                              <Grid item md={8}>
                                <Typography style={{ fontWeight: 400, fontSize: "18px" }}>Market Value</Typography>
                              </Grid>
                              <Grid item md={4}>
                                <Typography style={{ fontWeight: 500, fontSize: "18px" }}>₹ {data.loan_offer_detail.market_value}</Typography>
                              </Grid>
                            </Grid>
                            <Grid container style={{ marginTop: "10px" }}>
                              <Grid item md={8}>
                                <Typography style={{ fontWeight: 400, fontSize: "18px" }}>Loan Amount</Typography>
                              </Grid>
                              <Grid item md={4}>
                                <Typography style={{ fontWeight: 500, fontSize: "18px" }}>₹ {data.loan_offer_detail.loan_amount}</Typography>
                              </Grid>
                            </Grid>
                          </div>
                          {/* <Divider /> */}
                        </CardContent>
                      </Card>
                      
                    </Box>
            </Grid>

            <Grid mx="auto" mt={6} mb={8} sx={{ display: "flex", justifyContent: "center"}}>
              
          

            <Button variant="outlined" sx={{ mx:2, color:"success", width:"15rem", fontSize: "1rem"}} color='success' 
            startIcon={<TaskAltIcon />}
            onClick={() => {
              
              window.location.href = `/audit-trail/${data.attributes.inward_details.wr_reciept_no}`;
            }}> 
            Check Audit Trail
        </Button>

        <Button onClick={() => setTokenStatus("reject")} variant="outlined" sx={{ width:"15rem", mr:2, fontSize: "1rem"}} outlined color='error'>Reject</Button>

        <Button onClick={() => setTokenStatus("accept")} variant="contained" sx={{ width:"15rem", mr:2, color:"#fff", fontSize: "1rem"}} outlined color='success'>Accept</Button>
      
        <Button variant="contained" sx={{ width:"15rem", fontSize: "1rem"}} onClick={openModal}>   
          Counter Offer
        </Button>
    

              <Modal open={isOpen} onClose={closeModal}>
                <Box sx={{  width: "480px", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', borderRadius: 4, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                  <Typography textAlign={"center"} variant="body1" gutterBottom>
                    Counter Loan Offer
                  </Typography>
                  <Grid mt={2} item md={12} mx="auto" flex>
                      <Typography variant="h6" mx="auto">Countered Rate of Interest</Typography>
                      <TextField fullWidth style={{ background: "white", marginTop: "10px" }} 
                            value={rate}
                            onChange={(event) => setRate(event.target.value)}
                            label='Enter Rate (%)' />
                  </Grid>
                  <Grid mt={2}  item md={12} mx="auto" flex>
                      <Typography variant="h6" mx="auto">Countered Loan Amount</Typography>
                          <TextField fullWidth style={{ background: "white", marginTop: "10px" }} 
                            value={loanAmount}
                            onChange={(event) => setLoanAmount(event.target.value)}
                            label='Enter Loan Amount (₹)' />
                  </Grid>
                  <Grid item mb={4} mt={2} md={12}>
                        <Typography vairant="h6" >Other Details</Typography>
                        <TextField multiline fullWidth rows={2} variant='outlined'
                          value={otherDetails}
                          onChange={(event) => setOtherDetails(event.target.value)}
                          style={{ background: "white", marginTop: "10px" }} />
                  </Grid>


                  <Grid my="1rem" item xs={12}>
                    {showOtpField ? (
                        <Button variant="outlined" color="success" fullWidth>
                          Sent
                        </Button>
                      ) : (
                        <Button variant="contained" color="primary" onClick={sendOTP} fullWidth>
                          Send OTP
                        </Button>
                      )}
                  </Grid>
                    {showOtpField && (
                      <Grid item px={10} py={2}  mb="1.25rem" xs={12} >
                    
                        <MuiOtpInput value={otp} onChange={handleChange} />
                      </Grid>
                    )}
              
              
                  {showOtpField ? (
                    <Grid xs={12}>
            
                        <Button variant="outlined" onClick={closeModal}>Close</Button>
                        <Button style={{ background: "#214383", color: "white", width: "150px", marginLeft: "2rem" }} onClick={counterOffer}>   
                          Counter Offer
                        </Button>
                        </Grid>
                      ) : (
                      <Grid xs={12}>
                        <Button variant="outlined" onClick={closeModal}>Close</Button>
                        <Button disabled style={{ background: "#214383", color: "white", width: "150px", marginLeft: "2rem" }} onClick={counterOffer}>   
                          Counter Offer
                        </Button>
                        </Grid>
                      )}



                
                </Box>
                
              </Modal>
        
          {
            showMoreDetails ? (
              <Button onClick={() => setShowMoreDetails(false)} variant="outlined" sx={{ mx:2, width:"15rem", fontSize: "1rem"}} outlined color='primary' startIcon={<KeyboardBackspaceIcon sx={{ fontSize: '1.75rem' }}/>}>
              Show WR
              </Button>
            ) : (
            <Button onClick={() => setShowMoreDetails(true)} variant="outlined" sx={{ mx:2, width:"15rem", fontSize: "1rem"}} outlined color='primary' endIcon={<ListIcon sx={{ fontSize: '1.75rem' }}/>}>
              More Details
            </Button>
            )
          }
          
            </Grid>


  {/* 
            <Box elevation={1} sx={{ borderRadius: "5px", textAlign: "center", pt: 0.5, mb: "3rem" }}>
              <Typography item xs={4} sx={{ fontSize: "1.25rem", fontWeight: "400" }} color='#33af4c' gutterBottom></Typography> 

              <Button style={{ background: "#FD531D", color: "white", width: "150px" }} onClick={() => setTokenStatus("reject")}>
                Reject Offer
              </Button>
              <Button style={{ background: "#5A9A07", color: "white", width: "150px", marginLeft: "2rem" }} onClick={() => setTokenStatus("accept")}>   
                Accept Offer
              </Button>
              <Button style={{ background: "#214383", color: "white", width: "150px", marginLeft: "2rem" }} onClick={openModal}>   
                Counter Offer
              </Button>

              <Modal open={isOpen} onClose={closeModal}>
                <Box sx={{  width: "480px", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', borderRadius: 4, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                  <Typography textAlign={"center"} variant="body1" gutterBottom>
                    Counter Loan Offer
                  </Typography>
                  <Grid mt={2} item md={12} mx="auto" flex>
                      <Typography variant="h6" mx="auto">Countered Rate of Interest</Typography>
                      <TextField fullWidth style={{ background: "white", marginTop: "10px" }} 
                            value={rate}
                            onChange={(event) => setRate(event.target.value)}
                            label='Enter Rate (%)' />
                  </Grid>
                  <Grid mt={2}  item md={12} mx="auto" flex>
                      <Typography variant="h6" mx="auto">Countered Loan Amount</Typography>
                          <TextField fullWidth style={{ background: "white", marginTop: "10px" }} 
                            value={loanAmount}
                            onChange={(event) => setLoanAmount(event.target.value)}
                            label='Enter Loan Amount (₹)' />
                  </Grid>
                  <Grid item mb={4} mt={2} md={12}>
                        <Typography vairant="h6" >Other Details</Typography>
                        <TextField multiline fullWidth rows={2} variant='outlined'
                          value={otherDetails}
                          onChange={(event) => setOtherDetails(event.target.value)}
                          style={{ background: "white", marginTop: "10px" }} />
                  </Grid>


                  <Grid my="1rem" item xs={12}>
                    {showOtpField ? (
                        <Button variant="outlined" color="success" fullWidth>
                          Sent
                        </Button>
                      ) : (
                        <Button variant="contained" color="primary" onClick={sendOTP} fullWidth>
                          Send OTP
                        </Button>
                      )}
                  </Grid>
                    {showOtpField && (
                      <Grid item px={10} py={2}  mb="1.25rem" xs={12} >
                    
                        <MuiOtpInput value={otp} onChange={handleChange} />
                      </Grid>
                    )}
              
              
                  {showOtpField ? (
                    <Grid xs={12}>
            
                        <Button variant="outlined" onClick={closeModal}>Close</Button>
                        <Button style={{ background: "#214383", color: "white", width: "150px", marginLeft: "2rem" }} onClick={counterOffer}>   
                          Counter Offer
                        </Button>
                        </Grid>
                      ) : (
                      <Grid xs={12}>
                        <Button variant="outlined" onClick={closeModal}>Close</Button>
                        <Button disabled style={{ background: "#214383", color: "white", width: "150px", marginLeft: "2rem" }} onClick={counterOffer}>   
                          Counter Offer
                        </Button>
                        </Grid>
                      )}



                
                </Box>
                
              </Modal>
                    

            </Box>
  */}

          </Grid> 
        </Box>
      </Box>
    );
};

export default AccepReject;
