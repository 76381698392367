import React from 'react'
import { Grid, TextField, Button } from '@mui/material'

const SearchBarCard = () => {
  const [wrNumber, setWRNumber] = React.useState('');

  return (
    <Grid container justifyContent="center" alignItems="center" py={3} px={4} >

                        <Grid xs={7} item mx="auto" >

                          <TextField
                            label="Search By Warehouse Receipt Number"
                            variant="outlined"
                            fullWidth
                            value={wrNumber}
                            onChange={(e) => setWRNumber(e.target.value)}
                          />
                        </Grid>
                        <Grid mx="auto" py="auto" my="auto" justifyContent="center" alignItems="center" item xs={3}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  if (wrNumber) {
                                    const url = `/wr-details/${wrNumber}`;
                                    window.open(url, '_blank');
                                  }
                                }}
                                style={{ marginTop: '10px' }}
                                  >
                                  Get Warehouse Receipt
                              </Button>
                            
                        </Grid>
                       
                        </Grid>
  )
}

export default SearchBarCard