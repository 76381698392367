import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import HawkEyeAppBar from "../../components/AppBar";
import {
  Box,
  Divider,
  Typography,
  Grid,
  Chip,
  Button,
  CircularProgress,
} from "@mui/material";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ListIcon from '@mui/icons-material/List';
import GetAppIcon from '@mui/icons-material/GetApp';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'; // Import the error icon from Material-UI


import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import instance from "../../globals/axios";
import axios from "axios";

import WarehouseReciept from "../../components/WarehouseReciept";
import TestCertificate from "../../components/TestCertificate"

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { red } from '@mui/material/colors';

import VerifiedIcon from '@mui/icons-material/Verified';
import MarkFundedPopup from "../../components/Popup/MarkFunded"
import MarkUnfundedPopup from "../../components/Popup/MarkUnfunded"



import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  rootVideo: {
    minWidth: 275,
  },
  titleVideo: {
    fontSize: 20,
  },
  buttonVideo: {
    marginTop: theme.spacing(2),
  },
  cardHeader: {
    backgroundColor: "#0073b1",
    color: "#fff",
    fontWeight: "bold",
  },
  cardBody: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dropdownWrapper: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  dropdown: {
    textDecoration: "underline",
    marginRight: 8,
  },
  expandIcon: {
    transform: "rotate(0deg)",
    transition: "transform 0.2s ease-in-out",
  },
  expandIconOpen: {
    transform: "rotate(180deg)",
  },
  dropdownContent: {
    marginTop: 16,
    paddingLeft: 16,
    // borderLeft: "1px solid #ccc",
  },
  outline: {
    borderRadius:"1rem",
    border:"2px solid #d8daed", 
    backgroundColor: "#fff"
  },
  customCard: {
    backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)',
    boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
    borderRadius: '1rem',
    border:"2px solid #d8daed", 
  },
  subtitleFont: {
    fontSize: '1.5rem',
    fontWeight: '600',
  }
}));



const DataFromWR = (props) => {

  const classes = useStyles();
  const [showVideos, setShowVideos] = useState(false);
  // const location = useLocation();
  // const { tokenCode } = location.state;
  const { wr_reciept_no } = useParams();
  const [data, setData] = useState(null);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("An error occurred. Please try again.")
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
        try {
          const response = await instance.get(`/token/getTokenDataByWR?wr_receipt_no=${wr_reciept_no}`);
          console.log("ResponseData", response.data.data);
          setData(response.data.data[0]);
          setLoading(false)
        } 
        catch (error) {
          setLoading(false)
          if (error.response && error.response.status === 422) {
            alert("WR not found. Please try again."); 
            setError("Warehouse Receipt with requested WR number not found.")
          } else {
            alert("An error occurred. Please try again."); // Alerting the user about the error
          }
        }
      };
      fetchData();
  }, []);

  const [digitalCopyLink, setDigitalCopyLink] = useState(null);

  const [openAlert, setOpenAlert] = React.useState(false);

  const handleClick = async () => {

    const wrNo = data.attributes.inward_details.wr_reciept_no;
    const url = `https://www.agrigates.com/gtsEntryForBlockChain.php?url=url_link&wr_no=${wrNo}`;

    axios.get(url)
      .then(response => {
        if (response.data && response.data.data && response.data.data.length > 0) {
          const rawLink = response.data.data[0].digital_copy_link;
          const formattedLink = rawLink.replace(/\\\//g, '/');
          setDigitalCopyLink(formattedLink);
          window.open(formattedLink, '_blank');

        } else {
          setOpenAlert(true)
          // alert('Warehouse Receipt Digital Copy not found.');
        }
      })
      .catch(error => {
        setOpenAlert(true)
        console.error('Error:', error.message);
      });
  };


  return (
    <Box fullWidth>
      <HawkEyeAppBar />
      <Box mx='auto' sx={{ bgcolor: "background.paper", padding: "2rem 8rem", marginTop: "4rem" }}>
        <Grid my={2}>
          <Link to='/banker'>
            <Chip icon={<KeyboardBackspaceIcon />} style={{ cursor: "pointer" }} label='View WRs' variant='outlined' />
          </Link>
        </Grid>
        <Divider />
        
        {
          loading && (
            <Box sx={{ textAlign: "center", mx: "auto", my:"3rem"}}>
              <Typography variant="h4">Loading... 
              <br/>
              <CircularProgress sx={{ mt: "1.5rem"}} /></Typography>
            </Box>
          ) 
        }

        {
          !loading && (data === null || data === undefined ) && (
            <Box sx={{ textAlign: "center", mx: "auto", my: "3rem" }}>
                <Grid xs={8} sx={{ px: 6 }} >
                    <Alert
                      severity="info"
                      sx={{ mx: 6 }}
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                    <AlertTitle>Digital Copy Not Available </AlertTitle>
                    This Warehouse Reciept is not avaiable online, for offline copy please contact StarAgri Warehouse Collateral Manager
                    </Alert>
                  
            </Grid>
            </Box>
          )
        }


          
        { data && data?.length !== 0 && data !== undefined &&
          (
            
              showMoreDetails ? (
                <TestCertificate data={data} />
              ) :
              (
                <WarehouseReciept data={data} setShowMoreDetails={setShowMoreDetails} />
              )
            
          )
        }

        { console.log("asdcs",data)}
        {
          data === null || data === undefined || data?.length === 0 ? (
            <Box sx={{ textAlign: "center", mx: "auto", my:"3rem"}}>
                <Grid xs={8} sx={{ px: 6 }} >
                  <Collapse in={openAlert}>
                    <Alert
                      severity="info"
                      sx={{ mx: 6 }}
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpenAlert(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                    <AlertTitle>Digital Copy Not Available </AlertTitle>
                    This Warehouse Reciept is not avaiable online, for offline copy please contact StarAgri Warehouse Collateral Manager
                    </Alert>
                  </Collapse>
                  
            </Grid>
            </Box>
          ) : (
            <Grid style={{ display: data?.length > 0 ? 'none' : 'block' }}>

            <Grid container spacing={2} mx="auto" xs={12} sx={{ justifyContent: "center"}}>
               
            
                      <Grid item xs={5}>
                        <Button  fullWidth variant="outlined" sx={{ fontSize: "1rem"}} color='primary' 
                            startIcon={<GetAppIcon />} 
                            onClick={handleClick}
                          >
                            Download WR
                        </Button>
                      </Grid>

                      
                      <Grid item xs={5}>

                          {
                            showMoreDetails ? (
                              <Button fullWidth onClick={() => setShowMoreDetails(false)} variant="outlined" sx={{ fontSize: "1rem"}} outlined color='primary' startIcon={<KeyboardBackspaceIcon sx={{ fontSize: '1.75rem' }}/>}>
                              Show WR
                              </Button>
                            ) : (
                            <Button fullWidth onClick={() => setShowMoreDetails(true)} variant="outlined" sx={{ fontSize: "1rem"}} outlined color='primary' endIcon={<ListIcon sx={{ fontSize: '1.75rem' }}/>}>
                              More Details
                            </Button>
                            )
                          }
                        
                      </Grid>
            </Grid>
            <Grid container spacing={2} mx="auto" xs={12} mt={1} mb={8} sx={{ justifyContent: "center"}}>
            <Grid item xs={5}>
                        <Button fullWidth variant="contained" sx={{ color:"#fff",  fontSize: "1rem"}} color='success' 
                          startIcon={<TaskAltIcon />}
                          onClick={() => {
                            window.location.href = `/audit-trail/${data.attributes.inward_details.wr_reciept_no}`;
                          }}> 
                          Check Audit Trail
                        </Button>
                        
            </Grid>

            <Grid item xs={5}>
            {
              data.stepper_stage === 3 && (
                <React.Fragment>
                <Button fullWidth variant="contained" sx={{ color:"#fff", fontSize: "1rem"}} color='primary' startIcon={<VerifiedIcon />} onClick={() => setPopoverOpen(true)}>
                  Mark Funded             
                </Button>
                            <MarkFundedPopup data={data} open={popoverOpen} onClose={() => setPopoverOpen(false)} />
                </React.Fragment>

              )
            }
            {
              data.stepper_stage === 4 && (
                <React.Fragment>

                <Button fullWidth variant="contained" sx={{ color:"#fff", fontSize: "1rem"}} color='primary' startIcon={<VerifiedIcon />} onClick={() => setPopoverOpen(true)}>
                  Mark Unfunded             
                </Button>
                <MarkUnfundedPopup data={data} open={popoverOpen} onClose={() => setPopoverOpen(false)} />
                </React.Fragment>
              )
            }
            
            </Grid>

            </Grid>
            
            </Grid>
          )
        }
        

     

      </Box>
    </Box>
  );
};

export default DataFromWR;
