import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Link } from "react-router-dom";

import ListAltIcon from '@mui/icons-material/ListAlt';
import VerifiedIcon from "@mui/icons-material/Verified";

import { GiGrain, GiWeight } from "react-icons/gi";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { MdLocationPin, MdWarehouse, MdCalendarToday } from "react-icons/md";

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  customCard: {
    backgroundImage: 'linear-gradient(to right bottom, #ffffff, #fefefe, #fcfcfe, #fbfbfd, #f9fafd, #f7f8fc, #f4f6fb, #f2f4fa, #eff1f8, #eceff7, #e8ecf5, #e5eaf3)',
    boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
  },
});


const AcceptRejectCard = (props) => {
  const classes = useStyles();
  const { status, makeOfferButton, data } = props;


  return (
    <Card className={classes.customCard} elevation={3} 
    sx={{ w:"100%", my:4, borderRadius:'10px', 
          ':hover': {
            boxShadow: 15,
          },
    }}>    
        <CardContent sx={{ p: 3 }}>
          <Box>
            <Grid mb={1} container>
              <Grid xs={10}>
                <Typography item xs={4} variant='h6' color='#214383' gutterBottom>
                  {/* <VerifiedIcon sx={{ mr: 1, pt: 1 }} size='42px' color='black' /> */}
                  Token Code: {data.token_code}
                </Typography>
              </Grid>
              <Grid xs={2}>
                {props.status === "1" && (
                  <Box elevation={1} sx={{ border: "1px solid #f44336", borderRadius: "5px", textAlign: "center", pt: 0.5 }}>
                    <Typography item xs={4} sx={{ fontSize: "1.25rem", fontWeight: "400" }} color='#f44336' gutterBottom>
                      Rejected
                    </Typography>
                  </Box>
                )}

                {props.status === "0" && (
                  <Box elevation={1} sx={{ border: "1px solid #33af4c", borderRadius: "5px", textAlign: "center", pt: 0.5 }}>
                    <Typography item xs={4} sx={{ fontSize: "1.25rem", fontWeight: "400" }} color='#33af4c' gutterBottom>
                      Accepted
                    </Typography>
                  </Box>
                )}

                {props.status === "2" && (
                  <Box elevation={1} sx={{ border: "1px solid #ff5722", borderRadius: "5px", textAlign: "center", pt: 0.5 }}>
                    <Typography item xs={4} sx={{ fontSize: "1.25rem", fontWeight: "400" }} color='#ff5722' gutterBottom>
                      Pending
                    </Typography>
                  </Box>
                )}

                {props.status === "Accepted" && (
                  <Box elevation={1} sx={{ border: "1px solid #33af4c", borderRadius: "5px", textAlign: "center", pt: 0.5 }}>
                    <Typography item xs={4} sx={{ fontSize: "1.25rem", fontWeight: "400" }} color='#33af4c' gutterBottom>
                      Accepted
                    </Typography>
                  </Box>
                )}

                {props.status === "Rejected" && (
                  <Box elevation={1} sx={{ border: "1px solid #f44336", borderRadius: "5px", textAlign: "center", pt: 0.5 }}>
                    <Typography item xs={4} sx={{ fontSize: "1.25rem", fontWeight: "400" }} color='#f44336' gutterBottom>
                      Rejected
                    </Typography>
                  </Box>
                )}

                {props.status === "Pending" && (
                  <Box elevation={1} sx={{ border: "1px solid #ff5722", borderRadius: "5px", textAlign: "center", pt: 0.5 }}>
                    <Typography item xs={4} sx={{ fontSize: "1.25rem", fontWeight: "400" }} color='#ff5722' gutterBottom>
                      Pending
                    </Typography>
                  </Box>
                )}

                {props.fundable && (
                  <Box elevation={1} sx={{ border: "1px solid #33af4c", borderRadius: "5px", textAlign: "center", pt: 0.5 }}>
                    <Typography item xs={4} sx={{ fontSize: "1.25rem", fontWeight: "400" }} color='#33af4c' gutterBottom>
                      Free
                    </Typography>
                  </Box>
                )}

                {props.fundable === false && (
                  <Box elevation={1} xs={5} sx={{ border: "1px solid #ff5722", borderRadius: "5px", textAlign: "center", pt: "0.5rem" }}>
                    <Typography item color='#ff5722' sx={{ fontSize: "1.25rem", fontWeight: "400" }} gutterBottom>
                      Mortgaged
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>

          <Divider />
          
          <Grid mt={2} container>
            <Grid item xs={6}>
              <Grid container p={0.5}>
                <Grid xs={1}>
                  <GiGrain size='26px' color='black' />
                </Grid>
                <Grid xs={3}>
                  <Typography sx={{ fontSize: "1.25rem", fontWeight: "400" }}>Commodity:</Typography>
                </Grid>
                <Grid xs={8}>
                  <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>{data.attributes.commodity.name}</Typography>
                </Grid>
              </Grid>
              <Grid container p={0.5}>
                <Grid xs={1}>
                  <MdLocationPin size='26px' color='black' />
                </Grid>
                <Grid xs={3}>
                  <Typography sx={{ fontSize: "1.25rem", fontWeight: "400" }}>Location:</Typography>
                </Grid>
                <Grid xs={8}>
                  <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>{data.attributes.warehouse.location}</Typography>
                </Grid>
              </Grid>
              <Grid container p={0.5}>
                <Grid xs={1}>
                  <MdWarehouse size='26px' color='black' />
                </Grid>
                <Grid xs={3}>
                  <Typography sx={{ fontSize: "1.25rem", fontWeight: "400" }}>Warehouse:</Typography>
                </Grid>
                <Grid xs={8}>
                  <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>
                  {data.attributes.warehouse.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container p={0.5}>
                <Grid xs={1}>
                  <GiWeight size='26px' color='black' />
                </Grid>
                <Grid xs={3}>
                  <Typography sx={{ fontSize: "1.25rem", fontWeight: "400" }}>Quantity:</Typography>
                </Grid>
                <Grid xs={8}>
                  <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>{(data.attributes.inward_details.inward_weight)/1000} MT</Typography>
                </Grid>
              </Grid>
              <Grid container p={0.5}>
                <Grid xs={1}>
                  <MdCalendarToday size='26px' color='black' />
                </Grid>
                <Grid xs={3}>
                  <Typography sx={{ fontSize: "1.25rem", fontWeight: "400" }}>Inward Date: </Typography>
                </Grid>
                <Grid xs={8} >
                  <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}> {data.attributes.inward_details.inward_date.slice(0, 10)}</Typography>
                </Grid>
              </Grid>
              <Grid container p={0.5}>
                <Grid xs={1}>
                  <ListAltIcon size='26px' color='black' />
                </Grid>
                <Grid xs={4}>
                  <Typography sx={{ fontSize: "1.25rem", fontWeight: "400" }}>Inward Number:</Typography>
                </Grid>
                <Grid xs={7}>
                  <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>{data.attributes.inward_details.inward_number}</Typography>
                </Grid>
              </Grid>

              {
                props.status === "Pending" &&
                (<Grid container>
                  <Grid xs={6} px={4} mt={3}>
                      <Button variant="contained" fullWidth outlined color='error'>
                      <Typography variant="subtitle1" color="white">
                        Reject
                      </Typography>
                      </Button>
                  </Grid>  
                  <Grid fullWidth px={4} xs={6} mt={3} >
                      <Button variant="contained" fullWidth color='success'>
                      <Typography variant="subtitle1" color="white">
                        Accept
                      </Typography>
                      </Button>
                  </Grid>              
                </Grid>)
                
              }
              
            </Grid>
          </Grid>
        </CardContent>

  </Card>
  )
}


export default AcceptRejectCard

